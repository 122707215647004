import React, { useState } from 'react';

import opportunitiesClient from 'api/opportunitiesClient';

import useListUpdate from 'hooks/useListUpdate';
import {
  ManageDocuments,
  useDocumentSave,
  EditDocument,
} from 'views/Documents';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import DialogWithTitle from 'components/ui/DialogWithTitle';

const EditOpportunityDocuments = ({
  opportunity,
  onUpdate,
  canEdit = false,
}) => {
  const { id: opportunityId, documents = [], isAdmin } = opportunity;
  const { onListUpdate } = useListUpdate('documents', onUpdate);

  const withSave = useDocumentSave({
    params: { opportunityId },
    create: opportunitiesClient.createDocument,
    update: opportunitiesClient.updateDocument,
    remove: opportunitiesClient.deleteDocument,
    onChange: onListUpdate,
  });

  const [edit, setEdit] = useState();
  return (
    <Box>
      <Box>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs>
            <Box>
              <Typography variant="h6" color="primary">
                Financial Statements
              </Typography>

              <Typography variant="caption">
                This is where you put your Balance Sheet, Income Statement,
                Financial Projections and any other Auxillary Documents.
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              {edit ? (
                <DialogWithTitle
                  open
                  onClose={() => setEdit(null)}
                  title={`${(edit || {}).id ? 'Edit' : 'Add'} Document`}
                  fullWidth
                >
                  <EditDocument
                    onClose={() => setEdit(null)}
                    {...{ data: edit, withSave }}
                  />
                </DialogWithTitle>
              ) : null}
              {isAdmin && canEdit && (
                <Box marginTop={2}>
                  <Button variant="contained" onClick={() => setEdit({})}>
                    Add Document
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <ManageDocuments
          {...{
            documents,
            withSave,
            canEdit,
          }}
        ></ManageDocuments>
      </Box>
    </Box>
  );
};

export default EditOpportunityDocuments;
