import React, { useCallback, useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import { LoadingArea } from 'components/ui/Loading';

import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';
import { useArrayUpdate } from 'hooks/useListUpdate';
import SaveFormButton from 'components/Form/SaveFormButton';
import groupsClient from 'api/groups/groupsClient';

const ShareOpportunity = ({ opportunityId, groupId, onClose }) => {
  const [groups, setGroups] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await opportunitiesClient.sharingNetwork({
          id: opportunityId,
          groupId,
        });

        setGroups(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (opportunityId && groupId) {
      fetchData();
    }
  }, [opportunityId, groupId]);

  const { onListUpdate } = useArrayUpdate(setGroups);

  const onSave = useCallback(
    async (onError) => {
      try {
        const selected = groups.filter((x) => x.checked).map((x) => x.id);
        await groupsClient.shareOpportunity({
          id: groupId,
          data: {
            opportunityId,
            groupIds: selected,
          },
        });
        onClose();
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
      }
    },
    [groupId, groups, onClose, opportunityId],
  );

  if (!groups) {
    return (
      <CenteredContentBox>
        <LoadingArea style={{ backgroundColor: 'transparent' }} open />
      </CenteredContentBox>
    );
  }
  return (
    <Box marginLeft={2}>
      <Box>
        <Grid container justify="center" spacing={4}>
          <Grid item xs={6}>
            <Box>
              <Typography>Shared With</Typography>
            </Box>
            <Box height="15rem" style={{ overflowY: 'auto' }}>
              {groups
                .filter((x) => x.existing || x.checked)
                .map((group) => (
                  <Box key={group.id}>
                    <CustomCheckBox
                      label={group.name}
                      checked={true}
                      disabled={group.existing}
                      onChange={() => {
                        onListUpdate({ ...group, checked: !group.checked });
                      }}
                    />
                  </Box>
                ))}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box>
              <Typography>Available Groups</Typography>
            </Box>
            <Box height="15rem" style={{ overflowY: 'auto' }}>
              {groups
                .filter((x) => !x.existing && !x.checked)
                .map((group) => (
                  <Box key={group.id}>
                    <Box>
                      <CustomCheckBox
                        label={group.name}
                        onChange={() => {
                          onListUpdate({ ...group, checked: !group.checked });
                        }}
                      />
                    </Box>
                    <Box marginLeft={4}>
                      <Typography variant="caption">
                        {group.contactEmail}
                      </Typography>
                    </Box>
                  </Box>
                ))}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box padding={4}>
        <Grid container justify="center" spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton
              variant="contained"
              onSave={onSave}
              name={`Add Groups`}
              disabled={!groups.some((x) => x.checked)}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default ShareOpportunity;
