import React, { useState } from 'react';

import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import useListUpdate from 'hooks/useListUpdate';
import {
  ManageFeaturedInvestors,
  useFeaturedSave,
  EditFeaturedInvestor,
} from 'views/FeaturedInvestors';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import DialogWithTitle from 'components/ui/DialogWithTitle';

const EditFeaturedInvestors = ({
  opportunity,
  onUpdate,
  onError,
  readOnly = false,
}) => {
  const { id: opportunityId, featured = [], isAdmin } = opportunity;
  const { onListUpdate } = useListUpdate('featured', onUpdate);
  const onDelete = async (id) => {
    try {
      await opportunitiesClient.deleteFeaturedInvestor({ id });
      onListUpdate({ id, isDeleted: true }, 'Delete Successful');
    } catch (e) {
      console.log(getServerResponseErrors(e));
      if (onError) onError(e, 'Delete Failed');
    }
  };
  const withSave = useFeaturedSave({
    params: { opportunityId },
    create: opportunitiesClient.createFeaturedInvestor,
    update: opportunitiesClient.updateFeaturedInvestor,
    remove: opportunitiesClient.deleteFeaturedInvestor,
    onChange: onListUpdate,
  });

  const [editInvestor, setEditInvestor] = useState();

  return (
    <Box>
      <Box>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs>
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                Key Investors
              </Typography>
              <Typography variant="caption">
                Add any investors that you would like to feature for the
                opportunity.
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            {editInvestor ? (
              <DialogWithTitle
                open
                onClose={() => setEditInvestor(null)}
                title={`${
                  (editInvestor || {}).id ? 'Edit' : 'Add'
                } Key Investor`}
              >
                <EditFeaturedInvestor
                  onClose={() => setEditInvestor(null)}
                  {...{ data: editInvestor, withSave }}
                />
              </DialogWithTitle>
            ) : null}
            {isAdmin && !readOnly && (
              <Box marginTop={2}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setEditInvestor({})}
                >
                  Add Key Investor
                </Button>
              </Box>
            )}
          </Grid>
        </Grid>
      </Box>
      <Box>
        <ManageFeaturedInvestors
          {...{
            featured,
            withSave,
            onDelete,
            isAdmin,
            readOnly,
            emptyText: 'Featured investor(s) from prior round(s)',
          }}
        ></ManageFeaturedInvestors>
      </Box>
    </Box>
  );
};

export default EditFeaturedInvestors;
