import React, { useMemo, useState } from 'react';

import { Box, Grid } from '@material-ui/core';
import Button from 'components/ui/Button/Button';

import { useDocument } from 'components/ui/DocumentPicker';
import { DialogWithActions } from 'components/ui/DialogWithTitle';
import SaveFormButton from 'components/Form/SaveFormButton';

const useSmartDocument = ({ onSave, getLink }) => {
  const [modal, showModal] = useState(false);

  const popup = useMemo(() => {
    if (!modal) {
      return null;
    }

    return (
      <SmartDocumentEdit {...{ document: modal, onSave, showModal, getLink }} />
    );
  }, [getLink, modal, onSave]);

  return { modal: popup, showModal };
};

const SmartDocumentEdit = ({ document, onSave, showModal, getLink }) => {
  const { id } = document || {};
  const { currentDocument, documentPicker } = useDocument(
    document,
    'file',
    getLink,
  );

  return (
    <DialogWithActions
      {...{
        title: `${id ? 'Edit' : 'Add'} Document`,
        fullWidth: true,
        footer: (
          <Box padding={2}>
            <Grid container spacing={3} justify="space-between">
              <Grid item>
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => showModal()}
                >
                  Cancel
                </Button>
              </Grid>
              <Grid item>
                <SaveFormButton
                  name="Upload"
                  onSave={async (onError) => {
                    if (
                      await onSave({ ...document, ...currentDocument }, onError)
                    ) {
                      showModal();
                      return true;
                    }
                  }}
                  disabled={!currentDocument}
                />
              </Grid>
            </Grid>
          </Box>
        ),
        onClose: () => showModal(),
      }}
    >
      <Box>{documentPicker}</Box>
    </DialogWithActions>
  );
};

export default useSmartDocument;
