import React, { useState, useContext, useCallback } from 'react';
import {
  Box,
  Grid,
  Menu,
  MenuItem,
  Typography,
  useTheme,
  useMediaQuery,
  makeStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';

import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { GroupContext } from 'components/UserAuth/UserAuth';
import { useDispatch } from 'react-redux';
import { setSelectedGroup } from 'redux/currentUser/actions';
import { useSelectedGroup } from 'hooks/useAppState';

const useStyles = makeStyles((theme) => ({
  dropdown: {
    borderRadius: '100px',
    border: `1px solid ${theme.palette.grey[400]}`,
    width: '100%',
    borderColor: theme.palette.grey[400],
    color: '#4F4F4F',
    '&:hover': {
      borderColor: '#4547DC',
      cursor: 'pointer',
    },
    '&:active': {
      borderColor: theme.palette.accent.main,
    },
  },
  open: {
    borderColor: theme.palette.accent.main,
  },
}));

const selectionLookup = (path) => {
  if (path.indexOf('/user/dashboard') !== -1) {
    return 'Dashboard';
  } else if (path == '/user/actions') {
    return 'Dashboard';
  } else if (path == '/user/profile') {
    return 'Dashboard';
  }
  return 'Temp';
};

const NavigationMenu = () => {
  const classes = useStyles();
  const theme = useTheme();
  const isMedium = useMediaQuery(theme.breakpoints.only('md'));
  const isSmall = useMediaQuery(theme.breakpoints.only('sm'));
  const isTiny = useMediaQuery(theme.breakpoints.only('xs'));

  const history = useHistory();
  const handleGroupSwitchClick = useCallback(
    async (group) => {
      setSwitchGroupAnchorEl(null);
      history.push(`/${group.slug}/`);
    },
    [history],
  );

  const [switchGroupAnchorEl, setSwitchGroupAnchorEl] = useState();

  const { groups } = useContext(GroupContext) || {};

  const sortedList = groups?.sort((a, b) =>
    a?.name.toLowerCase() > b?.name.toLowerCase() ? 1 : -1,
  );

  const navigate = async (path) => {
    setSwitchGroupAnchorEl(null);
    await dispatch(setSelectedGroup());
    history.push(path);
  };

  const dispatch = useDispatch();
  const { isValid, name } = useSelectedGroup((state) => ({
    name: state.name,
    isValid: state.isValid,
  }));

  const selection = selectionLookup(history.location.pathname);

  return (
    <>
      <Menu
        id="simple-menu"
        anchorEl={switchGroupAnchorEl}
        keepMounted
        open={Boolean(switchGroupAnchorEl)}
        onClose={() => setSwitchGroupAnchorEl(null)}
        getContentAnchorEl={null}
        anchorOrigin={{
          vertical: 'bottom',
        }}
      >
        <Box paddingLeft={0} paddingTop={2}>
          <MenuItem onClick={() => navigate(`/user/dashboard`)}>
            Dashboard
          </MenuItem>
        </Box>
        <Box paddingLeft={2} paddingTop={2}>
          Groups
        </Box>
        <Box paddingLeft={2}>
          {sortedList?.map((g, index) => (
            <MenuItem
              key={`group-select-${index}`}
              onClick={() => handleGroupSwitchClick(g)}
            >
              {g.name}
            </MenuItem>
          ))}
        </Box>
      </Menu>

      <Box
        onClick={(e) => setSwitchGroupAnchorEl(e.currentTarget)}
        paddingLeft={1}
        marginRight={isTiny ? 1 : 0}
        title="Switch investment group"
        className={clsx(classes.dropdown, {
          [classes.open]: Boolean(switchGroupAnchorEl),
        })}
      >
        <Grid container justify="space-between" alignItems="center">
          <Grid item>
            <Box paddingX={1}>
              <Typography
                style={{
                  maxWidth: isMedium
                    ? '240px'
                    : isSmall
                    ? '200px'
                    : isTiny
                    ? '100%'
                    : '550px',
                }}
                noWrap
              >
                {isValid ? name : selection}
              </Typography>
            </Box>
            <Box></Box>
          </Grid>
          <Grid item justify="center">
            <Box
              height="100%"
              paddingX={0.75}
              marginLeft={1}
              style={{ display: 'flex' }}
            >
              <ExpandMoreIcon />
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default NavigationMenu;
