import React from 'react';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import { formatter } from './formatterUtils';

const MinimumsCard = ({ unitPrice, minimumUnits, editClick }) => {
  return (
    <Paper variant="outlined" square style={{ padding: '1rem' }}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Investment Details:
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" size="small" onClick={editClick}>
            Edit
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Price Per Unit</i>
            <br />
            {formatter.format(unitPrice)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Minimum Units</i>
            <br />
            {minimumUnits}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Minimum Investment</i>
            <br />
            {formatter.format(unitPrice * minimumUnits)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default MinimumsCard;
