import { useSelector, shallowEqual } from 'react-redux';

export const useFlags = (filterFunc) => {
  const selector = useSelector((state) => {
    const profile = state.currentUser.flags || {};
    return filterFunc({
      ...profile,
      isValid: !isNaN(profile.id),
    });
  }, shallowEqual);
  return selector;
};

export const useProfile = (filterFunc) => {
  const selector = useSelector((state) => {
    const profile = state.currentUser.data || {};
    return filterFunc({
      ...profile,
      isValid: !isNaN(profile.id),
    });
  }, shallowEqual);
  return selector;
};

export const useSelectedGroup = (
  filterFunc = (state) => (state.isValid ? state : null),
) => {
  const selector = useSelector((state) => {
    const group = state.currentUser.selectedGroup || {};

    const temp = {
      ...group,
      isValid: group.id && !!group.slug,
    };
    temp.defaultRoute = temp.isAdmin
      ? `/${temp.slug}/admin`
      : `/${temp.slug}/opportunities`;
    return filterFunc(temp);
  }, shallowEqual);
  return selector;
};
