import React from 'react';

import Box from '@mui/material/Box';
import Tooltip from '@mui/material/Tooltip';
import Hidden from '@mui/material/Hidden';

import CheckCircleRoundedIcon from '@material-ui/icons/CheckCircleRounded';
import ErrorRoundedIcon from '@material-ui/icons/ErrorRounded';

const InvestorStatus = ({ status, email }) => {
  if (status === 'suspended') {
    return (
      <Tooltip
        title={`${email} has been suspended. They can still access previous investments but will not be able to invest in new rounds.`}
      >
        <Box whiteSpace="nowrap">
          <ErrorRoundedIcon
            fontSize="small"
            color="disabled"
            style={{ verticalAlign: 'top' }}
          />
          <Hidden smDown>
            <Box marginLeft={1} display="inline">
              Suspended
            </Box>
          </Hidden>
        </Box>
      </Tooltip>
    );
  } else if (status === 'confirmed') {
    return (
      <Box whiteSpace="nowrap">
        <CheckCircleRoundedIcon
          fontSize="small"
          color="primary"
          style={{ verticalAlign: 'top' }}
        />
        <Hidden smDown>
          <Box marginLeft={1} display="inline">
            Access
          </Box>
        </Hidden>
      </Box>
    );
  } else if (status === 'pending') {
    return (
      <Tooltip title={`${email} has not accepted the invitation yet`}>
        <Box whiteSpace="nowrap">
          <ErrorRoundedIcon
            fontSize="small"
            color="action"
            style={{ verticalAlign: 'top' }}
          />
          <Hidden smDown>
            <Box marginLeft={1} display="inline">
              Invited
            </Box>
          </Hidden>
        </Box>
      </Tooltip>
    );
  } else if (status === 'rejected') {
    return (
      <Tooltip title={`${email} has been reject.`}>
        <Box whiteSpace="nowrap">
          <ErrorRoundedIcon
            fontSize="small"
            color="disabled"
            style={{ verticalAlign: 'top' }}
          />
          <Hidden smDown>
            <Box marginLeft={1} display="inline">
              Rejected
            </Box>
          </Hidden>
        </Box>
      </Tooltip>
    );
  }
  return (
    <Tooltip title={`${email} has Requested Access and needs confirmed`}>
      <Box whiteSpace="nowrap">
        <ErrorRoundedIcon
          fontSize="small"
          color="action"
          style={{ verticalAlign: 'top' }}
        />
        <Hidden smDown>
          <Box marginLeft={1} display="inline">
            Requested<Hidden mdDown> Access</Hidden>
          </Box>
        </Hidden>
      </Box>
    </Tooltip>
  );
};

export default React.memo(InvestorStatus);
