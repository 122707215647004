import apiClient from 'api/axios';

const index = ({ syndicateId, searchQuery }) => {
  return apiClient.get(`/api/v1/syndicates/${syndicateId}/tax_documents`, {
    params: {
      searchQuery,
    },
  });
};

const create = ({ syndicateId, data }) => {
  return apiClient.post(`/api/v1/syndicates/${syndicateId}/tax_documents`, {
    taxDocument: data,
  });
};

const get = ({ id }) => {
  return apiClient.get(`/api/v1/tax_documents/${id}`, {
    params: {},
  });
};

const update = ({ id, data }) => {
  return apiClient.patch(`/api/v1/tax_documents/${id}`, {
    taxDocument: data,
  });
};

const destroy = ({ id }) => {
  return apiClient.delete(`/api/v1/tax_documents/${id}`);
};

const link = ({ id }) => {
  return apiClient.get(`/api/v1/tax_documents/${id}/link`, {
    params: {},
  });
};

const taxDocumentsClient = {
  index,
  get,
  create,
  update,
  destroy,
  link,
};

export default taxDocumentsClient;
