import { useCallback, useState, useRef, useEffect } from 'react';

const useMore = () => {
  const [showMore, setMore] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const nodeRef = useRef();
  const checkMore = () => {
    const node = nodeRef.current;
    if (node !== null) {
      const more = node.scrollHeight > node.offsetHeight;
      setHasMore(more);
      if (!more) {
        setMore(false);
      }
    }
  };
  const setRef = useCallback((node) => {
    nodeRef.current = node;
    checkMore();
  }, []);
  useEffect(() => {
    window.addEventListener('resize', checkMore);
    return () => {
      window.removeEventListener('resize', checkMore);
    };
  }, []);

  return [showMore, hasMore, setMore, setRef, checkMore];
};

export default useMore;
