import React, { useCallback, useEffect, useState } from 'react';

import { useFormikContext } from 'formik';
import useDebouncedHandler from 'hooks/useDebounce';
import slugify from 'slugify';
import { CustomTextField } from 'components/ui/CustomTextField';
import { LoadingSpinner } from 'components/ui/Loading';
import { InputAdornment } from '@material-ui/core';
import getServerResponseErrors from 'api/getServerResponseErrors';
import groupsClient from 'api/groups/groupsClient';
import CheckIcon from '@material-ui/icons/Check';
import ErrorIcon from '@material-ui/icons/Error';
import { Alert } from '@material-ui/lab';

const EditSlug = ({ id, originalSlug, slug, setSlug }) => {
  const { values, setFieldValue } = useFormikContext();
  const { groupName } = values;

  const [temp, setTemp] = useState(originalSlug);

  useEffect(() => {
    if (!id) {
      setTemp(slugify(groupName || '', { lower: true }));
    }
  }, [groupName, id]);

  const handleChange = (e) => {
    const val = e.target.value;
    setTemp(val);
  };

  const checkSlug = useCallback(
    async (val) => {
      if (val) {
        try {
          const response = await groupsClient.getBySlug(val);
          if (response.data) {
            setError(`Portal ${val} is already taken`);
          }
        } catch (e) {
          if (e.response.status == 404) {
            setSlug(val);
          } else {
            console.log(getServerResponseErrors(e));
            setError(`Portal ${val} is already taken`);
          }
        }
      }
    },
    [setSlug],
  );
  const debounceValidate = useDebouncedHandler(checkSlug, 500);
  const [error, setError] = useState();
  useEffect(() => {
    if (temp == originalSlug) {
      setSlug(temp);
    } else if (temp && temp != originalSlug) {
      setSlug(null);
      setError(null);
      debounceValidate(temp);
    }
  }, [debounceValidate, originalSlug, setSlug, temp]);

  useEffect(() => {
    if (slug == temp) {
      setFieldValue('slug', temp);
    }
  }, [slug, setFieldValue, temp]);

  return (
    <>
      <CustomTextField
        label="Your portal URL"
        variant="filled"
        startAdornment={
          <InputAdornment position="start">
            https://app.smartcapitalx.com/
          </InputAdornment>
        }
        endAdornment={
          temp ? (
            <InputAdornment position="end">
              {error ? (
                <ErrorIcon />
              ) : slug ? (
                <CheckIcon />
              ) : (
                <LoadingSpinner />
              )}
            </InputAdornment>
          ) : null
        }
        onChange={handleChange}
        value={temp}
        required
      />
      {error && (
        <Alert severity="error" onClose={() => setError(null)}>
          <div>{error}</div>
        </Alert>
      )}
    </>
  );
};

export default EditSlug;
