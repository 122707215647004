import React from 'react';
import { Box } from '@material-ui/core';
import DashboardTodoWidget from './Summary/DashboardTodoWidget';

const UserDashboardTodo = () => {
  return (
    <Box style={{ height: '100%' }}>
      <Box>
        <DashboardTodoWidget showViewAll={false} />
      </Box>
    </Box>
  );
};

export default UserDashboardTodo;
