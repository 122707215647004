import React, { useState } from 'react';
import { Box } from '@material-ui/core';

import Form from 'components/Form/Form';

import { validateForm } from 'components/Form/SaveFormButton';

import { FormikTextInput } from 'components/ui/CustomTextField';

const useFormUserInvestmentNote = ({ data, formId = 0 }) => {
  const [formRef, setFormRef] = useState({});

  const validate = async () => {
    const {
      values: { text },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        text,
      },
    };
  };

  const form = (
    <Form
      key={formId}
      initialValues={data || { text: '' }}
      enableReinitialize
      setRef={setFormRef}
    >
      <Box>
        <FormikTextInput
          variant="filled"
          name="text"
          label="Note"
          multiline
          rows={4}
        />
      </Box>
    </Form>
  );

  return { form, validate };
};

export default useFormUserInvestmentNote;
