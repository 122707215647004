import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';

import getServerResponseErrors from 'api/getServerResponseErrors';
import DialogWithTitle from 'components/ui/DialogWithTitle';

import userInvestmentsClient from 'api/userInvestmentsClient';
import InterestWizard from './InterestWizard';
import SubscriptionWizard from './SubscriptionDetails/SubscriptionWizard';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import { LoadingSpinner } from 'components/ui/Loading';
import useStateNotification from 'hooks/useStateNotification';

const InvestWidget = ({ opportunity, investment }) => {
  const [wizard, showWizard] = useState(false);
  const [showNo, setNo] = useState();

  const {
    id: investmentId,
    myInvestmentId,
    syndicateId,
    dateClosed,
    status,
    disableSubscriptions,
    dateFiledOfferingAuthorization,
  } = investment || {};

  const [userInvestment, setUserInvestment] =
    useStateNotification('user_investments');
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.get({
          id: myInvestmentId,
        });
        setUserInvestment(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (myInvestmentId) {
      fetchData();
    } else {
      setUserInvestment(null);
    }
  }, [myInvestmentId, setUserInvestment]);

  if (userInvestment === undefined) {
    return null;
  }
  if (dateClosed) {
    return null;
  }

  const { id, dateReviewed, interestAmount, requestedUnits, acceptedUnits } =
    userInvestment || {};

  const handleNo = async () => {
    try {
      setNo(true);
      const upsert = id
        ? userInvestmentsClient.update
        : userInvestmentsClient.create;

      const response = await upsert({
        id,
        investmentId,
        data: {
          interestAmount: -1,
          isUndecided: false,
          requestedUnits: 0,
        },
      });
      // onChange(response.data);
      setUserInvestment(response.data);
      setNo('done');
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
    }
  };
  // const handleMaybe = async () => {
  //   try {
  //     if (!isUndecided || interestAmount < 0) {
  //       const upsert = id
  //         ? userInvestmentsClient.update
  //         : userInvestmentsClient.create;

  //       const response = await upsert({
  //         id,
  //         investmentId,
  //         data: {
  //           interestAmount: interestAmount < 0 ? 0 : interestAmount,
  //           isUndecided: true,
  //         },
  //       });
  //       setUserInvestment(response.data);
  //     }
  //     showWizard('maybe');
  //   } catch (e) {
  //     const error = getServerResponseErrors(e);
  //     console.log(error);
  //   }
  // };
  const canInvest =
    status == 'Accepting Investments' && (!dateClosed || dateReviewed);

  const { photo } = opportunity;
  return (
    <>
      {showNo && (
        <DialogWithTitle
          open
          onClose={() => setNo(false)}
          title="Investment Interest"
          fullWidth
        >
          {showNo === 'done' ? (
            <Box>
              <Box height="10rem">Thanks for your feedback</Box>
              <CenteredContentBox>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setNo(false)}
                >
                  Got it!
                </Button>
              </CenteredContentBox>
            </Box>
          ) : (
            <CenteredContentBox>
              <LoadingSpinner></LoadingSpinner>
            </CenteredContentBox>
          )}
        </DialogWithTitle>
      )}
      {wizard && (
        <DialogWithTitle
          open
          onClose={() => showWizard(false)}
          title={canInvest ? 'Invest' : 'Express Interest'}
          fullWidth
        >
          {canInvest ? (
            <SubscriptionWizard
              {...{
                onClose: () => showWizard(false),
                onChange: setUserInvestment,
                userInvestment,
                investmentId,
                photo,
                syndicateId,
              }}
            />
          ) : (
            <InterestWizard
              {...{
                onClose: () => showWizard(false),
                onChange: setUserInvestment,
                photo,
                investment,
                userInvestment,
                isMaybe: wizard === 'maybe',
              }}
            />
          )}
        </DialogWithTitle>
      )}
      {requestedUnits > 0 || canInvest ? (
        disableSubscriptions && acceptedUnits == 0 ? null : (
          <>
            {canInvest && !dateReviewed && (
              <Button
                variant={interestAmount < 0 ? 'contained' : 'outlined'}
                color={interestAmount < 0 ? 'default' : 'inherit'}
                style={{ marginRight: '1rem' }}
                onClick={handleNo}
              >
                {interestAmount < 0 ? 'Passed' : 'Pass'}
              </Button>
            )}
            <Button
              variant="contained"
              color="primary"
              onClick={() => showWizard(true)}
            >
              {acceptedUnits > 0 ? 'Edit Investment' : 'Invest'}
            </Button>
          </>
        )
      ) : dateClosed ||
        (dateFiledOfferingAuthorization && disableSubscriptions) ? null : (
        <>
          <Button
            variant={interestAmount < 0 ? 'contained' : 'outlined'}
            color={interestAmount < 0 ? 'default' : 'inherit'}
            style={{ marginRight: '1rem' }}
            onClick={handleNo}
          >
            {interestAmount < 0 ? 'Passed' : 'Pass'}
          </Button>
          <Button
            variant="contained"
            color="primary"
            onClick={() => showWizard(true)}
          >
            {interestAmount > 0 ? 'Change Interest' : 'Express Interest'}
          </Button>
        </>
      )}
    </>
  );
};

export default InvestWidget;
