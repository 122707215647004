import PropTypes from 'prop-types';
import React from 'react';
import { Box } from '@material-ui/core';

const CenteredContentBox = ({
  children,
  fullWidth,
  horizontal,
  vertical,
  ...rest
}) => {
  return (
    <Box
      minHeight="100%"
      display="flex"
      alignItems={vertical ? 'center' : null}
      justifyContent={horizontal ? 'center' : null}
      {...rest}
    >
      <Box width={fullWidth ? '100%' : 'initial'}>{children}</Box>
    </Box>
  );
};

CenteredContentBox.propTypes = {
  alignItems: PropTypes.bool,
  children: PropTypes.any,
  fullWidth: PropTypes.bool,
  horizontal: PropTypes.bool,
  justifyContent: PropTypes.bool,
  vertical: PropTypes.bool,
};

CenteredContentBox.defaultProps = {
  vertical: true,
  horizontal: true,
};

export default CenteredContentBox;
