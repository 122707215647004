import React, { useCallback, useState } from 'react';
import { Grid, Box, Link } from '@material-ui/core';

import { useField } from 'formik';
import DeleteIcon from '@material-ui/icons/Delete';
import { CustomTextField } from 'components/ui/CustomTextField';
import * as yup from 'yup';
import { CustomCheckBox } from './CustomCheckBox';

const parseManagers = (value) => {
  const parseManager = (item, index) => {
    const vals = item.replace('|,|', ',').split('|');
    return {
      text: vals[0] || '',
      email: vals[1] || '',
      primary: vals[2] === 'true' || false,
      index,
    };
  };
  const managers = (value || '')
    .split(', ')
    .map((item, index) => parseManager(item, index));
  return managers;
};
const managersToText = (managers) => {
  const text = managers
    .filter((x) => `${x.text}${x.email}` !== '')
    .map(
      (x) =>
        `${x.text.replace('|', '').replace(',', '|,|')}|${x.email}|${
          x.primary
        }`,
    )
    .join(', ')
    .trim();
  return text;
};
const FormikNameEmailList = ({
  label,

  addLabel,
  ...props
}) => {
  // eslint-disable-next-line

  let nameScheme = yup
    .string()
    .required(`${label ? label : 'Field'} Name is required`);
  let emailScheme = yup
    .string()
    .required(`${label ? label : 'Field'} Email is required`)
    .email(`${label} Email is invalid`);

  const validate = async (value) => {
    try {
      const managers = parseManagers(value);
      for (const manager of managers) {
        await nameScheme.validate(manager.text);
        await emailScheme.validate(manager.email);
      }
    } catch (err) {
      return err.message;
    }
  };
  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField({ ...props, validate });

  const { value } = field;
  const { setValue, setTouched } = helpers;
  const [items, setItems] = useState(parseManagers(value));

  const doUpdate = useCallback(() => {
    setItems((current) => {
      const val = managersToText(current);
      setValue(val);
      setTouched(true);
      return current;
    });
  }, [setTouched, setValue]);

  // useEffect(() => {
  //   const timer = setTimeout(doUpdate, 200);
  //   return () => {
  //     clearTimeout(timer);
  //   };
  // }, [items, doUpdate]);

  const handleInput = useCallback((event, index, field) => {
    const val = field === 'primary' ? event.target.checked : event.target.value;
    setItems((current) => {
      const existing = current.find((x) => x.index === index) || {
        index,
        text: '',
        email: '',
        primary: false,
      };
      const others = current.filter((x) => x.index !== index);
      if (field === 'primary') {
        others.forEach((x) => {
          x.primary = false;
        });
      }
      const update = [...others, { ...existing, [field]: val }];
      const sorted = update.sort((a, b) =>
        a.index < b.index ? -1 : a.index === b.index ? 0 : 1,
      );
      return sorted;
    });
  }, []);

  const deleteManager = useCallback(
    (index) => {
      setItems((current) => {
        const existing = current.find((x) => x.index === index) || {
          index,
          text: '',
          email: '',
          primary: false,
        };
        const others = current.filter((x) => x.index !== index);
        if (existing.primary && others.length > 0) {
          others[0].primary = true;
        }
        return others;
      });
      doUpdate();
    },
    [doUpdate],
  );

  // const handleInput = (event, index, field) => {
  //   const val = event.target.value;
  //   const existing = items.find((x) => x.index === index) || {
  //     index,
  //     text: '',
  //     email: '',
  //   };
  //   existing[field] = val;
  //   const update = [...items.filter((x) => x.index !== index), existing];
  //   const sorted = update.sort((a, b) =>
  //     a.index < b.index ? -1 : a.index === b.index ? 0 : 1,
  //   );
  //   debugger;
  //   setItems(sorted);
  // };

  return (
    <Box marginBottom={3}>
      {items.length > 1 && (
        <Box padding={1}>
          Please select which Manager will be designated as the primary signor
          for syndicate documents. This will be the entity or individual that
          will be responsible for signing documents such as the subscription
          agreement on behalf of the syndicate.
        </Box>
      )}
      {items &&
        items.map((item) => (
          <>
            <Grid key={item.id} container spacing={3}>
              <Grid item xs={12} sm={12} md={4}>
                <CustomTextField
                  label={`${label} Name`}
                  variant="filled"
                  value={item.text}
                  onChange={(event) => {
                    handleInput(event, item.index, 'text');
                  }}
                  onBlur={(event) => {
                    handleInput(event, item.index, 'text');
                    doUpdate();
                  }}
                />
              </Grid>
              <Grid item xs={10} sm={10} md={5}>
                <CustomTextField
                  label={`${label} Email`}
                  variant="filled"
                  value={item.email}
                  onChange={(event) => {
                    handleInput(event, item.index, 'email');
                  }}
                  onBlur={(event) => {
                    handleInput(event, item.index, 'email');
                    doUpdate();
                  }}
                />
              </Grid>
              {items.length > 1 && (
                <Grid item xs={2}>
                  <CustomCheckBox
                    name="primary"
                    label={`Primary`}
                    variant="filled"
                    checked={item.primary}
                    onChange={(event) => {
                      handleInput(event, item.index, 'primary');
                    }}
                    onBlur={(event) => {
                      handleInput(event, item.index, 'primary');
                      doUpdate();
                    }}
                  ></CustomCheckBox>
                  <Box>
                    <Link
                      style={{ cursor: 'pointer', color: 'black' }}
                      onClick={() => deleteManager(item.index)}
                    >
                      <DeleteIcon
                        style={{ verticalAlign: 'middle' }}
                      ></DeleteIcon>
                      &nbsp;Delete
                    </Link>
                  </Box>
                </Grid>
              )}
            </Grid>
          </>
        ))}
      <Box marginTop={1}>
        <Link
          variant="contained"
          color="primary"
          onClick={() =>
            setItems((current) => [
              ...current,
              {
                text: '',
                email: '',
                primary: false,
                index: Math.max(...current.map((x) => x.index)) + 1,
              },
            ])
          }
        >
          + Add {addLabel}
        </Link>
      </Box>
    </Box>
  );
};

export default FormikNameEmailList;
