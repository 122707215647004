import React, { useState, useCallback } from 'react';
import flattenDeep from 'lodash/flattenDeep';
import {
  Button,
  Snackbar,
  Backdrop,
  CircularProgress,
  Box,
  Tooltip,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';

// export const getForm = (formRef, onlyTouched) => {
//   if (!formRef || !formRef.current) return {};
//   if (onlyTouched) {
//     const values = {};
//     Object.keys(formRef.current.touched).forEach((field) => {
//       values[field] = formRef.current.values[field];
//     });
//     return values;
//   }
//   return formRef.current.values;
// };

export const validateForm = async (formRef) => {
  if (!formRef || !formRef.current)
    return { isValid: false, values: {}, errors: [] };
  const form = formRef.current;
  let errors = [];
  const formErrors = await form.validateForm();
  if (Object.keys(formErrors).length > 0) {
    form.setTouched(formErrors, false);
    errors = Object.values(formErrors);
  }
  return {
    isValid: errors.length === 0,
    values: form.values,
    errors,
    formErrors,
  };
};

const SaveFormButton = ({
  onSave,
  name,
  AlertWrapper = Snackbar,
  hideAlerts = false,
  buttonRef = null,
  hideSuccess = false,
  helperText = null,
  disabledText = null,
  disabled = false,
  children,
  ...others
}) => {
  const [success, setSuccess] = useState(false);
  const [isSaving, setSaving] = useState(false);
  const [minTime, setMinTime] = useState(false);
  const [error, setError] = useState();

  const saveForm = useCallback(async () => {
    setMinTime(true);
    const timerPromise = new Promise((resolve) => {
      setTimeout(() => {
        setMinTime(false);
        resolve();
      }, 500);
    });

    setSaving(true);
    setError(null);
    setSuccess(false);

    let hasError = false;
    const onError = (error) => {
      console.log(error);
      const message = Array.isArray(error)
        ? flattenDeep(error)
            .filter((e) => e !== null && e !== undefined)
            .map((x) => (typeof x == 'object' ? Object.values(x) : x))
            .join(', ')
        : error;
      setError(message);
      hasError = true;
    };
    try {
      if ((await onSave(onError, timerPromise)) && !hasError) {
        setSuccess(true);
      }
    } catch (e) {
      onError(`Unknown Error: ${e.message}`);
    }
    await timerPromise;
    setSaving(false);
  }, [onSave]);

  const Wrapper = helperText || (disabled && disabledText) ? Tooltip : 'span';
  return (
    <>
      <Wrapper title={disabled ? disabledText || helperText : helperText}>
        <span>
          <Button
            color="primary"
            onClick={() => saveForm()}
            {...others}
            ref={buttonRef}
            disabled={disabled}
          >
            {name || children || 'Save'}
          </Button>
        </span>
      </Wrapper>
      {(isSaving || minTime) && (
        <Backdrop open style={{ zIndex: 3 }}>
          <CircularProgress color="inherit" />
        </Backdrop>
      )}
      {!hideAlerts && (
        <>
          {error && (
            <AlertWrapper open onClose={() => setError(null)}>
              <Box marginTop={2}>
                <Alert severity="error" onClose={() => setError(null)}>
                  <div>There was an error: {error}</div>
                </Alert>
              </Box>
            </AlertWrapper>
          )}
          {success && !hideSuccess && (
            <AlertWrapper open onClose={() => setSuccess(false)}>
              <Box marginTop={2}>
                <Alert onClose={() => setSuccess(false)} severity="success">
                  {name || 'Save'} Success!
                </Alert>
              </Box>
            </AlertWrapper>
          )}
        </>
      )}
    </>
  );
};

export default SaveFormButton;
