import React from 'react';
import { Grid, Box, Typography, Button } from '@material-ui/core';

import useSyndicateFiling from 'views/GroupInvestment/SyndicateTaxes/useSyndicateFiling';
import useDeleteConfirm from 'hooks/useDeleteConfirm';
import syndicateFilingsClient from 'api/syndicateFilingsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

const SyndicateFilingItem = ({ filing, onChange }) => {
  const { id, document, name, canDelete } = filing;
  const {
    modal: uploadModal,
    showModal: showUpload,
    openLink,
  } = useSyndicateFiling({
    id,
    onChange,
  });

  const onDelete = async (onError) => {
    try {
      const response = await syndicateFilingsClient.destroy({ id });
      if (response) {
        onChange({ ...filing, isDeleted: true });
      }
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };
  const { modal: deleteModal, showModal: showDelete } = useDeleteConfirm({
    onConfirm: onDelete,
    headerName: filing.name,
  });

  return (
    <>
      {uploadModal}
      {deleteModal}
      <Grid container spacing={3}>
        <Grid item xs>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            {name}
          </Typography>
        </Grid>
        <Grid item xs></Grid>
        <Grid item>
          {document ? (
            <Box>
              <Button
                onClick={() => showUpload(document)}
                style={{ marginRight: '1rem' }}
              >
                Change
              </Button>
              <Button onClick={openLink}>Review</Button>
            </Box>
          ) : (
            <Box>
              {canDelete && (
                <Button
                  variant="contained"
                  onClick={() => showDelete({})}
                  style={{ marginRight: '1rem' }}
                >
                  Delete
                </Button>
              )}
              <Button
                variant="contained"
                color="primary"
                onClick={() => showUpload({})}
              >
                Upload
              </Button>
            </Box>
          )}
        </Grid>
      </Grid>
    </>
  );
};

export default SyndicateFilingItem;
