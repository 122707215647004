import React from 'react';
import { Box, withStyles } from '@material-ui/core';

const styles = () => ({
  content: {
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: '54vh',
    '@global': {
      '.MuiGrid-spacing-xs-3': {
        margin: '0 -12px',
      },
      '.MuiGrid-spacing-xs-2': {
        margin: '0 -8px',
      },
    },
  },
});
const Content = withStyles(styles)(({ classes, children, ...others }) => {
  return (
    <Box {...others} className={classes.content}>
      {children}
    </Box>
  );
});
export default Content;
