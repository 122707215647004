import React, { useState } from 'react';
import {
  Box,
  Button,
  Checkbox,
  FormControlLabel,
  Grid,
  Radio,
  RadioGroup,
  Typography,
} from '@material-ui/core';
import SaveFormButton from 'components/Form/SaveFormButton';
import syndicatesClient from 'api/syndicatesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { useDocument } from 'components/ui/DocumentPicker';

const ChangeDocument = ({ data, signatureWarning, onChange, onClose }) => {
  const { id, title, document, draft, signatureRequired } = data;
  const [method, setMethod] = useState('replace');
  const [signed, setSigned] = useState(false);
  // const [managers, setManagers] = useState(false);
  // const [members, setMembers] = useState(false);

  const onSave = async (onError) => {
    try {
      const response = await syndicatesClient.change_document({
        id,
        method,
        attachment: currentDocument.data,
        attachmentFileName: currentDocument.name,
        disable: signatureWarning,
      });
      onChange(response.data);
      onClose();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const { currentDocument, documentPicker } = useDocument();

  const handleChange = (event) => {
    setMethod(event.target.value);
  };

  const confirmationRequired =
    (signatureRequired || signatureWarning) && method !== 'replace';
  return (
    <>
      <Box>
        <Box>
          <RadioGroup
            aria-label="method"
            name="method"
            value={method}
            onChange={handleChange}
          >
            {!document && !draft ? (
              <>
                <Box>
                  <FormControlLabel
                    value="replace"
                    control={<Radio />}
                    label="Upload"
                  />
                </Box>
                <Box marginLeft={3}>
                  <Typography variant="caption">
                    Upload a {signatureWarning ? 'signed' : ''} {title}. New
                    subscriptions will use this document.
                  </Typography>
                </Box>
              </>
            ) : (
              <>
                <Box>
                  <FormControlLabel
                    value="replace"
                    control={<Radio />}
                    label="Replace"
                    disabled={!id}
                  />
                </Box>
                <Box marginLeft={4}>
                  <Typography variant="caption">
                    Replace existing {title} with a new document. New and
                    existing subscriptions will be updated to use this document.
                    Choose this option if the change is clerical in nature
                    {signatureWarning
                      ? ' or signatures are not required for changes.'
                      : '.'}
                  </Typography>
                </Box>
                <Box marginTop={2}>
                  <FormControlLabel
                    value="amend"
                    control={<Radio />}
                    label="Amend"
                    disabled={!id}
                  />
                </Box>
                <Box marginLeft={4}>
                  <Typography variant="caption">
                    Add an {signatureWarning ? 'amended and restated ' : ''}
                    {title}
                    {signatureWarning
                      ? ' that requires signatures from existing managers and/or members. This will temporarily disable new subscriptions. You will need to obtain signatures from existing managers and/or members before you re-enable new subscriptions.'
                      : '.  New subscriptions will use this document.  Existing subscriptions will not be affected'}
                  </Typography>
                </Box>
                {/* <Box marginLeft={4} marginTop={2}>
                  <Grid container>
                    <Grid item>
                      <Checkbox
                        checked={managers}
                        onChange={(e) => setManagers(e.target.checked)}
                      ></Checkbox>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption">
                        Send Signature Requests to each manager. If you plan on
                        obtaining signatures off platform leave this box
                        unchecked.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box>
                <Box marginLeft={4} marginTop={2}>
                  <Grid container>
                    <Grid item>
                      <Checkbox
                        checked={members}
                        onChange={(e) => setMembers(e.target.checked)}
                      ></Checkbox>
                    </Grid>
                    <Grid item xs>
                      <Typography variant="caption">
                        Send Signature Requests to each current member. If you
                        plan on obtaining signatures off platform leave this box
                        unchecked.
                      </Typography>
                    </Grid>
                  </Grid>
                </Box> */}
              </>
            )}
          </RadioGroup>
        </Box>
        <Box marginTop={4}>{documentPicker}</Box>
        {confirmationRequired && (
          <>
            <Box marginTop={2}>
              <Grid container>
                <Grid item>
                  <Checkbox
                    checked={signed}
                    onChange={(e) => setSigned(e.target.checked)}
                  ></Checkbox>
                </Grid>
                <Grid item xs>
                  I confirm that I will obtain the necessary number of manager
                  and/or member signatures before enabling new subscriptions.
                  Manager and/or member signatures will need to be obtained
                  outside the SmartCapital application.
                </Grid>
              </Grid>
            </Box>
          </>
        )}
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justify="space-between">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => onClose()}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton
              name="Upload"
              onSave={onSave}
              disabled={
                !currentDocument || !method || (confirmationRequired && !signed)
              }
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default ChangeDocument;
