import React, { useEffect, useState } from 'react';
import DocumentPicker from './DocumentPicker';

export const usePhoto = (data, height = '15rem', fallback) => {
  const [currentPhoto, setPhoto] = useState(null);
  const onPhotoChange = ({ data, uri, name, contentType }) => {
    setPhoto({ data, uri, name, contentType });
  };
  useEffect(() => {
    if (data) {
      const { photo, photoFileName, photoContentType } = data;
      setPhoto({
        uri: photo,
        name: photoFileName,
        contentType: photoContentType,
      });
    }
  }, [data]);

  const photoPicker = (
    <PhotoPicker
      onChange={onPhotoChange}
      photo={currentPhoto}
      height={height}
      fallback={fallback}
    ></PhotoPicker>
  );

  return {
    currentPhoto,
    photoPicker,
  };
};

const PhotoPicker = ({
  photo,
  onChange,
  height = '15rem',
  width = '100%',
  fallback,
}) => {
  return (
    <DocumentPicker
      {...{
        document: {
          ...photo,
          contentType: photo && photo.contentType ? photo.contentType : 'image',
        },
        documentType: 'image',
        acceptedTypes: ['image/*'],
        replaceText: 'Replace Image',
        onChange,
        height,
        width,
        fallback,
      }}
    ></DocumentPicker>
  );
};

// eslint-disable-next-line import/no-unused-modules
export default PhotoPicker;
