import React, { useState } from 'react';
import { Box, Typography } from '@material-ui/core';

import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';
import useFormAddress from './useFormAddress';
import { validateForm } from 'components/Form/SaveFormButton';

const useFormCheckInstructions = ({ data }) => {
  const { id, mailing } = data || {};
  const { form: checkForm, validate: checkValidate } = useFormAddress({
    data: mailing,
    prefix: '',
  });

  const [checked, setChecked] = useState(false);

  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: { checkRecipient },
      errors,
    } = await validateForm(formRef);

    const { values: checkValues, errors: checkErrors } = await checkValidate();

    return {
      errors: [...errors, ...checkErrors],
      values: {
        checkRecipient,
        mailing_attributes: checked ? null : checkValues,
      },
    };
  };

  const form = (
    <Form initialValues={data} setRef={setFormRef} enableReinitialize>
      <Box>
        <FormikTextInput
          name="checkRecipient"
          label="Make Checks Payable To"
          variant="filled"
          required
        />
      </Box>
      {!id && (
        <Box marginTop={1}>
          <CustomCheckBox
            name="useBusiness"
            label="Check Mailing Address matches Management Address"
            checked={checked}
            onChange={() => setChecked((x) => !x)}
          />
        </Box>
      )}
      {!checked && (
        <Box marginTop={2}>
          <Box marginBottom={1}>
            <Typography variant="subtitle2">Mail Checks to</Typography>
          </Box>
          <Box>{checkForm}</Box>
        </Box>
      )}
    </Form>
  );

  return { form, validate };
};

export default useFormCheckInstructions;
