import { useCallback, useEffect, useState } from 'react';
import isEqual from 'lodash/isEqual';
import { useArrayUpdate } from './useListUpdate';

const useStateNotification = (eventName, defaultValue, delay = 100) => {
  const [state, setState] = useState(defaultValue);

  const [last, setLast] = useState(null);
  // update last attempt if changed
  useEffect(() => {
    setLast(new Date());
  }, [state]);

  const currentId = state?.id;

  const handleChange = useCallback(
    (val, replace = true) => {
      setState((cache) => {
        const last = new Date();
        let data = val;
        if (typeof data === 'function') {
          data = val(cache);
        }
        data =
          data || (cache && replace ? { id: cache.id, isDeleted: true } : null);
        if (data && !isEqual(cache || {}, data || {})) {
          const detail = {
            id: last,
            data:
              cache?.isDeleted && data
                ? { id: cache.id, replaceWith: data }
                : data,
          };
          setTimeout(() => {
            window.dispatchEvent(
              new CustomEvent(eventName, {
                detail,
              }),
            );
          }, 0);
        } else if (cache && data) {
          setLast(last);
          return cache;
        }
        return data;
      });
    },
    [eventName],
  );

  useEffect(() => {
    if (currentId) {
      let timer = null;
      const parseData = ({ detail }) => {
        const { data, id } = detail;
        if (data.id == currentId) {
          if (isEqual(data, state)) {
            if (id != last) {
              setLast(new Date()); // reset any timers
              timer = setTimeout(() => {
                setLast(new Date()); // reset any timers to be after the callers
              }, delay);
            }
          } else if (data?.replaceWith) {
            setState(data.replaceWith);
          } else {
            setState(data);
          }
        }
      };
      window.addEventListener(eventName, parseData);
      return () => {
        window.removeEventListener(eventName, parseData);
        clearTimeout(timer);
      };
    }
  }, [eventName, state, currentId, delay, last]);

  return [state?.isDeleted ? null : state, handleChange, last];
};

export const useListNotification = (eventName, filter) => {
  const [list, setList] = useState();

  const { onListUpdate } = useArrayUpdate(setList);

  const updateList = useCallback(
    (vals) => {
      setList(vals);
      if (vals) {
        vals.forEach((val) => {
          const detail = {
            id: new Date(),
            data: val,
          };
          window.dispatchEvent(
            new CustomEvent(eventName, {
              detail,
            }),
          );
        });
      }
    },
    [eventName],
  );

  useEffect(() => {
    const parseData = ({ detail }) => {
      const { data } = detail;
      if (filter(data)) {
        onListUpdate(data);
      }
    };
    window.addEventListener(eventName, parseData);
    return () => {
      window.removeEventListener(eventName, parseData);
    };
  }, [eventName, filter, onListUpdate]);

  return [list, updateList];
};

export default useStateNotification;
