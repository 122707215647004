import React, { useEffect, useState } from 'react';

// import getServerResponseErrors from 'api/getServerResponseErrors';

import { useSelectedGroup } from 'hooks/useAppState';
import InvestWidget from './InvestWidget';
import groupInvestmentsClient from 'api/groupInvestmentsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
// import opportunitiesClient from 'api/opportunitiesClient';

export const OpportunityInvestWidget = ({ opportunity }) => {
  const { id } = opportunity;
  const groupId = useSelectedGroup((state) => state.id);
  const [investment, setInvestment] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupInvestmentsClient.currentInvestment({
          groupId,
          opportunityId: id,
        });

        setInvestment(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id && groupId) {
      fetchData();
    }
  }, [id, groupId]);

  if (!investment) {
    return null;
  }
  return <InvestWidget {...{ opportunity, investment }}></InvestWidget>;
};
