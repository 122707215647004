/* eslint-disable react-hooks/exhaustive-deps */
import { connect } from 'formik';
import { useEffect, useState, useRef } from 'react';
import isEqual from 'lodash/isEqual';

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}

function FormikEffect({ onChange, formik: { values, errors } }) {
  const [firstRender, setFirstRender] = useState(true);
  const previousValues = usePrevious(values);

  useEffect(() => {
    if (firstRender) {
      setFirstRender(false);
    } else if (!isEqual(previousValues, values)) {
      onChange(previousValues, values, errors);
    }
  }, [onChange, values]);

  return null;
}

export default connect(FormikEffect);
