import React, { useEffect, useState } from 'react';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { useField } from 'formik';
import * as yup from 'yup';

// eslint-disable-next-line import/no-unused-modules
export const CustomDatePicker = ({
  value,
  fullWidth = true,
  InputLabelProps,
  shrink = true,
  ...props
}) => {
  return (
    <KeyboardDatePicker
      variant="dialog"
      inputVariant="filled"
      format="MM/DD/yyyy"
      KeyboardButtonProps={{
        'aria-label': 'change date',
      }}
      value={value || null}
      fullWidth={fullWidth}
      InputLabelProps={{ shrink, ...InputLabelProps }}
      {...props}
    />
  );
};

export const FormikDatePicker = ({
  validation,
  required,
  defaultValue,
  label,
  ...props
}) => {
  const scheme = required
    ? (validation || yup.date())
        .required(`${label ? label : 'Field'} is required`)
        .nullable()
    : validation;
  const validate = async (value) => {
    try {
      await scheme.validate(value);
    } catch (err) {
      return err.message;
    }
  };

  const [field, meta, helpers] = useField(
    scheme ? { ...props, validate } : props,
  );
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const [once, setOnce] = useState(0);
  useEffect(() => {
    if (defaultValue && once === 0) {
      setOnce((x) => x + 1);
      if (!value) {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, value, setValue, once]);

  const handleChange = (e, val) => {
    setValue(val);
  };
  return (
    <>
      <CustomDatePicker
        error={error && touched}
        helperText={error && touched ? error : null}
        label={label}
        {...field}
        {...props}
        required={required}
        onChange={handleChange}
      />
    </>
  );
};
