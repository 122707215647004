import React, { useState } from 'react';
import { Grid, Typography } from '@material-ui/core';

import { FormikDatePicker } from 'components/ui/CustomDatePicker';
import Form from 'components/Form/Form';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicatesClient from 'api/syndicatesClient';

const SyndicateFormationDate = ({
  syndicateFormationDate,
  id,
  onChange,
  isAdmin,
  showLabel = true,
}) => {
  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: { formationDate },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const upsert = syndicatesClient.update;
      const response = await upsert({
        id,
        data: {
          formationDate,
        },
      });
      onChange(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        {showLabel && (
          <Grid item xs>
            <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
              Formation Date
            </Typography>
          </Grid>
        )}
        <Grid item>
          <Form
            initialValues={{ formationDate: syndicateFormationDate }}
            setRef={setFormRef}
          >
            <FormikDatePicker
              inputVariant="filled"
              name="formationDate"
              label="Formation Date"
              required
              disabled={!isAdmin}
            />
          </Form>
        </Grid>
        {isAdmin && (
          <Grid item>
            <SaveFormButton variant="contained" onSave={onSave} name="Save" />
          </Grid>
        )}
      </Grid>
    </>
  );
};

export default SyndicateFormationDate;
