import React, { useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Button } from '@material-ui/core';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';

import { LoadingArea } from 'components/ui/Loading';
import getServerResponseErrors from 'api/getServerResponseErrors';

import useStateNotification from 'hooks/useStateNotification';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

import { useSelectedGroup } from 'hooks/useAppState';
import InvestmentTab, {
  InvestmentRouter,
} from 'views/EditOpportunity/InvestmentTab';

const SyndicateDetail = ({ id }) => {
  const [investment, setInvestment] = useStateNotification('investments');
  const history = useHistory();
  const slug = useSelectedGroup((state) => state.slug);
  let { path, url } = useRouteMatch();
  const { params } = useRouteMatch(`${path}/:subtab?`);
  const { subtab } = params;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupInvestmentsClient.get({ id });
        setInvestment(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id, setInvestment]);
  if (!investment) {
    return (
      <Box position="relative" height="100%">
        <LoadingArea open />
      </Box>
    );
  }
  return (
    <Box>
      <Button
        variant="outlined"
        color="primary"
        onClick={() => {
          history.push(`/${slug}/syndicates`);
        }}
        style={{ cursor: 'pointer' }}
        startIcon={<ChevronLeftIcon fontSize="24px" />}
      >
        Back to Syndicates
      </Button>
      <Box display="flex" mt={2}>
        <Box width="10rem">
          <InvestmentTab
            key={id}
            {...{ investment, setInvestment, tab: id, subtab, url }}
          />
        </Box>
        <Box width="100%">
          <InvestmentRouter key={id} {...{ id, tab: id, path }} />
        </Box>
      </Box>
    </Box>
  );
};

export default SyndicateDetail;
