import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import FormWizard from './FormWizard';
import { FormikCurrencyInput } from 'components/ui/CustomCurrencyInput';
import * as yup from 'yup';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';
import { Alert } from '@mui/material';
import getServerResponseErrors from 'api/getServerResponseErrors';
import groupsClient from 'api/groups/groupsClient';

export const FormFeesFields = ({
  chargeManagementFee,
  setChargeManagementFee,
}) => {
  return (
    <Box>
      <Grid container>
        <Grid item xs={5}>
          <Box padding={2}>
            <Typography variant="subtitle2">Charge Manager Fees?</Typography>
            <RadioGroup
              row
              aria-label="position"
              name="position"
              defaultValue="top"
              value={chargeManagementFee}
              onChange={(e) => {
                const val = e.target.value;
                setChargeManagementFee(val);
              }}
            >
              <FormControlLabel
                value="yes"
                control={<Radio color="primary" />}
                label="Yes"
              />
              <FormControlLabel
                value="no"
                control={<Radio color="primary" />}
                label="No"
              />
              <FormControlLabel
                value="custom"
                control={<Radio color="primary" />}
                label="Custom"
              />
            </RadioGroup>
          </Box>
        </Grid>
        <Grid item xs>
          <Box paddingRight={3}>
            {chargeManagementFee == 'yes' && (
              <Grid container spacing={3}>
                <Grid item md={4}>
                  <FormikCurrencyInput
                    name="managementFeeBase"
                    label="Base Fee"
                    variant="filled"
                    defaultValue={0}
                    validation={yup.number().min(0)}
                  />
                </Grid>
                <Grid item md={4}>
                  <FormikCurrencyInput
                    name="managementFeePerMember"
                    label="Per Investor Fee"
                    variant="filled"
                    defaultValue={0}
                    validation={yup.number().min(0)}
                  />
                </Grid>
                <Grid item md={4}>
                  <FormikCurrencyInput
                    name="managementFeePercent"
                    label="% of Raise Fee"
                    variant="filled"
                    inputProps={{
                      prefix: '',
                      suffix: '%',
                      decimalScale: 2,
                    }}
                    defaultValue={0}
                    validation={yup.number().min(0)}
                  />
                </Grid>
              </Grid>
            )}
            {chargeManagementFee == 'custom' && (
              <Box padding={3}>
                <Grid container>
                  <Grid item>
                    <ErrorOutlineIcon
                      fontSize="large"
                      style={{ color: '#ff3333' }}
                    />
                  </Grid>
                  <Grid item xs>
                    <Box marginLeft={2}>
                      <Typography variant="subtitle2">
                        For a custom manager fee, you will need to use a custom
                        operating agreement, which you can upload at the end of
                        this guide.
                      </Typography>
                    </Box>
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const useSyndicateFormFees = ({
  data,
  smartcapitalFeeBase,
  smartcapitalFeePerMember,
  smartcapitalFeePercent,
  groupId,
  showManagementFees,
}) => {
  const {
    managementFeeBase,
    managementFeePerMember,
    managementFeePercent,
    blueSky = [],
  } = data;
  const [chargeManagementFee, setChargeManagementFee] = useState(
    managementFeeBase == 0 &&
      managementFeePerMember == 0 &&
      managementFeePercent == 0
      ? 'no'
      : managementFeeBase > 0 ||
        managementFeePerMember > 0 ||
        managementFeePercent > 0 ||
        managementFeeBase == null
      ? 'yes'
      : 'custom',
  );
  const [blueSkyData, setBlueSky] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.blueSkyInfo({ id: groupId });
        setBlueSky(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);
  const { fees, baseFee } = blueSkyData || {};
  const estimate = useMemo(() => {
    const temp = blueSky.reduce((acc, val) => {
      const fee = fees?.find((x) => x.state == val) || {
        maxFee: 0,
      };
      return acc + parseFloat(fee.maxFee);
    }, 0);
    return temp > 0 ? temp + parseFloat(baseFee) : 0;
  }, [fees, blueSky, baseFee]);
  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: {
        managementFeeBase,
        managementFeePerMember,
        managementFeePercent,
      },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        managementFeeBase:
          chargeManagementFee == 'yes'
            ? managementFeeBase
            : chargeManagementFee == 'no'
            ? 0
            : -1,
        managementFeePerMember:
          chargeManagementFee == 'no' ? 0 : managementFeePerMember,
        managementFeePercent:
          chargeManagementFee == 'no' ? 0 : managementFeePercent,
      },
    };
  };

  const formData = {
    managementFeeBase:
      chargeManagementFee == 'yes' && parseFloat(managementFeeBase) <= 0
        ? smartcapitalFeeBase + estimate
        : managementFeeBase || 0,
    managementFeePerMember:
      chargeManagementFee == 'yes' && parseFloat(managementFeePerMember) <= 0
        ? smartcapitalFeePerMember
        : managementFeePerMember || 0,
    managementFeePercent:
      chargeManagementFee == 'yes' && parseFloat(managementFeePercent) <= 0
        ? smartcapitalFeePercent
        : managementFeePercent || 0,
    smartcapitalFeeBase,
    smartcapitalFeePerMember,
  };
  const form = (
    <Form initialValues={formData} enableReinitialize setRef={setFormRef}>
      <Box marginTop={2}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Below are the fees which SmartCapital will charge the manager
        </Typography>
      </Box>
      <Box marginTop={3}>
        <Typography variant="subtitle1">
          <b>SmartCapital Fees</b>
        </Typography>
      </Box>
      <Box marginTop={1}>
        <Box>
          <Typography variant="subtitle2">
            SmartCapital fees outlined below are charged directly to the
            manager, not the LLC, so please make sure to plan accordingly.
          </Typography>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={5}>
              <Box padding={2}>
                <Typography variant="subtitle2">SmartCapital Fees</Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box paddingRight={3}>
                <Grid container spacing={3}>
                  <Grid item md={4}>
                    <FormikCurrencyInput
                      name="smartcapitalFeeBase"
                      label="Base Fee"
                      variant="outlined"
                      size="small"
                      disabled
                      defaultValue={0}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <FormikCurrencyInput
                      name="smartcapitalFeePerMember"
                      label="Per Investor Fee"
                      variant="outlined"
                      size="small"
                      disabled
                      defaultValue={0}
                    />
                  </Grid>
                  <Grid item md={4}>
                    <FormikCurrencyInput
                      name="smartcapitalFeePercent"
                      label="% of Raise Fee"
                      variant="outlined"
                      size="small"
                      inputProps={{
                        prefix: '',
                        suffix: '%',
                        decimalScale: 2,
                      }}
                      disabled
                      defaultValue={0}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box>
          <Grid container>
            <Grid item xs={5}>
              <Box padding={2}>
                <Typography variant="subtitle2">Blue Sky Fees</Typography>
              </Box>
            </Grid>
            <Grid item xs>
              <Box paddingRight={3}>
                <Grid container spacing={3}>
                  <Grid item md={4}>
                    <FormikCurrencyInput
                      name="blueSkyFee"
                      label="Blue Sky Fee"
                      variant="outlined"
                      size="small"
                      disabled
                      value={estimate}
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
      {showManagementFees && (
        <>
          <Box marginTop={3}>
            <Typography variant="subtitle1">
              <b>Manager Fees</b>
            </Typography>
          </Box>
          <Box marginTop={1}>
            <Box>
              <Typography variant="subtitle2">
                Managers can charge administration fees to the Syndicate LLC.
                These fees are what the Syndicate LLC will pay to you, the
                manager, upon creation.
              </Typography>
            </Box>
            <Box mt={1}>
              <Typography variant="subtitle2">
                The fee types are a base fee, per investor fee, and percent of
                raise fee.
              </Typography>
            </Box>
            <Box mt={1}>
              <Alert severity="warning">
                <Typography variant="subtitle2">
                  SmartCapital fees are charged directly to the manager, not the
                  LLC, so please make sure to plan accordingly by charging fees
                  to the syndicate needed to cover costs.
                </Typography>
              </Alert>
            </Box>

            <FormFeesFields
              chargeManagementFee={chargeManagementFee}
              setChargeManagementFee={setChargeManagementFee}
            />
          </Box>
        </>
      )}
    </Form>
  );

  return { form, validate };
};

const SyndicateFormFees = ({
  data,
  onNext,
  onBack,
  onClose,
  smartcapitalFeeBase,
  smartcapitalFeePerMember,
  groupId,
  showManagementFees = true,
  buttonName,
}) => {
  const { form, validate } = useSyndicateFormFees({
    data,
    smartcapitalFeeBase,
    smartcapitalFeePerMember,
    groupId,
    showManagementFees,
  });
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext,
        onBack,
        onClose,
        buttonName,
      }}
    />
  );
};

export default SyndicateFormFees;
