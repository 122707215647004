import React, { useState, useEffect, useMemo } from 'react';
import { Grid, Box, Button, makeStyles } from '@material-ui/core';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import CenteredContentBox from './CenteredContentBox/CenteredContentBox';
import ImageFallback from './ImageFallback';
import { LoadingSpinner } from './Loading';

const useStyles = makeStyles((theme) => ({
  uploadArea: {
    backgroundColor: theme.palette.common.white,
    border: '1px dashed',
    borderColor: theme.palette.grey[400],
    textAlign: 'center',
    borderRadius: theme.shape.borderRadius,
    margin: theme.spacing(2),
  },
  previewArea: {
    padding: theme.spacing(2),
  },
}));

export const useDocument = (
  data,
  prefix = 'document',
  getLink,
  replaceText = 'Replace File',
) => {
  const [currentDocument, setDocument] = useState(null);

  useEffect(() => {
    if (data) {
      const temp = data[`${prefix}Data`];
      if (temp) {
        const { metadata } =
          temp.constructor === Object ? temp : JSON.parse(temp || '{}');
        const { filename, mime_type, mimeType } = metadata || {};
        setDocument({
          uri: data[prefix],
          name: filename,
          contentType: mime_type || mimeType,
        });
      }
    } else {
      setDocument(null);
    }
  }, [prefix, data]);

  const documentPicker = useMemo(() => {
    const onDocumentChange = ({ data, uri, name, contentType }) => {
      setDocument({ data, uri, name, contentType });
    };
    return (
      <DocumentPicker
        onChange={onDocumentChange}
        document={currentDocument}
        getLink={getLink}
        replaceText={replaceText}
      ></DocumentPicker>
    );
  }, [currentDocument, getLink, replaceText]);

  return {
    currentDocument,
    documentPicker,
  };
};

const DocumentPicker = ({
  document,
  documentType = 'file',
  acceptedTypes = [],
  onChange,
  height = '5rem',
  width = '100%',
  replaceText = 'Replace File',
  fallback,
  getLink,
}) => {
  const classes = useStyles();
  const [file, setFile] = useState();
  const handleFileUpload = async (files) => {
    const upload = files[0];
    const newFile = {
      uri: upload.data,
      data: upload.data,
      name: upload.file.name,
      contentType: upload.file.type,
    };
    setFile(newFile);
    onChange(newFile);
  };

  useEffect(() => {
    if (document) {
      const { uri, name, contentType } = document || {};

      setFile({ uri, name, contentType });
    }
  }, [document, getLink]);

  const { uri, contentType } = file || {};
  const isImage = contentType && contentType.indexOf('image') >= 0;
  useEffect(() => {
    const lookup = async () => {
      const link = await getLink();
      setFile((x) => ({ ...x, uri: link }));
    };
    if (isImage && getLink && !uri) {
      lookup();
    }
  }, [isImage, getLink, uri]);

  return (
    <Box className={classes.uploadArea}>
      {file ? (
        <CenteredContentBox>
          <Box className={classes.previewArea} onClick={() => setFile()}>
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justify="center"
            >
              <Grid item xs={12}>
                {isImage && uri ? (
                  <ImageFallback
                    src={uri}
                    fallback={fallback}
                    alt=""
                    width={width}
                    height={height}
                  />
                ) : isImage && getLink ? (
                  <Box>
                    <LoadingSpinner />
                  </Box>
                ) : (
                  <Box>{file.name || uri}</Box>
                )}
              </Grid>
              <Grid item xs={12}>
                <Button size="small">{replaceText}</Button>
              </Grid>
            </Grid>
          </Box>
        </CenteredContentBox>
      ) : (
        <Box padding={2}>
          {fallback && (
            <ImageFallback src={fallback} alt="" width="50%" height="50%" />
          )}
          <DropzoneAreaBase
            onAdd={handleFileUpload}
            onDelete={(fileObj) => console.log('Removed File:', fileObj)}
            onAlert={(message, variant) =>
              console.log(`${variant}: ${message}`)
            }
            dropzoneText={`Drop ${documentType} here or click to select file`}
            acceptedFiles={acceptedTypes}
            showAlerts={false}
            filesLimit={1}
            maxFileSize={104857600}
          />
        </Box>
      )}
    </Box>
  );
};

export default DocumentPicker;
