import React from 'react';
import Routes from './Routes';
import CssBaseline from '@material-ui/core/CssBaseline';
import { createMuiTheme, lighten } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import { Provider } from 'react-redux';
import store from 'redux/store';
import UserAuth from 'components/UserAuth/UserAuth';
import { useMediaQuery } from '@material-ui/core';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';
import { themeColors } from 'utils/consts';

const baseMuiTheme = createMuiTheme();
const breakpoints = createBreakpoints({});

const buildTheme = ({
  primaryColor,
  secondaryColor,
  accentColor,
  linkColor,
}) => {
  const buttonOutlineOverrides = {
    borderColor: baseMuiTheme.palette.grey[400],
    color: '#4F4F4F',
    '&:hover': {
      borderColor: '#4547DC',
    },
    '&:active': {
      borderColor: accentColor,
    },
  };
  return createMuiTheme({
    palette: {
      // type: prefersDarkMode ? 'dark' : 'light',
      primary: {
        main: primaryColor,
        gradients: {
          main: `linear-gradient(93.48deg, ${primaryColor} 8.65%, ${secondaryColor} 99.32%)`,
        },
      },
      secondary: {
        main: secondaryColor || '#5D26A4',
      },
      accent: {
        main: accentColor,
      },
      link: {
        main: linkColor,
      },
      money: {
        main: '#00C805',
      },
      success: {
        main: themeColors.moneyColor,
      },
      danger: {
        main: '#D0342C',
      },
      warning: {
        main: '#D0342C',
      },
      error: {
        main: '#D0342C',
      },
      grey: {
        100: '#F0F2F5',
      },
      text: {
        primary: 'rgb(47, 50, 59)',
        secondary: 'rgba(47, 50, 59, .54)',
        disabled: 'rgba(47, 50, 59, .38)',
        hint: 'rgba(47, 50, 59, .38)',
      },
    },
    typography: {
      fontFamily: 'Lato, Helvetica, Arial, sans-serif',
      body1: {
        fontSize: '1.125rem',
      },
      h5: {
        fontWeight: '300',
      },
    },
    overrides: {
      MuiAvatar: {
        root: {
          fontSize: '1rem',
        },
        colorDefault: {
          backgroundColor: themeColors.accentLight,
          color: '#454545',
        },
      },
      MuiAlert: {
        standardInfo: {
          backgroundColor: lighten(themeColors.primaryColor, 0.9),
          color: themeColors.primaryColor,
        },
        outlinedInfo: {
          borderColor: themeColors.primaryColor,
          color: themeColors.primaryColor,
          '& .MuiAlert-icon': {
            color: themeColors.primaryColor,
          },
        },
      },
      MuiTab: {
        root: {
          [breakpoints.down('md')]: {
            minWidth: '100px',
          },
          '& :hover': {
            color: themeColors.secondaryColor,
          },
          selected: {
            color: themeColors.primaryColor,
          },
        },
        textColorPrimary: {
          color: themeColors.linkColor,
        },
      },
      MuiDivider: {
        root: {
          backgroundColor: baseMuiTheme.palette.grey[400],
          height: '0.75px',
          border: 'none',
        },
      },
      MuiTabs: {
        indicator: {
          backgroundColor: themeColors.primaryColor,
          height: '4px',
        },
      },
      '@global': {
        '.money-color': {
          color: themeColors.moneyColor,
        },
      },
      MuiLink: {
        root: {
          color: linkColor,
        },
      },
      MuiCssBaseline: {
        '@global': {
          '.MuiButton-containedWhite': {
            backgroundColor: 'white',
            color: primaryColor,
          },
          '.MuiDataGrid-root': {
            fontFamily: 'Lato, Helvetica, Arial, sans-serif',
          },
          body: {
            backgroundColor: baseMuiTheme.palette.common.white,
          },
          img: {
            maxWidth: '100%',
          },
          // '.MuiDataGrid-root .MuiDataGrid-columnHeaderTitleContainer': {
          //   padding: '0 !important',
          // },
          '.MuiDialog-root .MuiBackdrop-root': {
            background:
              'linear-gradient(95deg, rgba(21,70,166,0.9) 0%, rgba(93,38,164,0.9) 100%)',
          },
        },
      },
      MuiFab: {
        root: {
          boxShadow: 'none',
          '&:hover': {
            boxShadow: 'none',
          },
        },
      },
      MuiDataGrid: {
        root: {
          '& .data-grid-header-no-padding .MuiDataGrid-columnHeaderTitleContainer':
            {
              padding: 0,
            },
        },
      },
      MuiChip: {
        outlinedPrimary: {
          borderColor: themeColors.accentColor,
          color: themeColors.accentColor,
        },
      },
      MuiDropzoneArea: {
        root: {
          minHeight: 'initial',
          padding: 0,
          border: 'none',
          transition: '.2s background-color ease-out',
        },
        text: {
          marginBottom: 0,
          marginTop: baseMuiTheme.spacing(1),
          ...baseMuiTheme.typography.subtitle1,
        },
        icon: {
          color: baseMuiTheme.palette.primary.main,
        },
        active: {
          border: 'none',
          color: baseMuiTheme.palette.common.white,
          backgroundColor: baseMuiTheme.palette.primary.main,
          backgroundImage: 'none',
          '& .MuiDropzoneArea-icon': {
            color: baseMuiTheme.palette.common.white,
          },
        },
        invalid: {
          border: 'none',
          backgroundColor: baseMuiTheme.palette.error.main,
          backgroundImage: 'none',
          '& .MuiDropzoneArea-icon': {
            color: baseMuiTheme.palette.common.white,
          },
        },
      },
      MuiTextField: {
        root: {
          // overflowX: 'hidden',
        },
      },
      MuiInputLabel: {
        filled: {
          whiteSpace: 'nowrap',
          '&$shrink': {
            textOverflow: 'ellipsis',
            width: '100%',
            padding: baseMuiTheme.spacing(1),
            transform: 'none',
            fontSize: '1em',
            overflow: 'hidden',
          },
        },
      },
      MuiFilledInput: {
        root: {
          backgroundColor: baseMuiTheme.palette.common.white,
        },
        underline: {
          '&:before': {
            borderBottomColor: primaryColor,
          },
        },
      },
      MuiCheckbox: {
        colorPrimary: {
          '&$checked': {
            color: accentColor,
          },
          color: '#DDDDDD',
        },
        colorSecondary: {
          '&$checked': {
            color: accentColor,
          },
          color: '#DDDDDD',
        },
      },
      MuiButtonGroup: {
        root: {
          borderRadius: '100px',
          textTransform: 'none',
        },
      },
      MuiIconButton: {
        colorPrimary: {
          color: accentColor,
          '&:hover': {
            backgroundColor: lighten(accentColor, 0.8),
          },
        },
      },
      MuiButton: {
        root: {
          borderRadius: '100px',
          textTransform: 'none',
        },
        containedPrimary: {
          backgroundColor: accentColor,
          '&:hover': {
            backgroundColor: linkColor,
          },
          '&:active': {
            backgroundColor: secondaryColor,
          },
        },
        outlinedPrimary: {
          ...buttonOutlineOverrides,
        },
        outlinedSecondary: {
          ...buttonOutlineOverrides,
        },
        outlinedSizeSmall: {
          ...buttonOutlineOverrides,
        },
        outlinedSizeLarge: {
          ...buttonOutlineOverrides,
        },
        outlined: {
          ...buttonOutlineOverrides,
        },
        // contained: {
        //   backgroundColor: '#E0E0E0',
        //   color: '#4F4F4F',
        //   '&:hover': {
        //     backgroundColor: '#F2F2F2',
        //     color: secondaryColor,
        //   },
        //   '&:active': {
        //     backgroundColor: '#F2F2F2',
        //     color: accentColor,
        //   },
        // },
      },
      MuiDialogContent: {
        root: {
          backgroundColor: baseMuiTheme.palette.common.white,
        },
      },
    },
    props: {
      MuiButton: {
        size: 'large',
        disableElevation: true,
        variant: 'outlined',
      },
      MuiButtonGroup: {
        size: 'large',
        disableElevation: true,
      },
    },
  });
};

const App = () => {
  const prefersDarkMode = useMediaQuery('(prefers-color-scheme:dark)');
  try {
    console.log(`Running ${process.env.SOURCE_VERSION}`);
    // eslint-disable-next-line no-empty
  } catch {}

  return (
    <>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <Provider store={store}>
          <ThemeProvider
            theme={buildTheme({
              ...themeColors,
              prefersDarkMode,
            })}
          >
            <CssBaseline />
            <UserAuth>
              <Routes />
            </UserAuth>
          </ThemeProvider>
        </Provider>
      </MuiPickersUtilsProvider>
    </>
  );
};

export default App;
