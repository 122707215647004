import React, { useState, useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Grid,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
  MenuItem,
} from '@material-ui/core';

import Form from 'components/Form/Form';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { Field } from 'formik';

import { useDocument } from 'components/ui/DocumentPicker';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import DialogWithTitle from 'components/ui/DialogWithTitle';
import { FormikTextInput } from 'components/ui/CustomTextField';
import ActionWithConfirm from 'components/ui/ActionWithConfirm';
import { FormikSelect } from 'components/ui/CustomSelect';
import { FormikDatePicker } from 'components/ui/CustomDatePicker';
import moment from 'moment';

export const useDocumentSave = ({
  params,
  create,
  update,
  remove,
  onChange,
}) => {
  const withSave = ({
    id,
    currentDocument,
    formRef,
    setDefaultValues,
    onClose,
  }) => {
    const onSave = async (onError) => {
      try {
        const {
          values: { name, description, link, docType, dateEffective, isLink },
          errors,
        } = await validateForm(formRef);
        if (!link && !currentDocument.uri) {
          errors.push('You must add a link or upload a document');
        }
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const upsert = id ? update : create;

        const response = await upsert({
          id,
          ...params,
          data: {
            name,
            description,
            docType,
            dateEffective: moment(dateEffective).format('MM/DD/yyyy'),
            link: isLink === 'true' ? link : null,
            document: isLink !== 'true' ? currentDocument.data : null,
            documentFileName: isLink !== 'true' ? currentDocument.name : null,
          },
        });
        const data = response.data;
        onChange(data);
        setDefaultValues({ ...data, isLink: data.link ? 'true' : 'false' });
        onClose();
        return true;
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
      }
      return false;
    };

    const onDelete = async (onError) => {
      try {
        await remove({ id });
        onChange({ id, isDeleted: true }, 'Delete Successful');
        onClose();
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
      }
    };

    return { onSave, onDelete };
  };
  return withSave;
};

export const EditDocument = ({
  data,
  onClose,
  withSave,
  hasExtras,
  documentTypes,
}) => {
  const [defaultValues, setDefaultValues] = useState({
    ...data,
    isLink: data.link ? 'true' : 'false',
  });
  const { currentDocument, documentPicker } = useDocument(defaultValues);
  const [formRef, setFormRef] = useState({});
  const { id } = defaultValues;

  useEffect(() => {
    if (
      currentDocument &&
      !formRef.current.values['name'] &&
      !formRef.current.touched['name']
    ) {
      console.log('currentDocument', currentDocument);
      formRef.current.setFieldValue(
        'name',
        currentDocument.name.slice(0, currentDocument.name.lastIndexOf('.')),
      );
    }
  }, [currentDocument, formRef]);
  const { onSave, onDelete } = withSave({
    id,
    currentDocument,
    formRef,
    setDefaultValues,
    onClose,
  });
  return (
    <ActionWithConfirm onAction={onDelete}>
      {({ actionButton }) => (
        <Form
          initialValues={defaultValues}
          enableReinitialize
          setRef={setFormRef}
        >
          {({ values }) => {
            return (
              <>
                <Box marginBottom={3}>
                  <Grid container spacing={3}>
                    {documentTypes && (
                      <Grid item xs={12}>
                        <FormikSelect
                          name="docType"
                          variant="filled"
                          required
                          label="Document Type"
                        >
                          {documentTypes.map((type) => (
                            <MenuItem key={type.value} value={type.value}>
                              {type.label}
                            </MenuItem>
                          ))}
                        </FormikSelect>
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormikTextInput
                        name="name"
                        label="Document Name"
                        required
                      />
                    </Grid>
                    {hasExtras && (
                      <Grid item xs={12}>
                        <FormikDatePicker
                          name="dateEffective"
                          label="Document Effective Date"
                          required
                        />
                      </Grid>
                    )}
                    <Grid item xs={12}>
                      <FormikTextInput
                        name="description"
                        label="Document Description"
                      />
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Box marginBottom={2}>
                    <Typography variant="h6" color="primary">
                      Attach Document
                    </Typography>
                  </Box>
                  <Box marginBottom={3}>
                    <Grid container spacing={1} row>
                      <Grid item xs={12} sm={4}>
                        <label>
                          <Field
                            type="radio"
                            name="isLink"
                            value="false"
                          ></Field>
                          Upload File
                        </label>
                      </Grid>
                      <Grid item xs={12} sm={3}>
                        <label>
                          <Field
                            type="radio"
                            name="isLink"
                            value="true"
                          ></Field>
                          Website Link
                        </label>
                      </Grid>
                    </Grid>
                  </Box>
                </Box>
                <Box>
                  <Grid container spacing={3}>
                    {values.isLink === 'true' ? (
                      <Grid item xs={12}>
                        <FormikTextInput name="link" label="URL" url />
                      </Grid>
                    ) : (
                      documentPicker
                    )}
                  </Grid>
                </Box>

                <Box marginTop={12}>
                  <Grid container justify="space-between" spacing={3}>
                    <Grid item>
                      <Button variant="contained" onClick={onClose}>
                        Cancel
                      </Button>
                    </Grid>
                    {id && <Grid item>{actionButton}</Grid>}
                    <Grid item>
                      <SaveFormButton
                        onSave={onSave}
                        name={id ? 'Save' : 'Add'}
                      ></SaveFormButton>
                    </Grid>
                  </Grid>
                </Box>
              </>
            );
          }}
        </Form>
      )}
    </ActionWithConfirm>
  );
};

export const ManageDocuments = ({
  documents,
  withSave,
  canEdit = false,
  hasExtras,
  documentTypes,
}) => {
  return (
    <Box>
      {documents && documents.length > 0 ? (
        <Box marginTop={2}>
          <TableContainer component={Paper}>
            <Box>
              <DocumentsTable
                documents={documents}
                canEdit={canEdit}
                withSave={withSave}
                hasExtras={hasExtras}
                documentTypes={documentTypes}
              />
            </Box>
          </TableContainer>
        </Box>
      ) : (
        <Box
          marginTop={2}
          padding={3}
          height="10vh"
          bgcolor="grey.300"
          borderRadius="borderRadius"
          textAlign="center"
        >
          <CenteredContentBox>
            <Box marginBottom={2}>
              <Typography color="primary">
                No documents have been uploaded yet
              </Typography>
            </Box>
          </CenteredContentBox>
        </Box>
      )}
    </Box>
  );
};

export const DocumentsTable = ({
  documents,
  canEdit = false,
  withSave,
  hasExtras,
  documentTypes,
}) => {
  return (
    <Box bgcolor="white" border="1px solid lightgray" borderBottom="0">
      <Table size="small" stickyHeader aria-label="simple table">
        <TableHead>
          <TableRow>
            <TableCell>Name</TableCell>
            {hasExtras && <TableCell align="left">Effective Date</TableCell>}
            {documentTypes && <TableCell align="left">Type</TableCell>}
            <TableCell align="left">Description</TableCell>
            {canEdit && <TableCell align="right">Actions</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {documents &&
            documents.map((document) => (
              <DocumentView
                key={document.id}
                data={document}
                canEdit={canEdit}
                withSave={withSave}
                hasExtras={hasExtras}
                documentTypes={documentTypes}
              />
            ))}
        </TableBody>
      </Table>
    </Box>
  );
};

const DocumentView = ({
  data,
  canEdit = false,
  withSave,
  hasExtras,
  documentTypes,
}) => {
  const { name, document, link, description, dateEffective, docType } = data;

  const safeLink = link
    ? link.includes('://') || link.startsWith('//')
      ? link
      : `//${link}`
    : null;

  const [editModal, showEdit] = useState(false);

  const label = useMemo(() => {
    if (!documentTypes) return 'Other';
    const t = documentTypes.find((x) => x.value === docType);
    return t?.short || t?.label || 'Other';
  }, [docType, documentTypes]);

  return (
    <TableRow>
      {editModal ? (
        <DialogWithTitle
          open
          onClose={() => showEdit(false)}
          fullWidth
          title="Edit Document"
        >
          <EditDocument
            onClose={() => showEdit(false)}
            {...{ data, withSave }}
            hasExtras={hasExtras}
            documentTypes={documentTypes}
          ></EditDocument>
        </DialogWithTitle>
      ) : null}
      <TableCell style={{ width: '40%' }}>
        <a
          href={safeLink || document}
          target="_blank"
          rel="noopener noreferrer"
        >
          <Typography>{name}</Typography>
        </a>
      </TableCell>
      {hasExtras && (
        <TableCell align="left">
          <Typography>
            {dateEffective ? moment(dateEffective).format('MM/DD/yyyy') : ''}
          </Typography>
        </TableCell>
      )}
      {documentTypes && (
        <TableCell align="left">
          <Typography>{label}</Typography>
        </TableCell>
      )}

      <TableCell align="left">{description}</TableCell>
      {canEdit && (
        <TableCell align="right">
          <Link
            onClick={() => {
              showEdit((x) => !x);
            }}
            style={{ cursor: 'pointer' }}
          >
            Edit
          </Link>
        </TableCell>
      )}
    </TableRow>
  );
};
