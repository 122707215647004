import React, { useState } from 'react';

import {
  Box,
  Button,
  Grid,
  Paper,
  Typography,
  useTheme,
} from '@material-ui/core';

import SaveFormButton from 'components/Form/SaveFormButton';

import getServerResponseErrors from 'api/getServerResponseErrors';
import userInvestmentsClient from 'api/userInvestmentsClient';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';
import { investorTypes } from './SubscriptionType';
import useSyndicateDocumentPoll, {
  documentStates,
} from 'hooks/useSyndicateDocumentPoll';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const getEntityDisplay = (entityType) =>
  investorTypes.find((x) => x.id === entityType)?.title;

const SubscriptionReview = ({
  userInvestment,
  syndicate,
  onBack,
  onChange,
  actionButton,
}) => {
  const theme = useTheme();
  const {
    unitPrice,
    offeringCircularId,
    operatingAgreementId,
    subscriptionAgreementId,
  } = syndicate;

  const [affirmed, setAffirmed] = useState(userInvestment.affirmed);

  const {
    id,
    requestedUnits,
    // entityName,
    // userInfo,
    entityType,
  } = userInvestment;
  // const { photo, initials, name, email } = userInfo || {};
  const onSave = async (onError) => {
    try {
      const response = await userInvestmentsClient.affirm({ id });
      // save affirm
      onChange(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const amount = formatter.format(unitPrice * requestedUnits);
  const entityDisplay = getEntityDisplay(entityType);
  return (
    <>
      <Box>
        <Grid container spacing={2} style={{ justifyContent: 'center' }}>
          <Grid item xs={12} md={6}>
            <Paper variant="outlined" square style={{ padding: '1rem' }}>
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Amount:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    style={{ color: theme.palette.money.main }}
                  >
                    {amount}
                  </Typography>
                </Grid>
              </Grid>
            </Paper>
            <Paper
              variant="outlined"
              square
              style={{ padding: '1rem', marginTop: '1rem' }}
            >
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Investor Type:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subtitle1">{entityDisplay}</Typography>
                </Grid>
              </Grid>
            </Paper>
            {/* <Paper
              variant="outlined"
              square
              style={{ padding: '1rem', marginTop: '1rem' }}
            >
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Investor:
                  </Typography>
                </Grid>
                <Grid item>
                  <Paper variant="outlined" padding={1}>
                    <Box display="flex" alignItems="center">
                      <Box mx={1}>
                        <Avatar
                          style={{ fontSize: '16px' }}
                          src={photo}
                          className={classes.userAvatar}
                        >
                          {initials}
                        </Avatar>
                      </Box>
                      <Box padding={1} display="flex" flexDirection="column">
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ maxWidth: 250 }}
                        >
                          {name}
                        </Typography>
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ maxWidth: 250 }}
                        >
                          {email}
                        </Typography>
                        <Typography
                          variant="caption"
                          noWrap
                          style={{ maxWidth: 250 }}
                        >
                          {entityName}
                        </Typography>
                      </Box>
                    </Box>
                  </Paper>
                </Grid>
              </Grid>
            </Paper> */}

            <Paper
              variant="outlined"
              square
              style={{ padding: '1rem', marginTop: '1rem' }}
            >
              <Grid container justify="space-between">
                <Grid item>
                  <Typography
                    variant="subtitle1"
                    style={{ fontWeight: 'bold' }}
                  >
                    Investment Documents:
                  </Typography>
                  <Grid container spacing={1} direction="column">
                    <Grid item>
                      <DocumentButton
                        id={operatingAgreementId}
                        name="Operating Agreement"
                      />
                    </Grid>
                    <Grid item>
                      <DocumentButton
                        id={offeringCircularId}
                        name="Private Placement Memorandum"
                      />
                    </Grid>
                    {subscriptionAgreementId && (
                      <Grid item>
                        <DocumentButton
                          id={subscriptionAgreementId}
                          name="Subscription Agreement"
                        />
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
          <Grid item>
            <CustomCheckBox
              label={
                <Box maxWidth="30rem">
                  <Typography variant="subtitle2" style={{ fontSize: 12 }}>
                    By checking this box, I affirm that I have had adequate time
                    to review the operating agreement and understand this
                    investment. I understand there is a significant risk related
                    to this investment. I also understand that the syndication
                    of investments has administrative fees outlined in the
                    operating agreement.
                  </Typography>
                </Box>
              }
              checked={affirmed}
              disabled={userInvestment.affirmed}
              onChange={(e) => setAffirmed(e.target.checked)}
            />
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <Grid
          container
          spacing={1}
          justify="space-between"
          wrap="nowrap"
          alignItems="center"
        >
          <Grid item>{actionButton}</Grid>

          <Grid item>
            <Button
              variant="contained"
              onClick={onBack}
              style={{ marginRight: '1rem' }}
            >
              Back
            </Button>
            <SaveFormButton
              onSave={onSave}
              name="Next"
              disabled={!affirmed}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

const DocumentButton = ({ id, name }) => {
  const { documentState, openLink } = useSyndicateDocumentPoll({
    id,
    role: 'person',
  });
  return (
    <Button
      color="primary"
      variant="text"
      size="small"
      disabled={documentState != documentStates.COMPLETED}
      onClick={openLink}
    >
      {name}
    </Button>
  );
};

export default SubscriptionReview;
