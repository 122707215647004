import React, { useEffect, useRef, useState } from 'react';

import getServerResponseErrors from 'api/getServerResponseErrors';
import {
  Box,
  Grid,
  Typography,
  Button,
  Avatar,
  makeStyles,
} from '@material-ui/core';
import { LoadingArea } from 'components/ui/Loading';
import useFormUserInvestmentNote from 'hooks/forms/useFormUserInvestmentNote';
import SaveFormButton from 'components/Form/SaveFormButton';
import ActionWithConfirm from 'components/ui/ActionWithConfirm';

import moment from 'moment';
import investmentNotesClient from 'api/investmentNotesClient';

const useStyles = makeStyles((theme) => ({
  userAvatar: {
    width: theme.spacing(6),
    height: theme.spacing(6),
  },
}));

const UserInvestmentNotes = ({ groupInvestmentId, user, onChange }) => {
  const classes = useStyles();
  const [notes, setNotes] = useState([]);
  const { id: userId, name } = user;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await investmentNotesClient.index({
          groupInvestmentId,
          userId: userId,
        });
        setNotes(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupInvestmentId && userId) {
      fetchData();
    }
  }, [groupInvestmentId, userId]);

  const [edit, setEdit] = useState();
  const { form, validate } = useFormUserInvestmentNote({
    data: edit,
    formId: notes ? notes.length : 0,
  });

  const scrollRef = useRef();
  useEffect(() => {
    if (scrollRef.current) {
      scrollRef.current.scrollIntoView({ behaviour: 'smooth', block: 'end' });
    }
  }, [notes]);

  if (!notes) {
    return (
      <Box
        padding={3}
        border="1px solid lightgray"
        bgcolor="white"
        width="100%"
        height="15rem"
        position="relative"
      >
        <LoadingArea open />
      </Box>
    );
  }

  const onUpsert = async (onError) => {
    try {
      const { values, errors } = await validate();
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      const upsert = edit
        ? investmentNotesClient.update
        : investmentNotesClient.create;
      const response = await upsert({
        groupInvestmentId,
        userId,
        id: edit ? edit.id : null,
        text: values?.text,
      });
      let data = null;
      if (edit) {
        data = [...notes.filter((x) => x.id != edit.id), response.data];
      } else {
        data = [...notes, response.data];
      }
      setNotes(data);
      onChange(data.length);
      setEdit();

      // onClose();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const onDelete = async (id, onError) => {
    try {
      await investmentNotesClient.remove({ id });

      let data = [...notes.filter((x) => x.id != id)];
      setNotes(data);
      onChange(data.length);

      // onClose();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  return (
    <Box minHeight="15rem">
      <Box>
        <Typography>Notes for {name}</Typography>
      </Box>
      <Box marginTop={2} maxHeight="20rem" overflow="auto">
        {notes.map((note) => {
          const { id, text, createdAt, createdBy, editedBy, updatedAt } = note;
          const { name: creator, photo, initials } = createdBy;
          const { name: editor } = editedBy || {};
          return (
            <Box key={id} marginBottom={1}>
              <ActionWithConfirm
                onAction={(onError) => onDelete(id, onError)}
                buttonProps={{ variant: '' }}
                showWarningText={false}
              >
                {({ actionButton }) => (
                  <Box padding={1}>
                    <Grid container spacing={2}>
                      <Grid item>
                        <Avatar src={photo} className={classes.userAvatar}>
                          {initials}
                        </Avatar>
                      </Grid>
                      <Grid item xs>
                        <Typography display="inline">{creator}</Typography>
                        <Box display="inline" marginLeft={2}>
                          {moment(createdAt).fromNow()}
                        </Box>
                        <Box>{text}</Box>
                      </Grid>
                      <Grid item>
                        <Box display="inline-block">
                          <Button onClick={() => setEdit(note)}>Edit</Button>
                        </Box>
                        <Box display="inline-block" marginLeft={2}>
                          <Button>{actionButton}</Button>
                        </Box>
                      </Grid>
                    </Grid>
                    {editor && (
                      <Box marginTop={1}>
                        <Typography variant="caption">
                          Edited By: {editor}{' '}
                          <Box display="inline" marginLeft={2}>
                            {moment(updatedAt).fromNow()}{' '}
                          </Box>
                        </Typography>
                      </Box>
                    )}
                  </Box>
                )}
              </ActionWithConfirm>
            </Box>
          );
        })}
        <Box ref={scrollRef}></Box>
      </Box>
      <Box marginTop={4}>
        <Box marginTop={2}>{form}</Box>
        <Box marginTop={6}>
          <Grid container justify="space-between">
            <Grid item></Grid>
            <Grid item>
              {edit && (
                <Button
                  onClick={() => setEdit()}
                  variant="contained"
                  style={{ marginRight: '2rem' }}
                >
                  Cancel Edit
                </Button>
              )}
              <SaveFormButton
                onSave={onUpsert}
                name={`${edit ? 'Edit' : 'Add'} Note`}
              />
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default UserInvestmentNotes;
