import React, { useState } from 'react';

import useListUpdate from 'hooks/useListUpdate';
import {
  EditDocument,
  ManageDocuments,
  useDocumentSave,
} from 'views/Documents';
import groupInvestmentsClient from 'api/groupInvestmentsClient';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import DialogWithTitle from 'components/ui/DialogWithTitle';

const documentTypes = [
  { value: 'spa', label: 'Stock Purchase Agreement (SPA)', short: 'SPA' },
  {
    value: 'safe',
    label: 'Simple Agreement for Future Equity (SAFE)',
    short: 'SAFE',
  },
  { value: 'sla', label: 'Side Letter Agreement (SLA)', short: 'SLA' },
  { value: 'sc', label: 'Stock Certificate', short: 'Certificate' },
  { value: 'term', label: 'Term Sheet', short: 'Term Sheet' },
  { value: 'other', label: 'Other' },
];

const GroupInvestmentDocuments = ({
  investment,
  setInvestment,
  canEdit = false,
}) => {
  const { roundName, syndicateName } = investment;

  const { id: investmentId, documents = [], isAdmin } = investment;

  const { onListUpdate } = useListUpdate('documents', setInvestment);

  const withSave = useDocumentSave({
    params: { investmentId },
    create: groupInvestmentsClient.createDocument,
    update: groupInvestmentsClient.updateDocument,
    remove: groupInvestmentsClient.deleteDocument,
    onChange: onListUpdate,
  });

  const [edit, setEdit] = useState();
  return (
    <Box>
      <Box>
        {syndicateName ? (
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {syndicateName}
          </Typography>
        ) : (
          <Typography variant="h6" style={{ fontWeight: 'bold' }}>
            {roundName}
          </Typography>
        )}
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs>
            <Box>
              <Typography variant="h6" color="primary">
                Investment Agreements & Certificates
              </Typography>
              <Typography variant="caption">
                Signed Investment Documents & Certificates between the Syndicate
                (LLC) and the company receiving investment. Examples are Stock
                Purchase Agreement (SPA), Simple Agreement for Future Equity
                (SAFE), and Stock Certificate.
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              {edit ? (
                <DialogWithTitle
                  open
                  onClose={() => setEdit(null)}
                  title={`${(edit || {}).id ? 'Edit' : 'Add'} Document`}
                  fullWidth
                >
                  <EditDocument
                    onClose={() => setEdit(null)}
                    {...{ data: edit, withSave, documentTypes }}
                    hasExtras
                  />
                </DialogWithTitle>
              ) : null}
              {isAdmin && canEdit && (
                <Box marginTop={2}>
                  <Button color="primary" onClick={() => setEdit({})}>
                    Add Agreement or Certificate
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <ManageDocuments
          {...{
            documents,
            withSave,
            canEdit,
            hasExtras: true,
            documentTypes,
          }}
        ></ManageDocuments>
      </Box>
    </Box>
  );
};

export default GroupInvestmentDocuments;
