/* eslint-disable react/display-name */
import React, { useState, useEffect, useMemo } from 'react';
import moment from 'moment';
import { useTour } from '@reactour/tour';
import {
  DataGridPro,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarQuickFilter,
} from '@mui/x-data-grid-pro';
import { useLocation } from 'react-router-dom';
import {
  Box,
  Grid,
  Typography,
  makeStyles,
  createStyles,
  useTheme,
  Link,
} from '@material-ui/core';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { useSelectedGroup, useProfile } from 'hooks/useAppState';

import UserOnboardingMessage from 'components/Message/UserOnboardingMessage';

import { LoadingSpinner } from 'components/ui/Loading';
import { SiWebpack } from 'react-icons/si';

import { useSyndicateActions } from 'hooks/useSyndicate';
import groupInvestmentsClient from 'api/groupInvestmentsClient';
import SummaryItem from 'views/UserDashboard/Summary/SummaryItem';
import { FaPiggyBank, FaMoneyCheckAlt } from 'react-icons/fa';
import { CustomGridColumnMenu } from 'components/ui/CustomGridColumnMenu';

const SyndicatesIndex = () => {
  const { firstName, managerGuideDismissed, syndicateMessageDismissed } =
    useProfile((state) => ({
      firstName: state.firstName,
      managerGuideDismissed: state.managerGuideDismissed,
      syndicateMessageDismissed: state.syndicateMessageDismissed,
    }));
  const { groupId, isAdmin } = useSelectedGroup((state) => ({
    groupId: state.id,
    isAdmin: state.isAdmin,
    slug: state.slug,
  }));

  // const handleSearch = (event) => {
  //   const val = event.target.value;
  //   clearTimeout(timer.current);
  //   timer.current = setTimeout(() => {
  //     setLoading(true);
  //     fetchData(groupId, val);
  //   }, 500);
  // };

  const theme = useTheme();

  const [investments, setInvestments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupInvestmentsClient.index({
          groupId,
        });
        setInvestments(
          response.data?.filter(
            (x) => x.syndicateName || x.syndicateData?.syndicateName,
          ),
        );
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  const { setIsOpen, isOpen, setSteps, setCurrentStep } = useTour();
  const location = useLocation();
  useEffect(() => {
    if (
      !managerGuideDismissed &&
      !isOpen &&
      isAdmin &&
      investments &&
      investments.length == 0
    ) {
      setSteps([
        {
          selector: '[data-tour="create-syndicate"]',
          content: 'This is where you can create a new syndicate.',
        },
        {
          selector: '[data-tour="investor-tab"]',
          content:
            'This is where you can invite and manage your investors for this group.',
        },
        {
          selector: '[data-tour="investor-button"]',
          content: 'Use this when you want to add investors to your group.',
        },
        {
          selector: '[data-tour="group-tab"]',
          content:
            'Here is where you can manage specific settings about your group. Such as the name, description, and logo.',
        },
        {
          selector: '[data-tour="create-syndicate"]',
          content: "All done! Now let's create your first syndicate.",
        },
      ]);
      setCurrentStep(0);
      setIsOpen(true);
    }
  }, [
    isAdmin,
    isOpen,
    location.pathname,
    managerGuideDismissed,
    setCurrentStep,
    setIsOpen,
    setSteps,
    investments,
  ]);

  const { syndicateButton } = useSyndicateActions({});

  const totalInvested =
    investments
      ?.map((x) => parseFloat(x.investedAmount || 0))
      .reduce((p, c) => p + c, 0) || 0;
  return (
    <>
      <Box marginBottom={4}>
        <Box>
          {!syndicateMessageDismissed && (
            <UserOnboardingMessage dismissField="syndicateMessageDismissed">
              <Typography variant="h5" gutterBottom>
                Welcome {firstName}
              </Typography>
              <Typography variant="body1">
                This is the syndicates view. Here you can see all the syndicates
                for your group.
              </Typography>
            </UserOnboardingMessage>
          )}
        </Box>
        <Box marginTop={!syndicateMessageDismissed ? 1 : 0}>
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <SummaryItem
                name="Syndicates"
                summaryTitle="The number of syndicates this group has"
                value={investments?.length || 0}
                Icon={FaPiggyBank}
                valueProps={{
                  style: { fontSize: 48, fontWeight: 300, lineHeight: 1 },
                }}
                iconFontSize={46}
                titleProps={{
                  style: {
                    fontSize: 24,
                    fontWeight: 700,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <SummaryItem
                name="Total Invested"
                summaryTitle="The total amount of money this group has invested"
                value={currencyFormatter.format(totalInvested)}
                valueProps={{
                  style: {
                    fontSize: totalInvested < 1000000 ? 24 : 20, // when digits are under 6 use the default 24, otherwise use 20
                    color: theme.palette.money.main,
                  },
                }}
                iconFontSize={46}
                titleProps={{
                  style: {
                    fontSize: 24,
                    fontWeight: 700,
                  },
                }}
                Icon={FaMoneyCheckAlt}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={2}>
          <Grid
            container
            spacing={3}
            justify="space-between"
            alignItems="flex-end"
          >
            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  {/* <Button
                    variant="contained"
                    color="primary"
                    data-tour="create-syndicate"
                    // onClick={() => createSyndicate()}
                  >
                    Create Syndicate
                  </Button> */}
                  {syndicateButton}
                </Grid>
                {/* <Grid item>
                  <Button
                    disabled={!cache || !cache.length}
                    variant="contained"
                    onClick={() => {
                      csvRef.current.link.click();
                    }}
                  >
                    Export CSV
                  </Button>
                  <Hidden>
                    <CSVLink
                      data={formattedData}
                      filename="investors.csv"
                      target="_blank"
                      ref={csvRef}
                    ></CSVLink>
                  </Hidden>
                </Grid> */}
              </Grid>
            </Grid>
            {/* <Grid item>
              <Box>
                <Grid container spacing={3}>
                  <Grid item>
                    <CustomSelect
                      size="small"
                      variant="outlined"
                      value={filterTerms}
                      onChange={(e) => setFilters(e.target.value)}
                    >
                      <MenuItem value="all">Show All</MenuItem>
                      <MenuItem value="closed">Closed</MenuItem>
                      <MenuItem value="collect">Collecting</MenuItem>
                      <MenuItem value="accept">Open</MenuItem>
                      <MenuItem value="inactive">Inactive</MenuItem>
                      <MenuItem value="paused">Paused</MenuItem>
                    </CustomSelect>
                  </Grid>
                  <Grid item>
                    <CustomTextField
                      inputRef={searchRef}
                      onChange={(e) => debouncedSearch(e.target.value)}
                      fullWidth
                      size="small"
                      variant="outlined"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      placeholder="Search Syndicates"
                    />
                  </Grid>
                </Grid>
              </Box>
            </Grid> */}
          </Grid>
        </Box>
        <Box marginTop={2}>
          {investments ? (
            <Box>
              <InvestmentsTable {...{ investments }} />
            </Box>
          ) : (
            <Box marginTop={4}>
              <LoadingSpinner />
            </Box>
          )}
        </Box>
      </Box>
    </>
  );
};

export const useTableStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDataGrid-root': {
      fontFamily: 'Lato, Helvetica, Arial, sans-serif',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  },
  moneyColor: {
    color: theme.palette.money.main,
  },
}));

const useOverlayStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    label: {
      marginTop: theme.spacing(1),
    },
  }),
);

export const CustomNoRowsOverlay = () => {
  const classes = useOverlayStyles();

  return (
    <GridOverlay className={classes.root}>
      <SiWebpack fontSize={50} style={{ fill: 'url(#mygradient)' }} />
      <div className={classes.label}>No Results</div>
    </GridOverlay>
  );
};

const searchFilter = (investments, searchTerms) => {
  if (!searchTerms || searchTerms.trim().length === 0) {
    return investments;
  }
  const terms = searchTerms.toLowerCase().trim().split(' ');

  return investments.filter((investment) => {
    const name = (
      investment.syndicateName || investment.syndicateData?.syndicateName
    )
      .toLowerCase()
      .trim();
    const oppName = investment.opportunityName.toLowerCase().trim();
    const roundName = (investment.roundName || '').toLowerCase().trim();
    return terms.every(
      (term) =>
        name.indexOf(term) !== -1 ||
        oppName.indexOf(term) !== -1 ||
        roundName.indexOf(term) !== -1,
    );
  });
};

const filterFilter = (investments, filter) => {
  if (!filter || filter == 'all') {
    return investments;
  }

  return investments.filter((investment) => {
    switch (filter) {
      case 'closed':
        return investment.status === 'Investment Closed';
      case 'collect':
        return investment.status === 'Collecting Interest';
      case 'accept':
        return investment.status === 'Accepting Investments';
      case 'inactive':
        return investment.status === 'Inactive';
      case 'paused':
        return investment.status === 'Paused Investments';
      default:
        return false;
    }
  });
};

export const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarFilterButton />
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
      <Box style={{ flex: 1 }} />
      <GridToolbarQuickFilter
        {...{
          debounceMs: 500,
          variant: 'outlined',
          size: 'small',
          placeholder: 'Search Syndicates',
        }}
      />
    </GridToolbarContainer>
  );
};

const InvestmentsTable = ({ investments, searchTerms, filterTerms }) => {
  const theme = useTheme();
  const { slug } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  const columns = useMemo(() => {
    return [
      {
        field: 'syndicateName',
        headerName: 'Name',
        flex: 2,
        valueGetter: ({ row }) =>
          row.syndicateName || row.syndicateData?.syndicateName,
        renderCell: ({ row, value }) => (
          <Link
            style={{ cursor: 'pointer' }}
            href={`/${slug}/syndicates/${row.id}`}
          >
            {value}
          </Link>
        ),
      },
      {
        field: 'opportunity',
        headerName: 'Opportunity',
        flex: 1.5,
        valueGetter: ({ row }) => row.opportunityName,
        renderCell: ({ row }) => (
          <Link
            style={{ cursor: 'pointer' }}
            href={`/${slug}/opportunity/${row.opportunityId}/edit`}
          >
            {row.opportunityName}
          </Link>
        ),
      },
      {
        field: 'round',
        headerName: 'Round',
        flex: 1,
        valueGetter: ({ row }) => row.roundName,
        renderCell: ({ row }) => (
          <Link
            style={{ cursor: 'pointer' }}
            href={`/${slug}/opportunity/${row.opportunityId}/edit/about/rounds`}
          >
            {row.roundName}
          </Link>
        ),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 125,
        type: 'singleSelect',
        valueOptions: [
          { value: 'Closed', label: 'Closed' },
          { value: 'Collecting', label: 'Collecting' },
          { value: 'Open', label: 'Open' },
          { value: 'Paused', label: 'Paused' },
        ],
        valueGetter: ({ row }) => {
          switch (row.status) {
            case 'Investment Closed':
              return 'Closed';
            case 'Accepting Investments':
              return 'Open';
            case 'Paused Investments':
              return 'Paused';
            case 'Collecting Interest':
              return 'Collecting';
            default:
              return row.status;
          }
        },
      },
      {
        field: 'investorCount',
        headerName: 'Investors',
        align: 'left',
        headerAlign: 'left',
        type: 'number',
        width: 140,
        renderCell: ({ value }) => <b>{value}</b>,
      },
      {
        field: 'investedAmount',
        headerName: 'Collected',
        align: 'left',
        headerAlign: 'left',
        type: 'number',
        width: 130,
        renderCell: ({ value }) => {
          return (
            <Box color={theme.palette.money.main}>
              {value ? currencyFormatter.format(value) : '-'}
            </Box>
          );
        },
      },
      {
        field: 'syndicateClosed',
        headerName: 'Closed',
        type: 'date',
        width: 125,
        valueGetter: ({ value }) => (value ? moment(value).toDate() : null),
        renderCell: ({ value }) => {
          return value ? moment(value).format('MM-DD-yyyy') : '-';
        },
      },
    ];
  }, [slug, theme.palette.money.main]);
  const classes = useTableStyles();

  const filteredInvestments = useMemo(() => {
    let data = investments;
    data = searchFilter(data, searchTerms);
    data = filterFilter(data, filterTerms);
    return data;
  }, [investments, searchTerms, filterTerms]);
  return (
    <DataGridPro
      className={classes.root}
      components={{
        NoRowsOverlay: CustomNoRowsOverlay,
        ColumnMenu: CustomGridColumnMenu,
        Toolbar: CustomToolbar,
      }}
      rows={filteredInvestments}
      columns={columns}
      rowsPerPageOptions={[10]}
      disableSelectionOnClick
      autoHeight
      disableColumnPinning
      disableColumnSelector
    />
  );
};
export default SyndicatesIndex;
