export const NewFeatureFlags = {
  syndicateSettings: {
    enabled: true,
    expiration: '2022-06-15',
  },
  updatedAddInvestorButton: {
    enabled: true,
    expiration: '2022-07-15',
  },
};

export const themeColors = {
  primaryColor: '#1546A6',
  primaryColorLight: '#8eb0f1',
  secondaryColor: '#5D26A4',
  secondaryColorLight: '#b894e6',
  accentColor: '#DC4592',
  accentLight: '#f7d4e6',
  linkColor: '#4547DC',
  moneyColor: '#007D05',
};
