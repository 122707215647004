import React, { useState } from 'react';
import { Box, Chip, Grid, Typography } from '@material-ui/core';

import Form from 'components/Form/Form';
import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { usePhoto } from 'components/ui/PhotoPicker';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import { FormikTextInput } from 'components/ui/CustomTextField';
import { HelpOutline } from '@material-ui/icons';

const EditAbout = ({ opportunity, onUpdate }) => {
  const { id } = opportunity;

  const { currentPhoto, photoPicker } = usePhoto(opportunity);

  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: {
          name,
          legalName,
          industry,
          oneLiner,
          bio,
          pitchVideoUrl,
          slideDeckUrl,
          websiteUrl,
        },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      const response = await opportunitiesClient.update({
        id,
        data: {
          name,
          legalName,
          industry,
          oneLiner,
          bio,
          pitchVideoUrl,
          slideDeckUrl,
          photo: currentPhoto.data,
          photoFileName: currentPhoto.name,
          websiteUrl,
        },
      });
      onUpdate((cache) => ({
        ...cache,
        ...response.data,
      }));
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  return (
    <>
      <Box>
        <Typography variant="h6" color="primary">
          Overview
        </Typography>
        <Typography variant="caption">
          This is the basic information about the opportunity.
        </Typography>
      </Box>
      <Box marginTop={4}>
        <Form
          initialValues={opportunity}
          enableReinitialize
          setRef={setFormRef}
        >
          <Grid container spacing={3}>
            <Grid item xs={6}>
              <Grid container spacing={4} direction="column">
                <Grid item>
                  <FormikTextInput
                    variant="filled"
                    name="name"
                    label="Opportunity Name"
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    variant="filled"
                    name="legalName"
                    label="Legal Name"
                    required
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    variant="filled"
                    name="industry"
                    label="Industry"
                  />
                </Grid>
                <Grid item>
                  <FormikTextInput
                    variant="filled"
                    name="oneLiner"
                    label="One Liner"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6}>
              {photoPicker}
            </Grid>
            <Grid item xs={6}>
              <FormikTextInput
                variant="filled"
                name="pitchVideoUrl"
                label="Pitch Video URL"
                url
              />
            </Grid>
            <Grid item xs={6}>
              <FormikTextInput
                variant="filled"
                name="websiteUrl"
                label="Website URL"
                url
              />
            </Grid>
            <Grid item xs={12}>
              <FormikTextInput
                variant="filled"
                name="slideDeckUrl"
                label="Slide Deck URL"
                url
              />
              <Box mt={1}>
                <Chip
                  variant="outlined"
                  color="primary"
                  label={
                    <Box display="flex" alignItems="center">
                      <HelpOutline
                        fontSize="small"
                        style={{ marginRight: '0.25rem' }}
                      />
                      <Typography variant="subtitle2">
                        If you have a slide deck, you can share it on Google
                        Slides and paste the URL here. To learn more about
                        creating a slide deck url, visit{' '}
                        <a
                          href="https://support.google.com/docs/answer/183965?hl=en"
                          target="_blank"
                          rel="noreferrer"
                        >
                          this link
                        </a>
                        .
                      </Typography>
                    </Box>
                  }
                ></Chip>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <FormikTextInput
                name="bio"
                variant="filled"
                label="Elevator Pitch"
                multiline
                rowsMax={6}
                rows={2}
              />
            </Grid>

            <Grid item xs={12}>
              <SaveFormButton onSave={onSave}></SaveFormButton>
            </Grid>
          </Grid>
        </Form>
      </Box>
    </>
  );
};

export default EditAbout;
