import React, { useCallback, useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  MenuItem,
  Link,
} from '@material-ui/core';

import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingArea } from 'components/ui/Loading';

import { CustomCheckBox } from 'components/ui/CustomCheckBox';
import { CustomSelect } from 'components/ui/CustomSelect';
import groupsClient from 'api/groups/groupsClient';
import DisplayAddress from 'components/ui/DisplayAddress';
import { useArrayUpdate } from 'hooks/useListUpdate';

import { useEditPaymentCheck } from 'hooks/forms/useFormPaymentCheck';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

const SyndicatePaymentCheck = ({ investment, setInvestment }) => {
  const { id, groupId, paymentCheck, acceptChecks } = investment;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [selected, setSelection] = useState(paymentCheck?.id);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.checks({
          id: groupId,
        });
        setList(response.data);
        setLoading(false);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  const { onListUpdate: updateListItem } = useArrayUpdate(setList);

  const handleAccept = (e) => {
    const doChange = async (val) => {
      const response = await groupInvestmentsClient.update({
        id,
        data: {
          acceptChecks: val,
        },
      });
      setLoading(false);
      setInvestment(response.data);
    };
    const val = e.target.checked;
    setLoading(true);
    doChange(val);
  };

  const handleSelection = useCallback(
    (val) => {
      const doChange = async () => {
        const response = await groupInvestmentsClient.update({
          id,
          data: {
            paymentCheckId: val.id,
          },
        });
        setLoading(false);
        setInvestment(response.data);
      };
      setLoading(true);
      doChange();
    },
    [id, setInvestment],
  );

  const { modal, showModal } = useEditPaymentCheck({
    groupId,
    onChange: (data) => {
      updateListItem(data);
      handleSelection(data);
      setSelection(data.id);
    },
    onCancel: () => {
      setSelection(paymentCheck?.id);
    },
  });

  useEffect(() => {
    if (selected == -1) {
      showModal({});
      setSelection(null);
    } else {
      const item = list.find((x) => x.id == selected);
      if (item && item.id != paymentCheck?.id) {
        handleSelection(item);
      }
    }
  }, [handleSelection, list, paymentCheck?.id, selected, showModal]);

  return (
    <Box position="relative">
      {modal}
      {loading && (
        <Box>
          <LoadingArea open />
        </Box>
      )}
      <Box
        borderBottom="1px solid lightgray"
        style={{ paddingBottom: '0.5rem' }}
        marginTop={4}
      >
        <Grid container justify="space-between">
          <Grid item>
            <Typography color="primary">Checks</Typography>
          </Grid>
          <Grid item>
            <CustomCheckBox
              label={
                <Box>
                  <Typography variant="subtitle1">Accept Checks?</Typography>
                </Box>
              }
              checked={acceptChecks}
              onChange={handleAccept}
              disabled={loading}
              indeterminate={loading}
            />
          </Grid>
        </Grid>
      </Box>
      {acceptChecks && (
        <Box marginTop={2}>
          <Paper>
            <Box padding={3}>
              <Grid container justify="space-between">
                <Grid item>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Box minWidth="20rem">
                          <CustomSelect
                            variant="filled"
                            label="Select Mailing Address"
                            required
                            displayEmpty
                            value={selected}
                            onChange={(e) => setSelection(e.target.value)}
                          >
                            {(list || []).map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                            <MenuItem key="new" value={-1}>
                              Add New Address
                            </MenuItem>
                          </CustomSelect>
                        </Box>
                      </Grid>
                      {paymentCheck && (
                        <Grid item>
                          <Box marginTop={2} style={{ cursor: 'pointer' }}>
                            <Typography>
                              <Link
                                onClick={() =>
                                  showModal(
                                    list.find((x) => x.id == paymentCheck.id),
                                  )
                                }
                              >
                                Edit
                              </Link>
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box marginTop={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showModal({})}
                    >
                      Add New
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {paymentCheck && (
                <Box marginTop={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                      Payable Name
                    </Grid>
                    <Grid item xs={8}>
                      {paymentCheck?.checkRecipient}
                    </Grid>
                    <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                      Mailing Address
                    </Grid>
                    <Grid item xs={8}>
                      <DisplayAddress
                        {...{
                          address: list.find((x) => x.id == paymentCheck.id)
                            ?.mailingAddress,
                        }}
                      />
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default SyndicatePaymentCheck;
