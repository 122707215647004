import { useCallback } from 'react';

import taxDocumentsClient from 'api/taxDocumentsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import useSmartDocument from './useSmartDocument';

const useTaxDocument = ({ id, onChange }) => {
  const getLink = useCallback(async () => {
    try {
      const response = await taxDocumentsClient.link({ id });
      return response.data;
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
    }
  }, [id]);
  const openLink = useCallback(async () => {
    const w = window.open('/loading.html', `document-${id}`);
    const link = await getLink(id);
    if (link) {
      w.location = link;
    }
  }, [getLink, id]);

  const onSave = useCallback(
    async (doc, onError) => {
      try {
        const response = await taxDocumentsClient.update({
          id,
          data: {
            document: doc ? doc.data : null,
            documentFileName: doc ? doc.name : null,
          },
        });
        const data = response.data;
        onChange(data);
        return true;
      } catch (e) {
        const errors = getServerResponseErrors(e);
        onError(errors);
      }
    },
    [id, onChange],
  );

  const { modal, showModal } = useSmartDocument({ onSave, getLink });

  return { modal, showModal, getLink, openLink };
};

export default useTaxDocument;
