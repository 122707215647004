import {
  GETTING_CURRENT_USER_ERROR,
  GETTING_CURRENT_USER_SUCCESS,
  GETTING_CURRENT_USER_START,
  SIGNING_IN_START,
  SIGNING_IN_SUCCESS,
  SIGNING_IN_ERROR,
  SIGN_OUT_CURRENT_USER,
  SET_SELECTED_GROUP,
  CLEAR_SELECTED_GROUP,
  UPDATE_CURRENT_USER_DATA,
} from './types';

const initialState = {
  data: undefined,
  flags: {
    gettingCurrentUser: true,
    gettingCurrentUserError: false,
    signingIn: true,
    signingInError: false,
  },
};

const currentUserReducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case GETTING_CURRENT_USER_START:
      return {
        ...state,
        flags: {
          ...initialState.flags,
          gettingCurrentUser: true,
        },
      };
    case GETTING_CURRENT_USER_SUCCESS: {
      const groups = action.payload.userGroups;
      return {
        ...state,
        flags: {
          ...state.flags,
          gettingCurrentUser: false,
          gettingCurrentUserError: undefined,
        },
        data: action.payload.userData,
        groups,
      };
    }
    case GETTING_CURRENT_USER_ERROR:
      return {
        ...state,
        flags: {
          ...initialState.flags,
          gettingCurrentUser: false,
          gettingCurrentUserError: action.payload,
        },
      };
    case SIGNING_IN_START:
      return {
        ...state,
        flags: {
          ...state.flags,
          signingIn: true,
        },
      };
    case SIGNING_IN_SUCCESS:
      return {
        ...state,
        flags: {
          ...state.flags,
          signingIn: false,
          signingInError: undefined,
        },
      };
    case SIGNING_IN_ERROR: {
      return {
        ...state,
        flags: {
          ...initialState.flags,
          signingInError: action.payload,
        },
      };
    }
    case SIGN_OUT_CURRENT_USER:
      return {
        ...state,
        flags: {
          ...initialState.flags,
          gettingCurrentUser: false,
        },
      };
    case SET_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: action.payload,
      };
    case CLEAR_SELECTED_GROUP:
      return {
        ...state,
        selectedGroup: undefined,
      };
    case UPDATE_CURRENT_USER_DATA:
      return {
        ...state,
        data: {
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

export default currentUserReducer;
