import React, { useEffect, useState } from 'react';

import { useSelectedGroup } from 'hooks/useAppState';
import userInvestmentsClient from 'api/userInvestmentsClient';
import InvestmentInstructions from 'views/UserInvestment/InvestmentInstructions';
import getServerResponseErrors from 'api/getServerResponseErrors';
import useStateNotification from 'hooks/useStateNotification';

const OpportunitySectionInstructions = ({ opportunity }) => {
  const [userInvestment, setUserInvestment] =
    useStateNotification('user_investments');

  const groupId = useSelectedGroup((state) => state.id);
  const { id: opportunityId } = opportunity;

  const { id, subscriptionSignatureId } = userInvestment || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.active_investment({
          groupId,
          opportunityId,
        });
        setUserInvestment(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId && opportunityId) {
      fetchData();
    }
  }, [groupId, opportunityId, setUserInvestment]);

  const [instructions, setInstructions] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.instructions({
          id,
        });
        setInstructions(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id && subscriptionSignatureId) {
      fetchData();
    }
  }, [id, subscriptionSignatureId]);

  const { visible } = instructions || {};

  // const managerSigned = signatures.find((x) => x.memberType === 'manager')
  //   ?.dateSigned;
  if (visible) {
    return (
      <InvestmentInstructions
        {...{ userInvestment, instructions }}
      ></InvestmentInstructions>
    );
  }
  return null;
};

export default OpportunitySectionInstructions;
