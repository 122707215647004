import React, { useEffect, useState } from 'react';

import { useSelectedGroup } from 'hooks/useAppState';
import opportunitiesClient from 'api/opportunitiesClient';

import getServerResponseErrors from 'api/getServerResponseErrors';

import { useSyndicateActions } from 'hooks/useSyndicate';
import SaveFormButton from 'components/Form/SaveFormButton';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

import CloseSyndicateButton from 'views/Syndicate/CloseSyndicateButton';
import { useHistory } from 'react-router-dom';

const InvestmentWidget = ({ opportunity, hideSyndicateButton = false }) => {
  const { id: opportunityId } = opportunity;
  const { groupId, isAdmin } = useSelectedGroup((state) => ({
    groupId: state.id,
    isAdmin: state.isAdmin,
  }));

  const [isLoading, setLoading] = useState(true);
  const [investment, setInvestment] = useState();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await opportunitiesClient.investments({
          id: opportunityId,
          groupId,
        });
        const investments = response.data;
        if (investments.length > 0) {
          // setPrevious(true);
        }
        const temp = investments.find((x) => !x.dateClosed);
        setInvestment(temp);
        setLoading(false);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (opportunityId && groupId) {
      fetchData();
    }
  }, [opportunityId, groupId, setInvestment]);

  if (isLoading || !isAdmin) {
    return null;
  }
  const { id: investmentId } = investment || {};

  return (
    <InvestmentActions
      {...{
        opportunityId,
        investmentId,
        hideSyndicateButton,
      }}
    />
  );
};

const InvestmentActions = ({
  opportunityId,
  investmentId,
  startOpen = false,
  hideSyndicateButton = false,
}) => {
  const history = useHistory();
  const { groupId, slug } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));

  const { syndicate, investment, setInvestment, syndicateButton } =
    useSyndicateActions({
      opportunityId,
      investmentId,
      startOpen,
      hideSyndicateButton,
    });
  const reactivateSyndicate = async (inactiveInvestmentId) => {
    const result = await groupInvestmentsClient.update({
      id: inactiveInvestmentId,
      groupId,
      opportunityId,
      data: {
        disableSubscriptions: true,
        dateClosed: null,
      },
    });
    history.push(`/${slug}/syndicates/${inactiveInvestmentId}`);
    setInvestment(result.data);
  };

  const upsertSyndicate = async () => {
    const upsert =
      id && !(dateClosed && dateFiledOfferingAgreements)
        ? groupInvestmentsClient.update
        : groupInvestmentsClient.create;
    const response = await upsert({
      id,
      groupId,
      opportunityId,
      data: {
        disableSubscriptions: true,
        dateClosed: null,
      },
    });
    const { id: investmentId } = response.data;
    history.push(`/${slug}/syndicates/${investmentId}`);
    setInvestment(response.data);
  };

  const { id, dateClosed, disableSubscriptions } = investment || {};

  const { dateFiledOfferingAgreements } = syndicate || {};

  const handleCollect = async (onError) => {
    try {
      // check if there is an inactive investment
      // if there is, then we need to reactivate it
      const syndicateResponse = await groupInvestmentsClient.index({
        groupId,
        opportunityId,
        active: false,
      });
      const inactiveSyndicate = syndicateResponse.data.find(
        (x) => x.status === 'Inactive',
      );
      if (inactiveSyndicate) {
        // reactivate the investment
        reactivateSyndicate(inactiveSyndicate.id);
      } else {
        upsertSyndicate();
      }
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };

  return (
    <>
      {syndicate && dateFiledOfferingAgreements && !dateClosed ? (
        <CloseSyndicateButton {...{ syndicate, id, setInvestment }} />
      ) : (
        <>
          {(!id ||
            (dateClosed &&
              (dateFiledOfferingAgreements ||
                (!dateFiledOfferingAgreements && disableSubscriptions)))) && (
            <SaveFormButton
              onSave={async (onError, minDelay) =>
                await handleCollect(onError, minDelay, false)
              }
              name="Collect Interest"
              variant="contained"
              color="primary"
              style={{ marginRight: '1rem' }}
            />
          )}
          {syndicateButton}
        </>
      )}
    </>
  );
};

export default InvestmentWidget;
