import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Typography,
  Grid,
  FormControlLabel,
  Checkbox,
  MenuItem,
} from '@material-ui/core';
import getServerResponseErrors from 'api/getServerResponseErrors';
import * as yup from 'yup';

import userInvestmentsClient from 'api/userInvestmentsClient';

import Form from 'components/Form/Form';
import {
  CustomCurrencyInput,
  FormikCurrencyInput,
} from 'components/ui/CustomCurrencyInput';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import UserInvestmentProfile from './UserInvestmentProfile';
import { LoadingArea, LoadingSpinner } from 'components/ui/Loading';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import { FormikTextInput } from 'components/ui/CustomTextField';

import { FormikDatePicker } from 'components/ui/CustomDatePicker';
import { SendReminder } from './SendReminder';
import { CustomSelect } from 'components/ui/CustomSelect';
import useSyndicateDocumentPoll from 'hooks/useSyndicateDocumentPoll';
import { useDocument } from 'components/ui/DocumentPicker';

const DocumentReview = ({ userInvestment, onChange }) => {
  const {
    entityType,
    isAuthorizedSigner,
    signerName,
    signerEmail,
    subscriptionSignatureId,
    // signerPhone,
    // subscriptionSignature,
  } = userInvestment;

  const {
    data: subscription,
    openLink,
    clicked,
  } = useSyndicateDocumentPoll({
    id: subscriptionSignatureId,
    role: 'manager',
  });

  const {
    isReady,
    documentData,
    draftData,
    signatures = [],
    isExpired,
  } = subscription || {};
  const hasCustodian = entityType == 'ira';
  const investorSigned = signatures.find(
    (x) => x.memberType === 'person',
  )?.dateSigned;
  const custodianSigned = signatures.find(
    (x) => x.memberType === 'custodian',
  )?.dateSigned;
  const managerSigned = signatures.find(
    (x) => x.memberType === 'manager',
  )?.dateSigned;

  const waitingInvestorSignature = !isAuthorizedSigner && !investorSigned;
  const waitingCustodianSignature =
    !isAuthorizedSigner && hasCustodian && !custodianSigned;

  const isWaiting =
    !managerSigned && (waitingInvestorSignature || waitingCustodianSignature);

  return (
    <>
      {isExpired ? (
        <Box height="10rem">
          <CenteredContentBox>
            <Box>
              <Typography>
                Your Subscription Document has expired, you will need to restart
                your subscription.
              </Typography>
            </Box>
          </CenteredContentBox>
        </Box>
      ) : investorSigned && managerSigned && !documentData ? (
        <Box height="10rem">
          <CenteredContentBox>
            <Box>
              <Typography>
                Signature Page is currently stored on an external system
              </Typography>
            </Box>
          </CenteredContentBox>
        </Box>
      ) : !documentData && (!isReady || !draftData) ? (
        <Box height="10rem">
          <CenteredContentBox>
            <Box>
              <Typography>Preparing Signature Page ...</Typography>
              <LoadingSpinner></LoadingSpinner>
            </Box>
          </CenteredContentBox>
        </Box>
      ) : (
        <Box minHeight="10rem" bgcolor="white" textAlign="center" padding={2}>
          <CenteredContentBox>
            <Button
              variant={!isWaiting ? 'contained' : 'outlined'}
              color="primary"
              onClick={openLink}
              disabled={!subscription}
            >
              {!managerSigned && !isWaiting ? 'Sign' : 'Review'} Signature Pages
            </Button>
            {!managerSigned && !isWaiting && clicked ? (
              <Box marginTop={2}>
                <Typography variant="subtitle1">
                  Awaiting Signature Confirmation ...
                </Typography>
                <LoadingSpinner></LoadingSpinner>
              </Box>
            ) : waitingInvestorSignature ? (
              <Box padding={2} textAlign="center">
                <Box>
                  <Typography>Awaiting signature from {signerName}.</Typography>
                </Box>
                <Box>
                  <Typography variant="caption">{signerEmail}</Typography>
                </Box>
                <Box marginTop={1}>
                  <SendReminder {...{ userInvestment, onChange }} />
                </Box>
              </Box>
            ) : waitingCustodianSignature ? (
              <Box padding={2} textAlign="center">
                <Box>
                  <Typography>Awaiting signature from custodian.</Typography>
                </Box>
                <Box marginTop={1}>
                  <SendReminder {...{ userInvestment, onChange }} />
                </Box>
              </Box>
            ) : (
              investorSigned &&
              managerSigned && (
                <Box>
                  <Typography variant="caption">(completed)</Typography>
                </Box>
              )
            )}
          </CenteredContentBox>
        </Box>
      )}
    </>
  );
};

const AdminEditUserInvestment = ({
  hasSyndicate,
  unitPrice,
  data,
  edit = {},
  onChange,
  onClose,
  isFake,
  addInvestor,
  canCollect,
  managerSigned,
}) => {
  const [isLoading, showLoading] = useState(false);
  const [userInvestment, setUserInvestment] = useState(data);
  const { id } = userInvestment || {};
  const [isLead, setLead] = useState(false);
  const [formRef, setFormRef] = useState({});
  const { subscription, isUndecided, interestAmount, dateReviewed } =
    userInvestment || {};
  const { signatures = [] } = subscription || {};
  const interest = parseFloat(interestAmount);
  const investorSigned = signatures.find(
    (x) => x.memberType === 'person',
  )?.dateSigned;
  const { currentDocument, documentPicker } = useDocument(
    subscription,
    'document',
    null,
    'Replace Signature Pages',
  );
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.get({ id });
        const temp = { ...response.data, ...edit };
        setUserInvestment(temp);
        setLead(temp?.isLead);
        showLoading(false);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      showLoading(true);
      fetchData();
    }
  }, [id, edit]);

  useEffect(() => {
    if (isFake && !userInvestment) {
      setUserInvestment({ ...edit });
    }
  }, [isFake, edit, userInvestment]);

  const onSave = async (onError) => {
    try {
      const {
        values: {
          interestAmount,
          amountReceived,
          refundAmount,
          dateReceived,
          dateRefunded,
          interested,
          isUndecided,
          dateInterest,
          dateAccepted,
          dateReviewed,
          acceptedUnits: acceptedUnitsValue,
          requestedUnits: requestedUnitsValue,
        },
        errors,
      } = await validateForm(formRef);
      const refund = parseFloat(refundAmount) || 0;
      const collected = parseFloat(amountReceived) || 0;
      if (refund > collected) {
        errors.push(
          'Refund Amount cannot be greater than the Collected Amount',
        );
      }
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      let investor = null;
      if (isFake) {
        investor = await addInvestor(false);
      }
      const response = await userInvestmentsClient.update({
        id: id || investor?.id,
        data: {
          interestAmount: interested == 'no' ? -1 : interestAmount,
          amountReceived: collected,
          refundAmount: refund,
          isLead,
          dateReceived,
          dateRefunded,
          isUndecided,
          dateInterest,
          dateAccepted,
          dateReviewed,
          acceptedUnits: acceptedUnitsValue,
          requestedUnits: requestedUnitsValue,
        },
      });
      if (currentDocument && currentDocument.uri) {
        await userInvestmentsClient.uploadSignature({
          id,
          data: {
            acceptedUnits: acceptedUnitsValue,
            requestedUnits: requestedUnitsValue,
            attachment: currentDocument ? currentDocument.data : null,
            attachmentFileName: currentDocument ? currentDocument.name : null,
          },
        });
      }
      const data = response.data;
      setLead(data.isLead);
      // onChange(response.data);
      onChange(data);
      onClose();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const onUpdate = (temp) => {
    setUserInvestment(temp);
    onChange(temp);
  };

  if (isLoading) {
    return (
      <Box
        padding={3}
        border="1px solid lightgray"
        bgcolor="white"
        width="100%"
        height="15rem"
        position="relative"
      >
        <LoadingArea open />
      </Box>
    );
  }

  return (
    <Box>
      <Form
        initialValues={{
          ...userInvestment,
          interestAmount: interestAmount < 0 ? 0 : interestAmount,
          interested: interest < 0 ? 'no' : isUndecided ? 'maybe' : 'yes',
        }}
        enableReinitialize
        setRef={setFormRef}
        // validationSchema={validationSchema}
      >
        {(formikFields) => {
          const { setFieldValue, values } = formikFields;

          const {
            interested,
            amountReceived,
            refundAmount,
            acceptedUnits,
            requestedUnits,
          } = values;
          const proposedAmount = requestedUnits * unitPrice;
          const acceptedAmount = acceptedUnits * unitPrice;
          let collectionError = 0;
          if (acceptedAmount > 0 && amountReceived > 0) {
            collectionError = amountReceived - refundAmount - acceptedAmount;
          } else if (proposedAmount > 0 && amountReceived > 0) {
            collectionError = amountReceived - refundAmount - proposedAmount;
          }

          const handleInterest = (e) => {
            const val = e.target.value;
            setFieldValue('interested', val);
            if (val == 'no') {
              setFieldValue('interestAmount', 0);
              setFieldValue('isUndecided', false);
            } else if (val == 'yes') {
              setFieldValue('isUndecided', false);
            } else {
              setFieldValue('isUndecided', true);
            }
          };

          return (
            <Box marginTop={1}>
              <Grid container spacing={6}>
                <Grid item xs={4}>
                  <Box>
                    <Box>
                      <UserInvestmentProfile {...{ userInvestment }} />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={isLead}
                            onChange={(e) => setLead(e.target.checked)}
                          />
                        }
                        label={
                          <Typography variant="subtitle2">
                            Make this investor a lead.
                          </Typography>
                        }
                      />
                    </Box>
                    {subscription && (
                      <>
                        <Box marginTop={6}>
                          <DocumentReview
                            {...{
                              userInvestment,
                              onChange: onUpdate,
                            }}
                          />
                        </Box>
                        <Box>{documentPicker}</Box>
                      </>
                    )}
                  </Box>
                </Grid>
                <Grid item xs={8}>
                  <Box padding={1}>
                    <Grid container spacing={2}>
                      <Grid item xs={4}>
                        <CustomSelect
                          label="Interested"
                          variant="filled"
                          value={interested}
                          disabled={dateReviewed || investorSigned}
                          onChange={handleInterest}
                        >
                          <MenuItem value="yes">Yes</MenuItem>
                          <MenuItem value="no">Pass</MenuItem>
                        </CustomSelect>
                      </Grid>
                      <Grid item xs={4}>
                        <FormikCurrencyInput
                          name="interestAmount"
                          label="Interested Amount"
                          variant="filled"
                          disabled={requestedUnits > 0 || interested == 'no'}
                          required={
                            interested == 'yes' && (!id || requestedUnits == 0)
                          }
                          validation={
                            interested == 'yes' && (!id || requestedUnits == 0)
                              ? yup
                                  .number()
                                  .moreThan(
                                    0,
                                    'Interest amount must be greater than 0',
                                  )
                              : null
                          }
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <FormikDatePicker
                          label="Interested Date"
                          name="dateInterest"
                          variant="filled"
                        />
                      </Grid>

                      {hasSyndicate && (
                        <>
                          <Grid item xs={4}>
                            <FormikTextInput
                              label="Proposed Units"
                              name="requestedUnits"
                              type="number"
                              variant="filled"
                              validation={yup.number().min(0)}
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <CustomCurrencyInput
                              label="Proposed Amount"
                              value={proposedAmount}
                              variant="filled"
                              disabled
                            />
                          </Grid>
                          <Grid item xs={4}>
                            <FormikDatePicker
                              label="Proposed Date"
                              name="dateReviewed"
                              variant="filled"
                            />
                          </Grid>

                          {(canCollect || acceptedUnits > 0) && (
                            <>
                              <Grid item xs={4}>
                                <FormikTextInput
                                  label="Accepted Units"
                                  name="acceptedUnits"
                                  type="number"
                                  variant="filled"
                                  validation={yup.number().moreThan(0)}
                                  disabled={
                                    !managerSigned && !currentDocument?.uri
                                  }
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <CustomCurrencyInput
                                  label="Accepted Amount"
                                  value={acceptedAmount}
                                  variant="filled"
                                  disabled
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormikDatePicker
                                  label="Accepted Date"
                                  name="dateAccepted"
                                  variant="filled"
                                  disabled={
                                    !managerSigned && !currentDocument?.uri
                                  }
                                />
                              </Grid>

                              {collectionError != 0 ? (
                                <Grid item xs={4}>
                                  <CustomCurrencyInput
                                    label={`${
                                      collectionError > 0
                                        ? 'Overfunded'
                                        : 'Underfunded'
                                    } Amount`}
                                    value={collectionError}
                                    variant="filled"
                                    disabled
                                    error={true}
                                  />
                                </Grid>
                              ) : (
                                <Grid item xs={4} />
                              )}
                              <Grid item xs={4}>
                                <FormikCurrencyInput
                                  label="Collected Amount"
                                  name="amountReceived"
                                  variant="filled"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormikDatePicker
                                  label="Collected Date"
                                  name="dateReceived"
                                  variant="filled"
                                />
                              </Grid>
                              <Grid item xs={4} />
                              <Grid item xs={4}>
                                <FormikCurrencyInput
                                  label="Refunded Amount"
                                  name="refundAmount"
                                  variant="filled"
                                />
                              </Grid>
                              <Grid item xs={4}>
                                <FormikDatePicker
                                  label="Refunded Date"
                                  name="dateRefunded"
                                  variant="filled"
                                />
                              </Grid>

                              <Grid item xs={4} />
                              <Grid item xs={4}>
                                <CustomCurrencyInput
                                  label="Invested Amount"
                                  variant="filled"
                                  value={amountReceived - refundAmount}
                                  disabled
                                  inputProps={{ allowNegative: true }}
                                />
                              </Grid>
                              <Grid item xs={4} />
                            </>
                          )}
                        </>
                      )}
                    </Grid>
                  </Box>
                </Grid>
              </Grid>
            </Box>
          );
        }}
      </Form>
      <Box marginTop={4}>
        <Grid container spacing={3} justify="space-between">
          <Grid item>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid container item spacing={2} xs justify="flex-end">
            {/* <Grid item>
              <Button
                variant="contained"
                color="primary"
                href={link}
                target="_blank"
                disabled={!link}
              >
                Review Document {hasSigned ? ' ' : 'and Sign '}
                {!link && <LoadingSpinner size={20}></LoadingSpinner>}
              </Button>
            </Grid> */}
            <Grid item>
              <SaveFormButton onSave={onSave} name="Save"></SaveFormButton>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default AdminEditUserInvestment;
