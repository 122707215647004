import React, { useState, useEffect } from 'react';
import { Grid, Typography, Button } from '@material-ui/core';

import { DialogWithActions } from 'components/ui/DialogWithTitle';
import FilingInfo from './FilingInfo';
import syndicateFilingsClient from 'api/syndicateFilingsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

const SyndicateFilingInfo = ({ syndicate, investment }) => {
  const { id } = syndicate || {};
  const [filing, showFiling] = useState(false);
  const [filings, setFilings] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicateFilingsClient.index({
          syndicateId: id,
        });
        setFilings(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);
  return (
    <>
      <Grid container spacing={3} alignItems="center">
        <Grid item xs>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Filing Info
          </Typography>
        </Grid>
        <Grid item>
          {filing && (
            <DialogWithActions
              maxWidth="xs"
              {...{
                title: `Filing Info`,
                onClose: () => showFiling(false),
              }}
            >
              <FilingInfo {...{ investment, syndicate }} />
            </DialogWithActions>
          )}
          <Button
            variant="contained"
            disabled={!filings}
            onClick={() => showFiling(true)}
          >
            Filing Info
          </Button>
        </Grid>
      </Grid>
    </>
  );
};

export default SyndicateFilingInfo;
