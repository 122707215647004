import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button } from '@material-ui/core';

import FormWizard from './FormWizard';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';

import syndicatesClient from 'api/syndicatesClient';
import SaveFormButton from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';

import InvestmentCard from './ReviewCards/InvestmentCard';
import AddressCard from './ReviewCards/AddressCard';
import ClosingDatesCard from './ReviewCards/ClosingDatesCard';
import StateFilingsCard from './ReviewCards/StateFilingsCard';
import ManagersCard from './ReviewCards/ManagersCard';
import FeesCard, { SoftwareFees } from './ReviewCards/FeesCard';
import MinimumsCard from './ReviewCards/MinimumsCard';
import BankingCard from './ReviewCards/BankingCard';
import groupManagersClient from 'api/groupManagersClient';
import SyndicateFormBlueSky, {
  useSyndicateFormBlueSky,
} from './SyndicateFormBlueSky';
import { DialogWithActions } from 'components/ui/DialogWithTitle';
import SyndicateFormOpportunity from './SyndicateFormOpportunity';
import { handleInvestment } from './SyndicateFormationFlow';
import SyndicateFormBusiness from './SyndicateFormBusiness';
import SyndicateFormManagers from './SyndicateFormManagers';
import SyndicateFormFees from './SyndicateFormFees';
import SyndicateFormUnitPricing from './SyndicateFormUnitPricing';
import SyndicateFormClosingDates from './SyndicateFormClosingDates';
import SyndicateFormBanking from './SyndicateFormBanking';

export const useEditModal = ({ title }) => {
  const [open, setOpen] = React.useState(false);
  const [children, setChildren] = useState();
  const handleOpen = (form) => {
    setOpen(true);
    setChildren(form);
  };
  const handleClose = () => {
    setOpen(false);
    setChildren();
  };
  const modal = (
    <DialogWithActions
      {...{
        open,
        onClose: handleClose,
        title,
        fullWidth: true,
        maxWidth: 'lg',
        portal: true,
      }}
    >
      {children}
    </DialogWithActions>
  );
  return {
    modal,
    handleOpen,
    handleClose,
  };
};

const useSyndicateFormReview = ({
  data,
  investment,
  showFormationInfo = true,
  showOfferingInfo = true,
  smartcapitalFeeBase,
  smartcapitalFeePerMember,
  smartcapitalFeePercent,
  setInvestment,
  setSyndicate,
}) => {
  const [investmentState, setInvestmentState] = useState(investment || {});
  const { opportunityName, roundName } = investment || {};
  const {
    id,
    business,
    blueSky,
    shouldFile,
    primaryManager,
    managementFeeBase,
    managementFeePerMember,
    managementFeePercent,
    carryAmount,
    unitPrice,
    minimumUnits,
    initialClosing,
    finalClosing,
    syndicateName,
    dateFiledReviewed,
    useSmartCapitalAccount,
  } = data || {};
  const { groupId, paymentWire, paymentCheck, acceptWires, acceptChecks } =
    investment || {};
  const { estimate: blueSkyEstimate } = useSyndicateFormBlueSky({
    groupId,
    data,
  });
  const { modal, handleOpen, handleClose } = useEditModal({
    title: 'Edit Syndicate',
  });
  const [checked, setChecked] = useState(!!id);
  const validate = async () => {
    return {
      errors: checked
        ? []
        : ['You must confirm that you have reviewed the data'],
      values: {},
    };
  };
  const { groupManagerId } = primaryManager || {};
  const [groupManager, setManager] = useState();
  const handleSave = (data, callback) => {
    return async (val, onError) => {
      try {
        const response = await syndicatesClient.update({
          id,
          data: {
            ...data,
            ...val,
          },
        });
        callback(response.data);
      } catch (e) {
        onError(getServerResponseErrors(e));
        console.log(getServerResponseErrors(e));
      }
    };
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupManagersClient.get({
          id: groupManagerId,
        });
        setManager(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (groupManagerId) {
      fetchData();
    }
  }, [groupManagerId]);
  const handleBankOpen = (investState) =>
    handleOpen(() => (
      <SyndicateFormBanking
        {...{
          investment: investState,
          setInvestment: (val) => {
            setInvestmentState(val);
            handleBankOpen(val);
          },
          groupId,
          onNext: handleSave(data, (val) => {
            setSyndicate(val);
            handleClose();
          }),
          onClose: handleClose,
          buttonName: 'Save',
        }}
      />
    ));
  const form = (
    <>
      {modal}
      <Box>
        {showFormationInfo && (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <InvestmentCard // dry this out
                {...{
                  syndicateName,
                  roundName,
                  opportunityName,
                  editClick: () =>
                    handleOpen(() => (
                      <SyndicateFormOpportunity
                        {...{
                          groupId,
                          opportunityId: investment?.opportunityId,
                          data,
                          investment,
                          onNext: handleInvestment(
                            investment,
                            groupId,
                            investment?.id,
                            (val) => {
                              setInvestment(val);
                              handleClose();
                            },
                            'review',
                          ),
                          onClose: handleClose,
                          buttonName: 'Save',
                        }}
                      />
                    )),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <AddressCard
                {...{
                  business,
                  editClick: () =>
                    handleOpen(() => (
                      <SyndicateFormBusiness
                        {...{
                          groupId,
                          opportunityId: investment?.opportunityId,
                          data,
                          investment,
                          onNext: (val, error) =>
                            handleInvestment(
                              investment,
                              groupId,
                              investment?.id,
                              (val) => {
                                setInvestment(val);
                                handleClose();
                              },
                              'review',
                            )({ syndicateData: { ...val } }, error),
                          onClose: handleClose,
                          buttonName: 'Save',
                        }}
                      />
                    )),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <StateFilingsCard
                {...{
                  shouldFile,
                  blueSky,
                  blueSkyEstimate,
                  id,
                  editClick: () =>
                    handleOpen(() => (
                      <SyndicateFormBlueSky
                        {...{
                          groupId,
                          opportunityId: investment?.opportunityId,
                          data,
                          investment,
                          onNext: (val, error) =>
                            handleInvestment(
                              investment,
                              groupId,
                              investment?.id,
                              (val) => {
                                setInvestment(val);
                                handleClose();
                              },
                              'review',
                            )({ syndicateData: { ...val } }, error),
                          onClose: handleClose,
                          buttonName: 'Save',
                        }}
                      />
                    )),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <ManagersCard
                {...{
                  dateFiledReviewed,
                  groupManager,
                  groupManagerPerson: primaryManager,
                  editClick: () =>
                    handleOpen(() => (
                      <SyndicateFormManagers
                        {...{
                          groupId,
                          opportunityId: investment?.opportunityId,
                          data,
                          investment,
                          onNext: (val, error) =>
                            handleInvestment(
                              investment,
                              groupId,
                              investment?.id,
                              (val) => {
                                setInvestment(val);
                                handleClose();
                              },
                              'review',
                            )({ syndicateData: { ...val } }, error),
                          onClose: handleClose,
                          buttonName: 'Save',
                        }}
                      />
                    )),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <SoftwareFees
                {...{
                  smartcapitalFeeBase,
                  smartcapitalFeePerMember,
                  smartcapitalFeePercent,
                  editClick: () =>
                    handleOpen(() => (
                      <SyndicateFormFees
                        {...{
                          groupId,
                          opportunityId: investment?.opportunityId,
                          data,
                          investment,
                          smartcapitalFeeBase,
                          smartcapitalFeePerMember,
                          smartcapitalFeePercent,
                          showManagementFees: false,
                          onNext: (val, error) =>
                            handleInvestment(
                              investment,
                              groupId,
                              investment?.id,
                              (val) => {
                                setInvestment(val);
                                handleClose();
                              },
                              'review',
                            )({ syndicateData: { ...val } }, error),
                          onClose: handleClose,
                          buttonName: 'Save',
                        }}
                      />
                    )),
                }}
              />
            </Grid>
          </Grid>
        )}
        {showOfferingInfo && (
          <Grid container spacing={2}>
            <Grid item md={6}>
              <MinimumsCard
                {...{
                  unitPrice,
                  minimumUnits,
                  editClick: () =>
                    handleOpen(() => (
                      <SyndicateFormUnitPricing
                        {...{
                          data,
                          onNext: handleSave(data, (val) => {
                            setSyndicate(val);
                            handleClose();
                          }),
                          onClose: handleClose,
                          buttonName: 'Save',
                        }}
                      />
                    )),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <ClosingDatesCard
                {...{
                  initialClosing,
                  finalClosing,
                  editClick: () =>
                    handleOpen(() => (
                      <SyndicateFormClosingDates
                        {...{
                          data,
                          onNext: handleSave(data, (val) => {
                            setSyndicate(val);
                            handleClose();
                          }),
                          onClose: handleClose,
                          buttonName: 'Save',
                        }}
                      />
                    )),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <BankingCard
                {...{
                  dateFiledReviewed,
                  useSmartCapitalAccount,
                  paymentWire,
                  paymentCheck,
                  acceptWires,
                  acceptChecks,
                  groupId,
                  editClick: () => handleBankOpen(investmentState),
                }}
              />
            </Grid>
            <Grid item md={6}>
              <FeesCard
                {...{
                  managementFeeBase,
                  managementFeePerMember,
                  managementFeePercent,
                  carryAmount,
                  editClick: () =>
                    handleOpen(() => (
                      <SyndicateFormFees
                        {...{
                          data,
                          smartcapitalFeeBase,
                          smartcapitalFeePerMember,
                          smartcapitalFeePercent,
                          onNext: handleSave(data, (val) => {
                            setSyndicate(val);
                            handleClose();
                          }),
                          onClose: handleClose,
                          buttonName: 'Save',
                        }}
                      />
                    )),
                  showFormationInfo,
                  showOfferingInfo,
                }}
              />
            </Grid>
          </Grid>
        )}
      </Box>
    </>
  );
  const ackCheckbox = !id && (
    <CustomCheckBox
      name="useBusiness"
      label={
        <Box maxWidth="35rem">
          <Typography variant="caption">
            By selecting this box, you acknowledge that the Syndicate&apos;s
            name, address, and manager is complete in form and correct in
            spelling. Once filed, the system cannot change the Syndicate name or
            manager.
          </Typography>
        </Box>
      }
      checked={checked}
      onChange={() => setChecked((x) => (x ? null : new Date()))}
    />
  );

  return { form, validate, ackCheckbox, confirmed: checked };
};

const SyndicateFormReview = ({
  investment,
  data,
  onClose,
  setStep,
  onComplete,
  onBack,
  smartcapitalFeeBase,
  smartcapitalFeePerMember,
  smartcapitalFeePercent,
  showFormationInfo = true,
  showOfferingInfo = true,
  setInvestment,
  setSyndicate,
}) => {
  const { id: investmentId } = investment || {};
  const { id, dateFiledOfferingAuthorization } = data;

  const { form, validate, ackCheckbox, confirmed } = useSyndicateFormReview({
    investment,
    data,
    setStep,
    showFormationInfo,
    showOfferingInfo,
    smartcapitalFeeBase,
    smartcapitalFeePerMember,
    smartcapitalFeePercent,
    setInvestment,
    setSyndicate,
  });
  const handleSave = async (values, onError, regenerate = false) => {
    try {
      const syndicate = { ...data, values };

      const {
        syndicateManagers,
        primaryManager,
        blueSky,
        shouldFile,
        // useCustomDocuments,
        business,
        ...others
      } = syndicate;
      const upsert = id ? syndicatesClient.update : syndicatesClient.create;
      let response = await upsert({
        id,
        investmentId,
        regenerate,
        // useCustomDocuments,
        data: {
          ...others,
          businessId: business.id,
          primaryManager: primaryManager.id,
          managers: syndicateManagers,
          blueSky: shouldFile ? blueSky : null,
        },
      });
      await onComplete(response.data);
      if (onBack) {
        onBack();
      }
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const extraButtons = (
    <Box display="inline-block">
      {onBack && (
        <Button
          variant="contained"
          onClick={onBack}
          style={{ marginRight: '1rem' }}
        >
          Back
        </Button>
      )}
      {id && !dateFiledOfferingAuthorization && (
        <SaveFormButton
          onSave={async (values, onError) => {
            await handleSave(values, onError, true);
          }}
          name="Save &amp; Regenerate Documents"
          style={{ marginRight: '1rem' }}
        />
      )}
    </Box>
  );
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext: handleSave,
        onClose,
        extraInfo: ackCheckbox,
        extraButtons:
          onBack || (id && !dateFiledOfferingAuthorization)
            ? extraButtons
            : null,
        nextProps: { disabled: !confirmed && !id },
        buttonName: id ? 'Next' : 'Create Syndicate',
      }}
    />
  );
};

export default SyndicateFormReview;
