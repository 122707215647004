import React from 'react';
import {
  Grid,
  Box,
  Typography,
  useTheme,
  Button,
  Tooltip,
} from '@material-ui/core';

import useSyndicate from 'hooks/useSyndicate';
import useDeleteConfirm from 'hooks/useDeleteConfirm';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { useSelectedGroup } from 'hooks/useAppState';
import { useHistory } from 'react-router-dom';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

const SyndicateSettings = ({ investment, setInvestment }) => {
  const theme = useTheme();
  const slug = useSelectedGroup((state) => state.slug);
  const history = useHistory();

  const { syndicate } = useSyndicate({
    investment,
    setInvestment,
  });
  const { id } = investment || {};
  const { dateFiledFormationService } = syndicate || {};
  const onDeleteSyndicate = async (onError) => {
    try {
      await groupInvestmentsClient.destroy({ id });
      history.push(`/${slug}/syndicates`);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };
  const { modal, showModal } = useDeleteConfirm({
    onConfirm: onDeleteSyndicate,
    headerName: 'Syndicate',
  });

  const { roundName, syndicateName, syndicateData } = investment;
  const name = syndicateName || syndicateData?.syndicateName;

  return (
    <Box>
      <Box>
        <Grid container justify="space-between" spacing={6}>
          <Grid item xs>
            <Box>
              {name ? (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {name}
                </Typography>
              ) : (
                <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                  {roundName}
                </Typography>
              )}
            </Box>
            <Box>
              <Typography variant="body2">Settings</Typography>
            </Box>
          </Grid>
        </Grid>
      </Box>
      {modal}
      <Box marginY={2}>
        <Typography variant="h6" color="primary">
          Danger Zone
        </Typography>

        <Box
          marginY={1}
          display="flex"
          justifyContent="space-between"
          p={4}
          style={{
            border: `1px dashed ${theme.palette.accent.main}`,
            borderRadius: 4,
          }}
        >
          <Box>
            <Box>Remove this syndicate</Box>
            <Box>
              <Typography variant="caption">
                This will remove this syndicate from this opportunity and all
                associated data including interest and notes.
              </Typography>
            </Box>
            <Box>
              <Typography variant="caption">
                Note: Only syndicates that have not been signed may be removed.
              </Typography>
            </Box>
          </Box>
          <Tooltip
            title={
              dateFiledFormationService
                ? 'Cannot remove signed syndicate'
                : 'Remove syndicate'
            }
          >
            <Box>
              <Button
                variant="contained"
                color="primary"
                style={{ height: '100%' }}
                disabled={dateFiledFormationService}
                onClick={() =>
                  dateFiledFormationService ? null : showModal(true)
                }
              >
                Remove Syndicate from Opportunity
              </Button>
            </Box>
          </Tooltip>
        </Box>
      </Box>
    </Box>
  );
};

export default SyndicateSettings;
