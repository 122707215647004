import apiClient from 'api/axios';

const index = ({ groupId, searchQuery }) => {
  return apiClient.get(`/api/v1/groups/${groupId}/management_structures`, {
    params: {
      searchQuery,
    },
  });
};

const create = ({ groupId, data }) => {
  return apiClient.post(`/api/v1/groups/${groupId}/management_structures`, {
    ...data,
  });
};

const get = ({ id }) => {
  return apiClient.get(`/api/v1/management_structures/${id}`, {
    params: {},
  });
};

const update = ({ id, data }) => {
  return apiClient.patch(`/api/v1/management_structures/${id}`, {
    ...data,
  });
};

const destroy = ({ id }) => {
  return apiClient.delete(`/api/v1/management_structures/${id}`);
};

const managementStructuresClient = {
  index,
  get,
  create,
  update,
  destroy,
};

export default managementStructuresClient;
