import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Button,
  Typography,
  FormControlLabel,
  Checkbox,
} from '@material-ui/core';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import managementStructuresClient from 'api/managementStructuresClient';
import { FormikTextInput } from 'components/ui/CustomTextField';

import useFormManagementStructure from 'hooks/forms/useFormManagementStructure';
import Form from 'components/Form/Form';

const ManagementEdit = ({ groupId, data = {}, onClose, onChange }) => {
  const { id } = data;
  const [isDefaultManagementStructure, setIsDefault] = useState(
    !id || data.isDefaultManagementStructure,
  );

  const [structure, setStructure] = useState(data);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await managementStructuresClient.get({ id });
        setStructure(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  //const [formData, setFormData] = useState();
  const onSave = async (onError) => {
    const { values, errors } = await validate();
    if (errors.length > 0) {
      onError(errors);
      return false;
    }

    try {
      const method = id
        ? managementStructuresClient.update
        : managementStructuresClient.create;
      const response = await method({
        groupId,
        id,
        data: {
          ...values,
          isDefaultManagementStructure,
        },
      });
      if (onChange) onChange(response.data);
      onClose();
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const { form: structureForm, validate: structureValidate } =
    useFormManagementStructure({ data: structure, isEdit: true });

  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: { name },
      errors,
    } = await validateForm(formRef);

    const { values: structureValues, errors: structureErrors } =
      await structureValidate();

    return {
      errors: [...errors, ...structureErrors],
      values: {
        name,
        ...structureValues,
      },
    };
  };

  return (
    <>
      <Box marginBottom={4}>
        <Box marginBottom={3}>
          <Form
            setRef={setFormRef}
            initialValues={structure}
            enableReinitialize
          >
            <Box marginBottom={2}>
              <Typography variant="h5">Management Structure</Typography>
            </Box>
            <Box marginBottom={3}>
              <Grid container spacing={3}>
                <Grid item xs={8}>
                  <FormikTextInput
                    name="name"
                    label="Structure Name"
                    variant="filled"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
          </Form>
        </Box>
        <Box>{structureForm}</Box>

        <Box marginTop={4}>
          <FormControlLabel
            control={
              <Checkbox
                checked={isDefaultManagementStructure}
                name="isDefaultManagementStructure"
                onChange={(e) => setIsDefault(e.target.checked)}
              />
            }
            label="Set as Default Management Structure"
          />
          <Box>
            <Typography variant="caption">
              When selected, this Management Structure will be used as the
              default when creating new syndicates, however you can create other
              Management Structures and select those for each syndicate.
            </Typography>
          </Box>
        </Box>

        <Box marginTop={4} marginBottom={6}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => onClose()}
              >
                Cancel
              </Button>
            </Grid>
            <Grid item>
              <SaveFormButton onSave={onSave}></SaveFormButton>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default ManagementEdit;
