import React, { useEffect, useMemo, useRef, useState } from 'react';

import getServerResponseErrors from 'api/getServerResponseErrors';
import userInvestmentsClient from 'api/userInvestmentsClient';
import { Box, Typography, Button, Hidden, useTheme } from '@material-ui/core';
import { LoadingSpinner } from 'components/ui/Loading';

import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import { CSVLink } from 'react-csv';

import DialogWithTitle from 'components/ui/DialogWithTitle';
import { FiCheck } from 'react-icons/fi';

import moment from 'moment';

export const UserInvestmentExportButton = ({ investment, data }) => {
  const [modal, showExport] = useState(false);

  return (
    <>
      {modal ? (
        <DialogWithTitle
          open
          onClose={() => showExport(null)}
          title="Export"
          fullWidth
          maxWidth="sm"
        >
          <UserInvestmentExport
            onClose={() => showExport(null)}
            {...{
              investment,
              data,
            }}
          ></UserInvestmentExport>
        </DialogWithTitle>
      ) : null}
      <Button
        onClick={() => showExport(true)}
        size="small"
        endIcon={<ExitToAppIcon />}
      >
        Export
      </Button>
    </>
  );
};

const UserInvestmentExport = ({ data, investment }) => {
  const [queue, setQueue] = useState();
  const [notes, setNotes] = useState({});
  const theme = useTheme();
  useEffect(() => {
    const processItem = async (item, callback) => {
      try {
        const notesResponse = await userInvestmentsClient.notes({ id: item });
        setNotes((cache) => {
          return { ...cache, [item]: notesResponse.data };
        });
      } catch (e) {
        console.log(getServerResponseErrors(e));
        setNotes((cache) => {
          return { ...cache, [item]: 'Error retrieving Notes' };
        });
      }
      if (callback) {
        callback();
      }
    };
    const processQueue = async () => {
      setQueue((cache) => {
        if (cache && cache.length > 0) {
          const temp = cache.shift();
          processItem(temp, processQueue);
          return cache;
        } else {
          return cache;
        }
      });
    };
    if (data) {
      const q = data.filter((x) => x.data.noteCount > 0).map((x) => x.id);
      if (q.length > 0) {
        setQueue(q);
        processQueue();
      } else {
        setQueue([]);
      }
    }
  }, [data]);

  const [delay, setDelay] = useState(true);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDelay(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, []);

  const isLoading = !queue || queue.length > 0 || delay;

  const { exportButton } = useUserInvestmentExport({
    filteredData: isLoading ? null : data,
    notes,
    investment,
  });

  return (
    <Box textAlign="center">
      <Box minHeight={isLoading ? '8rem' : ''}>
        {isLoading ? (
          <>
            <Box>
              <Typography>Preparing Export ...</Typography>
            </Box>
            <Box marginTop={2}>
              <LoadingSpinner />
            </Box>
            <Box marginTop={1}>
              Processing {queue && queue.length > 0 ? queue.length : '...'}
            </Box>
          </>
        ) : (
          <Box>
            <Typography variant="h5">Export Ready</Typography>
            <Box>
              <FiCheck color={theme.palette.success.main} size="8em" />
            </Box>
          </Box>
        )}
      </Box>
      <Box>{exportButton}</Box>
    </Box>
  );
};

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const useUserInvestmentExport = ({ filteredData, investment, notes }) => {
  const { unitPrice } = investment;
  const csvRef = useRef();

  const exportData = useMemo(() => {
    if (!filteredData) {
      return [];
    }
    return filteredData.map((x) => {
      const { id, data, lastAction, isFake } = x;
      const {
        user,
        interestAmount,
        isUndecided,
        requestedUnits,
        acceptedUnits,
        amountReceived,
        refundAmount,
        isLead,
        hasRequestedLead,
        dateReviewed,
        entityType,
        dateReceived,
        dateAccepted,
        investmentCode,
        isAccredited,
        entityName,
        subscription,
        dateRefunded,
        userId,
        firstName,
        lastName,
      } = data;
      const itemNotes = notes[id] || [];
      const itemNotesText = itemNotes
        .reduce((acc, val) => {
          return (
            acc +
            `${val.createdBy.name} on ${moment(val.createdAt).format('L')} : ${
              val.text
            }\r\n`
          );
        }, '')
        .trim();
      const { signatures = [] } = subscription || {};
      const managerSigned = signatures.find(
        (x) => x.memberType === 'manager',
      )?.dateSigned;
      const custodianSigned = signatures.find(
        (x) => x.memberType === 'custodian',
      )?.dateSigned;
      const investorSigned = signatures.find(
        (x) => x.memberType === 'person',
      )?.dateSigned;
      const hasCustodian = entityType == 'ira';
      return {
        ID: isFake ? '' : id,
        UserID: userId,
        'First Name': firstName || user.firstName,
        'Last Name': lastName || user.lastName,
        Email: user.email,
        'Investor Name': entityName,
        'Investor Type': entityType,
        Custodian: hasCustodian,
        Accredited: isAccredited,
        'Lead Requested': hasRequestedLead,
        'Lead Confirmed': isLead,
        Interested: isFake
          ? 'Unknown'
          : interestAmount > 0 || isUndecided
          ? 'Interested'
          : 'Passed',
        'Interested Amount': interestAmount > 0 ? interestAmount : 0,
        'Proposed Units': requestedUnits,
        'Proposed Amount': formatter.format(requestedUnits * unitPrice),
        // 'Subsribed Units': investorSigned ? requestedUnits : 0,
        // 'Subscribed Amount': investorSigned
        //   ? formatter.format(requestedUnits * unitPrice)
        //   : 0,
        'Accepted Units': acceptedUnits,
        'Accepted Amount': formatter.format(acceptedUnits * unitPrice),
        'Collected Amount': formatter.format(amountReceived),
        'Refunded Amount': formatter.format(refundAmount),
        'Invested Amount': formatter.format(amountReceived - refundAmount),
        'Investment Code': investmentCode,
        'Last Action Date': lastAction ? lastAction.format('L') : null,
        'Reviewed Date': dateReviewed ? moment(dateReviewed).format('L') : null,
        'Investor Signed Date': investorSigned
          ? moment(investorSigned).format('L')
          : null,
        'Custodian Signed Date': custodianSigned
          ? moment(custodianSigned).format('L')
          : null,
        'Manager Signed Date': managerSigned
          ? moment(managerSigned).format('L')
          : null,

        'Accepted Date': dateAccepted ? moment(dateAccepted).format('L') : null,
        'Collected Date': dateReceived
          ? moment(dateReceived).format('L')
          : null,
        'Refunded Date': dateRefunded ? moment(dateRefunded).format('L') : null,
        Notes: itemNotesText,
      };
    });
  }, [filteredData, unitPrice, notes]);

  const exportButton = (
    <>
      <Button
        disabled={!filteredData}
        color="primary"
        variant="contained"
        onClick={() => {
          csvRef.current.link.click();
        }}
      >
        Download
      </Button>
      {filteredData && (
        <Hidden>
          <CSVLink
            data={exportData}
            filename="investments.csv"
            target="_blank"
            ref={csvRef}
          ></CSVLink>
        </Hidden>
      )}
    </>
  );

  return { exportButton };
};
