import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import { useSelectedGroup } from 'hooks/useAppState';
import * as yup from 'yup';

export const useSyndicateFormName = ({ data }) => {
  const { syndicateId } = data;
  const groupName = useSelectedGroup((state) => state.name);
  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: { syndicateName, registeredState },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        syndicateName,
        registeredState,
      },
    };
  };

  const form = (
    <Form initialValues={data} enableReinitialize setRef={setFormRef}>
      {({ values }) => {
        const { syndicateName } = values || {};
        return (
          <>
            <Box>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                What would you like the name of your syndicate to be?
              </Typography>
            </Box>
            <Box>
              <Grid container spacing={3}>
                <Grid item md={9}>
                  <FormikTextInput
                    name="syndicateName"
                    label="Syndicate Name"
                    variant="filled"
                    required
                    placeholder={`${groupName} Syndicate # LLC`}
                    disabled={syndicateId}
                    validation={yup
                      .string()
                      .ensure()
                      .matches(
                        /^[A-Za-z0-9 ]+$/,
                        'Syndicate name cannot have special characters',
                      )
                      .matches(
                        /^[a-zA-Z0-9 ]+LLC$/,
                        'Syndicate name must end with LLC',
                      )}
                  />
                </Grid>
                {/* <Grid item md={3}></Grid> */}
                {/* <Grid item md={6}>
                  <FormikRegionSelect
                    name="registeredState"
                    label="Registered State"
                    variant="filled"
                    required
                    statesOnly
                    disabled
                  />
                </Grid> */}
              </Grid>
              {!syndicateId && (
                <Typography variant="subtitle2">
                  Please click&nbsp;
                  <a
                    href={`https://www.sos.state.co.us/biz/BusinessEntityCriteriaExt.do?resetTransTyp=Y&searchName=${encodeURIComponent(
                      syndicateName || groupName,
                    )}&cmd=Search`}
                    rel="noreferrer"
                    target="_blank"
                  >
                    here
                  </a>
                  &nbsp;to check for name availability.
                </Typography>
              )}
            </Box>
          </>
        );
      }}
    </Form>
  );

  return { form, validate };
};

// const SyndicateFormName = ({ data, onNext, onBack = null, onClose }) => {
//   const { form, validate } = useSyndicateFormName({ data });
//   return (
//     <FormWizard
//       {...{
//         form,
//         validate,
//         onNext,
//         onBack,
//         onClose,
//       }}
//     />
//   );
// };

// export default SyndicateFormName;
