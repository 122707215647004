import React from 'react';
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Link,
} from '@material-ui/core';

import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import getServerResponseErrors from 'api/getServerResponseErrors';
import taxDocumentsClient from 'api/taxDocumentsClient';

export const ManageTaxDocuments = ({
  documents,
  showModal,
  showFiled = false,
  showDescription = false,
  canEdit = false,
}) => {
  return (
    <Box>
      {documents && documents.length > 0 ? (
        <TableContainer component={Paper}>
          <Box bgcolor="white" border="1px solid lightgray" borderBottom="0">
            <Table size="small" stickyHeader aria-label="simple table">
              <TableHead>
                <TableRow>
                  <TableCell>Year</TableCell>
                  {showFiled && <TableCell>Filed</TableCell>}
                  <TableCell>Name</TableCell>
                  {showDescription && <TableCell>Notes</TableCell>}

                  {canEdit && <TableCell align="right">Actions</TableCell>}
                </TableRow>
              </TableHead>
              <TableBody>
                {documents &&
                  documents.map((data) => {
                    const { id, formName, document, year, description } = data;
                    const getLink = async () => {
                      try {
                        const response = await taxDocumentsClient.link({ id });
                        return response.data;
                      } catch (e) {
                        const error = getServerResponseErrors(e);
                        console.log(error);
                      }
                    };
                    const openLink = async () => {
                      const w = window.open('/loading.html', `document-${id}`);
                      const link = await getLink();
                      if (link) {
                        w.location = link;
                      }
                    };

                    return (
                      <TableRow key={id}>
                        <TableCell style={{ width: '2rem' }}>
                          <Typography>{year}</Typography>
                        </TableCell>
                        {showFiled && (
                          <TableCell style={{ width: '2rem' }}>
                            <Typography>{document ? 'Yes' : 'No'}</Typography>
                          </TableCell>
                        )}
                        <TableCell>
                          <Link
                            onClick={openLink}
                            style={{ cursor: 'pointer' }}
                          >
                            <Typography>{formName}</Typography>
                          </Link>
                        </TableCell>
                        {showDescription && (
                          <TableCell>
                            <Typography>{description}</Typography>
                          </TableCell>
                        )}
                        {canEdit && (
                          <TableCell align="right" style={{ width: '2rem' }}>
                            <Link
                              onClick={() => {
                                showModal(data);
                              }}
                              style={{ cursor: 'pointer' }}
                            >
                              Edit
                            </Link>
                          </TableCell>
                        )}
                      </TableRow>
                    );
                  })}
              </TableBody>
            </Table>
          </Box>
        </TableContainer>
      ) : (
        <Box
          padding={3}
          height="10vh"
          bgcolor="grey.300"
          borderRadius="borderRadius"
          textAlign="center"
        >
          <CenteredContentBox>
            <Box marginBottom={2}>
              <Typography color="primary">
                No tax documents have been uploaded yet
              </Typography>
            </Box>
          </CenteredContentBox>
        </Box>
      )}
    </Box>
  );
};
