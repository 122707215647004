import React, { useState, useEffect } from 'react';
import {
  Box,
  Grid,
  Link,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';

import Social from 'components/ui/Social';
import useMore from 'components/useMore';
import urlParser from 'js-video-url-parser';
import defaultImage from 'images/default-opportunity.svg';
import ImageFallback from 'components/ui/ImageFallback';

const OpportunitySectionAbout = ({ opportunity }) => {
  const {
    name,
    oneLiner,
    bio,
    pitchVideoUrl,
    otherUrl,
    twitterUrl,
    facebookUrl,
    linkedinUrl,
    websiteUrl,
    crunchbaseUrl,
    instagramUrl,
    slideDeckUrl,
    photo,
  } = opportunity || {};
  const [showMore, hasMore, setMore, setRef] = useMore();
  let embedSlideDeckUrl = slideDeckUrl;
  // google by default uses the pub html url, but we want to use the embed url
  if (embedSlideDeckUrl && embedSlideDeckUrl.includes('/pub')) {
    embedSlideDeckUrl = embedSlideDeckUrl.replace('/pub', '/embed');
  } else if (embedSlideDeckUrl && embedSlideDeckUrl.includes('/view')) {
    embedSlideDeckUrl = embedSlideDeckUrl.replace('/view', '/embed');
  } else if (embedSlideDeckUrl && embedSlideDeckUrl.includes('/edit')) {
    embedSlideDeckUrl = embedSlideDeckUrl.replace('/edit', '/embed');
  }
  // if the url does not have start=true&loop=true&delayms=3000, add it
  if (
    embedSlideDeckUrl &&
    !embedSlideDeckUrl.includes('start=true&loop=true&delayms=3000')
  ) {
    // insert before #
    if (embedSlideDeckUrl.includes('#')) {
      embedSlideDeckUrl = embedSlideDeckUrl.replace(
        '#',
        `${
          embedSlideDeckUrl.includes('?') ? '&' : '?'
        }start=true&loop=true&delayms=3000#`,
      );
    } else {
      // insert at the end
      embedSlideDeckUrl += `${
        embedSlideDeckUrl.includes('?') ? '&' : '?'
      }start=true&loop=true&delayms=3000`;
    }
  }

  const [video, setVideo] = useState();
  useEffect(() => {
    if (pitchVideoUrl) {
      const videoInfo = urlParser.parse(pitchVideoUrl);
      const videoUrl = urlParser.create({ videoInfo, format: 'embed' });
      setVideo(videoUrl);
    }
  }, [pitchVideoUrl]);

  const theme = useTheme();
  const isTiny = useMediaQuery(theme.breakpoints.down('xs'));

  return (
    <>
      <Box marginTop={2}>
        <Grid container justify="space-between" spacing={2}>
          <Grid item>
            <Box marginRight={4}>
              <Box>
                <Typography variant="h4" gutterBottom color="primary">
                  {name}
                </Typography>
              </Box>
              {websiteUrl && (
                <Box>
                  <Link href={websiteUrl} target="_blank">
                    {websiteUrl}
                  </Link>
                </Box>
              )}
              {(otherUrl || twitterUrl || facebookUrl || linkedinUrl) && (
                <Box marginTop={1}>
                  <Social
                    justify="flex-start"
                    {...{
                      otherUrl,
                      twitterUrl,
                      facebookUrl,
                      linkedinUrl,
                      crunchbaseUrl,
                      instagramUrl,
                    }}
                  ></Social>
                </Box>
              )}
            </Box>
          </Grid>
          <Grid item sm={6} xs={12}>
            <ImageFallback
              src={photo}
              fallback={defaultImage}
              alt=""
              height={isTiny ? '6rem' : '8rem'}
              width={isTiny ? '100%' : 'auto'}
              objectFit="contain"
            />
          </Grid>
        </Grid>
      </Box>
      {oneLiner && (
        <Box marginTop={2}>
          <Typography
            variant={isTiny ? 'subtitle1' : 'h6'}
            style={{ fontWeight: 'bold', fontStyle: 'italic' }}
          >
            {oneLiner}
          </Typography>
        </Box>
      )}
      {bio && (
        <Box marginTop={2}>
          <Typography style={{ textTransform: 'uppercase' }}>
            About Opportunity
          </Typography>
          <Box
            maxHeight={showMore ? '' : '5rem'}
            overflow={showMore ? '' : 'hidden'}
            ref={setRef}
          >
            <Typography variant="subtitle1" style={{ whiteSpace: 'pre-line' }}>
              {bio}
            </Typography>
          </Box>
          {hasMore && (
            <Box marginTop={1}>
              <Link
                color="primary"
                size="small"
                onClick={() => setMore(!showMore)}
                style={{ cursor: 'pointer' }}
              >
                {showMore ? 'Less' : 'Read More'}
              </Link>
            </Box>
          )}
        </Box>
      )}
      {embedSlideDeckUrl && (
        <Box marginTop={2}>
          <iframe
            src={embedSlideDeckUrl}
            title="slide deck"
            frameborder="0"
            width="100%"
            style={{ height: '50vh' }}
            allowfullscreen="true"
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
          />
        </Box>
      )}
      {video && (
        <Box marginTop={4}>
          <iframe
            title="Pitch Deck"
            width="100%"
            style={{ height: '50vh' }}
            src={video}
            frameBorder="0"
            allow="accelerometer; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; rel=0;"
            allowFullScreen
          ></iframe>
        </Box>
      )}
    </>
  );
};

export default OpportunitySectionAbout;
