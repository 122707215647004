import apiClient from 'api/axios';

const index = ({ groupId, active, opportunityId = null }) => {
  return apiClient.get(`/api/v1/group_investments`, {
    params: { groupId, opportunityId, active },
  });
};

const get = ({ id }) => {
  return apiClient.get(`/api/v1/group_investments/${id}`, {
    params: {},
  });
};

const lookup = ({ groupId, roundId }) => {
  return apiClient.get(`/api/v1/group_investments/lookup`, {
    params: { groupId, roundId },
  });
};

const create = ({ groupId, opportunityId, data }) => {
  return apiClient.post(`/api/v1/group_investments`, {
    groupId,
    opportunityId,
    investment: data,
  });
};

const update = ({ id, data }) => {
  return apiClient.patch(`/api/v1/group_investments/${id}`, {
    investment: data,
  });
};

const addInvestor = ({ id, data }) => {
  return apiClient.post(`/api/v1/group_investments/${id}/add_investor`, {
    investor: data,
  });
};

const searchInvestors = ({ id, search_query }) => {
  return apiClient.get(`/api/v1/group_investments/${id}/search_investors`, {
    params: { search_query },
  });
};

const destroy = ({ id }) => {
  return apiClient.delete(`/api/v1/group_investments/${id}`);
};

const totals = ({ id }) => {
  return apiClient.get(`/api/v1/group_investments/${id}/totals`, {
    params: {},
  });
};

const createDocument = ({ investmentId, data }) => {
  return apiClient.post(
    `/api/v1/group_investments/${investmentId}/group_investment_documents`,
    {
      document: data,
    },
  );
};

const updateDocument = ({ id, data }) => {
  return apiClient.patch(`/api/v1/group_investment_documents/${id}`, {
    document: data,
  });
};

const deleteDocument = ({ id }) => {
  return apiClient.delete(`/api/v1/group_investment_documents/${id}`);
};

const currentInvestment = ({ groupId, opportunityId }) => {
  return apiClient.get(`/api/v1/group_investments/current_investment`, {
    params: { groupId, opportunityId },
  });
};

const usersWithNotes = (id) => {
  return apiClient.get(`/api/v1/group_investments/${id}/users_with_notes`);
};

const groupInvestmentsClient = {
  index,
  get,
  create,
  update,
  destroy,
  createDocument,
  updateDocument,
  deleteDocument,
  totals,
  addInvestor,
  searchInvestors,
  lookup,
  currentInvestment,
  usersWithNotes,
};

export default groupInvestmentsClient;
