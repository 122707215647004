import React, { useState, useEffect, useCallback, useMemo } from 'react';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import {
  Box,
  Button,
  Grid,
  Typography,
  useTheme,
  Paper,
  useMediaQuery,
  Badge,
} from '@material-ui/core';
import moment from 'moment';
import getServerResponseErrors from 'api/getServerResponseErrors';
import InviteUsers from './InviteUsers';

import { useSelectedGroup, useProfile } from 'hooks/useAppState';

import UserOnboardingMessage from 'components/Message/UserOnboardingMessage';
import EditInvestor from './EditInvestor';
import InvestorTable from './InvestorTable';
import DialogWithTitle from 'components/ui/DialogWithTitle';

import groupUsersClient from 'api/groupUsersClient';
import { useArrayUpdate } from 'hooks/useListUpdate';
import { LoadingArea } from 'components/ui/Loading';
import SummaryItem from 'views/UserDashboard/Summary/SummaryItem';
import { FaMoneyCheckAlt } from 'react-icons/fa';
import { currencyFormatter } from 'views/SyndicatesIndex/SyndicatesIndex';
import { NewFeatureFlags } from 'utils/consts';
import SendEmailModal from 'views/UserInvestment/SendEmailModal';
import {
  gridFilteredSortedRowEntriesSelector,
  gridVisibleRowCountSelector,
  useGridApiRef,
} from '@mui/x-data-grid-pro';

const getInvestors = async (groupId, searchTerms, filterTerms) => {
  return await groupUsersClient.index({
    groupId,
    searchQuery: searchTerms,
    filter: filterTerms,
  });
};

const InvestorsCard = ({
  Icon,
  iconFontSize = 46,
  name,
  value,
  titleProps,
  summaryTitle,
}) => {
  return (
    <Grid item>
      <Paper
        variant="outlined"
        alignItems="center"
        style={{
          paddingLeft: 25,
          paddingRight: 25,
          borderRadius: 19,
          height: 100,
        }}
        title={summaryTitle}
      >
        <Grid
          container
          direction="row"
          justify="space-between"
          alignItems="center"
          style={{ height: '100%' }}
        >
          <Grid item>
            <Grid
              container
              direction="row"
              justify="space-between"
              alignItems="center"
              style={{ height: '100%' }}
            >
              {Icon && (
                <Icon
                  fontSize={iconFontSize}
                  style={{ marginRight: 25, fill: 'url(#mygradient)' }}
                />
              )}
              <Typography
                style={{ fontSize: 24, fontWeight: 700 }}
                {...titleProps}
              >
                {name}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>{value}</Grid>
        </Grid>
      </Paper>
    </Grid>
  );
};

const CountDisplay = ({ name, value }) => (
  <Box display="flex" justifyContent="space-between" flexDirection="row">
    <Box mr={1}>
      <Typography variant="subtitle2">{name}:</Typography>
    </Box>
    <Box justifySelf="flex-end">
      <Typography variant="subtitle2" style={{ fontWeight: 600 }}>
        {value || 0}
      </Typography>
    </Box>
  </Box>
);

const SendEmailButton = ({ apiRef }) => {
  const [rowCount, setRowCount] = useState(0);
  const [recipients, setRecipients] = useState([]);
  const [emailVisibility, setEmailVisibility] = useState(false);

  const handleEvent = () => {
    const count = gridVisibleRowCountSelector(apiRef);
    setRowCount(count);
  };

  apiRef.current.subscribeEvent('stateChange', handleEvent);

  const handleOpen = () => {
    const filtered = gridFilteredSortedRowEntriesSelector(apiRef);
    const list = filtered.map((investor) => ({
      email: investor?.model?.data?.email || investor?.model?.data?.user?.email,
      name: investor?.model?.data?.user?.name,
    }));
    setRecipients(list);
    setEmailVisibility(true);
  };

  return (
    <>
      {emailVisibility ? (
        <DialogWithTitle
          open
          onClose={() => setEmailVisibility(null)}
          title="Send Email"
          fullWidth
        >
          <SendEmailModal
            onClose={() => setEmailVisibility(null)}
            {...{
              recipients,
            }}
          />
        </DialogWithTitle>
      ) : null}

      <Button onClick={handleOpen} size="small">
        Send Email ({rowCount})
      </Button>
    </>
  );
};

const InvestorsIndex = () => {
  const { firstName, investorsMessageDismissed } = useProfile((state) => ({
    firstName: state.firstName,
    investorsMessageDismissed: state.investorsMessageDismissed,
  }));
  const { groupId } = useSelectedGroup((state) => ({
    groupId: state.id,
  }));
  const [addModalOpen, showAddModal] = useState(false);
  const [cache, setCache] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [editInvestor, setEditInvestor] = useState();
  const [isLoading, setLoading] = useState(true);
  const [filterTerms, setFilters] = useState('all');

  const theme = useTheme();
  const showCounts = useMediaQuery(theme.breakpoints.up('sm'));
  const apiRef = useGridApiRef();

  const onUpdate = useCallback(
    async (func) => {
      setCache((cache) => func(cache));
      try {
        const response = await getInvestors(groupId, '', 'all');
        setAllUsers(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    },
    [groupId],
  );
  const { onListUpdate } = useArrayUpdate(onUpdate);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await getInvestors(groupId, '', filterTerms);
        if (filterTerms === 'all') {
          setAllUsers(response.data);
        }
        setCache(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      } finally {
        setLoading(false);
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId, filterTerms]);

  const investorUpdate = useCallback((row) => setEditInvestor(row), []);

  const [copied, setCopied] = useState(false);
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [copied]);

  const formattedData = useMemo(() => {
    return cache.map((item) => {
      const { id, firstName, lastName, email, phone, user, ...others } = item;
      return {
        ...others,
        id,
        firstName: firstName || user.firstName,
        lastName: lastName || user.lastName,
        email: email || user.email,
        phone: phone || user.phone,
        photo: user.photo,
        isAccredited: user.isAccredited,
        // totalInvested: (id % 55) * 2500,
      };
    });
  }, [cache]);
  const totalInvested =
    formattedData
      ?.map((x) => parseFloat(x.totalInvested || 0))
      .reduce((p, c) => p + c, 0) || 0;
  const totalUsers = allUsers.length;
  const requestedUsers = allUsers.filter(
    (x) => x.status.toLowerCase() === 'requested access',
  ).length;
  const invitedUsers = allUsers.filter(
    (x) => x.status.toLowerCase() === 'pending',
  ).length;
  const approvedUsers = allUsers.filter(
    (x) => x.status.toLowerCase() === 'confirmed',
  ).length;
  const suspendedUsers = allUsers.filter(
    (x) => x.status.toLowerCase() === 'suspended',
  ).length;
  const rejectedUsers = allUsers.filter(
    (x) => x.status.toLowerCase() === 'rejected',
  ).length;
  return (
    <>
      <Box marginBottom={4}>
        <Box>
          {!investorsMessageDismissed && (
            <UserOnboardingMessage dismissField="investorsMessageDismissed">
              <Typography variant="h5" gutterBottom>
                Welcome {firstName}
              </Typography>
              <Typography variant="body1">
                This is the investors view, any investor you&apos;ve invited, or
                has requested access will show up here.
              </Typography>
            </UserOnboardingMessage>
          )}
        </Box>
        <Box marginTop={investorsMessageDismissed ? 1 : 0}>
          <Grid container spacing={4}>
            <Grid item xs={12} lg={6}>
              {/* insert custom card here */}
              <InvestorsCard
                name="Investors"
                summaryTitle="The number of investors in your group"
                value={
                  <Grid
                    container
                    height="100%"
                    spacing={2}
                    display="flex"
                    flexDirection="row"
                  >
                    <Grid item style={{ display: 'flex' }} alignItems="center">
                      <Box
                        display="flex"
                        justifyContent="space-between"
                        flexDirection="row"
                      >
                        <Box mr={1}>
                          <Typography style={{ fontSize: 18, fontWeight: 300 }}>
                            Total:
                          </Typography>
                        </Box>
                        <Box justifySelf="flex-end">
                          <Typography style={{ fontSize: 18, fontWeight: 400 }}>
                            {totalUsers || 0}
                          </Typography>
                        </Box>
                      </Box>
                    </Grid>
                    {showCounts && (
                      <>
                        <Grid item>
                          <CountDisplay
                            value={requestedUsers}
                            name="Requested"
                          />
                          <CountDisplay value={invitedUsers} name="Invited" />
                          <CountDisplay
                            value={approvedUsers}
                            name="Has Access"
                          />
                        </Grid>
                        <Grid item>
                          <CountDisplay
                            value={suspendedUsers}
                            name="Suspended"
                          />
                          <CountDisplay value={rejectedUsers} name="Rejected" />
                        </Grid>
                      </>
                    )}
                  </Grid>
                }
                Icon={FaMoneyCheckAlt}
                // Icon={FaPiggyBank}
                // valueProps={{
                //   style: { fontSize: 48, fontWeight: 300, lineHeight: 1 },
                // }}
                iconFontSize={46}
                titleProps={{
                  style: {
                    fontSize: 24,
                    fontWeight: 700,
                  },
                }}
              />
            </Grid>
            <Grid item xs={12} lg={6}>
              <SummaryItem
                name="Total Invested"
                summaryTitle="The total amount of money this group has invested"
                value={currencyFormatter.format(totalInvested)}
                valueProps={{
                  style: {
                    fontSize: totalInvested < 1000000 ? 24 : 20, // when digits are under 6 use the default 24, otherwise use 20
                    color: theme.palette.money.main,
                  },
                }}
                iconFontSize={46}
                titleProps={{
                  style: {
                    fontSize: 24,
                    fontWeight: 700,
                  },
                }}
                Icon={FaMoneyCheckAlt}
              />
            </Grid>
          </Grid>
        </Box>
        <Box marginTop={4}>
          <Grid
            container
            spacing={3}
            justify="space-between"
            alignItems="flex-end"
          >
            <Grid item>
              <Grid container spacing={3}>
                <Grid item>
                  {NewFeatureFlags.updatedAddInvestorButton.enabled &&
                  moment(NewFeatureFlags.updatedAddInvestorButton.expiration) >
                    moment() ? (
                    <Badge
                      color="secondary"
                      badgeContent="Updated!"
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        data-tour="investor-button"
                        onClick={() => showAddModal(true)}
                      >
                        Add Investors
                      </Button>
                    </Badge>
                  ) : (
                    <Button
                      variant="contained"
                      color="primary"
                      data-tour="investor-button"
                      onClick={() => showAddModal(true)}
                    >
                      Add Investors
                    </Button>
                  )}
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box position="relative">
        <LoadingArea open={isLoading || !cache} />

        <>
          <DialogWithTitle
            open={!!editInvestor}
            onClose={() => setEditInvestor(null)}
            fullWidth
            title={`${(editInvestor || {}).id ? 'Edit' : 'Add'} Investor`}
          >
            <EditInvestor
              onChange={onListUpdate}
              onClose={() => setEditInvestor(null)}
              {...{ groupId, data: editInvestor }}
            ></EditInvestor>
          </DialogWithTitle>

          <Box marginBottom={2}>
            <InvestorTable
              {...{
                investors: cache,
                onEdit: investorUpdate,
                onChange: onListUpdate,
                groupId,
                onFail: () => {
                  setFilters('all');
                },
                filterTerms,
                setFilters,
                sendEmail: apiRef ? <SendEmailButton apiRef={apiRef} /> : null,
                tableRef: apiRef,
              }}
            />
            {formattedData && formattedData.length == 0 && (
              <Box
                padding={3}
                height="20vh"
                bgcolor="grey.300"
                borderRadius="borderRadius"
                textAlign="center"
              >
                <CenteredContentBox>
                  <Box marginBottom={2}>
                    <Typography color="primary">
                      Invite investors and create your investor network!
                    </Typography>
                  </Box>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => showAddModal(true)}
                  >
                    Add Investors
                  </Button>
                </CenteredContentBox>
              </Box>
            )}
          </Box>
        </>
      </Box>
      <DialogWithTitle
        title="Invite Investors"
        open={addModalOpen}
        onClose={() => showAddModal(false)}
        fullWidth
      >
        <InviteUsers
          onSuccess={onListUpdate}
          onClose={() => showAddModal(false)}
        />
      </DialogWithTitle>
    </>
  );
};

export default InvestorsIndex;
