import apiClient from 'api/axios';

const index = ({ investmentId }) => {
  return apiClient.get(
    `/api/v1/group_investments/${investmentId}/user_investments`,
    {
      params: {},
    },
  );
};

const get = ({ id }) => {
  return apiClient.get(`/api/v1/user_investments/${id}`, {
    params: {},
  });
};

const create = ({ investmentId, data }) => {
  return apiClient.post(
    `/api/v1/group_investments/${investmentId}/user_investments`,
    {
      userInvestment: data,
    },
  );
};

const update = ({ id, data, infoSave }) => {
  return apiClient.patch(`/api/v1/user_investments/${id}`, {
    infoSave,
    userInvestment: data,
  });
};

const void_subscription = ({ id, data }) => {
  return apiClient.post(`/api/v1/user_investments/${id}/void`, {
    userInvestment: data,
  });
};

const destroy = ({ id }) => {
  return apiClient.delete(`/api/v1/user_investments/${id}`);
};

const affirm = ({ id }) => {
  return apiClient.post(`/api/v1/user_investments/${id}/affirm`, {});
};

const subscription = ({ id }) => {
  return apiClient.get(`/api/v1/user_investments/${id}/subscription`, {
    params: {},
  });
};

const download = ({ id }) => {
  return apiClient.get(`/api/v1/user_investments/${id}/subscription_download`, {
    params: {},
  });
};

const documents = ({ id }) => {
  return apiClient.get(`/api/v1/user_investments/${id}/documents`, {
    params: {},
  });
};

const instructions = ({ id }) => {
  return apiClient.get(`/api/v1/user_investments/${id}/instructions`, {
    params: {},
  });
};

const active_investment = ({ groupId, opportunityId }) => {
  return apiClient.get(`/api/v1/user_investments/active_investment`, {
    params: { groupId, opportunityId },
  });
};

const send_signature_notification = ({ id }) => {
  return apiClient.post(
    `/api/v1/user_investments/${id}/send_signature_notification`,
    {},
  );
};

const taxDocuments = ({ id }) => {
  return apiClient.get(`/api/v1/user_investments/${id}/tax_documents`, {
    params: {},
  });
};

const uploadSignature = ({ id, data }) => {
  return apiClient.post(`/api/v1/user_investments/${id}/upload_signature`, {
    userInvestment: data,
  });
};

const userInvestmentsClient = {
  index,
  get,
  create,
  update,
  void_subscription,
  destroy,
  affirm,
  subscription,
  download,
  documents,
  instructions,
  active_investment,
  send_signature_notification,
  taxDocuments,
  uploadSignature,
};

export default userInvestmentsClient;
