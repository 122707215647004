import React from 'react';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import moment from 'moment';

const ClosingDatesCard = ({ initialClosing, finalClosing, editClick }) => {
  return (
    <Paper variant="outlined" square style={{ padding: '1rem' }}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Closing Dates:
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" size="small" onClick={editClick}>
            Edit
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Initial Closing Date</i>
            <br />
            {moment(initialClosing).format('MM-DD-yyyy')}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Final Closing Date</i>
            <br />
            {moment(finalClosing).format('MM-DD-yyyy')}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default ClosingDatesCard;
