import { useCallback, useEffect, useState } from 'react';

import getServerResponseErrors from 'api/getServerResponseErrors';
import syndicatesClient from 'api/syndicatesClient';
import useStateNotification from 'hooks/useStateNotification';
import { useProfile } from './useAppState';

export const documentStates = {
  CHECKING_STATUS: 'checking_status',
  NOT_READY: 'no_ready',
  NO_DOC: 'no_doc',
  AWAITING_UPLOAD: 'awaiting_upload',
  NEEDS_SIGNATURE: 'needs_signature',
  CONFIRMING_SIGNATURE: 'confirming_signature',
  AWAITING_SIGNATURE: 'awaiting_signature',
  COMPLETED: 'completed',
  NO_ID: 'no_id',
  EXPIRED: 'expired',
};
const {
  CHECKING_STATUS,
  NOT_READY,
  AWAITING_UPLOAD,
  NO_DOC,
  NEEDS_SIGNATURE,
  CONFIRMING_SIGNATURE,
  AWAITING_SIGNATURE,
  COMPLETED,
  NO_ID,
  EXPIRED,
} = documentStates;

const computeState = (id, doc, isSigning, signer, email) => {
  const {
    isReady,
    draftData,
    documentData,
    signatures = [],
    isExpired,
    externalIntegration,
  } = doc || {};

  let step = CHECKING_STATUS;
  if (isExpired) {
    step = EXPIRED;
  } else if (!id) {
    step = NO_ID;
  } else if (!doc) {
    step = CHECKING_STATUS;
  } else if (!documentData) {
    if (!externalIntegration) {
      step = AWAITING_UPLOAD;
    } else if (!isReady || !draftData) {
      step = NOT_READY;
    } else if (
      signatures.some((x) => !x.dateSigned && x.id == signer?.id) &&
      isSigning
    ) {
      step = CONFIRMING_SIGNATURE;
    } else if (
      signatures.some(
        (x) => !x.dateSigned && (x.id == signer?.id || x.signerEmail == email),
      )
    ) {
      step = NEEDS_SIGNATURE;
    } else if (signatures.some((x) => !x.dateSigned)) {
      step = AWAITING_SIGNATURE;
    } else {
      step = NO_DOC;
    }
  } else {
    step = COMPLETED;
  }
  return step;
};

const useSyndicateDocumentPoll = ({
  id,
  data = null,
  pollFrequency = 10000,
  role = 'person',
}) => {
  const { userId, email } = useProfile((state) => ({
    userId: state.id,
    email: state.email,
  }));
  const [doc, setDoc, last] = useStateNotification(
    `syndicateDocument-${id}`,
    data,
  );
  const { documentData, isReady, signatures = [] } = doc || {};
  const signer = signatures.find(
    (x) =>
      x.memberType === role &&
      (role != 'person' || x.userId == userId || x.signerEmail == email),
  );

  const [clicked, setClicked] = useState(false);
  const frequency = !isReady || (clicked && signer) ? 5000 : pollFrequency;
  const needsPoll = !!doc && !documentData;

  const [error, setError] = useState(false);
  const fetchData = useCallback(
    async (id) => {
      try {
        const response = await syndicatesClient.get_document(id);
        setDoc(response.data);
        setError(false);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
        setError(true);
      }
    },
    [setDoc],
  );
  useEffect(() => {
    if (id && !data) {
      fetchData(id);
    }
  }, [id, data, fetchData]);

  // last time we tried to fetch or it changed, restart the timer
  useEffect(() => {
    if (id && needsPoll) {
      const timer = setTimeout(() => {
        fetchData(id);
      }, frequency);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [id, needsPoll, frequency, fetchData, last]);

  const refreshData = useCallback(
    (val) => {
      if (val) {
        setDoc(val);
      } else {
        fetchData(id);
      }
    },
    [fetchData, id, setDoc],
  );

  const [documentState, setDocumentState] = useState(CHECKING_STATUS);
  useEffect(() => {
    const nextState = computeState(id, doc, clicked, signer, email);
    if (documentState != nextState) {
      setDocumentState(nextState);
    }
  }, [id, doc, documentState, clicked, signer, email]);

  const openLink = useCallback(async () => {
    const w = window.open('/loading.html', `document-${id}`);
    try {
      const response = await syndicatesClient.get_document_link({
        id,
        role: signer ? role : 'reviewer',
      });
      if (response.data) {
        setClicked(true);
        w.location = response.data;
      }
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
    }
  }, [id, role, signer]);

  return {
    data: doc,
    documentState,
    error,
    refreshData,
    openLink,
    clicked,
  };
};

export default useSyndicateDocumentPoll;
