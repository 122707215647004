import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';

import SaveFormButton from 'components/Form/SaveFormButton';

import getServerResponseErrors from 'api/getServerResponseErrors';
import userInvestmentsClient from 'api/userInvestmentsClient';

import imageIndividual from 'images/investor-individual.png';
import imageEntity from 'images/investor-entity.png';
import imageIRA from 'images/investor-ira.png';
import imageSingleMemberLLC from 'images/investor-singlememberllc.png';
import userClient from 'api/user/userClient';

const useSubscriptionTypeStyles = makeStyles((theme) => ({
  typeGraphic: {
    marginBottom: theme.spacing(1),
    img: {
      height: '5rem',
    },
  },
  selected: {
    height: '100%',
    border: '1px solid',
    borderColor: theme.palette.primary.main,
    '&:hover:not([disabled])': {
      cursor: 'pointer',
      borderColor: '#4547DC',
    },
    '&:active:not([disabled])': {
      borderColor: theme.palette.accent.main,
    },
  },
  notSelected: {
    height: '100%',
    border: '1px solid',
    borderColor: theme.palette.grey[400],
    '&:hover:not([disabled])': {
      cursor: 'pointer',
      borderColor: '#4547DC',
    },
    '&:active:not([disabled])': {
      borderColor: theme.palette.accent.main,
    },
  },
}));

export const TypesDisplay = ({ setSelectedType, selectedType }) => {
  const classes = useSubscriptionTypeStyles();
  return (
    <Grid container spacing={2} alignItems="stretch">
      {investorTypes.map((type) => (
        <Grid key={type.id} item md={3} sm={6} style={{ width: '100%' }}>
          <Paper
            variant="outlined"
            onClick={() => setSelectedType(type.id)}
            className={
              selectedType === type.id ? classes.selected : classes.notSelected
            }
          >
            <Box padding={2}>
              <Box className={classes.typeGraphic}>
                <img src={type.image} alt=""></img>
              </Box>
              <Typography variant="h6">{type.title}</Typography>
              <Typography variant="caption">{type.description}</Typography>
            </Box>
          </Paper>
        </Grid>
      ))}
    </Grid>
  );
};
export const investorTypes = [
  {
    id: 'individual',
    title: 'Individual',
    description:
      'You plan on making your investment in your individual capacity.',
    image: imageIndividual,
  },
  {
    id: 'ira',
    title: 'IRA / 401 (k)',
    description:
      'You plan on making an investment through an individual IRA / 401(k).',
    image: imageIRA,
  },
  {
    id: 'singlememberllc',
    title: 'Single Member LLC',
    description:
      'You plan on making an investment through a single-member/disregarded entity LLC.',
    image: imageSingleMemberLLC,
  },
  {
    id: 'entity',
    title: 'Entity',
    description: 'You plan on making your investment through any other entity.',
    image: imageEntity,
  },
];

const SubscriptionType = ({
  userInvestment,
  onBack,
  onChange,
  actionButton,
}) => {
  const { entityType } = userInvestment;
  const [selectedType, setSelectedType] = useState(entityType);

  const { id } = userInvestment;

  const onSave = async (onError) => {
    const setDefaults = async (data, entityType) => {
      try {
        const defaultResponse = await userClient.getInvestDefaults({
          entityType,
        });
        if (defaultResponse.data) {
          const response = await userInvestmentsClient.update({
            id,
            data: defaultResponse.data,
          });
          onChange(response.data);
          return;
        }
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.error(error);
      }
      onChange(data);
    };
    try {
      const updateResponse = await userInvestmentsClient.update({
        id,
        infoSave: false,
        data: {
          entityType: selectedType,
        },
      });
      const { entityType: newType } = updateResponse.data;
      if (newType !== entityType) {
        await setDefaults(updateResponse.data, newType);
      } else {
        onChange(updateResponse.data);
      }

      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };
  return (
    <Box>
      <Box>
        <Box marginTop={2} textAlign="center">
          <TypesDisplay {...{ setSelectedType, selectedType }} />
        </Box>
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justify="space-between">
          <Grid item>{actionButton}</Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={onBack}
              style={{ marginRight: '1rem' }}
            >
              Back
            </Button>
            <SaveFormButton onSave={onSave} name="Next"></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SubscriptionType;
