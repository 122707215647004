import React, { useEffect, useState } from 'react';

import { Box, Typography, Button as MuiButton, Paper } from '@material-ui/core';
import Button from 'components/ui/Button/Button';
import { LoadingSpinner } from 'components/ui/Loading';

import getServerResponseErrors from 'api/getServerResponseErrors';
import taxYearsClient from 'api/taxYearsClient';

import TaxQuestions from './TaxQuestions';
import TaxYearConfirm from './TaxYearConfirm';
import TaxYearReview from './TaxYearReview';
import TaxYearFederal from './TaxYearFederal';
import TaxYearState from './TaxYearState';
import { useHistory } from 'react-router';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { useProfile } from 'hooks/useAppState';

const TaxYear = ({ investment, year }) => {
  const { syndicateId, syndicateName } = investment;
  const [taxYear, setTaxYear] = useState();
  const history = useHistory();
  const isSuperAdmin = useProfile((state) => state.isAdmin);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await taxYearsClient.lookup({ syndicateId, year });
        setTaxYear(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (syndicateId && year) {
      fetchData();
    }
  }, [syndicateId, year]);

  if (!taxYear) {
    return <LoadingSpinner />;
  }

  const {
    id,
    dateStarted,
    dateAgreement,
    dateConfirmed,
    dateFiled,
    dateFederal,
    dateState,
    isManual,
    filingRequired,
    dateCompleted,
  } = taxYear;

  const handleStart = async (isManual, distributionAmount) => {
    try {
      await taxYearsClient.update({
        id,
        data: {
          isManual,
          distributionAmount,
        },
      });
      const response = await taxYearsClient.start({ id });
      setTaxYear(response.data);
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  };

  return (
    <Box>
      <Box>
        <MuiButton
          variant="link"
          startIcon={<ChevronLeftIcon fontSize="24px" />}
          onClick={() => {
            history.push(history.location.pathname.replace(year, ''));
          }}
        >
          Back to taxes overview
        </MuiButton>
      </Box>
      <Box display="flex" justifyContent="center">
        <Paper variant="outlined" style={{ maxWidth: '500px', width: '100%' }}>
          <Box textAlign="center" py={1}>
            <Typography style={{ fontWeight: 'bold', fontSize: 24 }}>
              {syndicateName}
            </Typography>
            <Typography style={{ fontSize: 20 }}>{year} Taxes</Typography>
          </Box>
        </Paper>
      </Box>
      <Box
        marginTop={2}
        minHeight="40vh"
        display="flex"
        flexDirection="column"
        justifyContent="space-between"
      >
        {dateCompleted && !filingRequired ? (
          <Box textAlign="center">
            <Box fontSize={24}>No filing for {year}</Box>

            {isSuperAdmin && (
              <Box marginTop={2}>
                <Button
                  onClick={() => {
                    setTaxYear((x) => ({ ...x, dateCompleted: null }));
                  }}
                >
                  Add Filing
                </Button>
              </Box>
            )}
          </Box>
        ) : !dateStarted ? (
          <Box>
            <TaxQuestions {...{ taxYear, handleStart }} />
          </Box>
        ) : !isManual && !dateAgreement ? (
          <Box>needs agreement</Box>
        ) : !dateConfirmed ? (
          <TaxYearConfirm {...{ taxYear, setTaxYear }} />
        ) : !isManual && !dateFiled ? (
          <Box>Awaiting filing of State and Federal documents</Box>
        ) : !dateFederal ? (
          <TaxYearFederal {...{ taxYear, setTaxYear }} />
        ) : !dateState ? (
          <TaxYearState {...{ taxYear, setTaxYear }} />
        ) : filingRequired ? (
          <TaxYearReview {...{ investment, taxYear, setTaxYear }} />
        ) : (
          <Box>No Filing Required</Box>
        )}
      </Box>
    </Box>
  );
};

export default TaxYear;
