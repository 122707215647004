import apiClient from 'api/axios';

const index = ({ opportunityId }) => {
  return apiClient.get(`/api/v1/opportunities/${opportunityId}/rounds`, {
    params: {},
  });
};

const get = ({ id }) => {
  return apiClient.get(`/api/v1/rounds/${id}`, {
    params: {},
  });
};

const create = ({ opportunityId, data }) => {
  return apiClient.post(`/api/v1/rounds`, {
    round: data,
    opportunityId: opportunityId,
  });
};

const update = ({ id, data }) => {
  return apiClient.patch(`/api/v1/rounds/${id}`, {
    round: data,
  });
};

const close = ({ id }) => {
  return apiClient.post(`/api/v1/rounds/${id}/close`);
};

const destroy = ({ id }) => {
  return apiClient.delete(`/api/v1/rounds/${id}`);
};

const createFeaturedInvestor = ({ roundId, data }) => {
  return apiClient.post(`/api/v1/rounds/${roundId}/round_featured_investors`, {
    featured: data,
  });
};

const updateFeaturedInvestor = ({ id, data }) => {
  return apiClient.patch(`/api/v1/round_featured_investors/${id}`, {
    featured: data,
  });
};

const deleteFeaturedInvestor = ({ id }) => {
  return apiClient.delete(`/api/v1/round_featured_investors/${id}`);
};

const createDocument = ({ roundId, data }) => {
  return apiClient.post(`/api/v1/rounds/${roundId}/round_documents`, {
    document: data,
  });
};

const updateDocument = ({ id, data }) => {
  return apiClient.patch(`/api/v1/round_documents/${id}`, {
    document: data,
  });
};

const deleteDocument = ({ id }) => {
  return apiClient.delete(`/api/v1/round_documents/${id}`);
};

const roundsClient = {
  index,
  get,
  create,
  update,
  destroy,
  close,
  createFeaturedInvestor,
  updateFeaturedInvestor,
  deleteFeaturedInvestor,
  createDocument,
  updateDocument,
  deleteDocument,
};

export default roundsClient;
