import React from 'react';
import { Grid } from '@material-ui/core';
import iconWebsite from 'images/icons/website.svg';
import iconTwitter from 'images/icons/twitter.svg';
import iconFacebook from 'images/icons/facebook.svg';
import iconLinkedin from 'images/icons/linkedin.svg';
import iconCrunchbase from 'images/icons/crunchbase.svg';
import iconInstagram from 'images/icons/instagram.svg';

const Social = ({
  otherUrl,
  twitterUrl,
  facebookUrl,
  linkedinUrl,
  websiteUrl,
  crunchbaseUrl,
  instagramUrl,
  justify = 'center',
}) => {
  return (
    <Grid container spacing={2} justify={justify} wrap="nowrap">
      {otherUrl && (
        <Grid item>
          <a href={otherUrl} target="_blank" rel="noopener noreferrer">
            <img alt="" src={iconWebsite} />
          </a>
        </Grid>
      )}
      {twitterUrl && (
        <Grid item>
          <a href={twitterUrl} target="_blank" rel="noopener noreferrer">
            <img alt="" src={iconTwitter} />
          </a>
        </Grid>
      )}
      {facebookUrl && (
        <Grid item>
          <a href={facebookUrl} target="_blank" rel="noopener noreferrer">
            <img alt="" src={iconFacebook} />
          </a>
        </Grid>
      )}
      {linkedinUrl && (
        <Grid item>
          <a href={linkedinUrl} target="_blank" rel="noopener noreferrer">
            <img alt="" src={iconLinkedin} />
          </a>
        </Grid>
      )}
      {crunchbaseUrl && (
        <Grid item>
          <a href={crunchbaseUrl} target="_blank" rel="noopener noreferrer">
            <img alt="" src={iconCrunchbase} />
          </a>
        </Grid>
      )}
      {instagramUrl && (
        <Grid item>
          <a href={instagramUrl} target="_blank" rel="noopener noreferrer">
            <img alt="" src={iconInstagram} />
          </a>
        </Grid>
      )}
      {websiteUrl && (
        <Grid item>
          <a href={websiteUrl} target="_blank" rel="noopener noreferrer">
            <img alt="" src={iconWebsite} />
          </a>
        </Grid>
      )}
    </Grid>
  );
};

export default Social;
