import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';

import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { DialogWithActions } from 'components/ui/DialogWithTitle';
import syndicatesClient from 'api/syndicatesClient';

const useFormStateRegistration = ({ data }) => {
  const [formRef, setFormRef] = useState({});

  const validate = async () => {
    const {
      values: { stateRegistrationLink },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        stateRegistrationLink,
      },
    };
  };

  const form = (
    <Box>
      <Form initialValues={data} enableReinitialize setRef={setFormRef}>
        <Box>
          <FormikTextInput
            name="stateRegistrationLink"
            label="State Registration Website"
            variant="filled"
            required
          />
        </Box>
      </Form>
    </Box>
  );

  return { form, validate };
};

export const useStateFiling = ({ data, onChange }) => {
  const [modal, showModal] = useState();

  const { form, validate } = useFormStateRegistration({
    data,
  });
  const onSave = useCallback(
    async (onError, minDelay) => {
      try {
        const { values, errors } = await validate();
        const { stateRegistrationLink } = values;
        const syndicate = { ...data };
        const { id } = syndicate;
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const response = await syndicatesClient.update({
          id,
          data: {
            stateRegistrationLink: stateRegistrationLink,
          },
        });
        await minDelay;
        if (onChange) onChange(response.data);
        showModal();
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    },
    [data, onChange, validate],
  );

  const modalElement = useMemo(() => {
    const footer = (
      <Box padding={2}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={() => showModal()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton
              onSave={onSave}
              name={data ? 'Save' : 'Add'}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        fullWidth
        maxWidth="sm"
        {...{
          title: `${data ? 'Change' : 'Add'} State Registration Website`,
          // header,
          footer,
          onClose: () => showModal(),
        }}
      >
        <Box padding={3}>{form}</Box>
      </DialogWithActions>
    ) : null;
  }, [data, form, modal, onSave]);

  return { modal: modalElement, showModal, form, validate, onSave };
};

// eslint-disable-next-line import/no-unused-modules
export default useFormStateRegistration;
