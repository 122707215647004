import React from 'react';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import { formatter, percentFormatter } from './formatterUtils';

export const SoftwareFees = ({
  smartcapitalFeeBase,
  smartcapitalFeePerMember,
  smartcapitalFeePercent,
  editClick,
}) => {
  return (
    <Paper variant="outlined" square style={{ padding: '1rem' }}>
      <Grid container justify="space-between">
        <Grid item xs>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Software Fees:
          </Typography>
        </Grid>
        {editClick && (
          <Grid item>
            <Button color="primary" size="small" onClick={editClick}>
              Edit
            </Button>
          </Grid>
        )}
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Base Fee</i>
            <br />
            {smartcapitalFeeBase < 0
              ? 'Custom'
              : formatter.format(smartcapitalFeeBase || 0)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Per Investor Fee</i>
            <br />
            {smartcapitalFeeBase < 0
              ? 'Custom'
              : formatter.format(smartcapitalFeePerMember || 0)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <i>% of Raise Fee</i>
            <br />
            {smartcapitalFeeBase < 0
              ? 'Custom'
              : percentFormatter.format((smartcapitalFeePercent || 0) / 100)}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

const FeesCard = ({
  managementFeeBase,
  managementFeePerMember,
  managementFeePercent,
  carryAmount,
  editClick,
}) => {
  return (
    <Paper variant="outlined" square style={{ padding: '1rem' }}>
      <Grid container justify="space-between">
        <Grid item xs>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Management Fees:
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" size="small" onClick={editClick}>
            Edit
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Base Fee</i>
            <br />
            {managementFeeBase < 0
              ? 'Custom'
              : formatter.format(managementFeeBase || 0)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Per Investor Fee</i>
            <br />
            {managementFeeBase < 0
              ? 'Custom'
              : formatter.format(managementFeePerMember || 0)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <i>% of Raise Fee</i>
            <br />
            {managementFeeBase < 0
              ? 'Custom'
              : percentFormatter.format((managementFeePercent || 0) / 100)}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="subtitle1">
            <i>Carry</i>
            <br />
            {carryAmount < 0 ? 'Custom' : `${carryAmount}%`}
          </Typography>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default FeesCard;
