import React from 'react';
import { Box, withStyles } from '@material-ui/core';
import Header from './Header';
import Content from './Content';
import Footer from './Footer';
const styles = () => ({});
const Wizard = withStyles(styles)(
  ({ header, content, footer, classes, ...others }) => {
    return (
      <Box className={classes.wrapper} {...others}>
        <Header className={classes.header}>{header}</Header>
        <Content className={classes.content}>{content}</Content>
        <Footer className={classes.footer}>{footer}</Footer>
      </Box>
    );
  },
);
export default Wizard;
