import apiClient from 'api/axios';

const index = ({ groupId, searchQuery, filter }) => {
  return apiClient.get(`/api/v1/groups/${groupId}/group_users`, {
    params: {
      searchQuery,
      filter,
    },
  });
};

const create = ({ groupId, data }) => {
  return apiClient.post(`/api/v1/groups/${groupId}/group_users`, {
    groupUser: data,
  });
};

const show = ({ id }) => {
  return apiClient.get(`/api/v1/group_users/${id}`, {
    params: {},
  });
};

const update = ({ id, data }) => {
  return apiClient.patch(`/api/v1/group_users/${id}`, {
    groupUser: data,
  });
};

const invite = ({ groupId, data }) => {
  return apiClient.post(`/api/v1/groups/${groupId}/group_users/invite`, {
    groupUsers: data,
  });
};

const confirm = ({ id }) => {
  return apiClient.post(`/api/v1/group_users/${id}/confirm`, {});
};

const suspend = ({ id }) => {
  return apiClient.post(`/api/v1/group_users/${id}/suspend`, {});
};

const resend = ({ id }) => {
  return apiClient.post(`/api/v1/group_users/${id}/resend`, {});
};

const reactivate = ({ id }) => {
  return apiClient.post(`/api/v1/group_users/${id}/reactivate`, {});
};

const updateSuper = ({ id, isAdmin }) => {
  return apiClient.post(`/api/v1/group_users/${id}/super`, { isAdmin });
};

const groupUsersClient = {
  index,
  show,
  create,
  update,
  invite,
  confirm,
  suspend,
  resend,
  reactivate,
  updateSuper,
};

export default groupUsersClient;
