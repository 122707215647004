import apiClient from 'api/axios';

const index = () => apiClient.get('/api/v1/groups', { data: {} });
const getBySlug = (groupSlug) =>
  apiClient.get(`/api/v1/groups/${groupSlug}`, { params: { slug: groupSlug } });
const get = (id) => apiClient.get(`/api/v1/groups/${id}`, {});

const create = ({ group, user }) =>
  apiClient.post('/api/v1/groups', { group, user });

const update = ({ id, data }) =>
  apiClient.patch(`/api/v1/groups/${id}`, { group: data });

const joinRound = ({ groupId, roundId }) => {
  return apiClient.post(`/api/v1/groups/${groupId}/join_round`, { roundId });
};

const admins = ({ id }) => {
  return apiClient.get(`/api/v1/groups/${id}/admins`, {
    params: {},
  });
};

const requestAccess = ({ id, data }) => {
  return apiClient.post(`/api/v1/groups/${id}/request_access`, {
    request: data,
  });
};

const blueSkyInfo = ({ id }) => {
  return apiClient.get(`/api/v1/groups/${id}/blue_sky_info`, {
    params: {},
  });
};

const wires = ({ id }) => {
  return apiClient.get(`/api/v1/groups/${id}/payment_wires`, {
    params: {},
  });
};

const checks = ({ id }) => {
  return apiClient.get(`/api/v1/groups/${id}/payment_checks`, {
    params: {},
  });
};

const removeOpportunity = ({ id, opportunityId }) => {
  return apiClient.post(`/api/v1/groups/${id}/remove_opportunity`, {
    opportunityId,
  });
};
const shareOpportunity = ({ id, data }) => {
  return apiClient.post(`/api/v1/groups/${id}/share_opportunity`, {
    share: data,
  });
};

const sendTrackedEmail = ({
  syndicateId,
  groupId,
  subject,
  text,
  emails,
  replyTo,
  buttonText,
  buttonUrl,
}) => {
  return apiClient.post(`/api/v1/groups/${groupId}/tracked_emails`, {
    trackedEmail: {
      groupInvestmentId: syndicateId,
      subject,
      text,
      recipients: emails,
      replyTo,
      buttonText,
      buttonUrl,
    },
  });
};

const getTrackedEmails = ({ groupInvestmentId, groupId, email, userId }) => {
  return apiClient.get(`/api/v1/groups/${groupId}/tracked_emails`, {
    params: {
      groupInvestmentId,
      email,
      userId,
    },
  });
};

const groupsClient = {
  index,
  get,
  create,
  update,
  getBySlug,
  joinRound,
  admins,
  requestAccess,
  blueSkyInfo,
  wires,
  checks,
  removeOpportunity,
  shareOpportunity,
  sendTrackedEmail,
  getTrackedEmails,
};

export default groupsClient;
