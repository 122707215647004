import React, { useState, useEffect } from 'react';
import {
  Box,
  Button,
  Grid,
  IconButton,
  Typography,
  useTheme,
} from '@material-ui/core';

import getServerResponseErrors from 'api/getServerResponseErrors';

import DialogWithTitle from 'components/ui/DialogWithTitle';
import roundsClient from 'api/roundsClient';
import opportunitiesClient from 'api/opportunitiesClient';
import useListUpdate, { useArrayUpdate } from 'hooks/useListUpdate';
import useDeleteConfirm from 'hooks/useDeleteConfirm';

import AddIcon from '@material-ui/icons/Add';

import {
  EditDocument,
  ManageDocuments,
  useDocumentSave,
} from 'views/Documents';
import { FeaturedInvestorView } from 'views/FeaturedInvestors';
import DocumentIcon from 'components/ui/DocumentIcon';

import moment from 'moment';
import { useEditRound } from 'hooks/forms/useFormRound';
import { useSelectedGroup } from 'hooks/useAppState';
import { DeleteForever } from '@material-ui/icons';

const EditRounds = ({ opportunity }) => {
  const { id: opportunityId } = opportunity;
  const [rounds, setRounds] = useState();
  const [investments, setInvestments] = useState();
  const { groupId } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
    enableOpportunitySharing: state.enableOpportunitySharing,
  }));
  useEffect(() => {
    const fetchInvestments = async () => {
      const { data } = await opportunitiesClient.investments({
        id: opportunityId,
        groupId,
      });
      setInvestments(data);
    };
    if (opportunityId && groupId) {
      fetchInvestments();
    }
  }, [groupId, opportunityId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await roundsClient.index({ opportunityId });
        setRounds(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (opportunityId) {
      fetchData();
    }
  }, [opportunityId]);

  const onUpdate = (func) => {
    setRounds((cache) => func(cache));
  };

  const { onListUpdate } = useArrayUpdate(onUpdate);
  const { modal, showModal } = useEditRound({
    opportunityId,
    onChange: onListUpdate,
  });

  const roundSort = (a, b) => {
    if (!a.dateClosed && b.dateClosed) {
      return -1;
    } else if (a.dateClosed && !b.dateClosed) {
      return 1;
    } else if (a.dateClosed === b.dateClosed) {
      return 0;
    } else if (a.dateClosed > b.dateClosed) {
      return -1;
    } else {
      return 1;
    }
  };
  // https://kovart.github.io/dashed-border-generator/
  const dashedline =
    "data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='lightgray' stroke-width='4' stroke-dasharray='6%2c 14' stroke-dashoffset='0' stroke-linecap='square'/%3e%3c/svg%3e";
  return (
    <Box>
      {modal}
      <Box>
        <Typography variant="h6" color="primary">
          Rounds
        </Typography>
        <Typography variant="caption">
          This is a list of current and previous rounds related to the
          opportunity.
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={3} alignItems="stretch">
          <Grid item xs={12} sm={6} md={4}>
            <Box
              padding={3}
              bgcolor="white"
              height="100%"
              textAlign="center"
              onClick={() => showModal({})}
              style={{
                cursor: 'pointer',
                backgroundImage: `url("${dashedline}")`,
              }}
            >
              <Box fontSize="5rem" lineHeight="1rem" marginTop={5}>
                <AddIcon color="primary" fontSize="inherit"></AddIcon>
              </Box>
              <Box>
                <Typography variant="h6">Add Round</Typography>
              </Box>
              <Box>
                <Typography variant="caption">
                  Add a current or previous funding round for this opportunity.
                </Typography>
              </Box>
              <Box marginTop={6}>
                <Button
                  variant="outlined"
                  color={
                    rounds && rounds.some((x) => !x.isClosed) ? '' : 'primary'
                  }

                  // disabled={rounds && rounds.some((x) => !x.isClosed)}
                >
                  Add Round
                </Button>
              </Box>
            </Box>
          </Grid>
          {rounds &&
            rounds.length > 0 &&
            rounds.sort(roundSort).map((round) => (
              <Grid item key={round.id} xs={12} sm={6} md={4}>
                <RoundView
                  {...{
                    data: round,
                    canEdit: true,
                    showModal,
                    onChange: onListUpdate,
                    canDelete: !investments?.some(
                      (x) => x.roundId === round.id,
                    ),
                  }}
                />
              </Grid>
            ))}
        </Grid>
      </Box>
    </Box>
  );
};

export const RoundView = ({
  data,
  canEdit = false,
  showModal,
  onChange,
  canDelete,
}) => {
  const {
    id: roundId,
    name,
    dateClosed,
    premoneyValuation,
    raisedAmount,
    targetAmount,
    investmentType,
    investmentCap,
    investmentDiscount,
    investmentInterest,
    investmentOther,
    featured = [],
    documents = [],
  } = data;

  const [featuredModal, showFeatured] = useState(false);
  const [documentModal, showDocuments] = useState(false);
  const theme = useTheme();
  const onDelete = async (onError) => {
    try {
      const response = await roundsClient.destroy({ id: roundId });
      if (response) {
        onChange({ ...data, isDeleted: true });
      }
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };
  const { modal, showModal: showDeleteModal } = useDeleteConfirm({
    onConfirm: onDelete,
    headerName: 'Round',
  });

  return (
    <>
      {modal}
      <Box
        padding={3}
        border="1px solid lightgray"
        bgcolor="white"
        height="100%"
      >
        {documentModal && (
          <>
            <DialogWithTitle
              open
              onClose={() => {
                showDocuments(false);
              }}
              title="Round Documents"
              fullWidth
            >
              <RoundDocumentsView
                data={data}
                canEdit={canEdit}
                onChange={onChange}
              />
            </DialogWithTitle>
          </>
        )}
        {featuredModal && (
          <>
            <DialogWithTitle
              open
              onClose={() => {
                showFeatured(false);
              }}
              title={`${name} Featured Investors`}
              fullWidth
            >
              <FeaturedInvestorList investors={featured} />
            </DialogWithTitle>
          </>
        )}
        <Box display="flex" justifyContent="space-between">
          <Typography variant="h6">{name}</Typography>
          {canDelete && canEdit && (
            <Box>
              <IconButton
                aria-label="close"
                size="small"
                color="primary"
                title="Delete Round"
                onClick={showDeleteModal}
              >
                <DeleteForever />
              </IconButton>
            </Box>
          )}
        </Box>
        <Box>
          <Typography variant="caption" color="primary">
            {dateClosed ? 'Previous' : 'Current'}
          </Typography>
        </Box>
        <Box>
          {(investmentType == 'preferred' || investmentType == 'common') && (
            <NameValue
              name="Pre Money Valuation"
              value={currencyFormatter.format(premoneyValuation || 0)}
            />
          )}
          {(investmentType == 'safe' || investmentType == 'note') && (
            <>
              <NameValue
                name="Cap"
                value={
                  investmentCap ? currencyFormatter.format(investmentCap) : '-'
                }
              />
              <NameValue
                name="Discount"
                value={
                  investmentDiscount
                    ? percentFormatter.format(investmentDiscount / 100)
                    : '-'
                }
              />
            </>
          )}
          {investmentType == 'note' && (
            <NameValue
              name="Interest"
              value={
                investmentInterest
                  ? percentFormatter.format(investmentInterest / 100)
                  : '-'
              }
            />
          )}
          {investmentType == 'other' && (
            <NameValue name="Investment Type" value={investmentOther} />
          )}

          {dateClosed ? (
            <NameValue
              name="Date Closed"
              value={moment(dateClosed).format('MM-DD-yyyy')}
            />
          ) : (
            <NameValue
              name="Target Raise"
              value={
                targetAmount ? currencyFormatter.format(targetAmount) : '-'
              }
            />
          )}
          <NameValue
            name={dateClosed ? 'Total Raised' : 'Currently Raised'}
            value={raisedAmount ? currencyFormatter.format(raisedAmount) : '-'}
            valueStyleProps={{ color: theme.palette.money.main }}
          />
        </Box>
        <Box
          marginTop={2}
          onClick={() => showDocuments(true)}
          style={{ cursor: 'pointer' }}
        >
          <Grid container spacing={1}>
            <Grid item>
              <DocumentIcon count={documents.length} />
            </Grid>
            <Grid item>
              <Box>
                <Typography>
                  Round
                  <br />
                  Documents
                </Typography>
              </Box>
            </Grid>
          </Grid>
        </Box>

        {canEdit && (
          <Box marginTop={2} width="100%">
            <Grid container spacing={2} justify="space-around">
              <Grid item>
                <Box>
                  <Button
                    color="primary"
                    variant="outlined"
                    style={{ cursor: 'pointer' }}
                    onClick={() => showModal(data)}
                  >
                    Edit
                  </Button>
                </Box>
              </Grid>
              {/* {investmentButton && <Grid item>{investmentButton}</Grid>} */}
            </Grid>
          </Box>
        )}
      </Box>
    </>
  );
};

var currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
var percentFormatter = new Intl.NumberFormat('en-US', {
  style: 'percent',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});
const NameValue = ({ name, value, valueStyleProps }) => {
  return (
    <Box
      marginTop={2}
      paddingBottom={1}
      style={{ borderBottom: '1px solid lightgray' }}
    >
      <Grid container spacing={3}>
        <Grid item xs>
          {name}:
        </Grid>
        <Grid item style={{ ...valueStyleProps }}>
          {value}
        </Grid>
      </Grid>
    </Box>
  );
};

const FeaturedInvestorList = ({ investors }) => {
  return (
    <Box>
      <Grid container spacing={3} alignItems="stretch">
        {investors.map((investor) => (
          <Grid key={investor.id} item>
            <Box
              padding={3}
              border="1px solid lightgray"
              height="100%"
              position="relative"
            >
              <FeaturedInvestorView data={investor} />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

const RoundDocumentsView = ({ data, onChange, canEdit }) => {
  const { id, documents = [] } = data;

  const { onListUpdate } = useListUpdate('documents', (func) => {
    onChange(func(data));
  });
  const withSave = useDocumentSave({
    params: { roundId: id },
    create: roundsClient.createDocument,
    update: roundsClient.updateDocument,
    remove: roundsClient.deleteDocument,
    onChange: onListUpdate,
  });

  const [edit, setEdit] = useState();
  return (
    <Box>
      {canEdit && (
        <Box marginBottom={4}>
          <Grid container spacing={4} justify="space-between">
            <Grid item xs>
              <Box>
                <Typography variant="caption">
                  This is where you put the round documents like Term Sheet,
                  Financing Agreement and others documents related to the round.
                </Typography>
              </Box>
            </Grid>

            <Grid item>
              <Box>
                {edit ? (
                  <DialogWithTitle
                    open
                    onClose={() => setEdit(null)}
                    title={`${(edit || {}).id ? 'Edit' : 'Add'} Document`}
                    fullWidth
                  >
                    <EditDocument
                      onClose={() => setEdit(null)}
                      {...{ data: edit, withSave }}
                    />
                  </DialogWithTitle>
                ) : null}

                <Button variant="contained" onClick={() => setEdit({})}>
                  Add Document
                </Button>
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
      <Box marginBottom={4}>
        <ManageDocuments
          {...{
            documents,
            withSave,
            canEdit,
          }}
        ></ManageDocuments>
      </Box>
    </Box>
  );
};

export default EditRounds;
