import React, { useContext } from 'react';
import {
  Box,
  Typography,
  makeStyles,
  useMediaQuery,
  Link,
  useTheme,
  Chip,
} from '@material-ui/core';
import { SiWebpack } from 'react-icons/si';
import Button from 'components/ui/Button/Button';
import {
  DataGridPro,
  GridOverlay,
  GridToolbarContainer,
  GridToolbarExport,
} from '@mui/x-data-grid-pro';
import moment from 'moment';
import { createStyles } from '@material-ui/styles';
import { GroupContext } from 'components/UserAuth/UserAuth';
import { useHistory, useRouteMatch } from 'react-router';
import { DialogWithActions } from 'components/ui/DialogWithTitle';
import InvestmentDetails from './InvestmentDetails';
import { CustomGridColumnMenu } from 'components/ui/CustomGridColumnMenu';

const CustomToolbar = () => {
  return (
    <GridToolbarContainer>
      <GridToolbarExport printOptions={{ disableToolbarButton: true }} />
    </GridToolbarContainer>
  );
};

const useStyles = makeStyles((theme) => ({
  root: {
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
      outline: 'none',
    },
    '&.MuiDataGrid-root .MuiDataGrid-iconSeparator': {
      display: 'none',
    },
  },
  moneyColor: {
    color: theme.palette.money.main,
  },
}));

const useOverlayStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    label: {
      marginTop: theme.spacing(1),
    },
  }),
);

const CustomNoRowsOverlay = () => {
  const classes = useOverlayStyles();

  return (
    <GridOverlay className={classes.root}>
      <SiWebpack fontSize={50} style={{ fill: 'url(#mygradient)' }} />
      <div className={classes.label}>No Results</div>
    </GridOverlay>
  );
};

const TaxCell = ({ row, value }) => {
  const history = useHistory();
  const theme = useTheme();
  const taxStatusColors = {
    Filed: { background: theme.palette.accent.main, color: 'white' },
    Pending: {
      background: theme.palette.danger.main,
      color: 'white',
    },
    'No Filing': {
      background: theme.palette.grey[300],
      color: theme.palette.text.primary,
    },
  };

  let extraChipProps = {};
  if (value !== 'Pending') {
    extraChipProps = {
      onClick: () =>
        history.push(`/user/dashboard/documents/${row?.syndicateId}/tax`),
    };
  }
  return (
    <Chip
      style={{
        background: taxStatusColors[value]?.background,
        color: taxStatusColors[value]?.color,
      }}
      size="small"
      label={value}
      {...extraChipProps}
    />
  );
};

const GroupCell = ({ row }) => {
  const { getGroupSlug } = useContext(GroupContext) || {};
  const history = useHistory();

  const slug = getGroupSlug(row?.groupId);
  return (
    <Link
      onClick={() => history.push(`/${slug}`)}
      style={{
        cursor: 'pointer',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        lineHeight: 1,
      }}
    >
      {row?.groupName}
    </Link>
  );
};
const OpportunityCell = ({ row }) => {
  const { getGroupSlug } = useContext(GroupContext) || {};
  const history = useHistory();

  const slug = getGroupSlug(row?.groupId);
  return (
    <Link
      onClick={() => history.push(`/${slug}/opportunity/${row?.opportunityId}`)}
      style={{
        cursor: 'pointer',
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        lineHeight: 1,
      }}
    >
      {row?.opportunityName}
    </Link>
  );
};
const NameCell = (props) => {
  const { row } = props;
  return (
    <Box
      style={{
        whiteSpace: 'normal',
        wordBreak: 'break-word',
        lineHeight: 1,
      }}
      title={row?.syndicateName}
    >
      {row?.syndicateName}
    </Box>
  );
};
const RowButtons = ({ row, open, initialTab }) => {
  const history = useHistory();

  return (
    <>
      {open && (
        <DialogWithActions
          fullWidth
          maxWidth="md"
          {...{
            title: `Investment Documents`,
            // header,
            onClose: () => history.push(`/user/dashboard`),
          }}
        >
          <InvestmentDetails {...{ data: row, initialTab }} />
        </DialogWithActions>
      )}

      <Box mr={2}>
        <Button
          variant="outlined"
          size="sm"
          onClick={() =>
            history.push(`/user/dashboard/documents/${row?.syndicateId}/legal`)
          }
          title="View your investment documents"
        >
          Legal
        </Button>
      </Box>

      <Button
        variant="outlined"
        size="sm"
        onClick={() =>
          history.push(`/user/dashboard/documents/${row?.syndicateId}/tax`)
        }
        title="View your investment documents"
      >
        Tax
      </Button>
    </>
  );
};

const InvestmentsList = ({ investments }) => {
  const classes = useStyles();
  const showInvestmentDate = useMediaQuery('(min-width:1260px)');
  const showTaxes = useMediaQuery('(min-width:1095px)');
  const showRound = useMediaQuery('(min-width:942px)');
  const showOpportunity = useMediaQuery('(min-width:818px)');
  const showInvested = useMediaQuery('(min-width:642px)');

  const { path } = useRouteMatch();
  const match = useRouteMatch(`${path}/documents/:id/:type`);

  let columns = [
    {
      field: 'syndicateName',
      headerName: 'Syndicate',
      width: 250,
      headerClassName: 'data-grid-header-no-padding',
      renderCell: NameCell,
    },
  ];

  if (showRound)
    columns.push({
      field: 'groupName',
      headerName: 'Group',
      headerClassName: 'data-grid-header-no-padding',
      width: 125,
      renderCell: GroupCell,
    });
  if (showOpportunity)
    columns.push({
      field: 'opportunityName',
      headerName: 'Opportunity',
      headerClassName: 'data-grid-header-no-padding',
      width: 175,
      renderCell: OpportunityCell,
    });

  if (showInvested)
    columns.push({
      field: 'amount',
      headerName: 'Invested',
      headerClassName: 'data-grid-header-no-padding',
      width: 150,
      valueGetter: ({ row }) => {
        const { amount } = row;
        return parseFloat(amount || 0);
      },
      valueFormatter: ({ value }) => {
        return parseFloat(value || 0).toLocaleString('en-US', {
          style: 'currency',
          currency: 'USD',
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        });
      },
      cellClassName: () => classes.moneyColor,
    });
  const today = new Date();

  if (showInvestmentDate)
    columns.push({
      field: 'investmentDate',
      type: 'dateTime',
      headerName: 'Investment Date',
      headerClassName: 'data-grid-header-no-padding',
      width: 200,
      valueFormatter: ({ value }) => {
        return value ? moment(value).utc().format('L') : null;
      },
      valueGetter: ({ row }) => {
        const { dateAccepted } = row;
        return dateAccepted ? moment(dateAccepted) : null;
      },
    });

  if (showTaxes)
    columns.push({
      field: 'lastYearTaxStatus',
      headerName: `${today.getFullYear() - 1} Taxes`,
      headerClassName: 'data-grid-header-no-padding',
      type: 'singleSelect',
      valueOptions: ['Pending', 'Filed', 'No Filing'],
      align: 'start',
      headerAlign: 'start',
      width: 150,
      renderCell: TaxCell,
      valueGetter: ({ row }) => {
        let { lastYearTaxStatus, formationDate } = row;
        const today = new Date();
        if (moment(formationDate).year() > today.getFullYear() - 1) {
          lastYearTaxStatus = 'Not Applicable';
        }
        return lastYearTaxStatus;
      },
    });

  columns.push({
    field: 'buttons',
    headerName: ' ',
    headerClassName: 'data-grid-header-no-padding',
    flex: 1,
    align: 'right',
    sortable: false,
    filterable: false,
    disableColumnMenu: true,
    disableExport: true,
    renderCell: ({ row }) => {
      return RowButtons({
        row,
        open: match && match.params.id == row.syndicateId,
        initialTab: match && match.params.type,
      });
    },
  });

  return (
    <Box>
      <Typography
        style={{ fontSize: 24, fontWeight: 700, padding: '15px 25px' }}
      >
        All Investments
      </Typography>
      <DataGridPro
        className={classes.root}
        components={{
          NoRowsOverlay: CustomNoRowsOverlay,
          ColumnMenu: CustomGridColumnMenu,
          Toolbar: CustomToolbar,
        }}
        rows={investments || []}
        columns={columns}
        rowsPerPageOptions={[10]}
        disableSelectionOnClick
        autoHeight
        disableColumnPinning
        disableColumnSelector
      />
    </Box>
  );
};
export default InvestmentsList;
