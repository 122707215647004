import React from 'react';
import { Container, Grid, Box, Typography } from '@material-ui/core';

const common = ['common.black', 'common.white'];

const colorTypes = ['main', 'light', 'dark', 'contrastText'];

const Theme = () => {
  return (
    <Container maxWidth="lg">
      <Box padding={3}>
        <Box marginBottom={8}>
          <Typography variant="h4" gutterBottom>
            Colors
          </Typography>
          <Box marginBottom={5}>
            <Typography variant="body1">COMMON</Typography>
            <Grid container spacing={3}>
              {common.map((color) => (
                <Grid item xs={3} key={color}>
                  <Box bgcolor={color} padding={3}></Box>
                  <Box textAlign="center">{color}</Box>
                </Grid>
              ))}
            </Grid>
          </Box>

          {['primary', 'secondary', 'error', 'warning', 'info', 'success'].map(
            (c) => (
              <Box marginBottom={5} key={c}>
                <Typography variant="body1">{c.toUpperCase()}</Typography>
                <Grid container spacing={3}>
                  {colorTypes.map((color) => {
                    const colorName = `${c}.${color}`;
                    return (
                      <Grid item xs={3} key={colorName}>
                        <Box bgcolor={colorName} padding={3}></Box>
                        <Box textAlign="center">{colorName}</Box>
                      </Grid>
                    );
                  })}
                </Grid>
              </Box>
            ),
          )}
          <Box marginBottom={5}>
            <Typography variant="body1">Text</Typography>
            <Grid container spacing={3}>
              {['primary', 'secondary', 'disabled', 'hint'].map((color) => {
                const colorName = `text.${color}`;
                return (
                  <Grid item xs={3} key={colorName}>
                    <Box bgcolor={colorName} padding={3}></Box>
                    <Box textAlign="center">{colorName}</Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
          <Box marginBottom={5}>
            <Typography variant="body1">Greys</Typography>
            <Grid container spacing={3}>
              {[
                '50',
                '100',
                '200',
                '300',
                '400',
                '500',
                '600',
                '700',
                '800',
                '900',
                'A100',
                'A200',
                'A400',
                'A700',
              ].map((color) => {
                const colorName = `grey.${color}`;
                return (
                  <Grid item xs={3} key={colorName}>
                    <Box bgcolor={colorName} padding={3}></Box>
                    <Box textAlign="center">{colorName}</Box>
                  </Grid>
                );
              })}
            </Grid>
          </Box>
        </Box>
        <Box marginBottom={8}>
          <Typography variant="h4" gutterBottom>
            Typography
          </Typography>
          <Typography variant="h1" component="h2" gutterBottom>
            h1. Heading
          </Typography>
          <Typography variant="h2" gutterBottom>
            h2. Heading
          </Typography>
          <Typography variant="h3" gutterBottom>
            h3. Heading
          </Typography>
          <Typography variant="h4" gutterBottom>
            h4. Heading
          </Typography>
          <Typography variant="h5" gutterBottom>
            h5. Heading
          </Typography>
          <Typography variant="h6" gutterBottom>
            h6. Heading
          </Typography>
          <Typography variant="subtitle1" gutterBottom>
            subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Quos blanditiis tenetur
          </Typography>
          <Typography variant="subtitle2" gutterBottom>
            subtitle2. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Quos blanditiis tenetur
          </Typography>
          <Typography variant="body1" gutterBottom>
            body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore
            consectetur, neque doloribus, cupiditate numquam dignissimos laborum
            fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="body1" gutterBottom>
            body1. Lorem ipsum dolor sit amet, consectetur adipisicing elit.
            Quos blanditiis tenetur unde suscipit, quam beatae rerum inventore
            consectetur, neque doloribus, cupiditate numquam dignissimos laborum
            fugiat deleniti? Eum quasi quidem quibusdam.
          </Typography>
          <Typography variant="button" display="block" gutterBottom>
            button text
          </Typography>
          <Typography variant="caption" display="block" gutterBottom>
            caption text
          </Typography>
          <Typography variant="overline" display="block" gutterBottom>
            overline text
          </Typography>
        </Box>
        <Box marginBottom={5}>
          <Typography variant="body1">Shadows</Typography>
          <Grid container spacing={3}>
            {Array.from(Array(25).keys()).map((shadow) => {
              return (
                <Grid item xs={3} key={shadow}>
                  <Box boxShadow={shadow} padding={3}></Box>
                  <Box textAlign="center">{shadow}</Box>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Container>
  );
};

export default Theme;
