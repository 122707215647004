import React from 'react';

import FormWizard from './FormWizard';
import { BankingOptions } from './SyndicateBanking';

const SyndicateFormBanking = ({
  investment,
  setInvestment,
  onNext,
  onBack = null,
  onClose,
  buttonName,
}) => {
  const { paymentCheck, paymentWire, acceptChecks, acceptWires } = investment;
  const validate = async () => {
    return {
      errors:
        (acceptChecks && paymentCheck) || (acceptWires && paymentWire)
          ? []
          : ['Please setup and accept a payment method'],
      values: {},
    };
  };

  const form = <BankingOptions {...{ investment, setInvestment }} />;
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext,
        onBack,
        onClose,
        buttonName,
      }}
    />
  );
};

export default SyndicateFormBanking;
