import React, { useCallback, useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import Form from 'components/Form/Form';

import opportunitiesClient from 'api/opportunitiesClient';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { Field } from 'formik';

import { useDocument } from 'components/ui/DocumentPicker';
import { usePhoto } from 'components/ui/PhotoPicker';
import { FormikTextInput } from 'components/ui/CustomTextField';
import ActionWithConfirm from 'components/ui/ActionWithConfirm';

const EditArticle = ({ opportunityId, data, onClose, onChange }) => {
  const [defaultValues, setDefaultValues] = useState({
    ...data,
    isLink: data.link ? 'true' : 'false',
  });
  const { currentPhoto, photoPicker } = usePhoto(data);
  const { currentDocument, documentPicker } = useDocument(data);

  const { id } = defaultValues;
  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: { name, description, link },
        errors,
      } = await validateForm(formRef);
      if (!link && !currentDocument.uri) {
        errors.push('You must add a link or upload a document');
      }
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      const upsert = id
        ? opportunitiesClient.updatePress
        : opportunitiesClient.createPress;

      const response = await upsert({
        id,
        opportunityId,
        data: {
          name,
          description,
          link,
          photo: currentPhoto?.data,
          photoFileName: currentPhoto?.name,
          document: currentDocument?.data,
          documentFileName: currentDocument?.name,
        },
      });
      const data = response.data;
      onChange(data);
      setDefaultValues({ ...data, isLink: data.link ? 'true' : 'false' });
      onClose();
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const handleDelete = useCallback(
    async (onError) => {
      try {
        await opportunitiesClient.deletePress({ id });
        onChange({ id, isDeleted: true }, 'Delete Successful');
        onClose();
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
      }
    },
    [id, onChange, onClose],
  );

  return (
    <ActionWithConfirm onAction={handleDelete}>
      {({ actionButton }) => (
        <Form
          initialValues={defaultValues}
          enableReinitialize
          setRef={setFormRef}
        >
          {({ values }) => (
            <Box>
              <Grid
                container
                spacing={3}
                alignItems="flex-start"
                justify="flex-start"
              >
                <Grid item xs={8}>
                  <Grid container spacing={3}>
                    <Grid item xs={12}>
                      <FormikTextInput
                        name="name"
                        label="Article Name"
                        required
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextInput
                        name="description"
                        label="Description"
                        multiline
                        rowsMax={2}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <Typography variant="h6" color="primary">
                        Attach Document
                      </Typography>
                    </Grid>
                    <Grid item xs={12}>
                      <Grid container spacing={1} row>
                        <Grid item xs={12} sm={3}>
                          <label>
                            <Field
                              type="radio"
                              name="isLink"
                              value="true"
                            ></Field>
                            Website Link
                          </label>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                          <label>
                            <Field
                              type="radio"
                              name="isLink"
                              value="false"
                            ></Field>
                            Upload File
                          </label>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={4}>
                  {photoPicker}
                </Grid>

                {values.isLink === 'true' ? (
                  <Grid item xs={12}>
                    <FormikTextInput name="link" label="URL" required url />
                  </Grid>
                ) : (
                  <Grid item>{documentPicker}</Grid>
                )}
              </Grid>

              <Box marginTop={6}>
                <Grid container justify="space-between" spacing={3}>
                  <Grid item>
                    <Button variant="contained" onClick={onClose}>
                      Cancel
                    </Button>
                  </Grid>
                  {id && <Grid item>{actionButton}</Grid>}
                  <Grid item>
                    <SaveFormButton
                      onSave={onSave}
                      name={id ? 'Save' : 'Add'}
                    ></SaveFormButton>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          )}
        </Form>
      )}
    </ActionWithConfirm>
  );
};

export default EditArticle;
