import { useCallback } from 'react';

import getServerResponseErrors from 'api/getServerResponseErrors';
import useSmartDocument from './useSmartDocument';
import syndicateFilingsClient from 'api/syndicateFilingsClient';

const useSyndicateFiling = ({ id, onChange, createParams }) => {
  const getLink = useCallback(async () => {
    try {
      const response = await syndicateFilingsClient.link({ id });
      return response.data;
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
    }
  }, [id]);
  const openLink = useCallback(async () => {
    const w = window.open('/loading.html', `document-${id}`);
    const link = await getLink(id);
    if (link) {
      w.location = link;
    }
  }, [getLink, id]);

  const onSave = useCallback(
    async (doc, onError) => {
      const modalId = id || doc?.id;
      try {
        if (modalId) {
          const response = await syndicateFilingsClient.update({
            id: modalId,
            data: {
              document: doc ? doc.data : null,
              documentFileName: doc ? doc.name : null,
            },
          });
          const data = response.data;
          onChange(data);
          return true;
        } else {
          const { syndicateId, ...otherParams } = createParams;
          const response = await syndicateFilingsClient.create({
            syndicateId,
            data: {
              ...otherParams,
              document: doc ? doc.data : null,
              documentFileName: doc ? doc.name : null,
            },
          });
          const data = response.data;
          onChange(data);
          return true;
        }
      } catch (e) {
        const errors = getServerResponseErrors(e);
        onError(errors);
      }
    },
    [id, onChange, createParams],
  );

  const { modal, showModal } = useSmartDocument({ onSave, getLink });

  return { modal, showModal, getLink, openLink };
};

export default useSyndicateFiling;
