import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import EditLeader from './EditLeader';
import DialogWithTitle from 'components/ui/DialogWithTitle';
import useListUpdate from 'hooks/useListUpdate';
import { LeaderView } from 'views/OpportunityDetails/OpportunitySectionLeadership';

const EditLeadership = ({ opportunity, onUpdate, readOnly = false }) => {
  const [editLeader, setEditLeader] = useState();

  const { id: opportunityId, isAdmin } = opportunity;
  const { onListUpdate } = useListUpdate('leaders', onUpdate);

  const { leaders } = opportunity || [];
  return (
    <Box>
      <Grid container spacing={4} justify="space-between">
        <Grid item xs>
          <Box marginBottom={2}>
            <Typography variant="h6" color="primary">
              Leadership
            </Typography>
            <Typography variant="caption">
              This is a list of your executive team members.
            </Typography>
          </Box>
        </Grid>
        <Grid item>
          {isAdmin && !readOnly && (
            <Box marginBottom={2}>
              <Button
                variant="contained"
                color="primary"
                onClick={() => setEditLeader({})}
              >
                Add Leader
              </Button>
              {editLeader ? (
                <DialogWithTitle
                  open
                  onClose={() => setEditLeader(null)}
                  fullWidth
                  title={`${(editLeader || {}).id ? 'Edit' : 'Add'} Leader`}
                >
                  <EditLeader
                    onChange={onListUpdate}
                    onClose={() => setEditLeader(null)}
                    {...{ opportunityId, data: editLeader }}
                  ></EditLeader>
                </DialogWithTitle>
              ) : null}
            </Box>
          )}
        </Grid>
      </Grid>
      <Box marginTop={2}>
        {leaders.map((leader) => (
          <Box key={leader.id} marginBottom={4}>
            <LeaderView data={leader} canEdit onChange={onListUpdate} />
          </Box>
        ))}
      </Box>
    </Box>
  );
};

export default EditLeadership;
