import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Button } from '@material-ui/core';

import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { DialogWithActions } from 'components/ui/DialogWithTitle';
import { useHistory } from 'react-router-dom';
import { useSelectedGroup } from 'hooks/useAppState';
import opportunitiesClient from 'api/opportunitiesClient';

const useFormCreateOpportunity = ({ data }) => {
  const [formRef, setFormRef] = useState({});

  const validate = async () => {
    const {
      values: { name },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        name,
        legalName: name,
      },
    };
  };

  const form = (
    <Box>
      <Form initialValues={data} enableReinitialize setRef={setFormRef}>
        <Box>
          <FormikTextInput
            name="name"
            label="Opportunity Name"
            variant="filled"
            required
          />
        </Box>
      </Form>
    </Box>
  );

  return { form, validate };
};

export const useCreateOpportunity = ({
  data,
  onChange,
  shouldRedirect = true,
}) => {
  const history = useHistory();
  const { groupId, slug } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  const [modal, showModal] = useState();

  const { form, validate } = useFormCreateOpportunity({
    data,
  });

  const onSave = useCallback(
    async (onError, minDelay) => {
      try {
        const { values, errors } = await validate();
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const response = data
          ? await opportunitiesClient.update({
              id: data.id,
              data: values,
            })
          : await opportunitiesClient.create({
              groupId,
              data: values,
            });
        const opportunity = response.data;
        await minDelay;
        if (shouldRedirect)
          history.push(`/${slug}/opportunity/${opportunity.id}/edit`);
        if (onChange) onChange(opportunity);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    },
    [data, groupId, history, onChange, shouldRedirect, slug, validate],
  );

  const modalElement = useMemo(() => {
    const footer = (
      <Box padding={2}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={() => showModal()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton
              onSave={onSave}
              name={data ? 'Save' : 'Create'}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        fullWidth
        maxWidth="sm"
        {...{
          title: `${data ? 'Edit' : 'Create'} Opportunity`,
          // header,
          footer,
          onClose: () => showModal(),
        }}
      >
        <Box padding={3}>{form}</Box>
      </DialogWithActions>
    ) : null;
  }, [data, form, modal, onSave]);

  return { modal: modalElement, showModal, form, validate, onSave };
};

// eslint-disable-next-line import/no-unused-modules
export default useFormCreateOpportunity;
