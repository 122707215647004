import React, { useEffect, useState } from 'react';
import MuiPhoneNumber from 'material-ui-phone-number';
import * as yup from 'yup';

import { useField } from 'formik';

// eslint-disable-next-line import/no-unused-modules
export const CustomPhoneInput = (props) => {
  return (
    <MuiPhoneNumber defaultCountry="us" {...props} enableClickOutside={false} />
  );
};

export const FormikPhoneInput = ({
  validation,
  required,
  defaultValue,
  label,
  disabled,
  fullWidth = true,
  ...props
}) => {
  const scheme = required
    ? (validation || yup.string())
        .min(8, `${label ? label : 'Field'} is required`)
        .typeError(`${label ? label : 'Field'} is required`)
    : validation;
  const validate = async (value) => {
    if (disabled) {
      setError(null);
      return;
    }
    try {
      await scheme.validate(value);
    } catch (err) {
      return err.message;
    }
  };
  const [field, meta, helpers] = useField(
    scheme ? { ...props, validate } : props,
  );
  const { value, error, touched } = meta;
  const { setValue, setError } = helpers;

  const [once, setOnce] = useState(0);
  useEffect(() => {
    if (defaultValue && once === 0) {
      setOnce((x) => x + 1);
      if (!value) {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, value, setValue, once]);

  const onChange = (value) => {
    setValue(value.replace(/\D/g, ''));
  };

  return (
    <CustomPhoneInput
      error={error && touched}
      helperText={error && touched ? error : null}
      required={required}
      label={label}
      disabled={disabled}
      fullWidth={fullWidth}
      {...field}
      {...props}
      onChange={onChange}
    />
  );
};
