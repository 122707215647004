import React from 'react';

import { CustomTextField, FormikTextInput } from './CustomTextField';

export const CustomSelect = ({ children, ...props }) => {
  return (
    <CustomTextField {...props} select>
      {children}
    </CustomTextField>
  );
};

export const FormikSelect = ({ children, ...props }) => {
  return (
    <FormikTextInput {...props} select>
      {children}
    </FormikTextInput>
  );
};
