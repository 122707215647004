import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';

import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import useFormAddress from './useFormAddress';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import paymentChecksClient from 'api/paymentChecksClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { DialogWithActions } from 'components/ui/DialogWithTitle';

const useFormPaymentCheck = ({ data }) => {
  const { mailingAddress } = data || {};
  const [formRef, setFormRef] = useState({});

  const { form: mailingForm, validate: mailingValidate } = useFormAddress({
    data: mailingAddress,
  });

  const validate = async () => {
    const {
      values: { name, checkRecipient },
      errors,
    } = await validateForm(formRef);

    const { values: mailingValues, errors: mailingErrors } =
      await mailingValidate();

    return {
      errors: [...errors, ...mailingErrors],
      values: {
        name,
        checkRecipient,
        mailingAddress: mailingValues,
      },
    };
  };

  const form = (
    <Box>
      <Form initialValues={data} enableReinitialize setRef={setFormRef}>
        <Box>
          <FormikTextInput
            name="name"
            label="Nickname"
            variant="filled"
            required
          />
        </Box>
        <Box marginTop={4}>
          <Typography>Recipient Name</Typography>
        </Box>
        <Box marginTop={2}>
          <FormikTextInput
            name="checkRecipient"
            label="Make checks out to"
            variant="filled"
            required
          />
        </Box>
      </Form>

      <Box marginTop={4}>
        <Typography>Recipient Address</Typography>
      </Box>
      <Box marginTop={2}>{mailingForm}</Box>
    </Box>
  );

  return { form, validate };
};

export const useEditPaymentCheck = ({ data, groupId, onChange, onCancel }) => {
  const [modal, showModal] = useState();

  const formValues = modal || data;
  const { id } = formValues || {};

  const { form, validate } = useFormPaymentCheck({
    data: formValues,
  });

  const onSave = useCallback(
    async (onError) => {
      try {
        const { values, errors } = await validate();
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const upsert = id
          ? paymentChecksClient.update
          : paymentChecksClient.create;

        const { mailingAddress, ...others } = values;
        const response = await upsert({
          groupId,
          id,
          data: {
            ...others,
            mailingAddressAttributes: mailingAddress,
          },
        });
        const data = response.data;
        onChange(data);
        setTimeout(() => {
          showModal(false);
        }, 500);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    },
    [groupId, id, onChange, validate],
  );

  const modalElement = useMemo(() => {
    const footer = (
      <Box padding={2}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Button
              variant="contained"
              onClick={() => {
                showModal();
                onCancel();
              }}
            >
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton onSave={onSave} name="Save"></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        {...{
          title: `${id ? 'Edit' : 'Add'} Check Instructions`,
          // header,
          footer,
          onClose: () => showModal(),
        }}
      >
        <Box>{form}</Box>
      </DialogWithActions>
    ) : null;
  }, [form, id, modal, onCancel, onSave]);

  return { modal: modalElement, showModal, form, validate, onSave };
};

// eslint-disable-next-line import/no-unused-modules
export default useFormPaymentCheck;
