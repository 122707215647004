import PropTypes from 'prop-types';
import React, { useEffect, useState, createContext } from 'react';
import { getCurrentUser } from 'redux/currentUser/actions';
import { useDispatch } from 'react-redux';
import { makeStyles, Box, CircularProgress } from '@material-ui/core';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import { useFlags } from 'hooks/useAppState';
import groupsClient from 'api/groups/groupsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

const useStyles = makeStyles(() => ({
  root: {
    height: '100vh',
  },
}));

export const GroupContext = createContext({ groups: [] });

const UserAuth = (props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const gettingCurrentUser = useFlags((state) => state.gettingCurrentUser);

  const [loading, setLoad] = useState(true);
  const [groups, setGroups] = useState();

  useEffect(() => {
    initCurrentUser();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await groupsClient.index();
        setGroups(result.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (!gettingCurrentUser && !groups) fetchData();
  }, [gettingCurrentUser, groups]);

  useEffect(() => {
    let timer = null;
    if (!gettingCurrentUser) {
      timer = setTimeout(() => {
        setLoad(false);
      }, 500);
    } else {
      setLoad(true);
    }
    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [gettingCurrentUser]);

  const initCurrentUser = async () => {
    await dispatch(getCurrentUser());
  };

  const getGroupSlug = (groupId) => {
    return groups.find((x) => x.id === groupId)?.slug;
  };

  if (loading && !groups)
    return (
      <Box className={classes.root}>
        <CenteredContentBox>
          <CircularProgress />
        </CenteredContentBox>
      </Box>
    );

  return (
    <GroupContext.Provider value={{ groups, getGroupSlug }}>
      {props.children}
    </GroupContext.Provider>
  );
};

UserAuth.propTypes = {
  children: PropTypes.any,
};

export default UserAuth;
