import React, { useEffect, useState } from 'react';
import { Grid, Typography, Button, Paper, Box } from '@material-ui/core';
import groupsClient from 'api/groups/groupsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import DisplayAddress from 'components/ui/DisplayAddress';
import { WarningRounded } from '@material-ui/icons';

const BankingCard = ({
  dateFiledReviewed,
  useSmartCapitalAccount,
  editClick,
  paymentWire,
  acceptWires,
  paymentCheck,
  acceptChecks,
  groupId,
}) => {
  const [wireList, setWireList] = useState([]);
  const [checkList, setCheckList] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const wireResponse = await groupsClient.wires({
          id: groupId,
        });
        setWireList(wireResponse.data);
        const checkResponse = await groupsClient.checks({
          id: groupId,
        });
        setCheckList(checkResponse.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);
  return (
    <Paper
      variant="outlined"
      square
      style={{ padding: '1rem', height: '100%' }}
    >
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Banking:
          </Typography>
        </Grid>
        <Grid item>
          <Button
            color="primary"
            size="small"
            onClick={editClick}
            disabled={!!dateFiledReviewed}
          >
            Edit
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={3}>
        <Grid item>
          {useSmartCapitalAccount ? (
            <Typography variant="subtitle1">
              SmartCapital Bank Account
            </Typography>
          ) : (
            <Grid container spacing={1}>
              {!acceptChecks && !acceptWires && (
                <Grid item xs={12}>
                  <Box padding={2}>
                    <Grid container spacing={2} alignItems="center">
                      <Grid item>
                        <Box fontSize={30} lineHeight={0}>
                          <WarningRounded fontSize="inherit" />
                        </Box>
                      </Grid>

                      <Grid item xs>
                        <Typography variant="subtitle1">
                          In order to accept investments you must setup and
                          allow checks or wires
                        </Typography>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
              {acceptWires && paymentWire && (
                <Grid item xs={12}>
                  <Box marginTop={2}>
                    <Typography variant="subtitle1">
                      Manager Bank Account
                    </Typography>
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                        Bank Name
                      </Grid>
                      <Grid item xs={8}>
                        {wireList.find((x) => x.id == paymentWire.id)?.name}
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                        Routing #
                      </Grid>
                      <Grid item xs={8}>
                        {paymentWire?.routingNumber}
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                        Account #
                      </Grid>
                      <Grid item xs={8}>
                        {paymentWire?.accountNumber}
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
              {acceptChecks && paymentCheck && (
                <Grid item xs={12}>
                  <Box marginTop={2}>
                    <Typography variant="subtitle1">Checks</Typography>
                  </Box>
                  <Box>
                    <Grid container spacing={2}>
                      <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                        Payable Name
                      </Grid>
                      <Grid item xs={8}>
                        {paymentCheck?.checkRecipient}
                      </Grid>
                      <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                        Mailing Address
                      </Grid>
                      <Grid item xs={8}>
                        <DisplayAddress
                          {...{
                            address: checkList.find(
                              (x) => x.id == paymentCheck.id,
                            )?.mailingAddress,
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              )}
            </Grid>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default BankingCard;
