import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Box, Typography, Paper, Button } from '@material-ui/core';
import { FormikTextInput } from 'components/ui/CustomTextField';
import LinkIcon from '@material-ui/icons/Link';

import Form from 'components/Form/Form';

import groupsClient from 'api/groups/groupsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { setSelectedGroup } from 'redux/currentUser/actions';
import { usePhoto } from 'components/ui/PhotoPicker';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';

import EditSlug from 'views/CreateGroup/EditSlug';
import { LoadingSpinner } from 'components/ui/Loading';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FormikCheckBox } from 'components/ui/CustomCheckBox';

const GroupSettings = ({ id }) => {
  const [cache, setCache] = useState();
  const [slug, setSlug] = useState();
  const [copied, setCopied] = useState(false);
  const [copiedSignIn, setCopiedSignIn] = useState(false);
  const dispatch = useDispatch();
  const { currentPhoto, photoPicker } = usePhoto(cache);
  const [formRef, setFormRef] = useState({});

  const { slug: originalSlug } = cache || {};
  useEffect(() => {
    const fetchData = async (groupId) => {
      try {
        const response = await groupsClient.get(groupId);
        const { slug } = response.data;
        setSlug(slug);
        setCache(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData(id);
    }
  }, [id]);

  useEffect(() => {
    if (cache) {
      dispatch(setSelectedGroup(cache));
    }
  }, [cache, dispatch]);

  const onSave = async (onError) => {
    try {
      const {
        values: { name, contactEmail, enableOpportunitySharing },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await groupsClient.update({
        id,
        data: {
          id,
          name,
          photo: currentPhoto.data,
          photoFileName: currentPhoto.name,
          contactEmail,
          slug,
          enableOpportunitySharing,
        },
      });
      setCache(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };
  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 5000);
      return () => {
        clearTimeout(timer);
      };
    }
    return () => {};
  }, [copied]);
  useEffect(() => {
    if (copiedSignIn) {
      const signInTimer = setTimeout(() => {
        setCopiedSignIn(false);
      }, 5000);
      return () => {
        clearTimeout(signInTimer);
      };
    }
    return () => {};
  }, [copiedSignIn]);
  const shareLink = `${window.location.origin}/${slug || '...'}/request-access`;
  const signInLink = `${window.location.origin}/${slug || '...'}/sign-in`;

  return (
    <>
      <Box marginBottom={4}>
        <Box>
          <Typography variant="h5">Group Settings</Typography>
        </Box>

        {!cache ? (
          <LoadingSpinner />
        ) : (
          <>
            <Box marginTop={2} data-tour="group-settings">
              <Form
                initialValues={cache}
                enableReinitialize
                setRef={setFormRef}
              >
                <Grid
                  container
                  spacing={3}
                  justify="flex-start"
                  alignItems="flex-start"
                >
                  <Grid item xs={8} container spacing={3}>
                    <Grid item xs={12}>
                      <FormikTextInput
                        name="name"
                        variant="filled"
                        label="Group Name"
                        required
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <FormikTextInput
                        name="contactEmail"
                        variant="filled"
                        label="Contact / Support Email"
                        required
                        email
                        InputLabelProps={{ shrink: true }}
                      />
                    </Grid>
                    <Grid item xs={12}>
                      <EditSlug {...{ id, originalSlug, slug, setSlug }} />
                    </Grid>
                    <Grid item xs={12}>
                      <Box paddingBottom={2}>
                        <Box>
                          <Typography variant="h6">
                            Sign In Page Link
                          </Typography>

                          <Typography variant="caption">
                            The link below is to a sign in page where investors
                            can sign into your investment group. You can place
                            this link on your website or in emails.
                          </Typography>
                        </Box>

                        <Paper
                          variant="outlined"
                          style={{ padding: '1rem', marginTop: '1rem' }}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography variant="subtitle2">
                                <LinkIcon
                                  style={{
                                    verticalAlign: 'bottom',
                                    marginRight: '1rem',
                                  }}
                                  marginRight={1}
                                ></LinkIcon>
                                {signInLink}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <CopyToClipboard
                                text={signInLink}
                                onCopy={() => setCopiedSignIn(true)}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                >
                                  {copiedSignIn ? 'Copied!' : 'Click to Copy'}
                                </Button>
                              </CopyToClipboard>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box paddingBottom={2}>
                        <Box>
                          <Typography variant="h6">
                            Request Access Page Link
                          </Typography>

                          <Typography variant="caption">
                            The link below is to a request access page where
                            investors can request access to your investment
                            group. You can place this link on your website or in
                            emails. You will need to confirm any investor that
                            request access before they can login to your
                            investment portal.
                          </Typography>
                        </Box>

                        <Paper
                          variant="outlined"
                          style={{ padding: '1rem', marginTop: '1rem' }}
                        >
                          <Grid container alignItems="center">
                            <Grid item xs>
                              <Typography variant="subtitle2">
                                <LinkIcon
                                  style={{
                                    verticalAlign: 'bottom',
                                    marginRight: '1rem',
                                  }}
                                  marginRight={1}
                                ></LinkIcon>
                                {shareLink}
                              </Typography>
                            </Grid>
                            <Grid item>
                              <CopyToClipboard
                                text={shareLink}
                                onCopy={() => setCopied(true)}
                              >
                                <Button
                                  variant="contained"
                                  color="primary"
                                  size="small"
                                >
                                  {copied ? 'Copied!' : 'Click to Copy'}
                                </Button>
                              </CopyToClipboard>
                            </Grid>
                          </Grid>
                        </Paper>
                      </Box>
                    </Grid>
                    <Grid item xs={12}>
                      <Box paddingBottom={2}>
                        <Box>
                          <Typography variant="h6">
                            Opportunity Sharing
                          </Typography>

                          <Typography variant="caption">
                            Check the box to enable opportunity sharing. The
                            &quot;Opportunity Sharing&quot; feature will allow
                            you to share opportunities with other investment
                            groups and allow other investment groups to share
                            opportunities with you.
                          </Typography>
                        </Box>

                        <Paper
                          variant="outlined"
                          style={{ padding: '1rem', marginTop: '1rem' }}
                        >
                          <FormikCheckBox
                            name="enableOpportunitySharing"
                            label="Opt in to Opportunity Sharing"
                          />
                        </Paper>
                      </Box>
                    </Grid>
                  </Grid>
                  <Grid item xs={4}>
                    <Box width="100%">{photoPicker}</Box>
                  </Grid>
                </Grid>
              </Form>
            </Box>
            <Box marginTop={6}>
              <SaveFormButton onSave={onSave}></SaveFormButton>
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default GroupSettings;
