import React, { useEffect, useState } from 'react';
import * as yup from 'yup';

import { CustomTextField } from 'components/ui/CustomTextField';

import { useField } from 'formik';
import NumberFormat from 'react-number-format';

function NumberFormatCustom({
  inputRef,
  onChange,
  decimalScale = 0,
  allowNegative = false,
  ...other
}) {
  const { name } = other;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name,
            value: values.value,
          },
        });
      }}
      allowNegative={allowNegative}
      decimalScale={decimalScale}
      fixedDecimalScale={true}
      thousandSeparator
      isNumericString
      defaultValue={0}
    />
  );
}

// eslint-disable-next-line import/no-unused-modules
export const CustomCurrencyInput = ({ inputProps, ...props }) => {
  return (
    <CustomTextField
      {...props}
      // type="number"
      // startAdornment={<InputAdornment position="start">$</InputAdornment>}
      InputProps={{
        inputProps: { prefix: '$', ...inputProps },
        inputComponent: NumberFormatCustom,
      }}
    />
  );
};

export const FormikCurrencyInput = ({
  validation,
  required,
  defaultValue,
  label,
  ...props
}) => {
  const scheme = required
    ? (validation || yup.number())
        .required(`${label ? label : 'Field'} is required`)
        .nullable()
    : validation;
  const validate = async (value) => {
    try {
      await scheme.validate(value);
    } catch (err) {
      return err.message;
    }
  };
  const [field, meta, helpers] = useField(
    scheme ? { ...props, validate } : props,
  );
  const { value, error, touched } = meta;
  const { setValue } = helpers;

  const [once, setOnce] = useState(0);
  useEffect(() => {
    if (defaultValue && once === 0) {
      setOnce((x) => x + 1);
      if (!value) {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, value, setValue, once]);

  const handleFocus = (event) => {
    event.target.select();
  };

  return (
    <CustomCurrencyInput
      error={error && touched}
      helperText={error && touched ? error : null}
      required={required}
      label={label}
      {...field}
      {...props}
      onFocus={handleFocus}
    />
  );
};
