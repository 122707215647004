import apiClient from 'api/axios';

const index = ({ groupId }) =>
  apiClient.get(`/api/v1/groups/${groupId}/group_managers`, {});
const hidden = ({ groupId }) =>
  apiClient.get(`/api/v1/groups/${groupId}/group_managers?hidden=true`, {});
const create = ({ groupId, data }) =>
  apiClient.post(`/api/v1/groups/${groupId}/group_managers`, {
    group_manager: data,
  });

const get = ({ id }) => apiClient.get(`/api/v1/group_managers/${id}`, {});
const destroy = ({ id }) =>
  apiClient.delete(`/api/v1/group_managers/${id}`, {});
const update = ({ id, data }) =>
  apiClient.patch(`/api/v1/group_managers/${id}`, {
    group_manager: data,
  });

const hide = ({ id }) =>
  apiClient.patch(`/api/v1/group_managers/${id}/hide`, {});

const groupManagersClient = {
  index,
  get,
  create,
  update,
  destroy,
  hidden,
  hide,
};

export default groupManagersClient;
