export const GETTING_CURRENT_USER_START = 'GETTING_CURRENT_USER_START';
export const GETTING_CURRENT_USER_SUCCESS = 'GETTING_CURRENT_USER_SUCCESS';
export const GETTING_CURRENT_USER_ERROR = 'GETTING_CURRENT_USER_ERROR';

export const SIGNING_IN_START = 'SIGNING_IN_START';
export const SIGNING_IN_SUCCESS = 'SIGNING_IN_SUCCESS';
export const SIGNING_IN_ERROR = 'SIGNING_IN_ERROR';

export const SIGN_OUT_CURRENT_USER = 'SIGN_OUT_CURRENT_USER';

export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const CLEAR_SELECTED_GROUP = 'CLEAR_SELECTED_GROUP';

export const UPDATE_CURRENT_USER_DATA = 'UPDATE_CURRENT_USER_DATA';
