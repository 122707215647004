import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';
import Form from 'components/Form/Form';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { usePhoto } from 'components/ui/PhotoPicker';
import DialogWithTitle from 'components/ui/DialogWithTitle';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import { FormikTextInput } from 'components/ui/CustomTextField';

import ActionWithConfirm from 'components/ui/ActionWithConfirm';

import defaultPerson from 'images/default-person.svg';
import ImageFallback from 'components/ui/ImageFallback';
import Social from 'components/ui/Social';

export const useFeaturedSave = ({
  params,
  create,
  update,
  remove,
  onChange,
}) => {
  const withSave = ({
    id,
    currentPhoto,
    formRef,
    setDefaultValues,
    onClose,
  }) => {
    const onSave = async (onError) => {
      try {
        const {
          values: {
            name,
            title,
            facebookUrl,
            linkedinUrl,
            twitterUrl,
            crunchbaseUrl,
            instagramUrl,
            websiteUrl,
          },
          errors,
        } = await validateForm(formRef);
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const upsert = id ? update : create;

        const response = await upsert({
          ...params,
          id,
          data: {
            name,
            title,
            facebookUrl,
            linkedinUrl,
            twitterUrl,
            crunchbaseUrl,
            instagramUrl,
            websiteUrl,
            photo: currentPhoto.data,
            photoFileName: currentPhoto.name,
          },
        });
        const data = response.data;
        onChange(data);
        setDefaultValues(data);
        onClose();
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    };

    const onDelete = async (onError) => {
      try {
        await remove({ id });
        onChange({ id, isDeleted: true }, 'Delete Successful');
        onClose();
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
      }
    };

    return { onSave, onDelete };
  };
  return withSave;
};

export const EditFeaturedInvestor = ({ data, onClose, withSave }) => {
  const { currentPhoto, photoPicker } = usePhoto(data, '10rem');
  const [defaultValues, setDefaultValues] = useState(data);

  const [formRef, setFormRef] = useState({});

  const { id } = defaultValues;

  const { onSave, onDelete } = withSave({
    id,
    currentPhoto,
    formRef,
    setDefaultValues,
    onClose,
  });

  return (
    <ActionWithConfirm onAction={onDelete}>
      {({ actionButton }) => (
        <Form initialValues={data} enableReinitialize setRef={setFormRef}>
          <Box marginBottom={4}>
            <Grid container spacing={4}>
              <Grid item xs={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12}>
                    <FormikTextInput name="name" label="Full Name" required />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      variant="filled"
                      name="facebookUrl"
                      label="Facebook URL"
                      url
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      variant="filled"
                      name="linkedinUrl"
                      label="LinkedIn URL"
                      url
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      variant="filled"
                      name="twitterUrl"
                      label="Twitter URL"
                      url
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      variant="filled"
                      name="crunchbaseUrl"
                      label="Crunchbase URL"
                      url
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      variant="filled"
                      name="instagramUrl"
                      label="Instagram URL"
                      url
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <FormikTextInput
                      variant="filled"
                      name="websiteUrl"
                      label="Website URL"
                      url
                    />
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                {photoPicker}
              </Grid>
            </Grid>
          </Box>
          <Box marginTop={6}>
            <Grid container justify="space-between" spacing={3}>
              <Grid item>
                <Button variant="contained" onClick={onClose}>
                  Cancel
                </Button>
              </Grid>
              {id && <Grid item>{actionButton}</Grid>}
              <Grid item>
                <SaveFormButton
                  onSave={onSave}
                  name={id ? 'Save' : 'Add'}
                ></SaveFormButton>
              </Grid>
            </Grid>
          </Box>
        </Form>
      )}
    </ActionWithConfirm>
  );
};

export const ManageFeaturedInvestors = ({ featured, withSave }) => {
  return (
    <>
      {featured && featured.length > 0 ? (
        <>
          <Box marginTop={2}>
            <Grid container spacing={3} alignItems="stretch">
              {featured.map((investor) => (
                <Grid item key={investor.id} xs={12} sm={12} md={6} lg={4}>
                  <FeaturedInvestorView
                    data={investor}
                    canEdit
                    withSave={withSave}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        </>
      ) : (
        <Box
          marginTop={2}
          padding={3}
          height="20vh"
          bgcolor="grey.300"
          borderRadius="borderRadius"
          textAlign="center"
        >
          <CenteredContentBox>
            <Box marginBottom={2}>
              <Typography color="primary">
                No featured investors have been added yet
              </Typography>
            </Box>
          </CenteredContentBox>
        </Box>
      )}
    </>
  );
};

export const FeaturedInvestorView = ({
  data,
  canEdit = false,
  withSave,
  onChange = () => {},
}) => {
  const {
    name,
    photo,
    facebookUrl,
    linkedinUrl,
    twitterUrl,
    crunchbaseUrl,
    instagramUrl,
    websiteUrl,
  } = data;

  const [editModal, showEdit] = useState(false);
  return (
    <Box
      padding={3}
      border="1px solid lightgray"
      height="100%"
      bgcolor="white"
      position="relative"
    >
      {editModal ? (
        <DialogWithTitle
          open
          onClose={() => showEdit(false)}
          fullWidth
          title="Edit Featured Investor"
        >
          <EditFeaturedInvestor
            onChange={onChange}
            onClose={() => showEdit(false)}
            {...{ data, withSave }}
          ></EditFeaturedInvestor>
        </DialogWithTitle>
      ) : null}
      <Box textAlign="center" height="8rem">
        <CenteredContentBox>
          <ImageFallback
            src={photo}
            fallback={defaultPerson}
            alt=""
            width="15rem"
            height="8rem"
          />
        </CenteredContentBox>
      </Box>
      <Box marginTop={2} textAlign="center">
        <Typography color="primary">{name}</Typography>
        {/* <Typography variant="subtitle2">{title}&nbsp;</Typography> */}
      </Box>
      <Box>
        {(facebookUrl ||
          linkedinUrl ||
          twitterUrl ||
          crunchbaseUrl ||
          instagramUrl ||
          websiteUrl) && (
          <Box marginTop={2}>
            <Social
              {...{
                facebookUrl,
                linkedinUrl,
                twitterUrl,
                crunchbaseUrl,
                instagramUrl,
                websiteUrl,
              }}
            ></Social>
          </Box>
        )}
      </Box>
      {canEdit && (
        <Box marginTop={4}>
          &nbsp;
          <Box
            marginTop={2}
            paddingBottom={3}
            textAlign="center"
            bottom="0"
            left="0"
            width="100%"
            position="absolute"
          >
            <Button
              color="primary"
              variant="outlined"
              style={{ cursor: 'pointer' }}
              onClick={() => showEdit((x) => !x)}
            >
              Edit
            </Button>
          </Box>
        </Box>
      )}
    </Box>
  );
};
