import React, { useEffect, useMemo, useState } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Button from 'components/ui/Button/Button';

import SaveFormButton from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';
import taxYearsClient from 'api/taxYearsClient';
import { LoadingSpinner } from 'components/ui/Loading';
import { useArrayUpdate } from 'hooks/useListUpdate';
import syndicatesClient from 'api/syndicatesClient';
import InvestorMiniProfile from './InvestorMiniProfile';
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro';
import { SiWebpack } from 'react-icons/si';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { createStyles } from '@material-ui/styles';
import useTaxDocument from './useTaxDocument';

const TaxYearState = ({ taxYear, setTaxYear }) => {
  const classes = useStyles();
  const { id, isManual, syndicateId } = taxYear;

  // const [checked, setChecked] = useState(false);

  const [documents, setDocuments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await taxYearsClient.documents({ id });
        setDocuments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const [investments, setInvestments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.investments(syndicateId);
        setInvestments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (syndicateId) {
      fetchData(syndicateId);
    }
  }, [syndicateId]);

  const onApprove = async (onError) => {
    try {
      const response = await taxYearsClient.finishState({ id });
      const data = response.data;
      setTaxYear(data);
      return true;
    } catch (e) {
      const errors = getServerResponseErrors(e);
      onError(errors);
    }
  };
  const { onListUpdate } = useArrayUpdate(setDocuments);

  const filteredDocuments = useMemo(() => {
    return (documents || []).filter((document) => document.form == 'state_k1');
  }, [documents]);

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        valueGetter: ({ row }) => row?.userInfo?.name,
        renderCell: ({ row }) => {
          return InvestorMiniProfile({ data: row });
        },
      },
      isManual
        ? {
            field: 'dateApproved',
            headerName: 'Uploaded',
            flex: 1,
            valueGetter: ({ row }) => row?.dateApproved,
            renderCell: ({ row }) => {
              const data = filteredDocuments.find(
                (x) => x.userInvestmentId === row.id,
              );
              const { document } = data;
              const Cell = (
                <Box>{document ? <Box>Yes</Box> : <Box>No</Box>}</Box>
              );
              return Cell;
            },
          }
        : {
            field: 'dateApproved',
            headerName: 'Approved',
            flex: 1,
            valueGetter: ({ row }) => row?.dateApproved,
            renderCell: ({ row }) => {
              const data = filteredDocuments.find(
                (x) => x.userInvestmentId === row.id,
              );
              const { dateApproved } = data;
              const Cell = (
                <Box>
                  {dateApproved ? <Box>Approved</Box> : <Box>Not Approved</Box>}
                </Box>
              );
              return Cell;
            },
          },
      {
        field: 'buttons',
        headerName: ' ',
        width: 225,
        align: 'right',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: ({ row }) => {
          const data = filteredDocuments.find(
            (x) => x.userInvestmentId === row.id,
          );
          const { id, document } = data;
          return RowButtons({
            id,
            document,
            onChange: onListUpdate,
          });
        },
      },
    ];
  }, [filteredDocuments, isManual, onListUpdate]);

  if (!documents || !investments) {
    return <LoadingSpinner />;
  }

  const state = documents.find((x) => x.form == 'colorado_dr_0106');

  return (
    <Box>
      <Box textAlign="center" marginTop={4}>
        <Box>
          <Typography variant="h4">State Tax Documents</Typography>
        </Box>
        <Box marginTop={4}>
          <TaxDocument
            {...{
              data: state,
              onChange: onListUpdate,
            }}
          />
        </Box>
        {/* <Box marginTop={2}>
          <CustomCheckBox
            name="useBusiness"
            label={
              <Box maxWidth="40rem" textAlign="left">
                <Typography variant="subtitle1">
                  I affirm that I have {isManual ? 'uploaded' : 'reviewed'} each
                  State Schedule K1. Additionally, I affirm that each State
                  Schedule K1 is accurate and complete in its entirety.
                </Typography>
              </Box>
            }
            checked={checked}
            onChange={() => setChecked((x) => (x ? null : new Date()))}
          />
        </Box> */}
        <Box marginTop={2}>
          <SaveFormButton
            name="Finish State Upload"
            onSave={onApprove}
            disabled={filteredDocuments.some((x) => !x.dateApproved)}
            variant="contained"
          />
        </Box>
      </Box>

      <Box fontSize={20} marginTop={4}>
        <Typography variant="subtitle1">
          Below is a list of all the State Schedule K1s. You will need to{' '}
          {isManual ? 'upload' : 'review and approve'} each individual K1 before
          you can continue
        </Typography>
      </Box>
      <Box marginTop={2}>
        {investments && (
          <DataGridPro
            className={classes.root}
            components={{
              NoRowsOverlay: CustomNoRowsOverlay,
            }}
            rows={investments || []}
            columns={columns.filter((x) => !!x)}
            rowsPerPageOptions={[10]}
            disableSelectionOnClick
            autoHeight
            disableColumnPinning
            disableColumnSelector
            rowHeight={65}
          />
        )}
      </Box>
    </Box>
  );
};

const RowButtons = ({ id, document, onChange }) => {
  const { modal, showModal, openLink } = useTaxDocument({ id, onChange });

  return (
    <Box>
      {modal}
      {document ? (
        <Box>
          <Button onClick={openLink} style={{ marginRight: '1rem' }}>
            Review
          </Button>
          <Button onClick={() => showModal(document)}>Change</Button>
        </Box>
      ) : (
        <Box>
          <Button onClick={() => showModal({})}>Upload</Button>
        </Box>
      )}
    </Box>
  );
};

const useOverlayStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    label: {
      marginTop: theme.spacing(1),
    },
  }),
);
const CustomNoRowsOverlay = () => {
  const classes = useOverlayStyles();

  return (
    <GridOverlay className={classes.root}>
      <SiWebpack fontSize={50} style={{ fill: 'url(#mygradient)' }} />
      <div className={classes.label}>No Results</div>
    </GridOverlay>
  );
};

const TaxDocument = ({ data, onChange }) => {
  const { id, formName, document } = data || {};

  const { modal, showModal, openLink } = useTaxDocument({ id, onChange });
  return (
    <Box>
      {modal}
      <Box margin="auto" maxWidth="30rem">
        <Grid container justify="space-between">
          <Grid item xs={6}>
            <Typography variant="h6">{formName}</Typography>
          </Grid>
          {document ? (
            <Grid item xs={6}>
              <Button
                style={{ marginRight: '1rem' }}
                variant="contained"
                color="primary"
                onClick={openLink}
              >
                Review
              </Button>
              <Button onClick={() => showModal(document)}>Change</Button>
            </Grid>
          ) : (
            <Grid item xs={6}>
              <Button
                onClick={() => showModal({})}
                variant="contained"
                color="primary"
              >
                Upload
              </Button>
            </Grid>
          )}
        </Grid>
      </Box>
    </Box>
  );
};

export default TaxYearState;
