import React, { useState } from 'react';
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Tooltip,
  Typography,
} from '@material-ui/core';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';

import getServerResponseErrors from 'api/getServerResponseErrors';
import Form from 'components/Form/Form';

import EditMailingAddress from 'views/ManagementStructure/EditMailingAddress';
import userInvestmentsClient from 'api/userInvestmentsClient';
import { FormikTextInput } from 'components/ui/CustomTextField';
import { FormikDatePicker } from 'components/ui/CustomDatePicker';
import { FormikSelect } from 'components/ui/CustomSelect';
import * as yup from 'yup';
import { FormikPhoneInput } from 'components/ui/CustomPhone';
import userClient from 'api/user/userClient';
import Content from 'components/ui/wizard/Content';
import Footer from 'components/ui/wizard/Footer';
import { FormikCheckBox } from 'components/ui/CustomCheckBox';
import { useDispatch } from 'react-redux';
import { updateCurrentUserData } from 'redux/currentUser/actions';
import { useProfile } from 'hooks/useAppState';
import { FaInfoCircle } from 'react-icons/fa';
import axios from 'axios';
import NumberFormat from 'react-number-format';

const nameHelper = (entityType) => {
  switch (entityType) {
    case 'individual':
      return 'Legal Name of Individual';
    case 'ira':
      return 'IRA / 401(k) Entity Name';
    case 'singlememberllc':
      return 'Legal Name of LLC';
    default:
      return 'Legal Name of Entity';
  }
};

const NumberFormatCustom = React.forwardRef(function NumericFormatCustom(
  props,
  ref,
) {
  const { onChange, ...other } = props;

  return (
    <NumberFormat
      {...other}
      getInputRef={ref}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
    />
  );
});

const SubscriptionForm = ({
  userInvestment,
  onBack,
  onChange,
  actionButton,
  isUserInvestment = true,
}) => {
  const [authorized, setAuthorized] = useState(
    userInvestment.isAuthorizedSigner,
  );
  const profile = useProfile((state) => state);
  const { id, mailing, user, entityType } = userInvestment;
  const dispatch = useDispatch();
  const [mailingRef, setMailingRef] = useState();
  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: {
          investorName,
          investorPhone,
          investorCount,
          taxSsn,
          taxEin,
          taxType,
          entityName,
          entityState,
          entityFormationDate,
          signerName,
          signerPhone,
          signerEmail,
          signerTitle,
          retirementType,
          accountName,
          taxTypeClassification,
          entityExemption,
          isAccredited,
        },
        errors: formErrors,
      } = await validateForm(formRef);
      const { values: mailingForm, errors: mailingErrors } = await validateForm(
        mailingRef,
      );
      const errors = [...formErrors, ...mailingErrors];
      if (errors.length > 0) {
        onError(errors);
        return false;
      }
      if (!profile.isAccredited) {
        try {
          const profileResponse = await userClient.updateUserData({
            data: { isAccredited },
          });
          dispatch(updateCurrentUserData(profileResponse.data));
        } catch (e) {
          const error = getServerResponseErrors(e);
          console.log(error);
          onError([error]);
          return false;
        }
      }
      // check mailing address with google api
      const addressData = {
        postalCode: mailingForm.postal,
        locality: mailingForm.city,
        administrativeArea: mailingForm.state,
        addressLines: [mailingForm.street, mailingForm.secondary],
      };

      const countryComponent = await axios
        .post(
          `https://addressvalidation.googleapis.com/v1:validateAddress?key=${process.env.REACT_APP_GOOGLE_API_KEY}`,
          {
            address: addressData,
            enableUspsCass: true,
          },
        )
        .then((response) => {
          return response.data.result.address.addressComponents.find(
            (x) => x.componentType == 'country',
          );
        })
        .catch(() => {
          return null;
        });
      if (
        countryComponent &&
        countryComponent.componentName?.text != 'USA' &&
        countryComponent.confirmationLevel != 'CONFIRMED'
      ) {
        onError([
          {
            message: 'Mailing Address must be in the United States',
          },
        ]);
        return false;
      }

      const isAuthorizedSigner =
        authorized ||
        entityType === 'individual' ||
        entityType === 'singlememberllc';
      const signerInfo = isAuthorizedSigner
        ? { signerName: investorName, signerTitle }
        : {
            signerName,
            signerPhone,
            signerEmail,
            signerTitle,
          };

      const data = {
        investorName,
        investorPhone,
        investorCount:
          entityType == 'entity' &&
          (entityExemption == 'section3c1' || entityExemption == 'section3c2')
            ? investorCount
            : 1,
        // taxSsn,
        taxEin,
        mailingAttributes: mailingForm,
        taxType,
        entityName,
        entityState,
        entityFormationDate,
        isAuthorizedSigner,
        retirementType,
        accountName,
        taxTypeClassification,
        entityExemption,
        ...signerInfo,
      };
      let response;
      if (isUserInvestment) {
        response = await userInvestmentsClient.update({
          id,
          infoSave: true,
          data: {
            ...data,
            taxSsn,
          },
        });
      }
      try {
        await userClient.updateInvestDefaults({
          entityType,
          data: {
            ...data,
            mailingAttributes: {
              ...mailingForm,
              id: null,
            },
          },
        });
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }

      // onChange(response.data);
      onChange(response?.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
      return false;
    }
    return true;
  };

  const defaultValues = {
    ...userInvestment,
    isAccredited: profile.isAccredited,
  };
  defaultValues.investorName = defaultValues.investorName || user.name;
  defaultValues.investorPhone = defaultValues.investorPhone || user.phone;

  const nameLabel = nameHelper(entityType);

  return (
    <>
      {!isUserInvestment && (
        <Box display="flex" justifyContent="right">
          <SaveFormButton
            onSave={onSave}
            variant={isUserInvestment ? 'outlined' : 'contained'}
            color={isUserInvestment ? '' : 'primary'}
            name={isUserInvestment ? 'Next' : 'Save'}
          />
        </Box>
      )}
      <Content style={{ maxHeight: isUserInvestment ? '48vh' : 'inherit' }}>
        <>
          <Form
            initialValues={defaultValues}
            enableReinitialize
            setRef={setFormRef}
            // validationSchema={validationSchema}
          >
            {({ values }) => {
              return (
                <>
                  <Box marginTop={2}>
                    <>
                      {entityType === 'ira' && (
                        <Box marginTop={1} marginBottom={2} marginLeft={1}>
                          <Typography variant="caption">
                            For IRA&apos;s and Trusts, the account name should
                            be the title or the capacity in which you are
                            executing the investment document. I.e. John Doe as
                            trustee for John Doe&apos;s 401(k) or John Doe as
                            custodian of John Doe&apos;s IRA or Directed Trust
                            Company FBO John Doe IRA.
                          </Typography>
                        </Box>
                      )}

                      <Grid container spacing={3}>
                        {entityType === 'ira' && (
                          <Grid item xs={12}>
                            <FormikTextInput
                              name="accountName"
                              label="Account Name"
                              required
                            />
                          </Grid>
                        )}
                        {entityType != 'entity' && (
                          <Grid item xs={12} sm={6} md={4}>
                            <FormikTextInput
                              name="investorName"
                              label="Legal Name of Investor"
                              required
                            />
                          </Grid>
                        )}
                        <Grid item xs={12} sm={6} md={4}>
                          <FormikPhoneInput
                            name="investorPhone"
                            label="Investor Phone"
                            variant="filled"
                            required
                          />
                        </Grid>
                        {entityType === 'individual' && (
                          <>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormikDatePicker
                                name="entityFormationDate"
                                label="Birth Date"
                                inputVariant="filled"
                                required
                              ></FormikDatePicker>
                            </Grid>
                          </>
                        )}
                        {(entityType === 'individual' ||
                          entityType === 'singlememberllc' ||
                          entityType === 'ira') && (
                          <Grid item xs={12} sm={6} md={4}>
                            <FormikTextInput
                              name="taxSsn"
                              label="Investor SSN"
                              required
                              taxid
                              InputProps={{
                                inputComponent: NumberFormatCustom,
                                inputProps: {
                                  format: '###-##-####',
                                  mask: 'X',
                                  allowEmptyFormatting: true,
                                },
                              }}
                            />
                          </Grid>
                        )}
                        {entityType !== 'individual' && (
                          <>
                            <Grid item xs={12} sm={12} md={8}>
                              <FormikTextInput
                                name="entityName"
                                label={nameLabel}
                                required={entityType !== 'ira'}
                              />
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                              <FormikTextInput
                                name="taxEin"
                                label="EIN"
                                required={entityType == 'entity'}
                                placeholder={
                                  entityType !== 'singlememberllc'
                                    ? ''
                                    : '(if available)'
                                }
                                taxid
                                InputProps={{
                                  inputComponent: NumberFormatCustom,
                                  inputProps: {
                                    format: '##-#######',
                                    mask: 'X',
                                    allowEmptyFormatting: true,
                                  },
                                }}
                              />
                            </Grid>
                            {entityType !== 'ira' && (
                              <>
                                <Grid item xs={12} sm={6} md={4}>
                                  <FormikTextInput
                                    name="entityState"
                                    label="Registered State"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={6} md={4}>
                                  <FormikDatePicker
                                    name="entityFormationDate"
                                    label="Date of Formation"
                                    inputVariant="filled"
                                    required
                                  ></FormikDatePicker>
                                </Grid>
                              </>
                            )}
                            {entityType == 'ira' && (
                              <Grid item xs={12} sm={6} md={4}>
                                <FormikSelect
                                  name="retirementType"
                                  label="Retirement Account Type"
                                  required
                                >
                                  <MenuItem value="ira">IRA</MenuItem>
                                  <MenuItem value="401(k)">401(k)</MenuItem>
                                </FormikSelect>
                              </Grid>
                            )}
                            {entityType !== 'individual' &&
                              entityType !== 'singlememberllc' && (
                                <>
                                  <Grid item xs={12} sm={6} md={4}>
                                    <FormikSelect
                                      name="taxType"
                                      label="Classification"
                                      required
                                    >
                                      {entityType === 'ira' && (
                                        <MenuItem value="individual">
                                          Individual
                                        </MenuItem>
                                      )}
                                      <MenuItem value="ccorp">
                                        C Corporation
                                      </MenuItem>
                                      <MenuItem value="scorp">
                                        S Corporation
                                      </MenuItem>
                                      <MenuItem value="llc">
                                        Limited Liability Company
                                      </MenuItem>
                                      <MenuItem value="partnership">
                                        Partnership
                                      </MenuItem>
                                      <MenuItem value="trust">
                                        Trust/Estate
                                      </MenuItem>
                                      <MenuItem value="other">Other</MenuItem>
                                    </FormikSelect>
                                  </Grid>
                                  {values.taxType == 'llc' && (
                                    <Grid item xs={12} sm={6} md={4}>
                                      <FormikSelect
                                        name="taxTypeClassification"
                                        label="Tax Classification"
                                        required
                                      >
                                        <MenuItem value="C">
                                          C Corporation
                                        </MenuItem>
                                        <MenuItem value="S">
                                          S Corporation
                                        </MenuItem>
                                        <MenuItem value="P">
                                          Partnership
                                        </MenuItem>
                                      </FormikSelect>
                                    </Grid>
                                  )}
                                  {values.taxType &&
                                    values.taxType != 'individual' &&
                                    values.taxType != 'ccorp' &&
                                    values.taxType != 'scorp' &&
                                    values.taxType != 'llc' &&
                                    values.taxType != 'partnership' &&
                                    values.taxType != 'trust' && (
                                      <Grid item xs={12} md={8}>
                                        <FormikTextInput
                                          name="taxOther"
                                          label="Other Classification"
                                          required
                                        />
                                      </Grid>
                                    )}
                                </>
                              )}
                            {entityType == 'entity' && (
                              <Grid item xs={12} sm={6} md={4}>
                                <FormikSelect
                                  name="entityExemption"
                                  label="Investment Company Matters"
                                  required
                                >
                                  <MenuItem value="registered">
                                    Registered Investment Company
                                  </MenuItem>
                                  <MenuItem value="section3c1">
                                    Section 3(c)(1) Exemption
                                  </MenuItem>
                                  <MenuItem value="section3c7">
                                    Section 3(c)(7) Exemption
                                  </MenuItem>
                                </FormikSelect>
                              </Grid>
                            )}

                            {entityType != 'ira' && values.taxType == 'trust' && (
                              <Grid item xs={12}>
                                <Box
                                  marginTop={1}
                                  marginBottom={2}
                                  marginLeft={1}
                                >
                                  <Typography variant="caption">
                                    For IRA&apos;s and Trusts, the account name
                                    should be the title or the capacity in which
                                    you are executing the investment document.
                                    I.e. John Doe as trustee for John Doe&apos;s
                                    401(k) or John Doe as custodian of John
                                    Doe&apos;s IRA or Directed Trust Company FBO
                                    John Doe IRA.
                                  </Typography>
                                </Box>
                                <FormikTextInput
                                  name="accountName"
                                  label="Account Name"
                                  required
                                />
                              </Grid>
                            )}
                          </>
                        )}
                      </Grid>
                    </>
                  </Box>
                  {entityType == 'entity' &&
                    (values.entityExemption == 'section3c1' ||
                      values.entityExemption == 'section3c7') && (
                      <Box marginTop={4}>
                        <Typography>Beneficial Owners</Typography>
                        <Typography variant="caption">
                          If the Investor relies on either Section 3(c)(1) or
                          Section 3(c)(7) of the Companies Act, to accurately
                          count the number of beneficial owners of the
                          Syndicate, please specify the number of beneficial
                          owners of outstanding securities (other than
                          short-term paper) of the Investor.
                        </Typography>

                        <Box marginTop={2}>
                          <FormikTextInput
                            name="investorCount"
                            label="Beneficial Owners"
                            type="number"
                            defaultValue={1}
                            validation={yup.number().min(1)}
                            required
                          />
                        </Box>
                      </Box>
                    )}
                  {entityType !== 'individual' &&
                    entityType !== 'singlememberllc' && (
                      <Box marginTop={4}>
                        <Typography>Authorized Signer</Typography>
                        <Box marginTop={1} marginBottom={1}>
                          <Typography variant="subtitle1" component="label">
                            <input
                              type="checkbox"
                              checked={authorized}
                              onChange={() => setAuthorized((x) => !x)}
                            />
                            &nbsp;I am an authorized{' '}
                            {entityType == 'ira' || values.taxType == 'trust'
                              ? 'custodian / trustee'
                              : 'signer'}
                          </Typography>
                        </Box>

                        <Box marginTop={2}>
                          <Grid container spacing={3}>
                            {entityType == 'entity' && (
                              <Grid item xs={12} sm={6} md={4}>
                                <FormikTextInput
                                  name="investorName"
                                  label={`${
                                    entityType == 'ira' ? 'Custodian' : 'Signer'
                                  } Name`}
                                  variant="filled"
                                  autoComplete="off"
                                  required
                                />
                              </Grid>
                            )}
                            {!authorized && entityType == 'ira' && (
                              <Grid item xs={12} sm={6} md={4}>
                                <FormikTextInput
                                  name="signerName"
                                  label={`${
                                    entityType == 'ira' ? 'Custodian' : 'Signer'
                                  } Name`}
                                  variant="filled"
                                  autoComplete="off"
                                  required
                                />
                              </Grid>
                            )}
                            {entityType !== 'individual' &&
                              entityType !== 'singlememberllc' && (
                                <Grid item xs={12} sm={6} md={4}>
                                  <FormikTextInput
                                    name="signerTitle"
                                    label={`${
                                      entityType == 'ira'
                                        ? 'Custodian'
                                        : 'Signer'
                                    } Title`}
                                    autoComplete="off"
                                    required
                                  />
                                </Grid>
                              )}
                            {!authorized && (
                              <>
                                <Grid item xs={12} sm={6} md={4}>
                                  <FormikPhoneInput
                                    name="signerPhone"
                                    label={`${
                                      entityType == 'ira'
                                        ? 'Custodian'
                                        : 'Signer'
                                    } Phone`}
                                    variant="filled"
                                    autoComplete="off"
                                    required
                                  />
                                </Grid>
                                <Grid item xs={12} sm={12} md={8}>
                                  <FormikTextInput
                                    name="signerEmail"
                                    label={`${
                                      entityType == 'ira'
                                        ? 'Custodian'
                                        : 'Signer'
                                    } Email`}
                                    autoComplete="off"
                                    required
                                  />
                                </Grid>
                              </>
                            )}
                          </Grid>
                        </Box>
                      </Box>
                    )}
                  <Box marginTop={4} display="flex" alignItems="center">
                    <Box>
                      <FormikCheckBox
                        name="isAccredited"
                        validation={yup
                          .bool()
                          .oneOf([true], 'You must be an accredited investor')}
                        variant="filled"
                        label="Accredited Investor"
                      />
                    </Box>
                    <Box paddingLeft={1}>
                      <Tooltip
                        title="Under the federal securities laws, 
                        only persons who are accredited investors may participate in certain securities offerings.
                      One reason these offerings are limited to accredited investors is to ensure that all 
                      participating investors are financially sophisticated and able to fend for 
                      themselves or sustain the risk of loss, thus rendering unnecessary the 
                      protections that come from a registered offering."
                      >
                        <Box style={{ display: 'inline' }}>
                          <FaInfoCircle size={20} />
                        </Box>
                      </Tooltip>
                    </Box>
                  </Box>
                </>
              );
            }}
          </Form>
          <Box marginTop={4}>
            <EditMailingAddress
              name="Mailing Address"
              setRef={setMailingRef}
              initialValues={mailing || {}}
            ></EditMailingAddress>
          </Box>
        </>
      </Content>
      <Footer>
        <Box marginTop={4}>
          <Grid container spacing={3} justify="space-between">
            <Grid item>{actionButton}</Grid>
            <Grid item>
              {isUserInvestment && (
                <Button
                  variant="contained"
                  onClick={onBack}
                  style={{ marginRight: '1rem' }}
                >
                  Back
                </Button>
              )}
              <SaveFormButton
                onSave={onSave}
                variant={isUserInvestment ? 'outlined' : 'contained'}
                color={isUserInvestment ? '' : 'primary'}
                name={isUserInvestment ? 'Next' : 'Save'}
              />
            </Grid>
          </Grid>
        </Box>
      </Footer>
    </>
  );
};

export default SubscriptionForm;
