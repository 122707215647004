import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Button,
  TableRow,
  TableCell,
  Link,
  MenuItem,
  Grid,
  makeStyles,
  Typography,
  Tabs,
  Tab,
} from '@material-ui/core';
import getServerResponseErrors from 'api/getServerResponseErrors';

import DialogWithTitle from 'components/ui/DialogWithTitle';
import ActionsMenu from 'components/ui/ActionsMenu';
import userInvestmentsClient from 'api/userInvestmentsClient';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';

import AdminEditUserInvestment from './AdminEditUserInvestment';
import UserInvestmentProfile from './UserInvestmentProfile';
import NoteIcon from '@material-ui/icons/Note';

import UserInvestmentNotes from './UserInvestmentNotes';
import useSyndicateDocumentPoll from 'hooks/useSyndicateDocumentPoll';

import moment from 'moment';
import SaveFormButton from 'components/Form/SaveFormButton';
import UserInvestmentSignatureUpload from './UserInvestmentSignatureUpload';
import TabPanel from 'components/ui/TabPanel/TabPanel';
import EmailLog from './EmailLog';

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});
const UserInvestmentRow = ({
  groupInvestmentId,
  hasSyndicate,
  unitPrice = 0,
  data,
  onChange,
  style,
  canEdit,
  earlyPayment,
  groupId,
  updateNotes,
}) => {
  const classes = useStyles();
  const {
    data: userInvestment,
    isFake,
    addInvestor,
    investor,
    initials,
    hasNotes,
  } = data;
  const {
    id,
    interestAmount,
    requestedUnits,
    subscription,
    acceptedUnits,
    amountReceived,
    refundAmount,
    isUndecided,
    dateReviewed,
    entityType,
    dateReceived,
    dateAccepted,
    lastSignature,
    dateInterest,
    added,
    user,
    isAuthorizedSigner,
    subscriptionSignatureId,
    userId,
  } = userInvestment;

  const [editModal, showEdit] = useState();
  const [voidModal, showVoid] = useState();
  const [removeModal, showRemove] = useState();
  const [noteModal, showNotes] = useState();
  const [signatureModal, showSignature] = useState();
  const [currentTab, setCurrentTab] = React.useState(0);

  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  // const [requested, setRequested] = useState(requestedUnits);

  const { interest, proposed, status, recieved, managerSigned, isSigned } =
    useMemo(() => {
      const { signatures = [], isExpired } = subscription || {};

      const managerSigned = signatures.find(
        (x) => x.memberType === 'manager',
      )?.dateSigned;
      const custodianSigned = signatures.find(
        (x) => x.memberType === 'custodian',
      )?.dateSigned;
      const investorSigned = signatures.find(
        (x) => x.memberType === 'person',
      )?.dateSigned;
      const hasCustodian = entityType == 'ira';
      const recieved =
        amountReceived > 0
          ? amountReceived - refundAmount
          : acceptedUnits * unitPrice;

      const isSigned =
        investorSigned &&
        (!hasCustodian || isAuthorizedSigner || custodianSigned);

      // const interest =
      //   requestedUnits > 0 ? requestedUnits * unitPrice : interestAmount;
      const interest = interestAmount;
      // const subscribed =
      //   acceptedUnits > 0 && managerSigned
      //     ? acceptedUnits * unitPrice
      //     : requestedUnits * unitPrice;

      const proposed = requestedUnits * unitPrice;

      const status = dateReviewed
        ? isExpired
          ? 'Expired'
          : !investorSigned
          ? 'Reviewing'
          : investorSigned &&
            hasCustodian &&
            !custodianSigned &&
            !isAuthorizedSigner
          ? 'Waiting'
          : investorSigned && !managerSigned
          ? 'Accept'
          : 'Completed'
        : requestedUnits > 0
        ? 'Started'
        : null;

      return {
        interest,
        proposed,
        status,
        recieved,
        investorSigned,
        managerSigned,
        isSigned,
      };
    }, [
      subscription,
      entityType,
      amountReceived,
      refundAmount,
      acceptedUnits,
      unitPrice,
      interestAmount,
      requestedUnits,
      dateReviewed,
      isAuthorizedSigner,
    ]);
  const canCollect = acceptedUnits > 0 || (isSigned && earlyPayment);

  const handleVoid = async (onError) => {
    try {
      const response = await userInvestmentsClient.void_subscription({
        id,
        // requestedUnits: requested,
      });
      showVoid(false);
      // save affirm
      onChange(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
  };

  const handleRemove = async () => {
    try {
      const response = await userInvestmentsClient.destroy({ id });
      showRemove(false);
      onChange({
        id,
        isDeleted: true,
      });
      if (response.data) {
        onChange(response.data);
      }
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
    }
  };

  const onClose = () => {
    showEdit(false);
    // if (added) {
    //   onChange({ ...userInvestment, added: null, created: new Date() });
    // }
  };

  useEffect(() => {
    if (added) {
      showEdit({});
    }
  }, [added]);

  return (
    <TableRow style={style} component="div">
      <TableCell component="div">
        {signatureModal && (
          <DialogWithTitle
            open
            onClose={() => showSignature(false)}
            title="Upload Signature"
            fullWidth
          >
            <UserInvestmentSignatureUpload
              {...{
                unitPrice,
                userInvestment,
                onChange,
                onClose: () => showSignature(false),
              }}
            />
          </DialogWithTitle>
        )}
        {noteModal && (
          <DialogWithTitle
            open
            onClose={() => showNotes(false)}
            title="User Investment Notes"
            fullWidth
          >
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              textColor="primary"
              style={{ marginBottom: 10 }}
            >
              <Tab label="Notes" value={0} />
              <Tab label="Email Log" value={1} />
            </Tabs>
            <TabPanel value={currentTab} index={0}>
              <UserInvestmentNotes
                {...{
                  groupInvestmentId,
                  user,
                  onChange: (noteLength) => {
                    updateNotes((notes) => {
                      const temp = notes.filter((x) => x !== user.id);
                      if (noteLength === 0) {
                        return temp;
                      }
                      return [...temp, user.id];
                    });
                  },
                  onClose: () => showNotes(false),
                }}
              />
            </TabPanel>
            <TabPanel value={currentTab} index={1}>
              <EmailLog
                {...{
                  id,
                  userInvestment,
                  user,
                  userId,
                  groupInvestmentId,
                  groupId,
                  onClose: () => showNotes(false),
                }}
              />
            </TabPanel>
          </DialogWithTitle>
        )}
        {editModal && (
          <DialogWithTitle
            open
            onClose={onClose}
            title="Investment Details"
            fullWidth
          >
            <AdminEditUserInvestment
              {...{
                isFake,
                addInvestor,
                hasSyndicate,
                unitPrice,
                data: isFake ? null : userInvestment,
                edit: editModal,
                onClose: onClose,
                onChange,
                canCollect,
                managerSigned,
              }}
            ></AdminEditUserInvestment>
          </DialogWithTitle>
        )}

        {voidModal && (
          <DialogWithTitle
            open
            onClose={() => showVoid(false)}
            title={'Void Subscription'}
            fullWidth
          >
            <Box>
              <Box>
                <Typography>
                  Are you sure you want to void this subscription? This will
                  remove the current subscription and any signed documents.
                </Typography>
              </Box>
            </Box>
            <Box></Box>
            <Box marginTop={4}>
              <Grid container spacing={3} justify="space-between">
                <Grid item>
                  <Button variant="contained" onClick={() => showVoid(false)}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <SaveFormButton
                    variant="contained"
                    style={{ marginRight: '1rem' }}
                    onSave={handleVoid}
                    className={classes.dangerButton}
                  >
                    Void
                  </SaveFormButton>
                </Grid>
              </Grid>
            </Box>
          </DialogWithTitle>
        )}
        {removeModal && (
          <DialogWithTitle
            open
            onClose={() => showRemove(false)}
            title={'Void Subscription'}
            fullWidth
          >
            <Box height="10rem">
              <CenteredContentBox>
                <Box>
                  Are you sure you want to reset this investor? This will clear
                  their interest and any associated notes.
                </Box>
              </CenteredContentBox>
            </Box>
            <Box marginTop={4}>
              <Grid container spacing={3} justify="space-between">
                <Grid item>
                  <Button variant="contained" onClick={() => showRemove(false)}>
                    Cancel
                  </Button>
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    style={{ marginRight: '1rem' }}
                    onClick={handleRemove}
                    className={classes.dangerButton}
                  >
                    Reset
                  </Button>
                </Grid>
              </Grid>
            </Box>
          </DialogWithTitle>
        )}

        <UserInvestmentProfile
          {...{
            userInvestment: { ...userInvestment, name: investor, initials },
          }}
        />
      </TableCell>
      <TableCell align="center">
        <Box marginTop="0.25rem">
          <Button
            variant={hasNotes ? 'contained' : 'outlined'}
            size="small"
            color="primary"
            startIcon={<NoteIcon />}
            onClick={() => showNotes(true)}
          >
            Notes
          </Button>
        </Box>
        {/* {!isFake && lastAction && (
          <Box marginTop="0.5rem">
            <Typography variant="caption">
              {moment(lastAction).format('MM-DD-yyyy')}
            </Typography>
          </Box>
        )} */}
      </TableCell>
      <TableCell align="center">
        {isFake ? (
          <Box>Unknown</Box>
        ) : interestAmount < 0 ? (
          <Box>
            $0
            <Box>
              <Link
                component="button"
                onClick={() => showEdit({})}
                disabled={!canEdit}
              >
                Passed
              </Link>
            </Box>
          </Box>
        ) : (
          <Box>
            <Box color={isUndecided ? '#5D26A4' : '#007D05'}>
              {interest > 0 ? formatter.format(interest) : '$0'}
            </Box>
            <Box>
              <Link
                component="button"
                onClick={() => showEdit({})}
                disabled={!canEdit}
              >
                Interested
              </Link>
            </Box>
          </Box>
        )}
        {dateInterest && (
          <Box>
            <Typography variant="caption">
              {moment(dateInterest).format('MM-DD-yyyy')}
            </Typography>
          </Box>
        )}
      </TableCell>
      {hasSyndicate && (
        <>
          <TableCell align="center">
            <Box
              color={
                managerSigned && acceptedUnits > 0
                  ? '#007D05'
                  : status == 'Sign'
                  ? '#5D26A4'
                  : null
              }
            >
              {proposed > 0 ? formatter.format(proposed) : '-'}
            </Box>
            <Box>
              {status == 'Accept' ? (
                <Link
                  component="button"
                  onClick={() => showEdit({})}
                  disabled={!canEdit}
                >
                  Accept
                </Link>
              ) : status == 'Completed' ? (
                <SignaturePage
                  {...{ id: subscriptionSignatureId, data: subscription }}
                />
              ) : (
                status
              )}
            </Box>
            {dateAccepted ? (
              <Box>
                <Typography variant="caption">
                  {moment(dateAccepted).format('MM-DD-yyyy')}
                </Typography>
              </Box>
            ) : lastSignature ? (
              <Box>
                <Typography variant="caption">
                  {moment(lastSignature).format('MM-DD-yyyy')}
                </Typography>
              </Box>
            ) : dateReviewed ? (
              <Box>
                <Typography variant="caption">
                  {moment(dateReviewed).format('MM-DD-yyyy')}
                </Typography>
              </Box>
            ) : null}
          </TableCell>
          <TableCell align="center">
            <Box
              color={
                amountReceived > 0 ? '#007D05' : recieved > 0 ? '#5D26A4' : null
              }
            >
              {recieved > 0 ? formatter.format(recieved) : '-'}
            </Box>
            {/* {refundAmount > 0 && (
          <>
            <Box>- {formatter.format(refundAmount)}</Box>
            <Box>= {formatter.format(amountReceived - refundAmount)}</Box>
          </>
        )} */}
            <Box>
              {amountReceived > 0 ? (
                <Link
                  component="button"
                  onClick={() => showEdit({})}
                  disabled={!canEdit}
                >
                  Collected
                </Link>
              ) : acceptedUnits > 0 || (isSigned && earlyPayment) ? (
                <Link
                  component="button"
                  onClick={() => showEdit({})}
                  disabled={!canEdit}
                >
                  Collect
                </Link>
              ) : null}
            </Box>
            {dateReceived && amountReceived > 0 ? (
              <Box>
                <Typography variant="caption">
                  {moment(dateReceived).format('MM-DD-yyyy')}
                </Typography>
              </Box>
            ) : null}
          </TableCell>
        </>
      )}
      {canEdit && (
        <TableCell>
          <Box textAlign="right">
            {isFake ? (
              <ActionsMenu {...{ id }}>
                <MenuItem onClick={() => showEdit({ user })}>
                  Edit Interest
                </MenuItem>
              </ActionsMenu>
            ) : (
              <ActionsMenu {...{ id }}>
                <MenuItem onClick={() => showEdit({})}>Edit</MenuItem>

                {requestedUnits > 0 ? (
                  <MenuItem onClick={() => showVoid(true)}>Void</MenuItem>
                ) : (
                  <MenuItem onClick={() => showRemove(true)}>Reset</MenuItem>
                )}
              </ActionsMenu>
            )}
          </Box>
        </TableCell>
      )}
    </TableRow>
  );
};

const SignaturePage = ({ id, data }) => {
  const { openLink } = useSyndicateDocumentPoll({ id, data });
  return (
    <Link component="button" onClick={openLink}>
      Accepted
    </Link>
  );
};

const useStyles = makeStyles((theme) => ({
  dangerButton: {
    backgroundColor: theme.palette.danger.main,
    '&:hover': {
      backgroundColor: theme.palette.danger.main,
    },
  },
}));

export default UserInvestmentRow;
