import apiClient from 'api/axios';

const index = ({ groupId = null, filter }) => {
  return apiClient.get(`/api/v1/opportunities`, {
    params: {
      groupId,
      filter,
    },
  });
};

const get = ({ id, groupId }) => {
  return apiClient.get(`/api/v1/opportunities/${id}`, {
    params: {
      groupId,
    },
  });
};

const create = ({ groupId, data }) => {
  return apiClient.post(`/api/v1/opportunities`, {
    groupId,
    opportunity: data,
  });
};

const update = ({ id, data }) => {
  return apiClient.patch(`/api/v1/opportunities/${id}`, {
    opportunity: data,
  });
};

const createLeader = ({ opportunityId, data }) => {
  return apiClient.post(
    `/api/v1/opportunities/${opportunityId}/opportunity_leaders`,
    {
      leader: data,
    },
  );
};

const updateLeader = ({ id, data }) => {
  return apiClient.patch(`/api/v1/opportunity_leaders/${id}`, {
    leader: data,
  });
};

const deleteLeader = ({ id }) => {
  return apiClient.delete(`/api/v1/opportunity_leaders/${id}`);
};

const createFeaturedInvestor = ({ opportunityId, data }) => {
  return apiClient.post(
    `/api/v1/opportunities/${opportunityId}/opportunity_featured_investors`,
    { featured: data },
  );
};

const updateFeaturedInvestor = ({ id, data }) => {
  return apiClient.patch(`/api/v1/opportunity_featured_investors/${id}`, {
    featured: data,
  });
};

const deleteFeaturedInvestor = ({ id }) => {
  return apiClient.delete(`/api/v1/opportunity_featured_investors/${id}`);
};

const createDocument = ({ opportunityId, data }) => {
  return apiClient.post(
    `/api/v1/opportunities/${opportunityId}/opportunity_documents`,
    { document: data },
  );
};

const updateDocument = ({ id, data }) => {
  return apiClient.patch(`/api/v1/opportunity_documents/${id}`, {
    document: data,
  });
};

const deleteDocument = ({ id }) => {
  return apiClient.delete(`/api/v1/opportunity_documents/${id}`);
};

const createPress = ({ opportunityId, data }) => {
  return apiClient.post(
    `/api/v1/opportunities/${opportunityId}/opportunity_articles`,
    { article: data },
  );
};

const updatePress = ({ id, data }) => {
  return apiClient.patch(`/api/v1/opportunity_articles/${id}`, {
    article: data,
  });
};

const deletePress = ({ id }) => {
  return apiClient.delete(`/api/v1/opportunity_articles/${id}`);
};

const rounds = ({ id }) => {
  return apiClient.get(`/api/v1/opportunities/${id}/rounds`, {
    params: {},
  });
};

const investments = ({ id, groupId }) => {
  return apiClient.get(`/api/v1/opportunities/${id}/investments`, {
    params: {
      group_id: groupId,
    },
  });
};

const visibility = ({ opportunityId, groupId, hidden }) => {
  return apiClient.post(`/api/v1/opportunities/${opportunityId}/visibility`, {
    groupId,
    hidden,
  });
};

const sharingNetwork = ({ id, groupId }) => {
  return apiClient.get(`/api/v1/opportunities/${id}/sharing_network`, {
    params: {
      group_id: groupId,
    },
  });
};

const opportunitiesClient = {
  index,
  get,
  create,
  update,
  createLeader,
  updateLeader,
  deleteLeader,
  createFeaturedInvestor,
  updateFeaturedInvestor,
  deleteFeaturedInvestor,
  createDocument,
  updateDocument,
  deleteDocument,
  createPress,
  updatePress,
  deletePress,
  rounds,
  investments,
  visibility,
  sharingNetwork,
};

export default opportunitiesClient;
