import {
  GETTING_CURRENT_USER_ERROR,
  GETTING_CURRENT_USER_SUCCESS,
  GETTING_CURRENT_USER_START,
  SIGNING_IN_START,
  SIGNING_IN_SUCCESS,
  SET_SELECTED_GROUP,
  SIGN_OUT_CURRENT_USER,
  UPDATE_CURRENT_USER_DATA,
} from './types';
import userClient from 'api/user/userClient';
import groupsClient from 'api/groups/groupsClient';
import get from 'get-value';
import getServerResponseErrors from 'api/getServerResponseErrors';

export const getCurrentUser = () => {
  return async (dispatch) => {
    dispatch({ type: GETTING_CURRENT_USER_START });

    try {
      const userResponse = await userClient.getCurrentUser();
      const payload = {
        userData: userResponse.data,
      };

      if (get(userResponse, 'data.id')) {
        dispatch({
          type: GETTING_CURRENT_USER_SUCCESS,
          payload,
        });
      } else {
        throw new Error('Unauthorized');
      }
    } catch (e) {
      dispatch({
        type: GETTING_CURRENT_USER_ERROR,
        payload: getServerResponseErrors(e),
      });
    }
  };
};

export const signIn = (credentials, onSignIn) => {
  return async (dispatch) => {
    await dispatch({ type: SIGNING_IN_START });

    await userClient.signIn(credentials);
    await dispatch(getCurrentUser());
    if (onSignIn) {
      await onSignIn();
    }
    await dispatch({ type: SIGNING_IN_SUCCESS });
  };
};

export const signOutCurrentUser = () => {
  authHeaderKeys.forEach((key) => localStorage.removeItem(key));
  return { type: SIGN_OUT_CURRENT_USER };
};

export const changePortal = (group) => {
  return async (dispatch) => {
    // dispatch({ type: SIGNING_IN_START });
    try {
      const groupResponse = await groupsClient.getBySlug(group);
      await dispatch(setSelectedGroup(groupResponse.data));
    } catch (e) {
      // dispatch({ type: SIGNING_IN_ERROR, payload: getServerResponseErrors(e) });
    }
  };
};

export const setSelectedGroup = (group) => ({
  type: SET_SELECTED_GROUP,
  payload: group,
});

export const updateCurrentUserData = (data) => ({
  type: UPDATE_CURRENT_USER_DATA,
  payload: data,
});

const authHeaderKeys = [
  'access-token',
  'token-type',
  'client',
  'uid',
  'expiry',
];
