import React, { useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  Link,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core';

import { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import useMore from 'components/useMore';

import FormWizard from './FormWizard';
import ErrorOutlineIcon from '@material-ui/icons/ErrorOutline';

const useSyndicateFormCarry = ({ data }) => {
  const { carryAmount } = data;
  console.log('carryAmount', carryAmount);

  const [managerCarry, setManagerCarry] = useState(
    carryAmount > 0
      ? 'yes'
      : carryAmount == 0
      ? 'no'
      : carryAmount == -1
      ? 'custom'
      : 'yes',
  );

  const [showMore, hasMore, setMore, setRef] = useMore();
  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: { carryAmount },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        carryAmount:
          managerCarry == 'yes' ? carryAmount : managerCarry == 'no' ? 0 : -1,
      },
    };
  };

  const formData = {
    carryAmount: managerCarry == 'yes' && carryAmount < 0 ? 20 : carryAmount,
  };
  const form = (
    <Form initialValues={formData} enableReinitialize setRef={setFormRef}>
      <Box>
        <Box marginTop={2}>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            What is the carried interest the manager will receive from the
            Syndicate?
          </Typography>
        </Box>
        <Box marginTop={3}>
          <Typography variant="subtitle1">
            <b>Manager Carry</b>
          </Typography>
        </Box>
        <Box marginTop={1}>
          <Box
            maxHeight={showMore ? '' : '3rem'}
            overflow={showMore ? '' : 'hidden'}
            ref={setRef}
          >
            <Typography variant="subtitle2">
              Managers can charge a carried interest or "carry" as a performance
              fee. Carry is a share of the profits on the return of the
              investment. Carry is calculated by taking a percentage of the
              total profits when a distribution event occurs in the future and
              awarding that percentage to the manager as a fee. If the
              Syndicate&apos;s investment does not return or capital is returned
              equal to or less than the initial amount invested, in that case,
              the manager does not receive this performance fee. Carry only
              rewards the manager for the profitable return on the investment.
              The standard carry for a syndicate is 20%.
            </Typography>
          </Box>
          {hasMore && (
            <Box>
              <Link
                color="primary"
                size="small"
                onClick={() => setMore(!showMore)}
                style={{ cursor: 'pointer' }}
              >
                {showMore ? 'Less' : 'Read More'}
              </Link>
            </Box>
          )}

          <Box>
            <Grid container spacing={3}>
              <Grid item>
                <Box padding={2}>
                  <Typography variant="subtitle2">Charge Carry?</Typography>
                  <RadioGroup
                    row
                    aria-label="position"
                    name="position"
                    defaultValue="top"
                    value={managerCarry}
                    onChange={(e) => {
                      const val = e.target.value;
                      setManagerCarry(val);
                    }}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio color="primary" />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio color="primary" />}
                      label="No"
                    />
                    <FormControlLabel
                      value="custom"
                      control={<Radio color="primary" />}
                      label="Custom"
                    />
                  </RadioGroup>
                </Box>
              </Grid>
              <Grid item xs>
                <Box>
                  {managerCarry == 'yes' && (
                    <Box marginTop={1.5}>
                      <FormikTextInput
                        name="carryAmount"
                        label="Carry %"
                        variant="filled"
                        type="number"
                        defaultValue="20"
                        required
                        fullWidth={false}
                      />
                    </Box>
                  )}
                  {managerCarry == 'custom' && (
                    <Box padding={3}>
                      <Grid container>
                        <Grid item>
                          <ErrorOutlineIcon
                            fontSize="large"
                            style={{ color: '#ff3333' }}
                          />
                        </Grid>
                        <Grid item xs>
                          <Box marginLeft={2}>
                            <Typography variant="subtitle2">
                              For a custom manager carry, you will need to use a
                              custom operating agreement, which you can upload
                              at the end of this guide.
                            </Typography>
                          </Box>
                        </Grid>
                      </Grid>
                    </Box>
                  )}
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </Box>
    </Form>
  );

  return { form, validate };
};

const SyndicateFormCarry = ({ data, onNext, onBack, onClose }) => {
  const { form, validate } = useSyndicateFormCarry({
    data,
  });
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext,
        onBack,
        onClose,
      }}
    />
  );
};

export default SyndicateFormCarry;
