import React, { useState, useEffect } from 'react';
import { Box, Grid, makeStyles, Typography } from '@material-ui/core';

import SaveFormButton from 'components/Form/SaveFormButton';

import getServerResponseErrors from 'api/getServerResponseErrors';

import userInvestmentsClient from 'api/userInvestmentsClient';
import { CustomTextField } from 'components/ui/CustomTextField';
import { TiEquals, TiTimes } from 'react-icons/ti';

const useStyles = makeStyles((theme) => ({
  unitChoice: {
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(3),
  },
  moneyValue: {
    color: theme.palette.money.main,
  },
}));

// Create our number formatter.
var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const computeUnits = (amount, minimumUnits, maximumUnits, unitPrice) => {
  let temp = Math.ceil(amount / unitPrice);
  temp = temp >= minimumUnits ? temp : minimumUnits;
  if (maximumUnits > 0) {
    temp = temp <= maximumUnits ? temp : maximumUnits;
  }
  return temp;
};
const SubscriptionAmount = ({
  syndicate,
  investmentId,
  userInvestment,
  onChange,
  actionButton,
}) => {
  const { id, interestAmount, requestedUnits } = userInvestment || {};
  const { unitPrice, minimumUnits, maximumUnits } = syndicate;

  const [requested, setRequested] = useState(requestedUnits);
  const classes = useStyles();

  const investAmount = requested < minimumUnits ? '-' : requested * unitPrice;

  useEffect(() => {
    const units = computeUnits(
      requestedUnits > 0 ? requestedUnits * unitPrice : interestAmount,
      minimumUnits,
      maximumUnits,
      unitPrice,
    );
    setRequested(units);
  }, [unitPrice, minimumUnits, maximumUnits, requestedUnits, interestAmount]);

  const onSave = async (onError) => {
    try {
      const upsert = id
        ? userInvestmentsClient.update
        : userInvestmentsClient.create;
      const response = await upsert({
        investmentId,
        id,
        data: {
          requestedUnits: requested,
          interestAmount: requested * unitPrice,
          entityType: null,
          taxType: null,
          investorName: null,
          isUndecided: false,
        },
      });
      // onChange(response.data);
      onChange(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const handleUnitChange = (e) => {
    const r = e.target.value || 0;
    setRequested(r);
  };

  return (
    <>
      <Box className={classes.unitChoice} marginTop={2}>
        <Grid container justify="space-around" spacing={3}>
          <Grid item>
            <Box paddingRight={2} display="flex" alignItems="center">
              <Box mr={2}>
                <CustomTextField
                  label="Units"
                  name="requestedUnits"
                  type="number"
                  variant="outlined"
                  style={{ width: '5rem' }}
                  onChange={handleUnitChange}
                  value={requested}
                />
              </Box>
              <Box mr={2}>
                <Box display="flex" alignItems="center">
                  <TiTimes size="24px" style={{ marginRight: '1rem' }} />
                  <Typography style={{ fontSize: '1.5rem' }} ml={2}>
                    {formatter.format(unitPrice)}
                  </Typography>
                </Box>
              </Box>
              <Box display="flex" alignItems="center" mr={2}>
                <TiEquals size="30px" />
              </Box>
              <Box>
                <Typography variant="h4" className={classes.moneyValue}>
                  ${investAmount}
                </Typography>
              </Box>
            </Box>
            <Box marginTop={1}>
              <Box>
                <Typography variant="caption">
                  {minimumUnits} unit minimum
                </Typography>
              </Box>
              {maximumUnits > 0 && (
                <Box>
                  <Typography variant="caption">
                    {maximumUnits} unit maximum
                  </Typography>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justify="space-between">
          <Grid item>{actionButton}</Grid>
          <Grid item>
            <SaveFormButton
              onSave={onSave}
              name="Next"
              disabled={requested < minimumUnits}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default SubscriptionAmount;
