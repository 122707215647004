const errorHandler = (serverError) => {
  const { response, message } = serverError;
  const { data } = response || {};
  const { errors } = data || {};
  let arrayOfErrors = [];

  if (!errors) {
    arrayOfErrors = [message];
  } else if (Array.isArray(errors)) {
    arrayOfErrors = errors;
  } else if (typeof errors && errors.fullMessages) {
    arrayOfErrors = errors.fullMessages;
  } else {
    arrayOfErrors = [errors];
  }
  return arrayOfErrors;
};

export default errorHandler;
