import React, { useCallback, useMemo, useState } from 'react';
import { Box, Grid, Typography, Button } from '@material-ui/core';

import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';
import useFormAddress from './useFormAddress';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { DialogWithActions } from 'components/ui/DialogWithTitle';
import paymentWiresClient from 'api/paymentWiresClient';

const useFormPaymentWire = ({ data }) => {
  const { bankAddress, recipientAddress } = data || {};
  const [formRef, setFormRef] = useState({});

  const { form: bankForm, validate: bankValidate } = useFormAddress({
    data: bankAddress,
    prefix: 'Bank',
    nameLabel: 'Bank Name',
  });
  const { form: recipientForm, validate: recipientValidate } = useFormAddress({
    data: recipientAddress,
    prefix: 'Recipient',
    nameLabel: 'Recipient Name',
  });

  const validate = useCallback(async () => {
    const {
      values: { name, accountNumber, routingNumber },
      errors,
    } = await validateForm(formRef);

    const { values: bankValues, errors: bankErrors } = await bankValidate();

    const { values: recipientValues, errors: recipientErrors } =
      await recipientValidate();

    return {
      errors: [...errors, ...bankErrors, ...recipientErrors],
      values: {
        name,
        accountNumber,
        routingNumber,

        bankAddress: bankValues,
        recipientAddress: recipientValues,
      },
    };
  }, [bankValidate, formRef, recipientValidate]);

  const form = useMemo(
    () => (
      <Box>
        <Form initialValues={data} enableReinitialize setRef={setFormRef}>
          <Box marginTop={2}>
            <FormikTextInput
              name="name"
              label="Nickname"
              variant="filled"
              required
            />
          </Box>
          <Box marginTop={4}>
            <Typography>Bank Information</Typography>
          </Box>
          <Box marginTop={2}>
            <FormikTextInput
              name="routingNumber"
              label="Routing Number"
              variant="filled"
              required
            />
          </Box>
          <Box marginTop={2}>{bankForm}</Box>

          <Box marginTop={4}>
            <Typography>Recipient Information</Typography>
          </Box>
          <Box marginTop={2}>
            <FormikTextInput
              name="accountNumber"
              label="Account Number"
              variant="filled"
              required
            />
          </Box>

          <Box marginTop={2}>{recipientForm}</Box>
        </Form>
      </Box>
    ),
    [bankForm, data, recipientForm],
  );

  return { form, validate };
};

export const useEditPaymentWire = ({ data, groupId, onChange }) => {
  const [modal, showModal] = useState();

  const formValues = modal || data;
  const { id } = formValues || {};

  const { form, validate } = useFormPaymentWire({
    data: formValues,
  });

  const onSave = useCallback(
    async (onError) => {
      try {
        const { values, errors } = await validate();
        if (errors.length > 0) {
          onError(errors);
          return false;
        }
        const upsert = id
          ? paymentWiresClient.update
          : paymentWiresClient.create;

        const { bankAddress, recipientAddress, ...others } = values;
        const response = await upsert({
          groupId,
          id,
          data: {
            ...others,
            bankAddressAttributes: bankAddress,
            recipientAddressAttributes: recipientAddress,
          },
        });
        const data = response.data;
        onChange(data);
        setTimeout(() => {
          showModal(false);
        }, 500);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    },
    [groupId, id, onChange, validate],
  );

  const modalElement = useMemo(() => {
    const footer = (
      <Box padding={2}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={() => showModal()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton onSave={onSave} name="Save"></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        {...{
          title: `${id ? 'Edit' : 'Add'} Wire Instructions`,
          // header,
          footer,
          onClose: () => showModal(),
        }}
      >
        <Box>{form}</Box>
      </DialogWithActions>
    ) : null;
  }, [form, id, modal, onSave]);

  return { modal: modalElement, showModal, form, validate, onSave };
};

// eslint-disable-next-line import/no-unused-modules
export default useFormPaymentWire;
