import { MenuItem } from '@material-ui/core';
import React from 'react';
import { FormikSelect } from './CustomSelect';

import regionData from 'country-region-data';

// https://github.com/country-regions/country-region-data/blob/master/data.js
const USA_STATES = [
  {
    name: 'Alabama',
    shortCode: 'AL',
  },
  {
    name: 'Alaska',
    shortCode: 'AK',
  },

  {
    name: 'Arizona',
    shortCode: 'AZ',
  },
  {
    name: 'Arkansas',
    shortCode: 'AR',
  },
  {
    name: 'California',
    shortCode: 'CA',
  },
  {
    name: 'Colorado',
    shortCode: 'CO',
  },
  {
    name: 'Connecticut',
    shortCode: 'CT',
  },
  {
    name: 'Delaware',
    shortCode: 'DE',
  },

  {
    name: 'Florida',
    shortCode: 'FL',
  },
  {
    name: 'Georgia',
    shortCode: 'GA',
  },
  {
    name: 'Hawaii',
    shortCode: 'HI',
  },
  {
    name: 'Idaho',
    shortCode: 'ID',
  },
  {
    name: 'Illinois',
    shortCode: 'IL',
  },
  {
    name: 'Indiana',
    shortCode: 'IN',
  },
  {
    name: 'Iowa',
    shortCode: 'IA',
  },
  {
    name: 'Kansas',
    shortCode: 'KS',
  },
  {
    name: 'Kentucky',
    shortCode: 'KY',
  },
  {
    name: 'Louisiana',
    shortCode: 'LA',
  },
  {
    name: 'Maine',
    shortCode: 'ME',
  },
  {
    name: 'Maryland',
    shortCode: 'MD',
  },
  {
    name: 'Massachusetts',
    shortCode: 'MA',
  },
  {
    name: 'Michigan',
    shortCode: 'MI',
  },
  {
    name: 'Minnesota',
    shortCode: 'MN',
  },
  {
    name: 'Mississippi',
    shortCode: 'MS',
  },
  {
    name: 'Missouri',
    shortCode: 'MO',
  },
  {
    name: 'Montana',
    shortCode: 'MT',
  },
  {
    name: 'Nebraska',
    shortCode: 'NE',
  },
  {
    name: 'Nevada',
    shortCode: 'NV',
  },
  {
    name: 'New Hampshire',
    shortCode: 'NH',
  },
  {
    name: 'New Jersey',
    shortCode: 'NJ',
  },
  {
    name: 'New Mexico',
    shortCode: 'NM',
  },
  {
    name: 'New York',
    shortCode: 'NY',
  },
  {
    name: 'North Carolina',
    shortCode: 'NC',
  },
  {
    name: 'North Dakota',
    shortCode: 'ND',
  },
  {
    name: 'Ohio',
    shortCode: 'OH',
  },
  {
    name: 'Oklahoma',
    shortCode: 'OK',
  },
  {
    name: 'Oregon',
    shortCode: 'OR',
  },
  {
    name: 'Pennsylvania',
    shortCode: 'PA',
  },
  {
    name: 'Puerto Rico',
    shortCode: 'PR',
  },
  {
    name: 'Rhode Island',
    shortCode: 'RI',
  },
  {
    name: 'South Carolina',
    shortCode: 'SC',
  },
  {
    name: 'South Dakota',
    shortCode: 'SD',
  },
  {
    name: 'Tennessee',
    shortCode: 'TN',
  },
  {
    name: 'Texas',
    shortCode: 'TX',
  },
  {
    name: 'Utah',
    shortCode: 'UT',
  },
  {
    name: 'Vermont',
    shortCode: 'VT',
  },
  {
    name: 'Virginia',
    shortCode: 'VA',
  },
  {
    name: 'Washington',
    shortCode: 'WA',
  },
  {
    name: 'West Virginia',
    shortCode: 'WV',
  },
  {
    name: 'Wisconsin',
    shortCode: 'WI',
  },
  {
    name: 'Wyoming',
    shortCode: 'WY',
  },
];
// const USA_REGIONS = [
//   {
//     name: 'American Samoa',
//     shortCode: 'AS',
//   },
//   {
//     name: 'District of Columbia',
//     shortCode: 'DC',
//   },
//   {
//     name: 'Micronesia',
//     shortCode: 'FM',
//   },
//   {
//     name: 'Guam',
//     shortCode: 'GU',
//   },
//   {
//     name: 'Marshall Islands',
//     shortCode: 'MH',
//   },
//   {
//     name: 'Northern Mariana Islands',
//     shortCode: 'MP',
//   },
//   {
//     name: 'Palau',
//     shortCode: 'PW',
//   },
//   {
//     name: 'Virgin Islands',
//     shortCode: 'VI',
//   },
//   {
//     name: 'Armed Forces Americas',
//     shortCode: 'AA',
//   },
//   {
//     name: 'Armed Forces Europe, Canada, Africa and Middle East',
//     shortCode: 'AE',
//   },
//   {
//     name: 'Armed Forces Pacific',
//     shortCode: 'AP',
//   },
// ];

export const FormikRegionSelect = ({
  country = 'US',
  statesOnly = false,
  ...props
}) => {
  const data = regionData.find((x) => x.countryShortCode == country);
  const regions =
    country === 'US' && statesOnly ? [...USA_STATES] : data.regions;
  return (
    <FormikSelect {...props}>
      {regions.map((region) => {
        const { name, shortCode } = region;
        return (
          <MenuItem key={shortCode} value={shortCode}>
            {name}
          </MenuItem>
        );
      })}
    </FormikSelect>
  );
};

const countryData = regionData.map((x) => ({
  name: x.countryName,
  shortCode: x.countryShortCode,
}));
export const FormikCountrySelect = (props) => {
  return (
    <FormikSelect {...props}>
      {countryData.map((region) => {
        const { name, shortCode } = region;
        return (
          <MenuItem key={shortCode} value={shortCode}>
            {name}
          </MenuItem>
        );
      })}
    </FormikSelect>
  );
};
