import { useCallback } from 'react';
import isEqual from 'lodash/isEqual';

const updateCache = (cache = [], data) => {
  const { id } = data;
  if (
    isEqual(
      data,
      cache.find((x) => x.id == id),
    )
  ) {
    return cache;
  }
  return [
    ...(data.isDeleted
      ? cache.filter((x) => x.id !== id)
      : cache.some((x) => x.id === id)
      ? cache.map((x) => (x.id === id ? data : x))
      : [data, ...cache]),
  ];
};
export const useArrayUpdate = (onUpdate) => {
  const onListUpdate = useCallback(
    (data, message) => {
      onUpdate((cache) => {
        return updateCache(cache, data);
      }, message);
    },
    [onUpdate],
  );
  return { onListUpdate };
};

const useListUpdate = (name, onUpdate) => {
  const onListUpdate = useCallback(
    (data, message) => {
      onUpdate((cache = {}) => {
        const temp = updateCache(cache[name], data);
        if (isEqual(temp, cache[name])) {
          return cache;
        }
        return {
          ...cache,
          [name]: temp,
        };
      }, message);
    },
    [name, onUpdate],
  );
  return { onListUpdate };
};

export default useListUpdate;
