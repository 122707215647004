import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from '@material-ui/core';

import groupsClient from 'api/groups/groupsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';

import { setSelectedGroup } from 'redux/currentUser/actions';

import ManagerList from './ManagerList';
import WireList from './WireList';
import CheckList from './CheckList';
import BusinessAddressList from './BusinessAddressList';
import { LoadingSpinner } from 'components/ui/Loading';

const GroupSettings = ({ id }) => {
  const [cache, setCache] = useState();
  useEffect(() => {
    const fetchData = async (groupId) => {
      try {
        const response = await groupsClient.get(groupId);
        setCache(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData(id);
    }
  }, [id]);

  const dispatch = useDispatch();
  useEffect(() => {
    if (cache) {
      dispatch(setSelectedGroup(cache));
    }
  }, [cache, dispatch]);

  return (
    <>
      <Box marginBottom={4}>
        {!cache ? (
          <LoadingSpinner />
        ) : (
          <>
            <Box marginTop={2}>
              <ManagerList {...{ groupId: id }} />
            </Box>
            <Box marginTop={6}>
              <BusinessAddressList {...{ groupId: id }} />
            </Box>
            <Box marginTop={6}>
              <WireList {...{ groupId: id }} />
            </Box>
            <Box marginTop={6}>
              <CheckList {...{ groupId: id }} />
            </Box>
          </>
        )}
      </Box>
    </>
  );
};

export default GroupSettings;
