import axios from 'axios';
import get from 'get-value';
import store from 'redux/store';
import { SIGN_OUT_CURRENT_USER } from 'redux/currentUser/types';
import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();
const apiClient = axios.create({
  baseURL: env.REACT_APP_API_URL || 'http://localhost:3001',
});

const AUTH_HEADER_KEYS = [
  'access-token',
  'token-type',
  'client',
  'uid',
  'expiry',
];

const setupInterceptors = (axiosInstance) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const savedHeaders = {};
      AUTH_HEADER_KEYS.forEach((key) => {
        savedHeaders[key] = localStorage.getItem(key);
      });

      config.headers = {
        ...savedHeaders,
        ...config.headers,
      };
      return config;
    },
    (error) => {
      return Promise.reject(error);
    },
  );

  axiosInstance.interceptors.response.use(
    async (response) => {
      if (response.headers['access-token']) {
        const updatedAuthHeaders = {};
        AUTH_HEADER_KEYS.forEach((key) => {
          updatedAuthHeaders[key] = response.headers[key];
          localStorage.setItem(key, response.headers[key]);
        });

        axiosInstance.defaults.headers.common = {
          ...axiosInstance.defaults.headers.common,
          ...updatedAuthHeaders,
        };
      }

      return response;
    },
    (error) => {
      if (get(error, 'response.status') === 401) {
        store.dispatch({ type: SIGN_OUT_CURRENT_USER });
      }
      return Promise.reject(error);
    },
  );
};
setupInterceptors(apiClient);

export default apiClient;
