import PropTypes from 'prop-types';
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Blank from 'layouts/Blank';
import { useProfile } from 'hooks/useAppState';
import SignIn from 'views/SignIn/SignIn';

const CustomRoute = ({
  layout: Layout,
  component: Component,
  isPublic,
  ...rest
}) => {
  const isValid = useProfile((state) => state.isValid);

  if (!isValid && !isPublic) {
    return (
      <Route {...rest}>
        <Blank>
          <SignIn></SignIn>
        </Blank>
      </Route>
    );
  }

  return (
    <Route
      {...rest}
      exact
      render={({ match }) => (
        <Layout>
          <Component {...match} />
        </Layout>
      )}
    />
  );
};

CustomRoute.defaultProps = {
  layout: Blank,
  isPublic: false,
  authRedirect: '/sign-in',
};

CustomRoute.propTypes = {
  component: PropTypes.any,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  layout: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.any,
  }),
  isPublic: PropTypes.bool,
  authRedirect: PropTypes.string,
};

export default withRouter(CustomRoute);
