import React, { useEffect, useState } from 'react';
import { Box, Grid } from '@material-ui/core';
import getServerResponseErrors from 'api/getServerResponseErrors';
import userClient from 'api/user/userClient';

import SummaryWidget from './Summary/SummaryWidget';

import InvestmentsList from './Investments/InvestmentsList';
import DashboardTodoWidget from './Summary/DashboardTodoWidget';

const UserDashboardIndex = () => {
  const [investments, setInvestments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userClient.dashboard_investments();
        setInvestments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    fetchData();
  }, []);

  return (
    <Box>
      <Box>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={12} md={4}>
            <SummaryWidget {...{ investments }} />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <DashboardTodoWidget />
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <InvestmentsList {...{ investments }} />
      </Box>
    </Box>
  );
};

export default UserDashboardIndex;
