import React from 'react';
import { Grid, Typography, Button, Paper } from '@material-ui/core';

const InvestmentCard = ({
  syndicateName,
  roundName,
  opportunityName,
  editClick,
}) => {
  return (
    <Paper variant="outlined" square style={{ padding: '1rem' }}>
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Syndicate Name:
          </Typography>
          <Typography variant="subtitle1">{syndicateName}</Typography>
          <Typography
            variant="subtitle1"
            style={{ fontWeight: 'bold', marginTop: '1rem' }}
          >
            Opportunity:
          </Typography>
          <Typography variant="subtitle1">{opportunityName}</Typography>
          <Typography variant="subtitle1">{roundName}</Typography>
        </Grid>
        <Grid item>
          <Button color="primary" size="small" onClick={editClick}>
            Edit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default InvestmentCard;
