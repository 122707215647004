import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import DialogWithTitle from 'components/ui/DialogWithTitle';
import useListUpdate from 'hooks/useListUpdate';
import { ArticleView } from 'views/OpportunityDetails/OpportunitySectionPress';
import EditArticle from './EditArticle';

const EditPress = ({ opportunity, onUpdate, readOnly = false }) => {
  const [editPress, setEditPress] = useState();
  const { articles, isAdmin } = opportunity || {};

  const { id: opportunityId } = opportunity;
  const { onListUpdate } = useListUpdate('articles', onUpdate);

  return (
    <Box>
      <Box>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs>
            <Box marginBottom={2}>
              <Typography variant="h6" color="primary">
                Press
              </Typography>
              <Typography variant="caption">
                This is a list of articles that showcase your opportunity.
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box marginBottom={2}>
              {isAdmin && !readOnly && (
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => setEditPress({})}
                >
                  Add Article
                </Button>
              )}
              {editPress ? (
                <DialogWithTitle
                  open
                  onClose={() => setEditPress(null)}
                  title={`${(editPress || {}).id ? 'Edit' : 'Add'} Article`}
                >
                  <EditArticle
                    onChange={onListUpdate}
                    onClose={() => setEditPress(null)}
                    {...{ opportunityId, data: editPress }}
                  ></EditArticle>
                </DialogWithTitle>
              ) : null}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={3} alignItems="stretch">
          {articles.map((article) => (
            <Grid item key={article.id} xs={12} sm={12} md={6}>
              <ArticleView data={article} canEdit onChange={onListUpdate} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </Box>
  );
};

export default EditPress;
