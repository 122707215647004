import React from 'react';

import { Typography, MenuItem } from '@material-ui/core';

export const getMenuOptions = ({
  investor,
  onResend,
  onSuspend,
  onReActivate,
  onConfirm,
  status,
  setActionEl,
}) => {
  let menuOptions = [];
  if (status === 'suspended') {
    menuOptions.push(
      <MenuItem
        onClick={() => {
          onReActivate(investor);
          setActionEl(null);
        }}
      >
        Unsuspend
      </MenuItem>,
    );
  }
  if (status === 'pending' || status === 'rejected') {
    menuOptions.push(
      <MenuItem
        onClick={() => {
          onResend(investor);
          setActionEl(null);
        }}
      >
        Invite
      </MenuItem>,
    );
  }
  if (status === 'pending' || status === 'confirmed') {
    menuOptions.push();
    menuOptions.push(
      <MenuItem
        onClick={() => {
          onSuspend(investor);
          setActionEl(null);
        }}
      >
        <Typography color="error">Suspend</Typography>
      </MenuItem>,
    );
  }
  if (status === 'requested access') {
    menuOptions.push(
      <MenuItem
        onClick={() => {
          onConfirm(investor);
          setActionEl(null);
        }}
      >
        Confirm
      </MenuItem>,
    );
    menuOptions.push(
      <MenuItem
        onClick={() => {
          onSuspend(investor);
          setActionEl(null);
        }}
      >
        <Typography color="error">Reject</Typography>
      </MenuItem>,
    );
  }

  return menuOptions;
};
