import React, { useState, useEffect } from 'react';
import { useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Grid, Tab, Tabs, withStyles, useTheme } from '@material-ui/core';

import EditLeadership from './components/EditLeadership';
import EditFinancials from './components/EditFinancials';

import TabPanel from 'components/ui/TabPanel/TabPanel';

import EditFunding from './components/EditFunding';
import EditFeaturedInvestors from './components/EditFeaturedInvestors';
import EditSocial from './components/EditSocial';
import EditAbout from './components/EditAbout';
import EditPress from './components/EditPress';

import { LoadingArea } from 'components/ui/Loading';
import EditOpportunitySyndicates from './EditOpportunitySyndicates';
import EditOpportunitySettings from './components/EditOpportunitySettings';

export const CustomTab = (props) => {
  const theme = useTheme();
  const { label, isNew } = props;
  const labelWithNew = (
    <Box position="relative">
      {label}
      {isNew && (
        <Box
          position="absolute"
          right="-15px"
          top="-9px"
          color={theme.palette.accent.main}
          style={{ fontSize: 10 }}
        >
          New!
        </Box>
      )}
    </Box>
  );
  return <Tab {...props} label={labelWithNew} />;
};

const StyledTab = withStyles({
  wrapper: {
    alignItems: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
  },
})((props) => <CustomTab {...props} />);

const EditOpportunityAbout = ({ opportunity, onUpdate }) => {
  const history = useHistory();
  let { path, url } = useRouteMatch();

  const [currentTab, setCurrentTab] = useState();
  const {
    params: { tab },
  } = useRouteMatch(`${path}/:tab`) || { params: {} };
  useEffect(() => {
    if (!tab) {
      history.replace(`${url}/details`);
    } else {
      setCurrentTab(tab);
    }
  }, [tab, history, url]);

  const handleTabChange = (_e, newValue) => {
    history.push(`${url}/${newValue}`);
    setCurrentTab(newValue);
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item>
          <Tabs
            value={currentTab}
            orientation="vertical"
            onChange={handleTabChange}
            TabIndicatorProps={{ style: { display: 'none' } }}
            textColor="primary"
          >
            <StyledTab label="Details" value="details" disableRipple />
            <StyledTab label="Leadership" value="leadership" disableRipple />
            <StyledTab label="Financials" value="financials" />
            <StyledTab label="Social" value="social" />
            <StyledTab label="Press" value="press" />
            <StyledTab label="Key Investors" value="featured-investors" />
            <StyledTab label="Rounds" value="rounds" />
            <StyledTab label="Syndicates" value="syndicates" />
            <StyledTab label="Settings" value="settings" />
          </Tabs>
        </Grid>
        <Grid item xs>
          {!currentTab || !opportunity ? (
            <Box position="relative" height="100%">
              <LoadingArea open />
            </Box>
          ) : (
            <>
              <TabPanel value={currentTab} index="details">
                <EditAbout {...{ opportunity, onUpdate }} />
              </TabPanel>
              <TabPanel value={currentTab} index="leadership">
                <EditLeadership {...{ opportunity, onUpdate }} />
              </TabPanel>
              <TabPanel value={currentTab} index="financials">
                <EditFinancials {...{ opportunity, onUpdate }} />
              </TabPanel>
              <TabPanel value={currentTab} index="social">
                <EditSocial {...{ opportunity, onUpdate }} />
              </TabPanel>
              <TabPanel value={currentTab} index="press">
                <EditPress {...{ opportunity, onUpdate }} />
              </TabPanel>
              <TabPanel value={currentTab} index="featured-investors">
                <EditFeaturedInvestors {...{ opportunity, onUpdate }} />
              </TabPanel>
              <TabPanel value={currentTab} index="rounds">
                <EditFunding {...{ opportunity, onUpdate }} />
              </TabPanel>
              <TabPanel value={currentTab} index="syndicates">
                <EditOpportunitySyndicates
                  {...{ opportunity, onUpdate: () => {} }}
                />
              </TabPanel>
              <TabPanel value={currentTab} index="settings">
                <EditOpportunitySettings
                  {...{ opportunity, onUpdate: () => {} }}
                />
              </TabPanel>
            </>
          )}
        </Grid>
      </Grid>
    </Box>
  );
};

export default EditOpportunityAbout;
