import React, { useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Typography,
  useTheme,
  CircularProgress,
  Tooltip,
} from '@material-ui/core';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';

import useSyndicateDocument, {
  SyndicateDocumentItem,
} from './SyndicateDocumentItem';
import syndicatesClient from 'api/syndicatesClient';
import { useProfile } from 'hooks/useAppState';
import { documentStates } from 'hooks/useSyndicateDocumentPoll';
import Content from 'components/ui/wizard/Content';
import Footer from 'components/ui/wizard/Footer';
import SyndicateFormationDate from './SyndicateFormationDate';

import SyndicateFilingInfo from './SyndicateFilingInfo';
import { StateRegistrationForm } from './SyndicateFiling';
import syndicateFilingsClient from 'api/syndicateFilingsClient';
import SyndicateFilingItem from './SyndicateFilingItem';
import { RestartSyndicate } from './SyndicateServiceAgreement';
import { useArrayUpdate } from 'hooks/useListUpdate';

const { COMPLETED } = documentStates;

const SyndicateStateDocuments = (props) => (
  <RestartSyndicate {...props} DocumentsComponent={StateDocuments} />
);

const StateDocuments = ({
  actionButton,
  investment,
  syndicate,
  setSyndicate,
}) => {
  const theme = useTheme();
  const isAdmin = useProfile((state) => state.isAdmin);
  const { id, articlesOfOrganizationId, formationDate, stateRegistrationLink } =
    syndicate || {};
  const articlesOfOrganization = useSyndicateDocument({
    id: articlesOfOrganizationId,
    documentName: 'Articles of Organization',
    canChange: isAdmin,
    role: 'manager',
  });
  const [filings, setFilings] = useState();
  const { onListUpdate } = useArrayUpdate(setFilings);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicateFilingsClient.index({
          syndicateId: id,
        });
        setFilings(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const onSave = async (onError) => {
    try {
      const response = await syndicatesClient.file_formation_filings({ id });
      setSyndicate(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };
  const hasCfgs =
    filings && filings.filter((x) => x.form == 'cfgs' && x.document);
  const resetDisabled = !(
    articlesOfOrganization.stepName != COMPLETED || formationDate == null
  );
  return (
    <>
      <Content>
        <Box padding={3} paddingTop={1}>
          {articlesOfOrganization.stepName != COMPLETED && (
            <Box
              marginBottom={2}
              bgcolor={theme.palette.primary.main}
              color="white"
              padding={2}
            >
              <Grid container spacing={2}>
                <Grid
                  item
                  style={{
                    color: theme.palette.accent.main,
                    display: 'flex',
                    alignItems: 'center',
                  }}
                >
                  <CircularProgress
                    variant="determinate"
                    value={75}
                    color="inherit"
                    size={40}
                  />
                </Grid>

                <Grid item xs>
                  <Typography variant="subtitle2">
                    SmartCapital is currently filing the Articles of
                    Organization for the Syndicate. Filings typically take one
                    business day to process. Once filed, the Syndicate can begin
                    accepting investments. SmartCapital will reach out to the
                    Manager once the filing has been completed, at which point
                    you can resume Syndicate Formation.
                  </Typography>
                </Grid>
              </Grid>
            </Box>
          )}
          {isAdmin && (
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateFilingInfo {...{ syndicate, investment }} />
            </Box>
          )}
          {isAdmin && (
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <StateRegistrationForm
                {...{ syndicate, onChange: setSyndicate }}
              />
            </Box>
          )}
          <Box
            borderBottom="1px solid lightgray"
            style={{ padding: '1rem', paddingLeft: '' }}
          >
            <SyndicateDocumentItem
              {...{
                data: articlesOfOrganization,
                progressText: 'Currently Being Filed By SmartCapital',
              }}
            />
          </Box>
          {isAdmin &&
            filings &&
            filings
              .filter((x) => x.form == 'cfgs')
              .sort((a, b) => (a.year > b.year ? -1 : 1))
              .map((filing) => {
                return (
                  <Box
                    key={filing.unique_id}
                    borderBottom="1px solid lightgray"
                    style={{ padding: '1rem', paddingLeft: '' }}
                  >
                    <SyndicateFilingItem
                      {...{ filing, onChange: onListUpdate }}
                    />
                  </Box>
                );
              })}
          <Box
            borderBottom="1px solid lightgray"
            style={{ padding: '1rem', paddingLeft: '' }}
          >
            <SyndicateFormationDate
              {...{
                syndicateFormationDate: formationDate,
                id,
                onChange: setSyndicate,
                isAdmin,
              }}
            />
          </Box>
        </Box>
      </Content>
      <Footer>
        <Box padding={2}>
          <Grid
            container
            spacing={1}
            wrap="nowrap"
            justify="space-between"
            alignItems="center"
          >
            {/* Reset button */}
            {isAdmin ? (
              <Tooltip
                title={
                  !resetDisabled ? (
                    <Typography variant="body2">
                      This will reset the Syndicate to before the service
                      documents were filed.
                    </Typography>
                  ) : (
                    <Typography variant="body2">
                      Reset can only be done before the Articles of Organization
                      and formation date are filed
                    </Typography>
                  )
                }
              >
                <Grid item>{actionButton}</Grid>
              </Tooltip>
            ) : (
              <Grid item>
                <Box />
              </Grid>
            )}
            <Tooltip
              title={
                <Typography variant="body2">
                  Please wait for SmartCapital to file the Articles of
                  Organization and formation date
                </Typography>
              }
            >
              <Grid item>
                <SaveFormButton
                  onSave={onSave}
                  name="Continue"
                  disabled={
                    !isAdmin ||
                    !stateRegistrationLink ||
                    !hasCfgs ||
                    articlesOfOrganization.stepName != COMPLETED ||
                    formationDate == null
                  }
                ></SaveFormButton>
              </Grid>
            </Tooltip>
          </Grid>
        </Box>
      </Footer>
    </>
  );
};

export default SyndicateStateDocuments;
