import React, { useState } from 'react';
import { Typography } from '@material-ui/core';

import { useProfile } from 'hooks/useAppState';
import UserOnboardingMessage from 'components/Message/UserOnboardingMessage';
import OpportunitiesList from './OpportunitiesList';

const OpportunitiesIndex = () => {
  const { firstName, opportunitiesMessageDismissed } = useProfile((state) => ({
    firstName: state.firstName,
    opportunitiesMessageDismissed: state.opportunitiesMessageDismissed,
  }));

  const [showWelcomeMessage] = useState(!opportunitiesMessageDismissed);

  return (
    <>
      {showWelcomeMessage && (
        <UserOnboardingMessage dismissField="opportunitiesMessageDismissed">
          <Typography variant="h5" gutterBottom>
            Welcome {firstName}
          </Typography>
          <Typography variant="body1">
            This is the opportunities view, you&apos;ll be able to find any
            active opportunities available to your group.
          </Typography>
        </UserOnboardingMessage>
      )}
      <OpportunitiesList />
    </>
  );
};

export default OpportunitiesIndex;
