import PropTypes from 'prop-types';
import React, { useRef, useEffect } from 'react';
import { Formik } from 'formik';
import FormikEffect from './components/FormikEffect/FormikEffect';
import { Box, Grow } from '@material-ui/core';
import { Alert } from '@material-ui/lab';

const noop = (_cache) => {};
const Form = ({
  setRef = noop,
  children,
  onValuesChange = null,
  onSubmit = null,
  ...rest
}) => {
  const formikRef = useRef();

  useEffect(() => {
    setRef(formikRef);
  }, [formikRef, setRef]);
  return (
    <Formik
      {...rest}
      initialValues={rest.initialValues || {}}
      onSubmit={onSubmit}
    >
      {(formikProps) => {
        formikRef.current = formikProps;
        return (
          <>
            <FormikEffect onChange={onValuesChange} />
            <form onSubmit={formikProps.handleSubmit}>
              {typeof children === 'function'
                ? children(formikProps)
                : children}
              {formikProps.errors && formikProps.errors.entries ? (
                <Grow in={formikProps.errors}>
                  <Box mt={2}>
                    <Alert severity="error">
                      {formikProps.errors.entries().map((key, val) => (
                        <div key={key}>
                          {key} : {val}
                        </div>
                      ))}
                    </Alert>
                  </Box>
                </Grow>
              ) : null}
              {onSubmit && (
                <input type="submit" style={{ display: 'none' }}></input>
              )}
            </form>
          </>
        );
      }}
    </Formik>
  );
};

Form.propTypes = {
  children: PropTypes.any,
  formErrors: PropTypes.array,
  onValuesChange: PropTypes.func,
  onFormikRef: PropTypes.func,
};

Form.defaultProps = {
  onValuesChange: () => {},
  onFormikRef: () => {},
  initialValues: {},
};

export default Form;
