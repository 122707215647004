import apiClient from 'api/axios';

const getAll = (groupId) => {
  return apiClient.get(`/api/v1/groups/${groupId}/users`, { data: {} });
};

const getCurrentUser = () => {
  return apiClient.get('/api/v1/users/profile');
};

const updateUserData = ({ data }) => {
  return apiClient.put('/api/v1/users/profile', { user: data });
};

const changePassword = ({ data }) => {
  return apiClient.post('/api/v1/users/profile/change_password', {
    user: data,
  });
};

const changeEmail = ({ data }) => {
  return apiClient.post('/api/v1/users/profile/change_email', {
    user: data,
  });
};

const signIn = (credentials) => {
  return apiClient.post('/api/v1/auth/sign_in', credentials);
};

const signUpUser = (credentials) => {
  return apiClient.post('/api/v1/auth', credentials);
};

const createGroupAdminWithGroup = ({ user, group }) => {
  return apiClient.post('/api/v1/auth', {
    user: { ...user, onboardingComplete: true },
    group,
  });
};

const getDashboardData = (groupId) => {
  return apiClient.get(`/api/v1/groups/${groupId}/users/dashboard`, {
    data: {},
  });
};

const forgotPassword = (email) => {
  return apiClient.post('/api/v1/auth/password', {
    email,
    redirectUrl: `${window.location.protocol}//${window.location.hostname}/reset-forgot-password`,
  });
};

const resetPassword = ({ password, passwordConfirmation, authHeaders }) => {
  return apiClient.put(
    '/api/v1/auth/password',
    {
      password,
      passwordConfirmation,
    },
    {
      headers: authHeaders,
    },
  );
};

const enable = ({ userId, groupId }) => {
  return apiClient.post(
    `/api/v1/groups/${groupId}/users/${userId}/enable_user`,
  );
};

const removeFromGroup = ({ userId, groupId }) => {
  return apiClient.post(
    `/api/v1/groups/${groupId}/users/${userId}/remove_from_group`,
  );
};

const inviteUser = (user) => {
  return apiClient.post(`/api/v1/auth/invitation`, { user });
};

const resendConfirmationInstructions = (userEmail) => {
  return apiClient.post(
    '/api/v1/users/profile/send_confirmation_instructions',
    {
      email: userEmail,
    },
  );
};

const getInvitation = ({ email, invitationToken }) => {
  return apiClient.get('/api/v1/auth/invitation/accept', {
    params: {
      email,
      invitation_token: invitationToken,
    },
  });
};

const acceptInvitation = (credentials) => {
  return apiClient.patch('/api/v1/auth/invitation', credentials);
};

const updateInvestDefaults = ({ entityType, data }) => {
  return apiClient.post('/api/v1/users/profile/invest_defaults', {
    user: {
      entityType,
      defaults: data,
    },
  });
};

const getInvestDefaults = ({ entityType }) => {
  return apiClient.get('/api/v1/users/profile/invest_defaults', {
    params: {
      entityType,
    },
  });
};

const dashboard_investments = () => {
  return apiClient.get('/api/v1/users/dashboard_investments');
};
const dashboard_groups = () => {
  return apiClient.get('/api/v1/users/dashboard_groups');
};
const dashboard_active = () => {
  return apiClient.get('/api/v1/users/dashboard_active');
};
const dashboard_opportunities = () => {
  return apiClient.get('/api/v1/users/dashboard_opportunities');
};

const userClient = {
  getAll,
  getCurrentUser,
  signIn,
  signUpUser,
  getDashboardData,
  updateUserData,
  forgotPassword,
  resetPassword,
  enable,
  inviteUser,
  resendConfirmationInstructions,
  getInvitation,
  acceptInvitation,
  createGroupAdminWithGroup,
  removeFromGroup,
  changePassword,
  changeEmail,
  getInvestDefaults,
  updateInvestDefaults,
  dashboard_investments,
  dashboard_groups,
  dashboard_active,
  dashboard_opportunities,
};

export default userClient;
