import React, { useEffect, useState } from 'react';
import { Box, Button } from '@material-ui/core';
import getServerResponseErrors from 'api/getServerResponseErrors';

import userInvestmentsClient from 'api/userInvestmentsClient';

import moment from 'moment';

export const SendReminder = ({ userInvestment, onChange }) => {
  const { id, dateLastEmail } = userInvestment;
  const handleReminder = async () => {
    try {
      const response = await userInvestmentsClient.send_signature_notification({
        id,
      });
      onChange(response.data);
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  };
  return (
    <Box>
      <Box marginTop={1}>
        <Button variant="contained" color="primary" onClick={handleReminder}>
          Send Reminder
        </Button>
      </Box>
      {dateLastEmail && (
        <Box marginTop={1}>
          Last sent <TimeFromNow stamp={dateLastEmail} />
        </Box>
      )}
    </Box>
  );
};

const TimeFromNow = ({ stamp }) => {
  const [display, setDisplay] = useState(
    stamp ? moment(stamp).fromNow() : null,
  );
  useEffect(() => {
    setDisplay(moment(stamp).fromNow());
  }, [stamp]);
  useEffect(() => {
    const timer = setTimeout(() => {
      setDisplay(moment(stamp).fromNow());
    }, 30000);
    return () => {
      clearTimeout(timer);
    };
  }, [stamp, display]);

  return <>{display}</>;
};
