import apiClient from 'api/axios';

const index = ({ groupId }) =>
  apiClient.get(`/api/v1/groups/${groupId}/group_locations`, {});
const create = ({ groupId, data }) =>
  apiClient.post(`/api/v1/groups/${groupId}/group_locations`, {
    group_location: data,
  });

const get = ({ id }) => apiClient.get(`/api/v1/group_locations/${id}`, {});
const destroy = ({ id }) =>
  apiClient.delete(`/api/v1/group_locations/${id}`, {});
const update = ({ id, data }) =>
  apiClient.patch(`/api/v1/group_locations/${id}`, {
    group_location: data,
  });

const groupLocationsClient = {
  index,
  get,
  create,
  update,
  destroy,
};

export default groupLocationsClient;
