import PropTypes from 'prop-types';
import React from 'react';
import { Button as MuiButton, CircularProgress, Grid } from '@material-ui/core';

const Button = (props) => {
  return (
    <MuiButton {...props} disabled={props.disabled || props.loading}>
      <Grid container spacing={1} alignItems="center" justify="center">
        <Grid item>{props.children}</Grid>
        {props.loading && (
          <Grid item>
            <Grid container alignItems="center">
              <CircularProgress size={14} color="inherit" />
            </Grid>
          </Grid>
        )}
      </Grid>
    </MuiButton>
  );
};

Button.propTypes = {
  children: PropTypes.any,
  disabled: PropTypes.bool,
  loading: PropTypes.bool,
  size: PropTypes.string,
};

export default Button;
