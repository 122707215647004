import React from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { FaInfoCircle } from 'react-icons/fa';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';

import useSyndicateDocument, {
  SyndicateDocumentItem,
} from './SyndicateDocumentItem';
import syndicatesClient from 'api/syndicatesClient';
import { documentStates } from 'hooks/useSyndicateDocumentPoll';
import Content from 'components/ui/wizard/Content';
import Footer from 'components/ui/wizard/Footer';

const { COMPLETED } = documentStates;

const SyndicateFederalDocuments = ({
  syndicate,
  setSyndicate,
  showFiled = true,
}) => {
  const theme = useTheme();
  const {
    primaryManager,
    id,
    powerOfAttorneyId,
    formDSignatureId,
    formDRelated,
    formDFiledId,
  } = syndicate || {};
  const {
    groupManager: { email },
  } = primaryManager || { groupManager: {} };
  const powerOfAttorney = useSyndicateDocument({
    id: powerOfAttorneyId,
    documentName: 'Power of Attorney',
    linkEmail: email,
    role: 'manager',
  });
  const formDSignature = useSyndicateDocument({
    id: formDSignatureId,
    documentName: 'Form D',
    linkEmail: email,
    role: 'manager',
  });
  const formDFiled = useSyndicateDocument({
    id: formDFiledId,
    documentName: 'Form D - Filed',
    linkEmail: email,
    role: 'manager',
  });

  const onSave = async (onError) => {
    try {
      const response = await syndicatesClient.file_offering_authorization({
        id,
      });
      setSyndicate(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  return (
    <>
      <Content>
        <Box
          marginBottom={2}
          bgcolor={theme.palette.primary.main}
          color="white"
          padding={2}
        >
          <Grid container spacing={2}>
            <Grid
              item
              style={{
                color: theme.palette.accent.main,
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <FaInfoCircle size={30} />
            </Grid>

            <Grid item xs>
              <Typography variant="subtitle2">
                For SmartCapital to file the Form ID and Form D to the SEC, the
                organization needs Power of Attorney to perform this action. By
                granting Power of Attorney to SmartCapital, you are allowing
                SmartCapital permission to perform filings. However, this does
                not create an attorney/client relationship.
              </Typography>
            </Grid>
          </Grid>
        </Box>
        <Box padding={3} paddingTop={1}>
          <Box
            borderBottom="1px solid lightgray"
            style={{ padding: '1rem', paddingLeft: '' }}
          >
            <SyndicateDocumentItem {...{ data: powerOfAttorney }} />
          </Box>
          <Box
            borderBottom="1px solid lightgray"
            style={{ padding: '1rem', paddingLeft: '' }}
          >
            <SyndicateDocumentItem {...{ data: formDSignature }} />
          </Box>
          {formDRelated?.length > 0 &&
            formDRelated.map((relatedId, index) => (
              <FormDRelated {...{ email, relatedId, index }} />
            ))}
          {showFiled && (
            <Box
              borderBottom="1px solid lightgray"
              style={{ padding: '1rem', paddingLeft: '' }}
            >
              <SyndicateDocumentItem {...{ data: formDFiled }} />
            </Box>
          )}
        </Box>
      </Content>
      <Footer>
        <Box padding={2}>
          <Grid
            container
            spacing={1}
            wrap="nowrap"
            justify="flex-end"
            alignItems="center"
          >
            <Grid item>
              <SaveFormButton
                onSave={onSave}
                name="Continue"
                disabled={powerOfAttorney.stepName != COMPLETED}
              ></SaveFormButton>
            </Grid>
          </Grid>
        </Box>
      </Footer>
    </>
  );
};

const FormDRelated = ({ relatedId, email, index }) => {
  const related = useSyndicateDocument({
    id: relatedId,
    documentName: `Form D - Related Persons - Page ${index + 1}`,
    linkEmail: email,
    role: 'manager',
  });

  return (
    <Box
      borderBottom="1px solid lightgray"
      style={{ padding: '1rem', paddingLeft: '' }}
    >
      <SyndicateDocumentItem {...{ data: related }} />
    </Box>
  );
};

export default SyndicateFederalDocuments;
