import React, { useState } from 'react';
import { Box, Grid, Typography, useTheme } from '@material-ui/core';
import { FaInfoCircle } from 'react-icons/fa';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';

import { CustomCheckBox } from 'components/ui/CustomCheckBox';
import useSyndicateDocument, {
  SyndicateDocumentItem,
} from './SyndicateDocumentItem';
import syndicatesClient from 'api/syndicatesClient';
import ActionWithConfirm from 'components/ui/ActionWithConfirm';
import { useHistory } from 'react-router-dom';
import { useSelectedGroup } from 'hooks/useAppState';

export const RestartSyndicate = ({
  DocumentsComponent,
  syndicate,
  ...rest
}) => {
  const history = useHistory();
  const { slug } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  const { id, groupInvestment } = syndicate || {};
  const { id: groupInvestmentId } = groupInvestment || {};

  const onDelete = async (onError) => {
    try {
      await syndicatesClient.remove({ id });
      history.push(`/${slug}/syndicates/${groupInvestmentId}`);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  return (
    <ActionWithConfirm onAction={onDelete} action="Restart Syndicate">
      {({ actionButton }) => (
        <DocumentsComponent {...{ actionButton, syndicate, ...rest }} />
      )}
    </ActionWithConfirm>
  );
};

const SyndicateServiceAgreement = (props) => (
  <RestartSyndicate {...props} DocumentsComponent={ServiceDocuments} />
);

const ServiceDocuments = ({ actionButton, syndicate, setSyndicate }) => {
  const {
    id,
    serviceDocumentSignatureId,
    statementOfWorkId,
    serviceAgreementId,
  } = syndicate || {};
  const theme = useTheme();

  const [checked, setChecked] = useState(false);

  const statementOfWork = useSyndicateDocument({
    id: statementOfWorkId,
    documentName: 'Statement of Work',
    role: 'manager',
  });
  const serviceAgreement = useSyndicateDocument({
    id: serviceAgreementId,
    documentName: 'Service Agreement',
    role: 'manager',
  });
  const serviceDocumentSignature = useSyndicateDocument({
    id: serviceDocumentSignatureId,
    documentName: 'Service Signature',
    role: 'manager',
  });

  const onSave = async (onError) => {
    try {
      const response = await syndicatesClient.file_formation_service({ id });
      setSyndicate(response.data);
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };
  return (
    <Box>
      <Box
        marginBottom={2}
        bgcolor={theme.palette.primary.main}
        color="white"
        padding={2}
      >
        <Grid container spacing={2}>
          <Grid
            item
            style={{
              color: theme.palette.accent.main,
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <FaInfoCircle size={30} />
          </Grid>

          <Grid item xs>
            <Typography variant="subtitle2">
              Service Documents are the agreements between the Manager of the
              Syndicate and SmartCapital. These documents outline the fees
              SmartCapital will charge the Manager for forming the LLC.
            </Typography>
          </Grid>
        </Grid>
      </Box>
      <Box padding={3} pt={1}>
        <Box
          borderBottom="1px solid lightgray"
          style={{ padding: '1rem', paddingLeft: '' }}
        >
          <SyndicateDocumentItem {...{ data: statementOfWork }} />
        </Box>
        <Box
          borderBottom="1px solid lightgray"
          style={{ padding: '1rem', paddingLeft: '' }}
        >
          <SyndicateDocumentItem {...{ data: serviceAgreement }} />
        </Box>
        <Box
          borderBottom="1px solid lightgray"
          style={{ padding: '1rem', paddingLeft: '' }}
        >
          <SyndicateDocumentItem {...{ data: serviceDocumentSignature }} />
        </Box>
        <Box marginTop={4}>
          <CustomCheckBox
            name="useBusiness"
            label="By checking this box, you confirm that you have reviewed the Service Agreement and Statement of Work, and agree to the terms of syndicating with SmartCapital."
            checked={checked}
            onChange={() => setChecked((x) => !x)}
          />
        </Box>
      </Box>

      <Box padding={2} marginTop={2} paddingBottom={4}>
        <Grid container spacing={1} justify="space-between">
          <Grid item>{actionButton}</Grid>
          <Grid item>
            <SaveFormButton
              onSave={onSave}
              name="Continue"
              disabled={
                !checked ||
                !!(
                  serviceDocumentSignature.isReady &&
                  serviceAgreement.isReady &&
                  statementOfWork.isReady
                )
              }
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default SyndicateServiceAgreement;
