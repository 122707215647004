import React from 'react';
import {
  GridColumnMenuContainer,
  GridFilterMenuItem,
  HideGridColMenuItem,
  GridColumnsMenuItem,
} from '@mui/x-data-grid-pro';

export const CustomGridColumnMenu = React.forwardRef(function GridColumnMenu(
  props,
  ref,
) {
  const { hideMenu, currentColumn } = props;
  return (
    <GridColumnMenuContainer ref={ref} {...props}>
      <GridFilterMenuItem onClick={hideMenu} column={currentColumn} />
      <HideGridColMenuItem onClick={hideMenu} column={currentColumn} />
      <GridColumnsMenuItem onClick={hideMenu} column={currentColumn} />
    </GridColumnMenuContainer>
  );
});
