import React from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { RoundView } from 'views/EditOpportunity/components/EditRounds';

const OpportunitySectionRounds = ({ opportunity }) => {
  const { rounds } = opportunity || {};

  if (!rounds || rounds.length === 0) {
    return null;
  }

  const sorted = rounds.sort((a, b) =>
    a.dateClosed && !b.dateClosed ? 1 : a.dateClosed < b.dateClosed ? 1 : -1,
  );

  return (
    <>
      <Box style={{ borderBottom: '1px solid lightgray' }}>
        <Typography color="primary" style={{ textTransform: 'uppercase' }}>
          Rounds
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Grid container spacing={3}>
          {sorted.map((round) => (
            <Grid item key={round.id} xs={12} sm="auto" md={6} lg={4}>
              <RoundView data={round} />
            </Grid>
          ))}
        </Grid>
      </Box>
    </>
  );
};

export default OpportunitySectionRounds;
