import apiClient from 'api/axios';

const index = ({ groupId, investmentId = null }) => {
  return apiClient.get(`/api/v1/groups/${groupId}/syndicates`, {
    params: {
      investmentId,
    },
  });
};

const create = ({ investmentId, data }) => {
  return apiClient.post(
    `/api/v1/group_investments/${investmentId}/syndicates`,
    {
      syndicate: data,
    },
  );
};

const get = ({ id }) => {
  return apiClient.get(`/api/v1/syndicates/${id}`, {
    params: {},
  });
};

const update = ({ id, data, regenerate = null }) => {
  return apiClient.patch(`/api/v1/syndicates/${id}`, {
    regenerate,
    syndicate: data,
  });
};

const close = ({ id, data }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/close`, {
    syndicate: data,
  });
};

const remove = ({ id }) => {
  return apiClient.delete(`/api/v1/syndicates/${id}`, {});
};

const file_service = ({ id }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/file_service`, {});
};

const file_formation_service = ({ id }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/file_formation_service`, {});
};

const file_formation_authorization = ({ id }) => {
  return apiClient.post(
    `/api/v1/syndicates/${id}/file_formation_authorization`,
    {},
  );
};

const file_formation_filings = ({ id }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/file_formation_filings`, {});
};

const file_offering_review = ({ id }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/file_offering_review`, {});
};

const file_offering_authorization = ({ id }) => {
  return apiClient.post(
    `/api/v1/syndicates/${id}/file_offering_authorization`,
    {},
  );
};
const file_offering_agreements = ({ id }) => {
  return apiClient.post(
    `/api/v1/syndicates/${id}/file_offering_agreements`,
    {},
  );
};

const file_state = ({ id }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/file_state`, {});
};

const file_federal = ({ id }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/file_federal`, {});
};

const file_review = ({ id }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/file_review`, {});
};

const file_confirm = ({ id }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/file_confirm`, {});
};

const operating_agreement_link = ({ id }) => {
  return apiClient.get(`/api/v1/syndicates/${id}/operating_agreement_link`, {
    params: {},
  });
};

const get_document = (id) => {
  return apiClient.get(`/api/v1/syndicate_documents/${id}`, {
    params: {},
  });
};

const get_document_link = ({ id, role }) => {
  return apiClient.get(`/api/v1/syndicate_documents/${id}/link`, {
    params: {
      role,
    },
  });
};

const get_document_references = ({ id, params }) => {
  return apiClient.get(`/api/v1/syndicate_documents/${id}/references`, {
    params,
  });
};

const get_document_citations = ({ id, params }) => {
  return apiClient.get(`/api/v1/syndicate_documents/${id}/citations`, {
    params,
  });
};

const change_document = ({ id, method, attachment, attachmentFileName }) => {
  return apiClient.put(`/api/v1/syndicate_documents/${id}`, {
    document: {
      method,
      attachment,
      attachmentFileName,
    },
  });
};

const get_managers = ({ id }) => {
  return apiClient.get(`/api/v1/syndicates/${id}/managers`, {
    params: {},
  });
};

const update_managers = ({ id, managers }) => {
  return apiClient.post(`/api/v1/syndicates/${id}/managers`, {
    syndicate: {
      managers,
    },
  });
};

const blueSkyFees = ({ id }) => {
  return apiClient.get(`/api/v1/syndicates/${id}/blue_sky_fees`, {
    params: {},
  });
};

const investmentStatus = ({ id }) => {
  return apiClient.get(`/api/v1/syndicates/${id}/investment_status`, {
    params: {},
  });
};

const investments = (id) => {
  return apiClient.get(`/api/v1/syndicates/${id}/investments`, {
    params: {},
  });
};

const syndicatesClient = {
  index,
  get,
  create,
  update,
  close,
  remove,
  operating_agreement_link,
  get_document,
  change_document,
  get_document_references,
  get_document_citations,
  get_document_link,
  get_managers,
  update_managers,
  blueSkyFees,
  file_service,
  file_formation_service,
  file_formation_authorization,
  file_formation_filings,
  file_offering_review,
  file_offering_authorization,
  file_offering_agreements,
  file_state,
  file_federal,
  file_review,
  file_confirm,
  investmentStatus,
  investments,
};

export default syndicatesClient;
