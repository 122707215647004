import React from 'react';
import { Box, useTheme } from '@material-ui/core';
import get from 'lodash/get';
import Button from 'components/ui/Button/Button';
import getServerResponseErrors from 'api/getServerResponseErrors';
import userInvestmentsClient from 'api/userInvestmentsClient';

const USER_INVESTMENT_STATUS = {
  COLLECTING_INTEREST: 0,
  OPEN: 1,
  NEEDS_SIGNED: 4,
  AWAITING_SIGNATURE: 5,
  PENDING_APPROVAL: 6,
  SEND_MONEY: 7,
  AWAITING_RECEIPT: 8,
  AWAITING_CLOSE: 9,
  COMPLETE: 10,
};
const {
  COLLECTING_INTEREST,
  OPEN,
  NEEDS_SIGNED,
  AWAITING_SIGNATURE,
  PENDING_APPROVAL,
  SEND_MONEY,
  AWAITING_RECEIPT,
  AWAITING_CLOSE,
  COMPLETE,
} = USER_INVESTMENT_STATUS;

const getStatusInfo = ({ status, interestAmount }) => {
  const statusInfo = STATUS_LOOKUP[status];
  if (
    status === USER_INVESTMENT_STATUS.COLLECTING_INTEREST &&
    parseFloat(interestAmount) > 0
  ) {
    statusInfo.buttonName = 'Change Interest';
    statusInfo.buttonTitle =
      'Click to change your interest amount for this opportunity';
  }
  return statusInfo;
};

export const useStatusColor = (status) => {
  const theme = useTheme();
  const { statusColor } = STATUS_LOOKUP[status] || {};
  return statusColor ? get(theme, statusColor) : theme.palette.primary.main;
};

export const STATUS_LOOKUP = {
  [COLLECTING_INTEREST]: {
    name: 'Collecting Interest',
    color: 'primary',
    statusColor: 'palette.primary.main',
    buttonName: 'Express Interest',
    buttonTitle: 'Click to express your monetary interest in this opportunity',
    disabled: false,
    canPass: true,
  },
  [OPEN]: {
    name: 'Open',
    color: 'primary',
    statusColor: 'palette.primary.main',
    buttonName: 'Invest',
    buttonTitle: 'Click to invest in this opportunity',
    disabled: false,
    canPass: true,
  },
  // [INTERESTED]: {
  //   name: 'Interested',
  //   color: 'primary',
  //   buttonName: 'Invest',
  //   disabled: false,
  //   canPass: true,
  // },
  // [MAYBE]: {
  //   name: 'Maybe',
  //   color: 'primary',
  //   buttonName: 'Invest',
  //   disabled: false,
  //   canPass: true,
  // },
  Passed: {
    name: 'Passed',
    color: 'primary',
    buttonName: 'Express Interest',
    disabled: false,
    canPass: false,
  },
  [NEEDS_SIGNED]: {
    name: 'Needs Signed',
    color: 'primary',
    statusColor: 'palette.error.main',
    buttonName: 'Complete',
    buttonTitle:
      'Click to complete your investment in this opportunity with a signature',
    disabled: false,
    canPass: false,
  },
  [AWAITING_SIGNATURE]: {
    name: 'Awaiting Signature',
    color: 'primary',
    statusColor: 'palette.text.primary',
    buttonName: 'Wait',
    buttonTitle: 'Awaiting signature confirmation, please check back later',
    disabled: true,
    canPass: false,
  },
  [PENDING_APPROVAL]: {
    name: 'Pending Approval',
    color: 'primary',
    statusColor: 'palette.secondary.main',
    buttonName: 'Wait',
    buttonTitle: 'Awaiting approval, please check back later',
    disabled: true,
    canPass: false,
  },
  [SEND_MONEY]: {
    name: 'Send Money',
    color: 'primary',
    statusColor: 'palette.accent.main',
    buttonName: 'Send',
    buttonTitle:
      'Click to send money, per your investment amount, to this opportunity',
    disabled: false,
    canPass: false,
  },
  [AWAITING_RECEIPT]: {
    name: 'Awaiting Receipt',
    color: 'primary',
    buttonName: 'Wait',
    buttonTitle: 'Awaiting receipt of money, please check back later',
    statusColor: 'palette.text.primary',
    disabled: true,
    canPass: false,
  },
  [AWAITING_CLOSE]: {
    name: 'Awaiting Close',
    color: 'primary',
    buttonName: 'Wait',
    buttonTitle: 'Awaiting close of opportunity, please check back later',
    statusColor: 'palette.text.primary',
    disabled: true,
    canPass: false,
  },
  [COMPLETE]: {
    name: 'Complete',
    color: 'primary',
    statusColor: 'palette.money.main',
    buttonName: null,
    buttonTitle: 'Complete this opportunity',
    disabled: false,
    canPass: false,
  },
};

export const TodoAction = ({
  status,
  investmentId,
  id,
  interestAmount,
  onChange,
  buttonWrapperOverrides,
  buttonSize = 'small',
  showModal,
}) => {
  const handleNo = async () => {
    try {
      const upsert = id
        ? userInvestmentsClient.update
        : userInvestmentsClient.create;

      const response = await upsert({
        id,
        investmentId,
        data: {
          interestAmount: -1,
          isUndecided: false,
          requestedUnits: 0,
        },
      });
      onChange(response.data);
    } catch (e) {
      const error = getServerResponseErrors(e);
      console.log(error);
    }
  };

  const { color, buttonName, variant, disabled, canPass, buttonTitle } =
    getStatusInfo({
      status,
      interestAmount,
    });
  return (
    <>
      <Box {...buttonWrapperOverrides} display="flex">
        {canPass && parseFloat(interestAmount || 0) != -1 && (
          <Button
            variant="outlined"
            onClick={handleNo}
            size={buttonSize}
            style={{ marginRight: '1rem' }}
            title="Click to pass on this opportunity"
          >
            Pass
          </Button>
        )}
        <Button
          variant={variant || 'contained'}
          color={color}
          size={buttonSize}
          onClick={() => showModal(true)}
          disabled={disabled}
          title={buttonTitle}
        >
          {buttonName}
        </Button>
      </Box>
    </>
  );
};
