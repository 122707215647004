import React from 'react';
import InsertDriveFileOutlinedIcon from '@material-ui/icons/InsertDriveFileOutlined';
import { Box } from '@material-ui/core';

const DocumentIcon = ({ count }) => {
  return (
    <Box position="relative">
      <InsertDriveFileOutlinedIcon
        color="primary"
        style={{ fontSize: '60px' }}
      />
      <Box
        position="absolute"
        top={28}
        textAlign="center"
        width="100%"
        fontWeight="bold"
        fontSize="1.25em"
      >
        {count || ''}
      </Box>
    </Box>
  );
};

export default DocumentIcon;
