import React from 'react';
import { Grid, Box, Button } from '@material-ui/core';

import getServerResponseErrors from 'api/getServerResponseErrors';

import SaveFormButton from 'components/Form/SaveFormButton';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

import useFormInvestmentVisibility from 'hooks/forms/useFormInvestmentVisibility';

const EditVisibilitySettings = ({
  data,
  dateFiledOfferingAuthorization,
  onChange,
  onClose,
}) => {
  const { id } = data;

  const { form, validate } = useFormInvestmentVisibility({
    data,
    dateFiledOfferingAuthorization,
  });

  const onSave = async (onError) => {
    try {
      const { values, errors } = await validate();
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await groupInvestmentsClient.update({
        id,
        data: values,
      });
      onChange(response.data);
      onClose();
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  return (
    <>
      <Box marginBottom={4}>
        <Box marginBottom={2}>{form}</Box>
      </Box>
      <Box marginTop={4}>
        <Grid container spacing={3} justify="space-between">
          <Grid item>
            <Button variant="contained" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton
              onSave={onSave}
              name={id ? 'Save' : 'Add'}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default EditVisibilitySettings;
