import React, { useState, useEffect } from 'react';
import {
  Grid,
  Box,
  Typography,
  makeStyles,
  CircularProgress,
  Button,
  InputAdornment,
  MenuItem,
} from '@material-ui/core';
import OpportunityCard from 'components/OpportunityCard/OpportunityCard';
import SearchIcon from '@material-ui/icons/Search';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import { useSelectedGroup } from 'hooks/useAppState';
import opportunitiesClient from 'api/opportunitiesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { CustomSelect } from 'components/ui/CustomSelect';
import { CustomTextField } from 'components/ui/CustomTextField';
import { useCreateOpportunity } from 'hooks/forms/useFormCreateOpportunity';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTab-root': {
      minWidth: '5rem',
    },
    '& .MuiFilledInput-root': {
      backgroundColor: theme.palette.common.white,
    },
  },
  findNewButton: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  searchContainer: {
    background: 'linear-gradient(105.33deg, #5E4DEF 8.65%, #5F97F7 99.32%)',
    padding: theme.spacing(2),
  },
}));

const cmp = (a, b) => {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

const OpportunitiesList = () => {
  const classes = useStyles();
  const [opportunities, setOpportunities] = useState();
  const [searchQuery, setSearchQuery] = useState();
  const [filter, setFilter] = useState('all');

  const { groupId, isAdmin } = useSelectedGroup((state) => ({
    groupId: state.id,
    isAdmin: state.isAdmin,
    slug: state.slug,
  }));

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await opportunitiesClient.index({
          groupId,
          filter: filter != 'all' ? filter : null,
        });
        // response.data[1].investmentState = 'interest';
        setOpportunities(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
        setOpportunities([]);
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId, filter]);

  const getFilteredOpportunities = () => {
    if (!Array.isArray(opportunities)) return [];
    let filteredArray = opportunities;

    if (searchQuery && !!searchQuery.length) {
      filteredArray = filteredArray.filter(
        (opp) =>
          opp.name &&
          opp.name.toLowerCase().includes(searchQuery.toLowerCase()),
      );
    }
    // if (filter === 'mine') {
    //   filteredArray = filteredArray.filter((x) =>
    //     x.investments.find((y) => y.myInvestmentId),
    //   );
    // } else if (filter === 'active') {
    //   filteredArray = filteredArray.filter((x) =>
    //     x.investments.find((y) => !y.dateClosed),
    //   );
    // }

    return filteredArray.sort(
      (a, b) =>
        cmp(
          b.groupOpportunity.status == 'Accepting Investments' ||
            b.groupOpportunity.status == 'Collecting Interest',
          a.groupOpportunity.status == 'Accepting Investments' ||
            a.groupOpportunity.status == 'Collecting Interest',
        ) || cmp(a.name.toLowerCase(), b.name.toLowerCase()),
    );
  };

  const { modal, showModal } = useCreateOpportunity({ groupId });

  if (!opportunities)
    return (
      <CenteredContentBox>
        <CircularProgress />
      </CenteredContentBox>
    );

  return (
    <Box className={classes.root}>
      <Box marginBottom={3}>
        <Grid
          container
          spacing={3}
          justify="space-between"
          alignItems="flex-end"
        >
          <Grid item>
            <Typography variant="h6">Your Investment Opportunities</Typography>
          </Grid>

          <Grid item>
            <Box>
              <Grid container spacing={3}>
                <Grid item>
                  {modal}
                  {isAdmin && (
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showModal(true)}
                    >
                      Create Opportunity
                    </Button>
                  )}
                </Grid>
                <Grid item>
                  <CustomSelect
                    size="small"
                    variant="outlined"
                    value={filter}
                    onChange={(e) => setFilter(e.target.value)}
                  >
                    <MenuItem value="all">Show All</MenuItem>
                    <MenuItem value="interested">Interested</MenuItem>
                    <MenuItem value="invested">Invested</MenuItem>
                    <MenuItem value="active">Active</MenuItem>
                  </CustomSelect>
                </Grid>
                <Grid item>
                  <CustomTextField
                    onChange={(e) => setSearchQuery(e.target.value)}
                    fullWidth
                    size="small"
                    variant="outlined"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    // endAdornment={
                    //   <CustomSelect
                    //     size="small"
                    //     variant="outlined"
                    //     placeholder="filter"
                    //     value="all"
                    //   >
                    //     <MenuItem value="all">All</MenuItem>
                    //     <MenuItem value="requested">Requested</MenuItem>
                    //     <MenuItem value="pending">Pending</MenuItem>
                    //   </CustomSelect>
                    // }
                    placeholder="Search Opportunities"
                  />
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>

      {opportunities && opportunities.length > 0 ? (
        <Grid container spacing={4} alignContent="stretch">
          {getFilteredOpportunities().map((opportunity) => (
            <Grid item xs={12} sm={6} md={4} key={opportunity.id}>
              <OpportunityCard {...{ opportunity }} />
            </Grid>
          ))}
        </Grid>
      ) : (
        <Box
          padding={3}
          height="30vh"
          bgcolor="grey.300"
          borderRadius="borderRadius"
          textAlign="center"
        >
          <CenteredContentBox>
            <Box marginBottom={2}>
              <Typography color="primary">
                No opportunities found that match your criteria
              </Typography>
            </Box>
          </CenteredContentBox>
        </Box>
      )}
    </Box>
  );
};

export default OpportunitiesList;
