import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Grid,
  Link,
  Paper,
  Tab,
  Typography,
  useTheme,
} from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@material-ui/lab';
import syndicatesClient from 'api/syndicatesClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { GroupContext } from 'components/UserAuth/UserAuth';
import userInvestmentsClient from 'api/userInvestmentsClient';
import { LoadingSpinner } from 'components/ui/Loading';
import useTaxDocument from 'views/GroupInvestment/SyndicateTaxes/useTaxDocument';
import useSyndicateDocumentPoll from 'hooks/useSyndicateDocumentPoll';
import { useHistory } from 'react-router-dom';
import moment from 'moment';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const InvestmentDetails = ({ data, initialTab }) => {
  const theme = useTheme();
  const {
    amount,
    syndicateName,
    opportunityName,
    opportunityId,
    userInvestment,
    investment,
    dateAccepted,
    groupName,
    groupId,
  } = data;
  const { getGroupSlug } = useContext(GroupContext) || {};
  const history = useHistory();

  const slug = getGroupSlug(groupId);

  const { roundName } = investment || {};
  const { acceptedUnits, entityName } = userInvestment || {};

  const [tab, setTab] = React.useState(initialTab || 'legal');
  const handleTab = (event, newValue) => {
    setTab(newValue);
  };
  return (
    <Box>
      <Box>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={7}>
            <Paper variant="outlined" style={{ height: '100%' }}>
              <Box padding={3}>
                <Box>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography> Syndicate:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Box textAlign="right">
                        <Typography>{syndicateName}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography> Group:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Box textAlign="right" whiteSpace="break-spaces">
                        <Typography>
                          <Link
                            onClick={() => history.push(`/${slug}`)}
                            style={{ cursor: 'pointer' }}
                          >
                            {groupName}
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography> Opportunity:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Box textAlign="right" whiteSpace="break-spaces">
                        <Typography>
                          <Link
                            onClick={() =>
                              history.push(
                                `/${slug}/opportunity/${opportunityId}`,
                              )
                            }
                            style={{ cursor: 'pointer' }}
                          >
                            {opportunityName}
                          </Link>
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
                <Box>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography> Round:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Box textAlign="right" whiteSpace="break-spaces">
                        <Typography>{roundName}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid>
          <Grid item xs={12} sm={5}>
            <Paper variant="outlined" style={{ height: '100%' }}>
              <Box padding={3}>
                <Box>
                  <Grid container>
                    <Grid item xs={5}>
                      <Typography> Investor Profile: </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Box textAlign="right" whiteSpace="break-spaces">
                        <Typography>{entityName}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography>Invested:</Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Box textAlign="right">
                        <Typography style={{ color: theme.palette.money.main }}>
                          {formatter.format(parseFloat(amount))}
                        </Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={5}>
                      <Typography> Units: </Typography>
                    </Grid>
                    <Grid item xs={7}>
                      <Box textAlign="right">
                        <Typography>{acceptedUnits}</Typography>
                      </Box>
                    </Grid>
                    <Grid item xs={6}>
                      <Typography> Investment Date: </Typography>
                    </Grid>
                    <Grid item xs={6}>
                      <Box textAlign="right">
                        <Typography>
                          {dateAccepted
                            ? moment(dateAccepted).utc().format('L')
                            : null}
                        </Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <Paper variant="outlined" style={{ height: '100%' }}>
              <Box padding={3}>
                <Box>
                  <Typography variant="h6" style={{ fontWeight: 'bold' }}>
                    Syndicate Investment
                  </Typography>
                </Box>
                <Box>
                  <Typography>{opportunityName}</Typography>
                </Box>
                <Box marginTop={2}>
                  <Grid container>
                    <Grid item xs={3}>
                      <Typography> Round:</Typography>
                    </Grid>
                    <Grid item xs={9}>
                      <Box textAlign="right">
                        <Typography>{roundName}</Typography>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Box>
            </Paper>
          </Grid> */}
        </Grid>
      </Box>

      <Box mt={2}>
        <TabContext value={tab}>
          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <TabList onChange={handleTab} aria-label="lab API tabs example">
              <Tab label="Legal Documents" value="legal" />
              <Tab label="Tax Documents" value="tax" />
              <Tab label="Other Documents" value="other" />
            </TabList>
          </Box>
          <TabPanel value="legal">
            <LegalDocuments {...{ userInvestment }} />
          </TabPanel>
          <TabPanel value="tax">
            <TaxDocuments {...{ userInvestment }} />
          </TabPanel>
          <TabPanel value="other">
            <OtherDocuments {...{ investment }} />
          </TabPanel>
        </TabContext>
      </Box>
    </Box>
  );
};

const LegalDocuments = ({ userInvestment }) => {
  const { subscriptionSignatureId, subscription } = userInvestment;
  const [documents, setDocuments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.get_document_references({
          id: subscriptionSignatureId,
        });
        setDocuments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (subscriptionSignatureId) {
      fetchData();
    }
  }, [subscriptionSignatureId]);

  const docs = [
    ...(documents || []),
    { ...subscription, title: 'Signature Pages' },
  ];
  return (
    <Box>
      {docs.map((data) => {
        return (
          <Box key={data.id}>
            <SyndicateDocument {...{ data }} />
          </Box>
        );
      })}
    </Box>
  );
};

const TaxDocuments = ({ userInvestment }) => {
  const { id } = userInvestment;

  const [documents, setDocuments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.taxDocuments({ id });
        setDocuments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  if (!documents) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      {documents.map((data) => {
        return (
          <Box key={data.id}>
            <TaxDocument {...{ data }} />
          </Box>
        );
      })}
    </Box>
  );
};

const TaxDocument = ({ data }) => {
  const { formName, year } = data;
  const { openLink } = useTaxDocument(data);

  return (
    <Link onClick={openLink} style={{ cursor: 'pointer' }}>
      <Typography>
        {year} - {formName}
      </Typography>
    </Link>
  );
};

const OtherDocuments = ({ investment }) => {
  const { documents } = investment;

  // const [documents, setDocuments] = useState();
  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await groupInvestmentsClient.get({ id });
  //       setDocuments(response.data);
  //     } catch (e) {
  //       console.log(getServerResponseErrors(e));
  //     }
  //   };
  //   if (id) {
  //     fetchData();
  //   }
  // }, [id]);

  if (!documents) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      {documents.map((data) => {
        return (
          <Box key={data.id}>
            <SyndicateDocument {...{ data }} />
          </Box>
        );
      })}
    </Box>
  );
};

const SyndicateDocument = ({ data }) => {
  const { id, title, name } = data;
  const { openLink } = useSyndicateDocumentPoll({ id, data });

  return (
    <Box>
      <Typography
        color="primary"
        style={{ cursor: 'pointer' }}
        onClick={openLink}
      >
        {title || name}
      </Typography>
    </Box>
  );
};

export default InvestmentDetails;
