import React, { useEffect, useRef, useState } from 'react';
import { Box, Grid, Link, Typography } from '@material-ui/core';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import Form from 'components/Form/Form';

import getServerResponseErrors from 'api/getServerResponseErrors';
import { withRouter } from 'react-router-dom';
import { FormikTextInput } from 'components/ui/CustomTextField';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import GenericPortal from 'views/GenericPortal';
import { useSelectedGroup } from 'hooks/useAppState';
import groupsClient from 'api/groups/groupsClient';
import { useDispatch } from 'react-redux';
import { signOutCurrentUser } from 'redux/currentUser/actions';
import { FormikCheckBox } from 'components/ui/CustomCheckBox';

const RequestAccess = () => {
  const { groupName, slug, id } = useSelectedGroup((state) => ({
    id: state.id,
    slug: state.slug,
    groupName: state.name,
  }));

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(signOutCurrentUser());
  }, [dispatch]);

  const [sent, setSent] = useState();
  const [formRef, setFormRef] = useState({});
  const onSave = async (onError) => {
    try {
      const {
        values: { firstName, lastName, email, isAccredited },
        errors,
      } = await validateForm(formRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await groupsClient.requestAccess({
        id,
        data: {
          firstName,
          lastName,
          email,
          isAccredited,
        },
      });
      if (response.data) {
        setSent(true);
      }

      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const submitButton = useRef(null);
  const handleSubmit = () => {
    submitButton.current.click();
  };

  if (!slug) {
    return (
      <GenericPortal title={`Request Access to ${groupName}`}>
        <CenteredContentBox>
          Unknown Portal. Go to&nbsp;
          <Link href={`/sign-in`}>Sign In</Link>
        </CenteredContentBox>
      </GenericPortal>
    );
  }

  return (
    <GenericPortal title={`Request Access to ${groupName}`}>
      {sent ? (
        <Box>
          <CenteredContentBox>
            <Typography>Request has been sent</Typography>
          </CenteredContentBox>
        </Box>
      ) : (
        <>
          <Form setRef={setFormRef} onSubmit={handleSubmit}>
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <FormikTextInput name="firstName" label="First Name" required />
              </Grid>
              <Grid item xs={12}>
                <FormikTextInput name="lastName" label="Last Name" required />
              </Grid>
              <Grid item xs={12}>
                <FormikTextInput name="email" label="Email" required />
              </Grid>
              <Grid item xs={12}>
                <FormikCheckBox
                  name="isAccredited"
                  variant="filled"
                  label="Accredited Investor"
                />
                <Box>
                  <Typography variant="caption">
                    The SEC has rules around private investments as it relates
                    to &quot;Accredited Investors&quot;. For some investment
                    offerings by this investment group, accreditation may be
                    required. Please reach out to the fund manager for more
                    information.
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Form>

          <Box marginTop={5} textAlign="center">
            <SaveFormButton
              onSave={onSave}
              name={'Request Access'}
              buttonRef={submitButton}
            ></SaveFormButton>
          </Box>
        </>
      )}
      <Box marginTop={4}>
        <CenteredContentBox>
          Already have an account?&nbsp;
          <Link href={`/${slug}/sign-in`}>Sign In</Link>
        </CenteredContentBox>
      </Box>
    </GenericPortal>
  );
};

export default withRouter(RequestAccess);
