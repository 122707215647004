import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { Box, Grid, makeStyles, Typography } from '@material-ui/core';
import Button from 'components/ui/Button/Button';

import getServerResponseErrors from 'api/getServerResponseErrors';
import taxYearsClient from 'api/taxYearsClient';
import { LoadingSpinner } from 'components/ui/Loading';

import { useArrayUpdate } from 'hooks/useListUpdate';
import syndicatesClient from 'api/syndicatesClient';
import InvestorMiniProfile from './InvestorMiniProfile';
import { DataGridPro, GridOverlay } from '@mui/x-data-grid-pro';
import { SiWebpack } from 'react-icons/si';
import { useStyles } from '@material-ui/pickers/views/Calendar/SlideTransition';
import { createStyles } from '@material-ui/styles';
import moment from 'moment';
import { DialogWithActions } from 'components/ui/DialogWithTitle';

import useEditTaxDocument from 'hooks/forms/useFormTaxDocument';
import { ManageTaxDocuments } from 'views/TaxDocuments';

const TaxYearReview = ({ investment, taxYear }) => {
  const classes = useStyles();
  const { id, syndicateId, year } = taxYear;

  const [documents, setDocuments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await taxYearsClient.documents({ id });
        setDocuments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (id) {
      fetchData();
    }
  }, [id]);

  const [userInvestments, setUserInvestments] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await syndicatesClient.investments(syndicateId);
        setUserInvestments(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (syndicateId) {
      fetchData(syndicateId);
    }
  }, [syndicateId]);

  const sendEmail = useCallback(
    async (investorIds) => {
      setUserInvestments((x) => {
        const selected = x
          .filter((x) => investorIds.includes(x.id))
          .map((x) => ({ ...x, isUpdating: true }));
        const others = x.filter((x) => !investorIds.includes(x.id));

        return [...others, ...selected];
      });
      try {
        const response = await taxYearsClient.email({
          id,
          investors: investorIds,
        });
        const documents = response.data;
        setDocuments((x) => [
          ...x.filter((x) => !documents.some((y) => y.id === x.id)),
          ...documents,
        ]);
        setTimeout(() => {
          setUserInvestments((x) => {
            const selected = x
              .filter((x) => investorIds.includes(x.id))
              .map((x) => ({ ...x, isUpdating: false }));
            const others = x.filter((x) => !investorIds.includes(x.id));

            return [...others, ...selected];
          });
        }, 250);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    },
    [id],
  );

  const columns = useMemo(() => {
    return [
      {
        field: 'name',
        headerName: 'Name',
        flex: 1,
        valueGetter: ({ row }) => row?.userInfo?.name,
        renderCell: ({ row }) => {
          return InvestorMiniProfile({ data: row });
        },
      },
      {
        field: 'dateEmailed',
        headerName: 'Last Emailed',
        flex: 1,
        valueGetter: ({ row }) => row?.dateApproved,
        renderCell: ({ row }) => {
          const { isUpdating } = row;
          const data = documents
            .filter((x) => x.userInvestmentId === row.id && x.dateEmailed)
            .map((x) => moment(x.dateEmailed));
          const lastEmailed = data.length > 0 ? moment.max(data) : null;
          // TODO: add third state for partially sent
          // TODO: treat updated documents as not sent
          const Cell = (
            <Box>
              {isUpdating ? (
                <Box marginTop={3}>
                  <LoadingSpinner />
                </Box>
              ) : lastEmailed ? (
                <Box>{lastEmailed.format()}</Box>
              ) : (
                <Box>Not Sent</Box>
              )}
            </Box>
          );
          return Cell;
        },
      },
      {
        field: 'buttons',
        headerName: ' ',
        width: 275,
        align: 'right',
        sortable: false,
        filterable: false,
        disableColumnMenu: true,
        disableExport: true,
        renderCell: ({ row }) => {
          const { isUpdating } = row;
          const Buttons = (
            <Box>
              <Button
                onClick={() => showDocuments(row)}
                style={{ marginRight: '1rem' }}
              >
                Documents
              </Button>
              <Button onClick={() => sendEmail([row.id])} disabled={isUpdating}>
                Send Email
              </Button>
            </Box>
          );
          return Buttons;
        },
      },
    ];
  }, [documents, sendEmail]);

  const [modal, showDocuments] = useState();

  const [options, showOptions] = useState(false);

  if (!documents || !userInvestments) {
    return <LoadingSpinner />;
  }

  const notSent = documents.filter((x) => x.userInvestmentId && !x.dateEmailed);

  return (
    <Box>
      <Box marginTop={2}>
        <SyndicateTaxesList
          {...{
            investment,
            documents,
            setDocuments,
            year,
          }}
        />
      </Box>
      <Box marginTop={4}>
        {options && (
          <DialogWithActions
            title={<Typography variant="h6">Email Options</Typography>}
            titleOverrides={{ style: { padding: 24, paddingBottom: 12 } }}
            bodyOverrides={{ paddingTop: 0 }}
            {...{
              onClose: () => showOptions(),
            }}
            fullWidth
          >
            <Box textAlign="center">
              <Typography variant="h6">Who do you want to email?</Typography>
            </Box>
            <Box textAlign="center" marginTop={2}>
              <Button
                onClick={() => {
                  sendEmail(userInvestments.map((x) => x.id));
                  showOptions(false);
                }}
                variant="contained"
                color="primary"
              >
                Email Everyone
              </Button>
              <Button
                onClick={() => {
                  sendEmail(userInvestments.map((x) => x.id));
                  showOptions(false);
                }}
                style={{ marginLeft: '1rem' }}
                disabled={notSent.length === 0}
                variant="contained"
              >
                Just Not Sent
              </Button>
            </Box>
          </DialogWithActions>
        )}
        <Grid container justify="space-between">
          <Grid item>
            <Typography style={{ fontSize: 20 }}>
              Investor Tax Documents
            </Typography>
            <Typography variant="caption">
              Tax filings and documents intended to only be seen by managers and
              the investor
            </Typography>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              color="primary"
              onClick={() => showOptions(true)}
            >
              Email Options
            </Button>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2}>
        {modal && (
          <DialogWithActions
            title={<Typography variant="h6">{modal.userInfo.name}</Typography>}
            titleOverrides={{ style: { padding: 24, paddingBottom: 12 } }}
            bodyOverrides={{ paddingTop: 0 }}
            {...{
              onClose: () => showDocuments(),
            }}
          >
            <TaxDocuments
              {...{
                investment,
                userInvestment: modal,
                documents: documents.filter(
                  (x) => x.userInvestmentId === modal.id,
                ),
                setDocuments,
                year,
              }}
            />
          </DialogWithActions>
        )}
        <DataGridPro
          className={classes.root}
          components={{
            NoRowsOverlay: CustomNoRowsOverlay,
          }}
          rows={userInvestments || []}
          columns={columns.filter((x) => !!x)}
          rowsPerPageOptions={[10]}
          disableSelectionOnClick
          autoHeight
          disableColumnPinning
          disableColumnSelector
          rowHeight={65}
        />
      </Box>
    </Box>
  );
};

const TaxDocuments = ({
  investment,
  userInvestment,
  documents,
  setDocuments,
  year,
}) => {
  const { syndicateClosed, syndicateId, isAdmin } = investment;
  const finalClosingYear = moment(syndicateClosed).year();
  const { id } = userInvestment;

  const { onListUpdate } = useArrayUpdate(setDocuments);

  const { modal, showModal } = useEditTaxDocument({
    syndicateId,
    userInvestmentId: id,
    onChange: onListUpdate,
    finalClosingYear,
  });
  return (
    <Box>
      <Box>
        <Grid container spacing={4} justify="space-between">
          <Grid item xs>
            <Box>
              <Typography variant="h6">Tax Documents</Typography>

              <Typography variant="caption">
                Tax filings and documents intended to only be seen by managers
                and the investor
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              {modal}

              <Box marginTop={2}>
                <Button
                  color="primary"
                  onClick={() => showModal({ form: 'other', year })}
                >
                  Add Document
                </Button>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={4}>
        <ManageTaxDocuments
          {...{
            documents,

            showModal,
            canEdit: isAdmin,
          }}
        />
      </Box>
    </Box>
  );
};

const useOverlayStyles = makeStyles((theme) =>
  createStyles({
    root: {
      flexDirection: 'column',
    },
    label: {
      marginTop: theme.spacing(1),
    },
  }),
);
const CustomNoRowsOverlay = () => {
  const classes = useOverlayStyles();

  return (
    <GridOverlay className={classes.root}>
      <SiWebpack fontSize={50} style={{ fill: 'url(#mygradient)' }} />
      <div className={classes.label}>No Results</div>
    </GridOverlay>
  );
};

const SyndicateTaxesList = ({ investment, documents, setDocuments, year }) => {
  const { syndicateId, syndicateClosed, isAdmin } = investment;
  const finalClosingYear = moment(syndicateClosed).year();

  const { onListUpdate } = useArrayUpdate(setDocuments);
  const { modal, showModal } = useEditTaxDocument({
    syndicateId,
    onChange: onListUpdate,
    finalClosingYear,
  });

  const currentYear = moment().year();
  const syndicateDocuments = documents.filter(
    (x) => x.userInvestmentId === null,
  );

  return (
    <>
      <Box>
        <Grid container justify="space-between">
          <Grid item xs>
            <Box paddingTop={2}>
              <Typography style={{ fontSize: 20 }}>
                Syndicate Tax Documents
              </Typography>
              <Typography variant="caption">
                Tax filings and documents intended to only be seen by managers
              </Typography>
            </Box>
          </Grid>
          <Grid item>
            <Box>
              {modal}
              {currentYear > finalClosingYear && (
                <Box marginTop={2}>
                  <Button
                    color="primary"
                    onClick={() => showModal({ form: 'other', year })}
                  >
                    Add Document
                  </Button>
                </Box>
              )}
            </Box>
          </Grid>
        </Grid>
      </Box>
      <Box marginTop={2}>
        <ManageTaxDocuments
          {...{
            documents: syndicateDocuments,
            showDescription: true,
            showModal,
            canEdit: isAdmin,
          }}
        />
      </Box>
    </>
  );
};

export default TaxYearReview;
