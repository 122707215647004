const downloadFile = (fileName, data) => {
  var element = document.createElement('a');
  element.setAttribute('href', data);
  element.setAttribute('download', fileName);

  element.style.display = 'none';
  document.body.appendChild(element);

  element.click();

  document.body.removeChild(element);
};

export default downloadFile;
