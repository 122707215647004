import { useCallback, useEffect, useState } from 'react';

/**
 * @description turn any handler into a debounced handler
 * @export useDebouncedFunction
 */
const useDebouncedHandler = (handler, delay = 1000) => {
  const [val, setValue] = useState();
  const debouncedHandler = useCallback((...args) => {
    setValue(args);
  }, []);
  useEffect(() => {
    const timeoutHandler = setTimeout(() => {
      handler.apply(null, val);
    }, delay);
    return () => {
      clearTimeout(timeoutHandler);
    };
  }, [handler, delay, val]);

  return debouncedHandler;
};

export default useDebouncedHandler;
