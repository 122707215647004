import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { TextField as MuiTextField, InputAdornment } from '@material-ui/core';
import * as yup from 'yup';
import { useField } from 'formik';

export const CustomTextField = (props) => {
  const {
    startAdornment,
    endAdornment,
    children,
    readOnly,
    InputProps,
    InputLabelProps,
    shrink = true,
    fullWidth = true,
    required,
    label,
    ...rest
  } = props;
  const MuiTextFieldProps = {
    startAdornment: startAdornment ? (
      <InputAdornment position="start">{startAdornment}</InputAdornment>
    ) : null,
    endAdornment: endAdornment ? (
      <InputAdornment position="end">{endAdornment}</InputAdornment>
    ) : null,
  };
  return (
    <MuiTextField
      {...rest}
      label={label ? `${label}${required ? ' *' : ''}` : null}
      InputProps={{ readOnly, ...InputProps, ...MuiTextFieldProps }}
      InputLabelProps={{ shrink, ...InputLabelProps }}
      fullWidth={fullWidth}
    >
      {children}
    </MuiTextField>
  );
};

CustomTextField.propTypes = {
  children: PropTypes.any,
  endAdornment: PropTypes.any,
  startAdornment: PropTypes.any,
  type: PropTypes.string,
};

CustomTextField.defaultProps = {
  type: 'text',
};

const numbersOnlyRegex = /^\d+$/;
export const FormikTextInput = ({
  validation = null,
  required = false,
  defaultValue = null,
  disabled = false,
  url = false,
  email = false,
  placeholder = null,
  label,
  taxid = false,
  ...props
}) => {
  let hint = placeholder;
  let scheme =
    required || url || email || taxid ? validation || yup.string() : validation;
  if (required) {
    scheme = scheme
      .required(`${label ? label : 'Field'} is required`)
      .nullable();
  }
  if (taxid) {
    scheme = scheme
      .test({
        name: 'length',
        exclusive: true,
        params: { label: label || 'Field' },
        message: `${label} is invalid`,
        test: (value) =>
          (!required && (value == null || value.length === 0)) ||
          (value && value.length === 9 && numbersOnlyRegex.test(value)),
      })
      .nullable();
    // .length(9, `${label ? label : 'Field'} is not invalid`);
  }
  if (url) {
    hint = hint || 'http://';
    scheme = scheme.nullable().test({
      name: 'urlvalidator',
      exclusive: true,
      params: { label: label || 'Field' },
      message: `${label} is invalid`,
      test: (value) => {
        if (value == null || value.length === 0) return true;
        var pattern = new RegExp(
          '^((http|ftp)s?:\\/\\/)?' + // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|' + // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))' + // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' + // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?' + // query string
            '(\\#[-a-z\\d_=.]*)?$',
          'i',
        ); // fragment locator
        return !!pattern.test(value);
      },
    });
  }
  if (email) {
    hint = hint || 'some@email.com';
    scheme = scheme.email(`${label} is invalid`);
  }
  const validate = async (value) => {
    if (disabled) {
      setError(null);
      return;
    }
    try {
      await scheme.validate(value);
    } catch (err) {
      return err.message;
    }
  };
  const [field, meta, helpers] = useField(
    scheme ? { ...props, validate } : props,
  );
  const { value, error, touched } = meta;
  const { setValue, setError } = helpers;

  const [once, setOnce] = useState(0);
  useEffect(() => {
    if (defaultValue && once === 0) {
      setOnce((x) => x + 1);
      if (!value) {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, value, setValue, once]);

  return (
    <CustomTextField
      error={error && touched}
      helperText={error && touched ? error : null}
      variant="filled"
      required={required}
      disabled={disabled}
      placeholder={hint}
      label={label}
      {...field}
      {...props}
    />
  );
};
