import React, { useState, useEffect } from 'react';
import { Switch, Route, useHistory, useRouteMatch } from 'react-router-dom';
import { Box, Grid, Typography, Button } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';

import opportunitiesClient from 'api/opportunitiesClient';

import { useSelectedGroup } from 'hooks/useAppState';
import { LoadingArea } from 'components/ui/Loading';
import InvestmentWidget from './Investment/InvestmentWidget';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import getServerResponseErrors from 'api/getServerResponseErrors';
import EditOpportunityAbout from './EditOpportunityAbout';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';

const EditOpportunity = ({ params: { id }, url, path }) => {
  const [minDelay, setDelay] = useState(false);
  const [isLoading, setLoading] = useState(true);
  const [opportunity, setOpportunity] = useState();

  // const { path, url } = useRouteMatch();
  const { slug, groupId } = useSelectedGroup((state) => ({
    groupId: state.id,
    slug: state.slug,
  }));
  const history = useHistory();
  const {
    params: { tab },
  } = useRouteMatch(`${path}/:tab`) || { params: {} };

  useEffect(() => {
    if (!tab) {
      history.replace(`${url}/about/details`);
    }
  }, [tab, url, history]);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await opportunitiesClient.get({ id, groupId });
        if (response.data.groupOpportunity) {
          setOpportunity(response.data);
        }
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      } finally {
        setLoading(false);
      }
    };
    if (id) {
      fetchData();
    }
    setDelay(true);
    const timer = setTimeout(() => {
      setDelay(false);
    }, 500);
    return () => {
      clearTimeout(timer);
    };
  }, [id, groupId]);

  const { name } = opportunity || {};

  if (isLoading || minDelay) {
    return (
      <CenteredContentBox>
        <LoadingArea style={{ backgroundColor: 'transparent' }} open />
      </CenteredContentBox>
    );
  }
  return (
    <Box>
      <Box>
        <Grid container justify="space-between">
          <Grid item>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                history.push(`/${slug}/opportunities`);
              }}
              style={{ cursor: 'pointer' }}
              startIcon={<ChevronLeftIcon fontSize="24px" />}
            >
              Back to Opportunities
            </Button>
            <Button
              variant="outlined"
              color="primary"
              endIcon={<VisibilityIcon style={{ fontSize: '1rem' }} />}
              onClick={() =>
                history.push(`${url.replace(new RegExp('/edit$'), '')}`)
              }
              style={{ marginLeft: '1rem' }}
            >
              Investor View
            </Button>
          </Grid>

          <Grid item>
            <InvestmentWidget hideSyndicateButton {...{ opportunity }} />
          </Grid>
        </Grid>
        <Box marginTop={2}>
          <Grid container justify="space-between" spacing={4}>
            <Grid item>
              <Box>
                <Typography color="primary" variant="h5" gutterBottom>
                  Edit {name ? name : 'Opportunity'}
                </Typography>
              </Box>

              <Typography variant="subtitle1">
                An opportunity can be a startup, property, or other investment
                project.
              </Typography>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <Box marginTop={4}>
        <Switch>
          <Route path={`${path}/about`}>
            <EditOpportunityAbout
              {...{ opportunity, onUpdate: setOpportunity }}
            />
          </Route>
        </Switch>
      </Box>
    </Box>
  );
};

export default EditOpportunity;
