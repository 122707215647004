import apiClient from 'api/axios';

const index = ({ syndicateId }) => {
  return apiClient.get(`/api/v1/syndicates/${syndicateId}/tax_years`, {
    params: {},
  });
};
const lookup = ({ syndicateId, year }) => {
  return apiClient.get(`/api/v1/syndicates/${syndicateId}/tax_years/lookup`, {
    params: { year },
  });
};

const get = ({ id }) => {
  return apiClient.get(`/api/v1/tax_years/${id}`, {
    params: {},
  });
};

const documents = ({ id }) => {
  return apiClient.get(`/api/v1/tax_years/${id}/documents`, {
    params: {},
  });
};

const update = ({ id, data }) => {
  return apiClient.patch(`/api/v1/tax_years/${id}`, {
    taxYear: data,
  });
};

const start = ({ id }) => {
  return apiClient.post(`/api/v1/tax_years/${id}/start`, {});
};

const confirm = ({ id }) => {
  return apiClient.post(`/api/v1/tax_years/${id}/confirm`, {});
};

const finishFederal = ({ id }) => {
  return apiClient.post(`/api/v1/tax_years/${id}/approve_federal`, {});
};

const finishState = ({ id }) => {
  return apiClient.post(`/api/v1/tax_years/${id}/approve_state`, {});
};

const email = ({ id, investors }) => {
  return apiClient.post(`/api/v1/tax_years/${id}/email`, {
    taxYear: { investors },
  });
};

const taxYearsClient = {
  index,
  lookup,
  get,
  update,
  start,
  confirm,
  documents,
  finishFederal,
  finishState,
  email,
};

export default taxYearsClient;
