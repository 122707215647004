import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  Grid,
  Box,
  Typography,
  useTheme,
  Tabs,
  withStyles,
} from '@material-ui/core';
import Form from 'components/Form/Form';

import getServerResponseErrors from 'api/getServerResponseErrors';
import userClient from 'api/user/userClient';
import { updateCurrentUserData } from 'redux/currentUser/actions';
import { FormikTextInput } from 'components/ui/CustomTextField';
import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';
import useFormUserProfile from 'hooks/forms/useFormUserProfile';
import TabPanel from 'components/ui/TabPanel/TabPanel';
import { CustomTab } from 'views/EditOpportunity/EditOpportunityAbout';
import { TypesDisplay } from 'views/UserInvestment/SubscriptionDetails/SubscriptionType';
import SubscriptionForm from 'views/UserInvestment/SubscriptionDetails/SubscriptionForm';

const StyledTab = withStyles({
  wrapper: {
    alignItems: 'flex-start',
    textAlign: 'left',
    textTransform: 'none',
  },
})((props) => <CustomTab {...props} />);

const Profile = () => {
  const theme = useTheme();
  const profile = useSelector((state) => state.currentUser.data);
  const dispatch = useDispatch();
  const [selectedType, setSelectedType] = useState('individual');
  const [loadingDefaults, setLoadingDefaults] = useState(false);
  const [investorDefaults, setInvestorDefaults] = useState();

  const { form: formProfile, validate: validateProfile } = useFormUserProfile({
    data: profile,
    showTitle: false,
  });
  const [emailRef, setEmailRef] = useState({});
  const [passwordRef, setPasswordRef] = useState({});
  const [passwordKey, setPasswordKey] = useState(0);
  const [currentTab, setCurrentTab] = React.useState(0);
  useEffect(() => {
    const getInvestDefaults = async () => {
      setLoadingDefaults(true);
      const defaultResponse = await userClient.getInvestDefaults({
        entityType: selectedType,
      });
      if (defaultResponse.data) {
        setInvestorDefaults(defaultResponse.data);
      }
      setLoadingDefaults(false);
    };

    if (selectedType) {
      getInvestDefaults();
    }
  }, [selectedType]);
  const handleTabChange = (event, newValue) => {
    setCurrentTab(newValue);
  };
  const onSave = async (onError) => {
    try {
      const { values, errors } = await validateProfile();
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await userClient.updateUserData({
        data: values,
      });
      dispatch(updateCurrentUserData(response.data));
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const onSaveEmail = async (onError) => {
    try {
      const {
        values: { email, currentPassword },
        errors,
      } = await validateForm(emailRef);
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await userClient.changeEmail({
        data: {
          email,
          currentPassword,
        },
      });
      dispatch(updateCurrentUserData(response.data));
      return true;
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const onPasswordChange = async (onError) => {
    try {
      const {
        values: { currentPassword, password, passwordConfirmation },
        errors,
      } = await validateForm(passwordRef);
      if (password !== passwordConfirmation) {
        errors.push('Passwords do not match');
      }
      if (errors.length > 0) {
        onError(errors);
        return false;
      }

      const response = await userClient.changePassword({
        data: {
          currentPassword,
          password,
          passwordConfirmation,
        },
      });
      if (response) {
        setPasswordKey((x) => x + 1);
        return true;
      }
    } catch (e) {
      let errors = getServerResponseErrors(e);
      const { currentPassword, password, passwordConfirmation } =
        e.response.data;
      if (currentPassword) {
        errors.push(
          `Current Password: ${
            Array.isArray(currentPassword)
              ? currentPassword.join(', ')
              : currentPassword
          }`,
        );
      }
      if (password) {
        errors.push(
          `Password: ${
            Array.isArray(password) ? password.join(', ') : password
          }`,
        );
      }
      if (passwordConfirmation) {
        errors.push(
          `Password Confirmation: ${
            Array.isArray(passwordConfirmation)
              ? passwordConfirmation.join(', ')
              : passwordConfirmation
          }`,
        );
      }
      onError(errors);
    }
    return false;
  };

  return (
    <>
      <Box>
        <Grid container spacing={2}>
          <Grid item>
            <Tabs
              value={currentTab}
              onChange={handleTabChange}
              orientation="vertical"
              textColor="primary"
              TabIndicatorProps={{ style: { display: 'none' } }}
            >
              <StyledTab label="Information" value={0} />
              <StyledTab label="Security" value={1} />
              <StyledTab label="Investor Profiles" value={2} />
            </Tabs>
          </Grid>
          <Grid item xs>
            <>
              <TabPanel value={currentTab} index={0}>
                <Box maxWidth={theme.breakpoints.values['md']}>
                  {formProfile}
                </Box>
                <Box marginTop={4}>
                  <SaveFormButton
                    name="Update Profile"
                    onSave={onSave}
                  ></SaveFormButton>
                </Box>
              </TabPanel>
              <TabPanel value={currentTab} index={1}>
                <Box marginTop={2}>
                  <Box marginBottom={2}>
                    <Typography variant="h6">Change Password</Typography>
                  </Box>
                  <Form key={passwordKey} setRef={setPasswordRef}>
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      container
                      spacing={3}
                      direction="column"
                    >
                      <Grid item>
                        <FormikTextInput
                          name="currentPassword"
                          variant="filled"
                          label="Current Password"
                          type="password"
                          required
                        />
                      </Grid>
                      <Grid item>
                        <FormikTextInput
                          name="password"
                          variant="filled"
                          label="New Password"
                          type="password"
                          required
                        />
                      </Grid>
                      <Grid item>
                        <FormikTextInput
                          name="passwordConfirmation"
                          variant="filled"
                          label="Confirm New Password"
                          type="password"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Form>
                </Box>
                <Box marginTop={3}>
                  <SaveFormButton
                    name="Change Password"
                    onSave={onPasswordChange}
                  ></SaveFormButton>
                </Box>
                <Box marginTop={10}>
                  <Box marginBottom={2}>
                    <Typography variant="h6">Change Email</Typography>
                  </Box>
                  <Form
                    initialValues={profile}
                    enableReinitialize
                    setRef={setEmailRef}
                  >
                    <Grid
                      item
                      xs={12}
                      sm={6}
                      md={4}
                      container
                      spacing={3}
                      direction="column"
                    >
                      <Grid item>
                        <FormikTextInput
                          name="currentPassword"
                          variant="filled"
                          label="Current Password"
                          type="password"
                          required
                        />
                      </Grid>
                      <Grid item>
                        <FormikTextInput
                          name="email"
                          variant="filled"
                          label="Email"
                          required
                        />
                      </Grid>
                    </Grid>
                  </Form>
                  <Box marginTop={3}>
                    <SaveFormButton
                      name="Change Email"
                      onSave={onSaveEmail}
                    ></SaveFormButton>
                  </Box>
                </Box>
              </TabPanel>
              <TabPanel value={currentTab} index={2}>
                <Box textAlign="center">
                  <TypesDisplay {...{ setSelectedType, selectedType }} />
                </Box>
                <Box marginTop={4}>
                  {!loadingDefaults && (
                    <SubscriptionForm
                      {...{
                        userInvestment: {
                          ...investorDefaults,
                          user: { ...profile },
                          entityType: selectedType,
                          mailing: { ...investorDefaults?.mailingAttributes },
                        },
                        isUserInvestment: false,
                        onChange: () => {},
                      }}
                    />
                  )}
                </Box>
              </TabPanel>
            </>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default Profile;
