import apiClient from 'api/axios';

const index = ({ groupInvestmentId, userId }) => {
  return apiClient.get(
    `/api/v1/group_investments/${groupInvestmentId}/investment_notes`,
    {
      params: { user_id: userId },
    },
  );
};

const create = ({ groupInvestmentId, userId, text }) => {
  return apiClient.post(
    `/api/v1/group_investments/${groupInvestmentId}/investment_notes`,
    {
      note: {
        userId,
        text,
      },
    },
  );
};

const update = ({ id, text }) => {
  return apiClient.patch(`/api/v1/investment_notes/${id}`, {
    note: { text },
  });
};

const remove = ({ id }) => {
  return apiClient.delete(`/api/v1/investment_notes/${id}`);
};

const investmentNotesClient = {
  index,
  create,
  update,
  remove,
};

export default investmentNotesClient;
