import React, { useEffect, useState } from 'react';
import { Box, Typography, Grid, Button, IconButton } from '@material-ui/core';
import GetAppIcon from '@material-ui/icons/GetApp';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import CenteredContentBox from 'components/ui/CenteredContentBox/CenteredContentBox';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import bulkInvestorImportTemplate from './bulk-investor-import-template.csv';
import downloadFile from 'services/downloadFile';
import Papa from 'papaparse';
import { useSelectedGroup } from 'hooks/useAppState';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import { nanoid } from 'nanoid';

import groupUsersClient from 'api/groupUsersClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import SaveFormButton from 'components/Form/SaveFormButton';
import { CustomTextField } from 'components/ui/CustomTextField';

import { useArrayUpdate } from 'hooks/useListUpdate';

const InviteUsers = ({ onSuccess, onClose }) => {
  const [list, updateList] = useState([{ id: nanoid() }]);

  const [step, setStep] = useState(0);

  useEffect(() => {
    console.log(list.length);
    if (list.length === 0) {
      updateList([{ id: nanoid() }]);
    } else if (list.length && list[list.length - 1]) {
      const last = { ...list[list.length - 1] };
      delete last.id;
      if (Object.values(last).some((x) => x)) {
        updateList((cache) => [...cache, { id: nanoid() }]);
      }
    }
  }, [list]);

  return step == 0 ? (
    <ReviewInvestors
      {...{
        list,
        updateList,
        onClose,
        onSuccess,
        goToBulk: () => setStep(1),
      }}
    />
  ) : (
    <BulkInvite
      {...{
        list,
        updateList,
        onBack: () => setStep(0),
      }}
    />
  );
};
const BulkInvite = ({ list, updateList, onBack }) => {
  const addList = (list) => {
    const validRows = list.filter((d) => !!d.email);

    updateList((current) => {
      const temp = [];
      validRows.forEach((row) => {
        const match =
          current.some((filtered) => filtered.email === row.email) ||
          temp.some((filtered) => filtered.email === row.email);
        if (!match) {
          temp.push(row);
        }
      });
      temp.forEach((x) => (x.id = nanoid()));
      return [...current, ...temp];
    });
  };

  const handleUpload = (fileList) => {
    Papa.parse(fileList[0].file, {
      header: true,
      complete: (result) => {
        const list = result.data;
        addList(list);
      },
    });
  };

  return (
    <Box>
      <Box marginBottom={4}>
        <Box marginBottom={2}>
          <Typography variant="subtitle1" color="primary" gutterBottom>
            Bulk Investor Invite
          </Typography>
        </Box>
        <Box marginBottom={2}>
          <Typography variant="body2">
            To invite a list of investors, you can upload a .csv file of
            investors. Please format the file with the columns in the
            &quot;Investor Invite Template&quot; and then upload the list of
            investors.
          </Typography>
        </Box>
        <Grid container spacing={3}>
          <Grid item xs>
            <Box
              textAlign="center"
              border={1}
              borderRadius="borderRadius"
              padding={3}
              borderColor="grey.400"
              height="100%"
              onClick={() =>
                downloadFile(
                  'user-invite-template.csv',
                  bulkInvestorImportTemplate,
                )
              }
            >
              <CenteredContentBox>
                <Box marginBottom={1}>
                  <Typography variant="subtitle1">
                    Download Investor Invite Template
                  </Typography>
                </Box>
                <IconButton>
                  <GetAppIcon color="primary" fontSize="large" />
                </IconButton>
              </CenteredContentBox>
            </Box>
          </Grid>
          <Grid item xs={1}>
            <CenteredContentBox color="text.secondary">
              <ArrowForwardIcon color="inherit" />
            </CenteredContentBox>
          </Grid>
          <Grid item xs>
            <Box
              textAlign="center"
              border={1}
              borderRadius="borderRadius"
              padding={3}
              borderColor="grey.400"
              style={{ borderStyle: 'dashed' }}
            >
              <DropzoneAreaBase
                onAdd={handleUpload}
                onDelete={(fileObj) => console.log('Removed File:', fileObj)}
                onAlert={(message, variant) =>
                  console.log(`${variant}: ${message}`)
                }
                dropzoneText={'Upload Investor Invite Template'}
                showAlerts={false}
                filesLimit={1}
                acceptedFiles={[
                  '.csv, text/csv, application/vnd.ms-excel, application/csv, text/x-csv, application/x-csv, text/comma-separated-values, text/x-comma-separated-values',
                ]}
              />
            </Box>
          </Grid>
        </Grid>
      </Box>

      <Box marginTop={4}>
        <Grid container justify="space-between" spacing={4}>
          <Grid item>
            <Button variant="outlined" onClick={onBack}>
              Back
            </Button>
          </Grid>
          <Grid item>
            <Button
              variant="contained"
              onClick={onBack}
              disabled={list.filter((x) => !!x.email).length == 0}
              color="primary"
            >
              Review{' '}
              {list.filter((x) => !!x.email).length > 0
                ? `(${list.filter((x) => !!x.email).length})`
                : ''}
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

const ReviewInvestors = ({
  list,
  updateList,
  onSuccess,
  onClose,
  goToBulk,
}) => {
  const { groupId } = useSelectedGroup((state) => ({
    groupId: state.id,
  }));

  const onSave = async (onError) => {
    try {
      const response = await groupUsersClient.invite({
        groupId,
        data: list.filter((x) => !!x.email),
      });
      const { success, failed } = response.data;
      updateList((cache) =>
        cache.filter((x) => !success.some((y) => y.email === x.email)),
      );
      success.forEach((x) => onSuccess(x));
      if (failed.length === 0) {
        onClose();
        return true;
      }
      onError(`Failed to invite ${failed.length} investors.`);
    } catch (e) {
      const error = getServerResponseErrors(e);
      onError(error);
    }
    return false;
  };

  const { onListUpdate } = useArrayUpdate(updateList);

  const onDelete = (item) => {
    onListUpdate({ id: item.id, isDeleted: true });
  };

  return (
    <>
      <Box marginBottom={2}>
        <Button variant="outlined" onClick={goToBulk}>
          Bulk Add (CSV)
        </Button>
      </Box>
      <Box style={{ height: '25rem', width: '100%', overflowY: 'auto' }}>
        {list.map((item) => (
          <Box display="flex" key={item.id} my={2}>
            <CustomTextField
              style={{ width: '200px', paddingRight: '1rem' }}
              label="First Name"
              value={item.firstName}
              variant="filled"
              onChange={(e) =>
                onListUpdate({
                  ...item,
                  firstName: e.target.value,
                })
              }
            />
            <CustomTextField
              style={{ width: '200px', paddingRight: '1rem' }}
              label="Last Name"
              value={item.lastName}
              variant="filled"
              onChange={(e) =>
                onListUpdate({
                  ...item,
                  lastName: e.target.value,
                })
              }
            />
            <CustomTextField
              style={{ width: '400px', paddingRight: '1rem' }}
              label="Email"
              value={item.email}
              variant="filled"
              onChange={(e) => onListUpdate({ ...item, email: e.target.value })}
            />
            <IconButton
              style={{ margin: '0.25rem' }}
              onClick={() => onDelete(item)}
            >
              <DeleteForeverIcon />
            </IconButton>
          </Box>
        ))}
      </Box>

      <Box marginTop={4}>
        <Grid container justify="space-between" spacing={4}>
          <Grid item>
            <Button variant="outlined" onClick={onClose}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton
              onSave={onSave}
              disabled={list.filter((x) => !!x.email).length == 0}
              variant="contained"
              name={`Invite (${list.filter((x) => !!x.email).length})`}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default InviteUsers;
