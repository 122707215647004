import React from 'react';

import {
  Backdrop,
  Box,
  CircularProgress,
  withStyles,
  makeStyles,
} from '@material-ui/core';

import CenteredContentBox from './CenteredContentBox/CenteredContentBox';

const useStyles = makeStyles(() => ({
  root: {},
}));

export const LoadingSpinner = (props) => {
  const classes = useStyles();
  return (
    <CenteredContentBox className={classes.root}>
      <CircularProgress {...props} />
    </CenteredContentBox>
  );
};

const LimitedBackdrop = withStyles({
  root: {
    position: 'absolute',
    zIndex: 100,
  },
})(Backdrop);

export const LoadingArea = ({
  children = <CircularProgress color="inherit" />,
  ...others
}) => {
  return (
    <Box>
      <LimitedBackdrop {...others}>{children}</LimitedBackdrop>
    </Box>
  );
};
