import React from 'react';
import { Grid, Typography, Button, Paper } from '@material-ui/core';
import DisplayAddress from 'components/ui/DisplayAddress';

const AddressCard = ({ business, editClick }) => {
  return (
    <Paper
      variant="outlined"
      square
      style={{ padding: '1rem', height: '100%' }}
    >
      <Grid container justify="space-between">
        <Grid item>
          <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
            Principal Business Address:
          </Typography>
          <Typography variant="subtitle1">
            <DisplayAddress {...{ address: business }} />
          </Typography>
        </Grid>
        <Grid item>
          <Button color="primary" size="small" onClick={editClick}>
            Edit
          </Button>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default AddressCard;
