import React, { useEffect, useState } from 'react';
import { isEqual, isNil, transform, isObject } from 'lodash';
import { Box } from '@material-ui/core';
import { FaFileInvoiceDollar, FaCheckDouble } from 'react-icons/fa';
import PeopleIcon from '@material-ui/icons/People';
import CloudIcon from '@material-ui/icons/Cloud';
import BusinessIcon from '@material-ui/icons/Business';

import SyndicateFormBusiness from './SyndicateFormBusiness';
import SyndicateFormFees from './SyndicateFormFees';
import SyndicateFormReview from './SyndicateFormReview';
import SyndicateFormManagers from './SyndicateFormManagers';
import SyndicateFormBlueSky from './SyndicateFormBlueSky';
import Header from 'components/ui/wizard/Header';
import CustomizedSteppers from 'components/ui/CustomStepper';
import groupInvestmentsClient from 'api/groupInvestmentsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingSpinner } from 'components/ui/Loading';
import { useSelectedGroup } from 'hooks/useAppState';

import MailIcon from '@material-ui/icons/Mail';
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import SyndicateFormOpportunity from './SyndicateFormOpportunity';
import SyndicateFormIntro from './SyndicateFormIntro';
import { HiDocumentSearch } from 'react-icons/hi';
import SyndicateServiceAgreement from './SyndicateServiceAgreement';
import SyndicateStateDocuments from './SyndicateStateDocuments';
import SyndicateAuthorizationDocuments from './SyndicateAuthorzationDocuments';

const defaultSteps = [
  {
    id: 'opportunity',
    name: 'Opportunity',
    icon: <InsertDriveFileIcon />,
  },
  {
    id: 'managers',
    name: 'Managers',
    icon: <PeopleIcon />,
  },
  {
    id: 'business',
    name: 'Address',
    icon: <MailIcon />,
  },
  {
    id: 'bluesky',
    name: 'Blue Sky',
    icon: <CloudIcon />,
  },
  {
    id: 'fees',
    name: 'Software Fees',
    icon: <FaFileInvoiceDollar />,
  },
  {
    id: 'review',
    name: 'Review',
    icon: <FaCheckDouble />,
  },
  {
    id: 'agreements',
    name: 'Service Documents',
    icon: <HiDocumentSearch />,
  },
  {
    id: 'authorization',
    name: 'Formation Authorization',
    icon: <HiDocumentSearch />,
  },
  {
    id: 'organization',
    name: 'Formation Filings',
    icon: <BusinessIcon />,
  },
];
function deepOmit(obj) {
  function omitFromObject(obj) {
    // the inner function which will be called recursivley
    return transform(obj, function (result, value, key) {
      // transform to a new object
      if (isNil(value)) return;

      result[key] = isObject(value) ? omitFromObject(value) : value; // if the key is an object run it through the inner function - omitFromObject
    });
  }

  return omitFromObject(obj); // return the inner function result
}

const SyndicateFormationFlow = ({
  syndicate,
  setSyndicate,
  investment,
  setInvestment,
  opportunityId,
  onClose,
  onComplete,
  onBack,
  defaultStep,
}) => {
  const {
    business: defaultAddress,
    groupId,
    smartcapitalFeeBase,
    smartcapitalFeePerMember,
    smartcapitalFeePercent,
  } = useSelectedGroup((state) => ({
    business: state.business,
    groupId: state.id,
    smartcapitalFeeBase: state.smartcapitalFeeBase,
    smartcapitalFeePerMember: state.smartcapitalFeePerMember,
    smartcapitalFeePercent: state.smartcapitalFeePercent,
  }));

  const { id: investmentId, syndicateId } = investment || {};
  const [data, setData] = useState();

  const [isReviewing, setReviewing] = useState(false);
  const [step, setStep] = useState(
    isReviewing && !defaultStep ? 'review' : defaultStep || 'intro',
  );
  const [steps, setSteps] = useState(defaultSteps);

  useEffect(() => {
    if (step == 'review') {
      setReviewing(true);
    }
  }, [step]);
  const goStep = async (step, val, isBack) => {
    if (val) {
      const temp = deepOmit(val);
      setData((cache) => ({ ...cache, ...temp, step }));
    }
    setStep((prevState) => {
      const updatedSteps = [...steps];
      const prevIndex = updatedSteps.map((x) => x.id).indexOf(prevState);
      if (!isBack && prevIndex !== -1) {
        updatedSteps[prevIndex] = {
          ...updatedSteps[prevIndex],
          completed: true,
        };
      }
      setSteps(updatedSteps);
      return isReviewing ? 'review' : step;
    });
  };
  const nextStep = (step) => {
    return async (val) => {
      await goStep(step, val);
    };
  };
  const prevStep = (step) => {
    return async (val) => {
      await goStep(step, val, true);
    };
  };

  useEffect(() => {
    if (data) {
      return;
    }
    const { syndicateData } = investment || {};
    if (syndicate) {
      const { useSmartCapitalAccount } = investment;
      const {
        id,
        dateFiledOfferingAuthorization,
        dateFiledReviewed,
        dateFiledFormationService,
        dateFiledFormationState,
        dateFiledFormationAuthorization,
        primaryManager,
        business,
        blueSky,

        managementFeeBase,
        managementFeePerMember,
        managementFeePercent,
        carryAmount,
        unitPrice,
        minimumUnits,
        initialClosing,
        finalClosing,
        syndicateName,
        registeredState,
      } = syndicate;
      setData({
        registeredState,
        id,
        dateFiledOfferingAuthorization,
        dateFiledReviewed,
        dateFiledFormationService,
        dateFiledFormationState,
        dateFiledFormationAuthorization,
        primaryManager: primaryManager ? primaryManager.groupManager : null,
        business,
        blueSky,
        shouldFile: blueSky && blueSky.length > 0,

        managementFeeBase,
        managementFeePerMember,
        managementFeePercent,
        carryAmount,
        unitPrice,
        minimumUnits,
        initialClosing,
        finalClosing,
        syndicateName,

        useSmartCapitalAccount,
      });
      let lastStep = 'agreements';
      if (!dateFiledFormationService) {
        setStep('agreements');
      } else if (!dateFiledFormationAuthorization) {
        setStep('authorization');
        lastStep = 'authorization';
      } else if (!dateFiledFormationState) {
        setStep('organization');
        lastStep = 'organization';
      }
      const updatedSteps = [...steps];
      const prevIndex = updatedSteps.map((x) => x.id).indexOf(lastStep);
      for (let index = 0; index < prevIndex; index++) {
        updatedSteps[index] = {
          ...updatedSteps[index],
          completed: true,
        };
      }
      setSteps(updatedSteps);
    } else if (investment && syndicateData) {
      const { step: lastStep } = syndicateData;
      setData(syndicateData);
      let lastStepId = lastStep || 'intro';

      const updatedSteps = [...steps];
      const prevIndex = updatedSteps.map((x) => x.id).indexOf(lastStepId);
      for (let index = 0; index < prevIndex; index++) {
        updatedSteps[index] = {
          ...updatedSteps[index],
          completed: true,
        };
      }
      setSteps(updatedSteps);
      setStep(lastStepId);
    } else {
      // eslint-disable-next-line no-unused-vars
      const { id: _id, ...address } = defaultAddress || {};
      setData({
        registeredState: 'CO',
        unitPrice: 5000,
        business: address,
        managementFeeBase: null,
        carryAmount: 20,
        opportunityId,
      });
    }
  }, [
    data,
    defaultAddress,
    investment,
    opportunityId,
    steps,
    syndicate,
    syndicateId,
  ]);
  useEffect(() => {
    const { id, syndicateData, syndicateId } = investment || {};
    if (id && !syndicateId && data && !isEqual(data, syndicateData)) {
      const saveProgress = async () => {
        try {
          await groupInvestmentsClient.update({
            id,
            data: {
              syndicateData: data,
            },
          });
        } catch (e) {
          console.log(getServerResponseErrors(e));
        }
      };
      saveProgress();
    }
  }, [data, investment, setInvestment, syndicateId]);
  if (!data) {
    return <LoadingSpinner />;
  }

  const handleInvest = handleInvestment(
    investment,
    groupId,
    investmentId,
    setInvestment,
  );

  const handleComplete = (...args) => {
    setSteps(defaultSteps);
    onComplete(...args);
  };
  const handleClose = (...args) => {
    setSteps(defaultSteps);
    onClose(...args);
  };

  let stepContent = <Box>Initial Step</Box>;
  switch (step) {
    case 'intro':
      stepContent = (
        <SyndicateFormIntro
          {...{
            onNext: nextStep('opportunity'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'opportunity':
      stepContent = (
        <SyndicateFormOpportunity
          {...{
            groupId,
            opportunityId,
            data,
            investment,
            onNext: handleInvest,
            // onBack: isReviewing ? goStep : prevStep('name'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'managers':
      stepContent = (
        <SyndicateFormManagers
          {...{
            groupId,
            data,
            onNext: nextStep('business'),

            onBack: isReviewing ? goStep : prevStep('opportunity'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'business':
      stepContent = (
        <SyndicateFormBusiness
          {...{
            groupId,
            data,
            onNext: nextStep('bluesky'),

            onBack: isReviewing ? goStep : prevStep('managers'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'bluesky':
      stepContent = (
        <SyndicateFormBlueSky
          {...{
            groupId,
            data,
            onNext: nextStep('fees'),
            onBack: isReviewing ? goStep : prevStep('business'),
            onClose: handleClose,
          }}
        />
      );
      break;
    case 'fees':
      stepContent = (
        <SyndicateFormFees
          {...{
            data,
            onNext: nextStep('review'),
            smartcapitalFeeBase,
            smartcapitalFeePerMember,
            smartcapitalFeePercent,
            onBack: isReviewing ? goStep : prevStep('bluesky'),
            onClose: handleClose,
            groupId,
            showManagementFees: false,
          }}
        />
      );
      break;
    case 'review':
      stepContent = (
        <SyndicateFormReview
          {...{
            investment,
            data,
            setStep,
            onComplete: (data) => {
              setInvestment(data.groupInvestment);
              nextStep('agreements');
            },
            setInvestment,
            onClose: handleClose,
            onBack,
            showOfferingInfo: false,
            smartcapitalFeeBase,
            smartcapitalFeePerMember,
            smartcapitalFeePercent,
          }}
        />
      );
      break;
    case 'agreements':
      stepContent = (
        <SyndicateServiceAgreement
          {...{
            syndicate,
            setSyndicate,
          }}
        />
      );
      break;
    case 'authorization':
      stepContent = (
        <SyndicateAuthorizationDocuments
          {...{
            investment,
            syndicate,
            setSyndicate,
          }}
        />
      );
      break;
    case 'organization':
      stepContent = (
        <SyndicateStateDocuments
          {...{
            investment,
            syndicate,
            setSyndicate: handleComplete,
          }}
        />
      );
      break;

    default:
      break;
  }
  return (
    <>
      {step !== 'intro' && (
        <Header>
          <CustomizedSteppers
            steps={steps}
            activeStep={steps.map((x) => x.id).indexOf(step)}
          />
        </Header>
      )}
      {stepContent}
    </>
  );
};

export default SyndicateFormationFlow;
export const handleInvestment = (
  investment,
  groupId,
  investmentId,
  setInvestment,
  step = 'managers',
) => {
  return async (val, onError) => {
    try {
      const { opportunityId, targetAmount, roundId } = val;
      const { syndicateData = {} } = investment || {};
      const { data: currentInvestment } =
        await groupInvestmentsClient.currentInvestment({
          groupId,
          opportunityId,
        });
      const investId = currentInvestment?.id || investmentId;
      const upsert =
        currentInvestment?.id || investmentId
          ? groupInvestmentsClient.update
          : groupInvestmentsClient.create;
      const response = await upsert({
        id: investId,
        groupId,
        opportunityId,
        data: {
          ...investment,
          ...val,
          targetAmount,
          roundId,
          syndicateData: investmentId
            ? { ...syndicateData, ...val?.syndicateData, step }
            : { step, ...val?.syndicateData },
          acceptWires: true,
        },
      });
      setInvestment(response.data);
    } catch (e) {
      onError(getServerResponseErrors(e));
    }
  };
};
