import PropTypes from 'prop-types';
import React, { useState } from 'react';
import { Paper, makeStyles, Box, Collapse } from '@material-ui/core';
import cn from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import Button from 'components/ui/Button/Button';
import { useDispatch } from 'react-redux';
import { updateCurrentUserData } from 'redux/currentUser/actions';
import userClient from 'api/user/userClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { useProfile } from 'hooks/useAppState';

const useStyles = makeStyles((theme) => ({
  root: {
    background: 'linear-gradient(105.33deg, #5E4DEF 8.65%, #5F97F7 99.32%)',
    color: theme.palette.common.white,
    padding: theme.spacing(3),
    position: 'relative',
  },
  closeIcon: {
    position: 'absolute',
    top: theme.spacing(3),
    right: theme.spacing(3),
    cursor: 'pointer',
  },
}));

const UserOnboardingMessage = ({ type, dismissField, children }) => {
  const id = useProfile((state) => state.id);
  const dispatch = useDispatch();
  const classes = useStyles();
  const className = cn(classes.root, { [classes[type]]: type });
  const [show, setShow] = useState(true);

  const handleMessageDismiss = async () => {
    try {
      const response = await userClient.updateUserData({
        id,
        data: {
          [dismissField]: true,
        },
      });
      await dispatch(updateCurrentUserData(response.data));
      setShow(false);
    } catch (e) {
      console.log(getServerResponseErrors(e));
    }
  };
  return (
    <Collapse in={show}>
      <Box marginBottom={4}>
        <Paper className={className}>
          {children}
          <Box marginTop={2}>
            <Button variant="contained" onClick={handleMessageDismiss}>
              Dismiss
            </Button>
          </Box>
          <Box className={classes.closeIcon}>
            <CloseIcon onClick={handleMessageDismiss} />
          </Box>
        </Paper>
      </Box>
    </Collapse>
  );
};

UserOnboardingMessage.propTypes = {
  children: PropTypes.any,
  dismissible: PropTypes.any,
  onDismiss: PropTypes.any,
  type: PropTypes.any,
};

export default UserOnboardingMessage;
