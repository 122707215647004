import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Button, Grid, Typography, useTheme } from '@material-ui/core';
import { LoadingArea } from './Loading';
import { Alert } from '@material-ui/lab';
import CenteredContentBox from './CenteredContentBox/CenteredContentBox';
import { DialogWithActions } from './DialogWithTitle';

const ActionWithConfirm = ({
  action = 'Delete',
  details = null,
  children,
  onAction,
  AlertWrapper = CenteredContentBox,
  buttonProps = null,
  saveOverrides = null,
  showWarningText = true,
}) => {
  const theme = useTheme();
  const [actionConfirm, showConfirm] = useState(false);
  const [isSaving, showSave] = useState(false);
  const [error, setError] = useState();

  const actionButton = (
    <Button
      variant="outlined"
      onClick={() => showConfirm(true)}
      style={{ color: theme.palette.accent.main }}
      {...buttonProps}
    >
      {action}
    </Button>
  );
  const onError = (error) => {
    console.log(error);
    const message = Array.isArray(error) ? error.join(', ') : error;
    setError(message);
    showSave(false);
  };
  return (
    <Box>
      {isSaving && <LoadingArea open />}
      {actionConfirm ? (
        <Box>
          {showWarningText && (
            <Box textAlign="center" padding={4} paddingTop={0}>
              <Typography
                variant="h5"
                style={{ color: theme.palette.accent.main }}
              >
                {action} Confirmation
              </Typography>
              <Typography>Are you sure you want to proceed?</Typography>
              {details}
            </Box>
          )}
          <Box>
            <Grid container justify="space-around">
              <Grid item>
                <Button onClick={() => showConfirm(false)}>Cancel</Button>
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => {
                    showSave(true);
                    onAction(onError);
                  }}
                  {...saveOverrides}
                >
                  {action}
                </Button>
              </Grid>
            </Grid>
          </Box>
          {error && (
            <AlertWrapper open onClose={() => setError()}>
              <Box marginTop={2}>
                <Alert severity="error" onClose={() => setError()}>
                  <div>There was an error: {error}</div>
                </Alert>
              </Box>
            </AlertWrapper>
          )}
        </Box>
      ) : (
        <Box style={{ display: actionConfirm ? 'none' : '' }}>
          {children && typeof children === 'function'
            ? children({
                actionButton,
                showConfirm,
                actionConfirm,
              })
            : children}
        </Box>
      )}
    </Box>
  );
};

export const useConfirmModal = ({
  action = 'Delete',
  details = null,
  onAction,
  AlertWrapper = CenteredContentBox,
  buttonProps = null,
  saveOverrides = null,
  showWarningText = true,
}) => {
  const theme = useTheme();
  const [modal, showModal] = useState();
  const [isSaving, showSave] = useState(false);
  const [error, setError] = useState();

  useEffect(() => {
    showSave(false);
    setError();
  }, [modal]);

  const actionButton = useMemo(
    () => (
      <Button
        variant="outlined"
        onClick={() => showModal(true)}
        style={{ color: theme.palette.accent.main }}
        {...buttonProps}
      >
        {action}
      </Button>
    ),
    [action, buttonProps, theme.palette.accent.main],
  );
  const onError = useCallback((error) => {
    console.log(error);
    const message = Array.isArray(error) ? error.join(', ') : error;
    setError(message);
    showSave(false);
  }, []);

  const modalElement = useMemo(() => {
    const footer = (
      <Box margin={4}>
        {error && (
          <AlertWrapper open onClose={() => setError()}>
            <Box marginTop={2}>
              <Alert severity="error" onClose={() => setError()}>
                <div>There was an error: {error}</div>
              </Alert>
            </Box>
          </AlertWrapper>
        )}
        <Box>
          <Grid container justify="space-around" spacing={3}>
            <Grid item>
              <Button onClick={() => showModal(false)}>Cancel</Button>
            </Grid>
            <Grid item>
              <Button
                variant="contained"
                color="primary"
                onClick={() => {
                  showSave(true);
                  onAction(onError);
                }}
                {...saveOverrides}
              >
                {action}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        {...{
          title: `${modal.id ? 'Edit' : 'Add'} Document`,
          // header,
          footer,
          onClose: () => showModal(),
          fullWidth: true,
        }}
      >
        <Box>
          {isSaving && <LoadingArea open />}

          <Box>
            {showWarningText && (
              <Box textAlign="center" padding={4} paddingTop={0}>
                <Typography
                  variant="h5"
                  style={{ color: theme.palette.accent.main }}
                >
                  {action} Confirmation
                </Typography>
                <Typography>Are you sure you want to proceed?</Typography>
                {details}
              </Box>
            )}
          </Box>
        </Box>
      </DialogWithActions>
    ) : null;
  }, [
    action,
    details,
    error,
    isSaving,
    modal,
    onAction,
    onError,
    saveOverrides,
    showWarningText,
    theme.palette.accent.main,
  ]);

  return { confirmModal: modalElement, showConfirm: showModal, actionButton };
};

export default ActionWithConfirm;
