import apiClient from 'api/axios';

const index = ({ groupId }) => {
  return apiClient.get(`/api/v1/groups/${groupId}/payment_checks`, {
    params: {},
  });
};
const create = ({ groupId, data }) => {
  return apiClient.post(`/api/v1/groups/${groupId}/payment_checks`, {
    paymentCheck: data,
  });
};

const get = ({ id }) => apiClient.get(`/api/v1/payment_checks/${id}`, {});

const update = ({ id, data }) =>
  apiClient.patch(`/api/v1/payment_checks/${id}`, { paymentCheck: data });

const paymentChecksClient = {
  index,
  get,
  create,
  update,
};

export default paymentChecksClient;
