import React, { useEffect, useState } from 'react';
import {
  Grid,
  Box,
  Typography,
  Button,
  Paper,
  MenuItem,
  Link,
} from '@material-ui/core';

import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingArea } from 'components/ui/Loading';

import { CustomCheckBox } from 'components/ui/CustomCheckBox';
import { CustomSelect } from 'components/ui/CustomSelect';
import groupsClient from 'api/groups/groupsClient';
import { useArrayUpdate } from 'hooks/useListUpdate';

import { useEditPaymentWire } from 'hooks/forms/useFormPaymentWire';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

const SyndicatePaymentWire = ({ investment, setInvestment }) => {
  const { id, groupId, paymentWire, acceptWires } = investment;

  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const { onListUpdate: updateListItem } = useArrayUpdate(setList);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.wires({
          id: groupId,
        });
        setList(response.data);
        setLoading(false);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  const handleAccept = (e) => {
    const doChange = async (val) => {
      const response = await groupInvestmentsClient.update({
        id,
        data: {
          acceptWires: val,
        },
      });
      setLoading(false);
      setInvestment(response.data);
    };
    const val = e.target.checked;
    setLoading(true);
    doChange(val);
  };

  const handleSelection = (val) => {
    const doChange = async () => {
      const response = await groupInvestmentsClient.update({
        id,
        data: {
          paymentWireId: val.id,
        },
      });
      setLoading(false);
      setInvestment(response.data);
    };
    setLoading(true);
    doChange();
  };

  const { modal, showModal } = useEditPaymentWire({
    groupId,
    onChange: (data) => {
      updateListItem(data);
      handleSelection(data);
    },
  });

  return (
    <Box position="relative">
      {modal}
      {loading && (
        <Box>
          <LoadingArea open />
        </Box>
      )}
      <Box
        borderBottom="1px solid lightgray"
        style={{ paddingBottom: '0.5rem' }}
        marginTop={4}
      >
        <Grid container justify="space-between">
          <Grid item>
            <Typography color="primary">Wires</Typography>
          </Grid>
          <Grid item>
            <CustomCheckBox
              label={
                <Box>
                  <Typography variant="subtitle1">Accept Wires?</Typography>
                </Box>
              }
              checked={acceptWires}
              onChange={handleAccept}
              disabled={loading}
              indeterminate={loading}
            />
          </Grid>
        </Grid>
      </Box>
      {acceptWires && (
        <Box marginTop={2}>
          <Paper>
            <Box padding={3}>
              <Grid container justify="space-between">
                <Grid item>
                  <Box>
                    <Grid container spacing={3}>
                      <Grid item>
                        <Box minWidth="20rem">
                          <CustomSelect
                            variant="filled"
                            label="Select Wire Account"
                            required
                            value={paymentWire?.id}
                            onChange={(e) => {
                              const val = list.find(
                                (x) => x.id == e.target.value,
                              );
                              handleSelection(val);
                            }}
                            disabled={loading}
                          >
                            {(list || []).map((item) => (
                              <MenuItem key={item.id} value={item.id}>
                                {item.name}
                              </MenuItem>
                            ))}
                          </CustomSelect>
                        </Box>
                      </Grid>
                      {paymentWire && (
                        <Grid item>
                          <Box marginTop={2} style={{ cursor: 'pointer' }}>
                            <Typography>
                              <Link
                                onClick={() =>
                                  showModal(
                                    list.find((x) => x.id == paymentWire.id),
                                  )
                                }
                              >
                                Edit
                              </Link>
                            </Typography>
                          </Box>
                        </Grid>
                      )}
                    </Grid>
                  </Box>
                </Grid>
                <Grid item>
                  <Box marginTop={1}>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => showModal({})}
                    >
                      Add New
                    </Button>
                  </Box>
                </Grid>
              </Grid>
              {paymentWire && (
                <Box marginTop={2}>
                  <Grid container spacing={2}>
                    <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                      Bank Name
                    </Grid>
                    <Grid item xs={8}>
                      {list.find((x) => x.id == paymentWire.id)?.name}
                    </Grid>
                    <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                      Routing #
                    </Grid>
                    <Grid item xs={8}>
                      {paymentWire?.routingNumber}
                    </Grid>
                    <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                      Account #
                    </Grid>
                    <Grid item xs={8}>
                      {paymentWire?.accountNumber}
                    </Grid>
                  </Grid>
                </Box>
              )}
            </Box>
          </Paper>
        </Box>
      )}
    </Box>
  );
};

export default SyndicatePaymentWire;
