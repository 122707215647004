import apiClient from 'api/axios';

const index = ({ groupId, userId }) => {
  return apiClient.get(`/api/v1/groups/${groupId}/investor_notes`, {
    params: { user_id: userId },
  });
};

const create = ({ groupId, userId, text }) => {
  return apiClient.post(`/api/v1/groups/${groupId}/investor_notes`, {
    note: {
      userId,
      text,
    },
  });
};

const update = ({ id, text }) => {
  return apiClient.patch(`/api/v1/investor_notes/${id}`, {
    note: { text },
  });
};

const remove = ({ id }) => {
  return apiClient.delete(`/api/v1/investor_notes/${id}`);
};

const investorNotesClient = {
  index,
  create,
  update,
  remove,
};

export default investorNotesClient;
