import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Typography,
  Paper,
  TableContainer,
  Table,
  TableHead,
  TableCell,
  TableRow,
  TableBody,
  RadioGroup,
  Radio,
} from '@material-ui/core';

import FormWizard from './FormWizard';
import groupsClient from 'api/groups/groupsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingArea } from 'components/ui/Loading';

import regionData from 'country-region-data';
import useTableSort from 'hooks/useTableSort';
import { CustomCheckBox } from 'components/ui/CustomCheckBox';

const blueSkyRegions = regionData.find(
  (x) => x.countryShortCode == 'US',
).regions;

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const useSyndicateFormBlueSky = ({ groupId, data }) => {
  const { blueSky = [], shouldFile = true } = data || {};
  const validate = async () => {
    return {
      errors: [],
      values: {
        blueSky: filing,
        shouldFile: file == '1',
      },
    };
  };

  const [filing, setFiling] = useState(blueSky);
  const [blueSkyData, setBlueSky] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await groupsClient.blueSkyInfo({ id: groupId });
        setBlueSky(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (groupId) {
      fetchData();
    }
  }, [groupId]);

  useEffect(() => {
    if (blueSky && blueSky.length != 0) {
      setFiling(blueSky);
    }
  }, [blueSky, blueSkyData]);

  const columns = useMemo(
    () => [
      { id: 'state', align: 'left', label: 'State', padding: 'none' },
      { id: 'investors', align: 'left', label: 'Investors' },
      { id: 'fee', align: 'left', label: 'State Fee' },
    ],
    [],
  );

  // @ts-ignore
  const { fees = [], locations, baseFee } = blueSkyData || {};
  const rows = useMemo(() => {
    return fees.map((fee) => {
      const region = blueSkyRegions.find((x) => x.shortCode == fee.state);
      return {
        id: fee.id,
        shortCode: fee.state,
        state: region.name,
        investors: locations[fee.state.toLowerCase()] || 0,
        fee: parseFloat(fee.maxFee),
      };
    });
  }, [fees, locations]);

  const { pagedItems, headerCells } = useTableSort({
    columns,
    rows,
    pageCount: 0,
    defaultOrderBy: 'investors',
    defaultOrder: 'desc',
  });

  const estimate = useMemo(() => {
    const temp = filing.reduce((acc, val) => {
      const fee = fees.find((x) => x.state == val) || { maxFee: 0 };
      return acc + parseFloat(fee.maxFee);
    }, 0);
    return temp > 0 ? temp + parseFloat(baseFee) : 0;
  }, [fees, filing, baseFee]);

  const [file, setFile] = useState(shouldFile ? '1' : '0');

  const form = (
    <>
      <Box marginTop={2}>
        <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
          Which states do you want to file with?
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Typography variant="subtitle2">
          Blue Sky Laws are state-level securities regulations requiring issuers
          of securities to be registered and disclose details of their offerings
          in the state where their investors reside. Each state has different
          filing requirements and fees. The manager can select which states to
          file using the checkboxes below or can file themselves.
        </Typography>
      </Box>
      <Box marginTop={2} overflow="auto">
        {!blueSkyData ? (
          <LoadingArea open />
        ) : (
          <RadioGroup
            name="useCustomDocuments"
            value={file}
            onChange={(e) => {
              setFile(e.target.value);
            }}
          >
            <Box
              display="flex"
              style={{ cursor: 'pointer' }}
              onClick={() => {
                document.getElementById('file-1').click();
              }}
            >
              <Radio
                id="file-1"
                name="file"
                value="1"
                style={{ alignItems: 'start' }}
              />
              <Box marginTop={1}>
                <Typography variant="subtitle1">
                  Select the states for which you want SmartCapital to file per
                  Blue Sky Laws initially. The corresponding fee for each state
                  is listed in the table below.{' '}
                  {baseFee && (
                    <>
                      The estimated cost is a base fee of{' '}
                      {formatter.format(baseFee)} plus each state&apos;s fee{' '}
                    </>
                  )}
                </Typography>
                <Box
                  position="relative"
                  marginTop={2}
                  style={{ maxWidth: '45rem' }}
                >
                  {file == '1' && (
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: '14rem' }}
                    >
                      <Table stickyHeader size="small">
                        <TableHead>
                          <TableRow>
                            <TableCell
                              size="small"
                              padding="checkbox"
                            ></TableCell>
                            {headerCells}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {pagedItems.map((row) => (
                            <FeeRow
                              key={row.id}
                              {...{ filing, row, setFiling }}
                            />
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )}
                </Box>
              </Box>
            </Box>

            <Box
              display="flex"
              style={{ cursor: 'pointer' }}
              marginTop={2}
              onClick={() => {
                document.getElementById('file-0').click();
              }}
            >
              <Radio
                id="file-0"
                name="file"
                value="0"
                style={{ alignItems: 'start' }}
              />
              <Box marginTop={1}>
                <Typography variant="subtitle1">
                  The manager will file independently with each state for which
                  investors reside according to Blue Sky Laws.
                </Typography>
              </Box>
            </Box>
          </RadioGroup>
        )}
      </Box>
    </>
  );

  return { form, validate, estimate: file == '1' ? estimate : 0 };
};

const FeeRow = ({ filing, row, setFiling }) => {
  const { shortCode, state, investors, fee } = row;
  const checked = !!filing.find((x) => x == shortCode);

  return (
    <TableRow key={fee.id}>
      <TableCell size="small" padding="checkbox">
        <CustomCheckBox
          size="small"
          InputLabelProps={{ margin: 'none' }}
          checked={checked}
          onChange={() => {
            setFiling((cache) => {
              const temp = [...cache.filter((x) => x != shortCode)];
              if (!checked) {
                temp.push(shortCode);
              }
              return temp;
            });
          }}
        ></CustomCheckBox>
      </TableCell>
      <TableCell align="left" padding="none">
        <Box>{state}</Box>
      </TableCell>
      <TableCell>{investors > 0 ? investors : '-'}</TableCell>
      <TableCell>{formatter.format(fee)}</TableCell>
    </TableRow>
  );
};

const SyndicateFormBlueSky = ({
  groupId,
  data,
  onNext,
  onBack = null,
  onClose,
  buttonName,
}) => {
  const { form, validate, estimate } = useSyndicateFormBlueSky({
    groupId,
    data,
  });
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext,
        onBack,
        onClose,
        extraInfo: <Box>Total Estimate: {formatter.format(estimate)}</Box>,
        buttonName,
      }}
    />
  );
};

export default SyndicateFormBlueSky;
