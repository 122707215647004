import React, { useEffect } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
  useHistory,
} from 'react-router-dom';
import AuthenticatedRoute from './components/Route/AuthenticatedRoute';
import Blank from './layouts/Blank';
import Theme from 'views/Theme/Theme';
import CreateGroup from 'views/CreateGroup/CreateGroup';
import SignIn from 'views/SignIn/SignIn';
import GroupRoutes from 'components/Route/GroupRoutes';

import AcceptInvitation from 'views/Profile/AcceptInvitation';
import ResetPassword from 'views/SignIn/ResetPassword';
import SignUpConfirmation from 'views/SignIn/SignUpConfirmation';
import UserRoutes from 'views/UserDashboard/UserRoutes';

const ScrollToTop = () => {
  const history = useHistory();
  useEffect(() => {
    const watcher = history.listen(() => {
      window.scrollTo(0, 0);
    });
    return () => {
      watcher();
    };
  }, [history]);

  return null;
};

const Routes = () => {
  return (
    <Router>
      <ScrollToTop />
      <Switch>
        <AuthenticatedRoute
          isPublic
          exact
          path={'/accept-invitation'}
          component={AcceptInvitation}
          layout={Blank}
        />

        <AuthenticatedRoute
          isPublic
          path="/reset-forgot-password"
          component={ResetPassword}
        />
        <AuthenticatedRoute isPublic path="/sign-in" component={SignIn} />
        <AuthenticatedRoute
          isPublic
          path="/sign-up-confirmation"
          component={SignUpConfirmation}
        />
        <AuthenticatedRoute isPublic path="/theme" component={Theme} />
        <AuthenticatedRoute
          isPublic
          path="/create-group"
          component={CreateGroup}
        />
        <AuthenticatedRoute
          isPublic
          path="/group-create"
          component={CreateGroup}
        />
        <AuthenticatedRoute
          isPublic
          path="/group/create"
          component={CreateGroup}
          exact
        />
        <Route path="/user/*" component={UserRoutes} />
        <Route path="/user" component={UserRoutes} />
        <Route path="/:groupSlug/*" component={GroupRoutes} />
        <Route path="/:groupSlug" component={GroupRoutes} />

        <Redirect from="/" to="/user/dashboard" />
      </Switch>
    </Router>
  );
};

export default Routes;
