import React, { useState } from 'react';
import { Box, Grid, Typography } from '@material-ui/core';

import { validateForm } from 'components/Form/SaveFormButton';
import Form from 'components/Form/Form';

import { FormikTextInput } from 'components/ui/CustomTextField';

import FormWizard from './FormWizard';
import { FormikCurrencyInput } from 'components/ui/CustomCurrencyInput';
import * as yup from 'yup';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'decimal',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const useSyndicateFormUnitPricing = ({ data }) => {
  const { dateFiledReviewed } = data;
  const [formRef, setFormRef] = useState({});
  const validate = async () => {
    const {
      values: { unitPrice, minimumUnits },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        unitPrice,
        minimumUnits,
      },
    };
  };

  const form = (
    <Form initialValues={data} enableReinitialize setRef={setFormRef}>
      {({ values }) => {
        const { unitPrice, minimumUnits } = values;
        const minimumInvestment =
          parseFloat(unitPrice) * parseFloat(minimumUnits);
        return (
          <>
            <Box marginTop={2}>
              <Typography variant="subtitle1" style={{ fontWeight: 'bold' }}>
                What is the investment amount per investor for this Syndicate?
              </Typography>
            </Box>
            <Box marginTop={2}>
              <Typography variant="subtitle2">
                Set the price per unit for investors to invest in the syndicate.
                As well as the minimum number of units the investor needs to
                purchase for their investment to be accepted.
              </Typography>
            </Box>
            <Box marginTop={4}>
              <Grid container spacing={3}>
                <Grid item>
                  <FormikCurrencyInput
                    name="unitPrice"
                    label="Price Per Unit"
                    variant="filled"
                    required
                    validation={yup.number().moreThan(0)}
                    disabled={!!dateFiledReviewed}
                  />
                </Grid>
                <Grid item>
                  <Box fontSize={36}>x</Box>
                </Grid>
                <Grid item>
                  <FormikTextInput
                    name="minimumUnits"
                    label="Minimum Units"
                    variant="filled"
                    type="number"
                    defaultValue={1}
                    required
                    validation={yup.number().integer().moreThan(0)}
                  />
                </Grid>
                <Grid item>
                  {' '}
                  <Box fontSize={36}>=</Box>
                </Grid>
                <Grid item md={3}>
                  <Box>
                    <Typography variant="subtitle2">
                      Minimum Investment Amount
                    </Typography>
                  </Box>
                  <Box fontSize={36} lineHeight={1}>
                    ${formatter.format(minimumInvestment || 0)}
                  </Box>
                </Grid>
              </Grid>
            </Box>
          </>
        );
      }}
    </Form>
  );

  return { form, validate };
};

const SyndicateFormUnitPricing = ({
  data,
  onNext,
  onBack,
  onClose,
  buttonName,
}) => {
  const { form, validate } = useSyndicateFormUnitPricing({ data });
  return (
    <FormWizard
      {...{
        form,
        validate,
        onNext,
        onBack,
        onClose,
        buttonName,
      }}
    />
  );
};

export default SyndicateFormUnitPricing;
