import React, { useEffect, useState } from 'react';
import { Box, Grid, Link, Tab, Tabs, Typography } from '@material-ui/core';
import userInvestmentsClient from 'api/userInvestmentsClient';
import getServerResponseErrors from 'api/getServerResponseErrors';
import { LoadingSpinner } from 'components/ui/Loading';

import SwapHorizIcon from '@material-ui/icons/SwapHoriz';
import DisplayAddress from 'components/ui/DisplayAddress';
import useSyndicateDocumentPoll from 'hooks/useSyndicateDocumentPoll';

var formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',

  // These options are needed to round to whole numbers if that's what you want.
  minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
});

const InvestmentInstructions = ({ userInvestment, instructions }) => {
  const [documents, setDocuments] = useState();

  const [transferType, set_transferType] = useState('wire');
  const { id, investmentCode, subscription } = userInvestment || {};

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.documents({ id });
        // save affirm
        setDocuments(response.data);
      } catch (e) {
        const error = getServerResponseErrors(e);
        console.log(error);
      }
    };
    if (id && subscription) {
      fetchData();
    } else {
      setDocuments(null);
    }
  }, [id, subscription]);

  const { paymentCheck, paymentWire, amount } = instructions;
  const { accountNumber, routingNumber, bankAddress, recipientAddress } =
    paymentWire || {};
  const { checkRecipient, mailingAddress } = paymentCheck || {};

  if (!subscription) {
    return null;
  }

  return (
    <Box bgcolor="white" padding={2} marginTop={2}>
      <Box>
        <Grid container spacing={2} alignItems="center">
          <Grid item>
            <SwapHorizIcon fontSize="large"></SwapHorizIcon>
          </Grid>
          <Grid item>
            <Typography variant="h6">Investment Instructions</Typography>
          </Grid>
        </Grid>
        <hr></hr>
      </Box>
      <Grid container spacing={4}>
        <Grid item sm={12} md={8}>
          <Box>
            <Tabs
              value={transferType}
              indicatorColor="primary"
              textColor="primary"
              onChange={(e, val) => set_transferType(val)}
              aria-label="transfer type"
            >
              {paymentWire && <Tab label="Wire" value="wire" />}
              {paymentCheck && <Tab label="Check" value="check" />}
            </Tabs>
            {transferType === 'wire' && paymentWire && (
              <Box marginTop={2}>
                <Grid container spacing={1}>
                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    ABA / Routing Number
                  </Grid>
                  <Grid item xs={8}>
                    {routingNumber}
                  </Grid>
                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Receiving Bank
                  </Grid>
                  <Grid item xs={8}>
                    <DisplayAddress {...{ address: bankAddress }} />
                  </Grid>

                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Account Number
                  </Grid>
                  <Grid item xs={8}>
                    {accountNumber}
                  </Grid>
                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Recipient
                  </Grid>
                  <Grid item xs={8}>
                    <DisplayAddress {...{ address: recipientAddress }} />
                  </Grid>
                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Reference Code
                  </Grid>
                  <Grid item xs={8}>
                    {investmentCode}
                  </Grid>
                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Amount
                  </Grid>
                  <Grid item xs={8}>
                    {formatter.format(parseFloat(amount))}
                  </Grid>
                </Grid>
              </Box>
            )}
            {transferType === 'check' && paymentCheck && (
              <Box marginTop={2}>
                <Grid container spacing={1}>
                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Checks Payable To
                  </Grid>
                  <Grid item xs={8}>
                    {checkRecipient}
                  </Grid>

                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Mailing Address
                  </Grid>
                  <Grid item xs={8}>
                    <DisplayAddress {...{ address: mailingAddress }} />
                  </Grid>
                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Memo / Reference
                  </Grid>
                  <Grid item xs={8}>
                    {investmentCode}
                  </Grid>
                  <Grid item xs={4} style={{ fontWeight: 'bold' }}>
                    Amount
                  </Grid>
                  <Grid item xs={8}>
                    {formatter.format(parseFloat(amount))}
                  </Grid>
                </Grid>
              </Box>
            )}
          </Box>
        </Grid>
        <Grid item md={4} sm={12}>
          <Box marginTop={2}>
            <Typography>Signed Agreements</Typography>
          </Box>
          <Box marginTop={2}>
            {!documents || documents.length === 0 ? (
              <Box>
                <Typography>Loading Documents</Typography>
                <LoadingSpinner></LoadingSpinner>
              </Box>
            ) : (
              <Grid container spacing={2} direction="column">
                {documents &&
                  [...documents, subscription].map((doc) => (
                    <Grid item key={doc.id}>
                      <SyndicateDocument
                        {...{ data: doc, subscriptionId: subscription.id }}
                      />
                    </Grid>
                  ))}
              </Grid>
            )}
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

const SyndicateDocument = ({ data, subscriptionId }) => {
  const { id, title, dateCompleted, documentData } = data;
  const { openLink } = useSyndicateDocumentPoll({ id, data });

  return (
    <Box>
      {documentData ? (
        <Link color="primary" onClick={openLink} style={{ cursor: 'pointer' }}>
          {id == subscriptionId ? 'Signature Page' : title}
        </Link>
      ) : (
        <>
          <Link underline="none" color="primary">
            {id == subscriptionId ? 'Signature Page' : title}
          </Link>
          <Box>
            {dateCompleted ? (
              <Typography variant="caption">
                (signed and currently stored on an external system)
              </Typography>
            ) : (
              <Typography variant="caption">
                (awaiting counter-signatures)
              </Typography>
            )}
          </Box>
        </>
      )}
    </Box>
  );
};

export default InvestmentInstructions;
