import React, { useEffect, useState } from 'react';

import { Box, Button, Grid, Typography } from '@material-ui/core';

import getServerResponseErrors from 'api/getServerResponseErrors';

import InvestorTaxesList from './InvestorTaxesList';
import { LoadingSpinner } from 'components/ui/Loading';
import moment from 'moment';
import taxYearsClient from 'api/taxYearsClient';
import { useHistory, useRouteMatch } from 'react-router';
import TaxYear from './TaxYear';
import { useProfile, useSelectedGroup } from 'hooks/useAppState';

const SyndicateTaxes = ({ investment }) => {
  const subPath = `/syndicates/${investment.id}/taxes`;
  const root = window.location.pathname.replace(
    new RegExp(`${subPath}.*$`),
    '',
  );
  const path = `${root}${subPath}`;

  const { params } = useRouteMatch(`${path}/:year?`);
  const { year } = params || {};

  if (year) {
    return <TaxYear {...{ year, investment }} />;
  }

  return <SyndicateTaxDashboard {...{ investment, path }} />;
};

const SyndicateTaxDashboard = ({ investment, path }) => {
  const isSuperAdmin = useProfile((state) => state.isAdmin);
  const allowSelfFiling = useSelectedGroup((state) => state.allowSelfFiling);
  const { syndicateName, syndicateClosed, syndicateId, unitPrice, isAdmin } =
    investment;
  const finalClosingYear = moment(syndicateClosed).year();

  const history = useHistory();
  const [taxYears, setTaxYears] = useState();
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await taxYearsClient.index({ syndicateId });
        setTaxYears(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (syndicateId) {
      fetchData();
    }
  }, [syndicateId]);

  if (!taxYears) {
    return <LoadingSpinner />;
  }

  return (
    <Box>
      <Box textAlign="center">
        <Typography variant="h6" style={{ fontWeight: 'bold' }}>
          {syndicateName}
        </Typography>
        <Typography variant="caption">
          Final Closing Date:
          <span style={{ marginLeft: '1rem' }}>
            {syndicateClosed
              ? moment(syndicateClosed).format('MM-DD-yyyy')
              : 'Not Closed Yet'}
          </span>
        </Typography>
      </Box>
      <Box marginTop={2}>
        <Box>
          <Typography
            style={{ fontSize: 20, fontWeight: 700, padding: '15px 0px' }}
          >
            Syndicate Taxes
          </Typography>
        </Box>
        <Box marginTop={2}>
          <Grid container spacing={4}>
            {taxYears
              .sort((x, y) => (x.year < y.year ? -1 : 1))
              .map((tax) => {
                const canFile =
                  isSuperAdmin ||
                  (isAdmin &&
                    (allowSelfFiling || tax.dateCompleted || tax.dateState));
                return (
                  <Grid key={tax.year} item>
                    <Box
                      py={2}
                      px={4}
                      border="1px solid lightgray"
                      height="100%"
                      bgcolor="white"
                      position="relative"
                      textAlign="center"
                      borderRadius={29}
                    >
                      <Typography style={{ fontSize: '24px' }}>
                        {tax.year}
                      </Typography>
                      {canFile ? (
                        <Box marginTop={2}>
                          <Button
                            size="small"
                            onClick={() => {
                              history.push(`${path}/${tax.year}`);
                            }}
                            color="primary"
                            variant={
                              tax.dateCompleted || tax.dateState
                                ? 'outlined'
                                : 'contained'
                            }
                          >
                            {tax.dateCompleted || tax.dateState
                              ? 'View'
                              : tax.dateStarted
                              ? 'Continue'
                              : 'Start'}
                          </Button>
                        </Box>
                      ) : (
                        <Box>In Progress</Box>
                      )}
                    </Box>
                  </Grid>
                );
              })}
          </Grid>
          {taxYears.length === 0 && !isNaN(finalClosingYear) && (
            <Box marginTop={2}>
              Awaiting start of {finalClosingYear} tax season.
            </Box>
          )}
        </Box>
      </Box>

      <Box marginTop={4}>
        <InvestorTaxesList
          {...{
            unitPrice,
            syndicateId,
            syndicateName,
            finalClosingYear,
            taxYears,
          }}
        />
      </Box>
    </Box>
  );
};

export default SyndicateTaxes;
