import React, { useCallback, useEffect, useMemo, useState } from 'react';
import getServerResponseErrors from 'api/getServerResponseErrors';
import userInvestmentsClient from 'api/userInvestmentsClient';
import { useArrayUpdate } from 'hooks/useListUpdate';
import { FilterMenu } from 'components/ui/ActionsMenu';
import useDebouncedHandler from 'hooks/useDebounce';
import UserInvestmentsFilter from './UserInvestmentsFilter';
import groupUsersClient from 'api/groupUsersClient';
import groupInvestmentsClient from 'api/groupInvestmentsClient';

import moment from 'moment';

const cmp = (a, b) => {
  if (a > b) return +1;
  if (a < b) return -1;
  return 0;
};

const useDataUserInvestments = (
  investment,
  onAdd,
  dateFiledOfferingAuthorization,
  notesData,
) => {
  const {
    groupId,
    id: investmentId,
    unitPrice,
    isAdmin,
    earlyPayment,
  } = investment;

  const [data, setData] = useState();

  const [searchTerms, setSearchTerms] = useState();
  const [filterTerms, setFilters] = useState();

  const searchHandler = useCallback((val) => {
    setSearchTerms(val);
  }, []);
  const debouncedSearch = useDebouncedHandler(searchHandler, 500);

  const onUpdate = useCallback((func) => {
    setData((cache) => func(cache));
  }, []);
  const { onListUpdate } = useArrayUpdate(onUpdate);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await userInvestmentsClient.index({ investmentId });
        setData(response.data);
      } catch (e) {
        console.log(getServerResponseErrors(e));
      }
    };
    if (investmentId) {
      fetchData();
    }
  }, [investmentId]);

  const [investors, setInvestors] = useState();
  useEffect(() => {
    const fetchData = async () => {
      const response = await groupUsersClient.index({
        groupId,
      });
      setInvestors(response.data);
    };
    if (groupId && isAdmin) {
      fetchData();
    } else if (groupId) {
      setInvestors([]);
    }
  }, [groupId, isAdmin]);

  const combinedData = useMemo(() => {
    const primary = (data || []).map((x) => {
      const lastAction =
        x.dateReceived ||
        x.dateAccepted ||
        x.lastSignature ||
        x.dateReviewed ||
        x.dateInterest;
      const groupUser = (investors || []).find((gu) => gu.userId == x.userId);
      return {
        id: x.id,
        data: x,
        hasNotes: notesData?.find((y) => y == x.userId),
        investor: groupUser?.name || x.user.name,
        initials: groupUser?.initials || x.user.initials,
        interest: parseFloat(x.interestAmount),
        subscribed: x.requestedUnits * unitPrice,
        confirmed:
          x.amountReceived > 0
            ? parseFloat(x.amountReceived) - parseFloat(x.refundAmount)
            : x.acceptedUnits > 0
            ? x.acceptedUnits * unitPrice
            : -1,
        lastAction: lastAction ? moment(lastAction) : null,
      };
    });
    const addInvestor = async (investorId, updateList = true) => {
      const response = await groupInvestmentsClient.addInvestor({
        id: investmentId,
        data: { id: investorId },
      });
      const investor = response.data;
      if (updateList) {
        onListUpdate({ ...investor, added: new Date() });
        // setSearchTerms();
        setFilters();
        onAdd();
      }
      return investor;
    };
    const secondary = (investors || [])
      .filter((x) => !primary.find((y) => y.data.userId == x.userId))
      .filter((x) => x.dateConfirmed && x.dateJoined && !x.dateSuspended)
      .map((x) => {
        return {
          id: `user_${x.id}`,
          data: {
            ...x,
            id: null,
          },
          hasNotes: notesData?.find((y) => y == x.userId),
          investor: x.name,
          initials: x.initials,
          interest: 0,
          subscribed: '',
          confirmed: '',
          lastAction: null,
          isFake: x.id,
          addInvestor: (updateList = true) => addInvestor(x.id, updateList),
        };
      });

    const unordered = [...primary, ...secondary];
    const ordered = unordered.sort(
      (a, b) =>
        !a.data.added - !b.data.added ||
        cmp(b.data.added, a.data.added) ||
        !a.data.created - !b.data.created ||
        cmp(b.data.created, a.data.created) ||
        cmp(a.investor, b.investor),
    );

    return ordered;
  }, [
    investmentId,
    onListUpdate,
    data,
    unitPrice,
    investors,
    onAdd,
    notesData,
  ]);

  const filteredData = useMemo(() => {
    if (searchTerms || filterTerms) {
      return combinedData.filter((item) => {
        return (
          (!searchTerms || searchFilter(item, searchTerms.split(' '))) &&
          (!filterTerms || filterFilter(item, filterTerms, earlyPayment))
        );
      });
    } else {
      return combinedData;
    }
  }, [searchTerms, filterTerms, combinedData, earlyPayment]);

  const filterMenu = useMemo(
    () => (
      <FilterMenu
        {...{
          label: 'Filter',
          buttonProps: {
            variant: 'outlined',
            size: 'small',
            style: { color: filterTerms ? '#DC4592' : '' },
          },
        }}
      >
        {({ onClose }) => (
          <UserInvestmentsFilter
            {...{
              filterTerms,
              onChange: setFilters,
              onClose,
              dateFiledOfferingAuthorization,
            }}
          />
        )}
      </FilterMenu>
    ),
    [dateFiledOfferingAuthorization, filterTerms],
  );

  const clearFilters = useCallback(() => {
    setFilters();
    setSearchTerms();
  }, []);

  const isLoading = !data || !investors;
  return {
    isLoading,
    userInvestments: data || [],
    filteredData,
    debouncedSearch,
    filterMenu,
    clearFilters,
    onListUpdate,
    filterTerms,
    searchTerms,
  };
};

const searchFilter = (item, terms) => {
  const { investor, data } = item;
  const {
    entityName,
    user: { email },
  } = data;
  const nameMatch = terms.every((term) => {
    return investor.toLowerCase().includes(term.toLowerCase());
  });
  const emailMatch = terms.every((term) => {
    return email.toLowerCase().includes(term.toLowerCase());
  });
  const entityMatch =
    !!entityName &&
    terms.every((term) => {
      return entityName.toLowerCase().includes(term.toLowerCase());
    });
  return nameMatch || emailMatch || entityMatch;
};
const filterFilter = (item, filterTerms, earlyPayment) => {
  const {
    lastAction,
    isFake,
    data: {
      isUndecided,
      requestedUnits,
      acceptedUnits,
      interestAmount,
      subscription,
      entityType,
      amountReceived,
      noteCount,
      isAuthorizedSigner,
    },
  } = item;
  const { signatures = [], isExpired } = subscription || {};

  const managerSigned = signatures.find(
    (x) => x.memberType === 'manager',
  )?.dateSigned;
  const custodianSigned = signatures.find(
    (x) => x.memberType === 'custodian',
  )?.dateSigned;
  const investorSigned = signatures.find(
    (x) => x.memberType === 'person',
  )?.dateSigned;
  const hasCustodian = entityType == 'ira';
  const {
    activity,
    noactivity,
    notes,
    missing,
    // yes,
    no,
    // maybe,
    interested,
    blank,
    // started,
    // investor,
    manager,
    // custodian,
    completed,
    notconfirmed,
    confirmed,
    expired,
    progress,
    notinvested,
  } = filterTerms;

  const activityFilter =
    (!notes && !activity && !noactivity) ||
    (notes && noteCount > 0) ||
    (activity && lastAction) ||
    (noactivity && !lastAction);

  const interestFilter =
    (!missing && !no && !interested) ||
    // (!yes && !no && !maybe) ||
    (missing && isFake) ||
    // (yes && interestAmount > 0 && !isUndecided) ||
    (no && interestAmount < 0) ||
    (interested && (interestAmount > 0 || isUndecided));
  // (maybe && isUndecided);

  const isSigned =
    investorSigned && (!hasCustodian || isAuthorizedSigner || custodianSigned);

  const subscribedFilter =
    (!manager && !blank && !progress && !completed && !expired) ||
    // (!started && !signed && !accepted) ||
    (expired && isExpired) ||
    (blank && !isExpired && requestedUnits == 0) ||
    // (started && requestedUnits > 0 && !investorSigned && !isExpired) ||
    // (dateReviewed &&
    //   !isExpired &&
    //   ((investor && !investorSigned) ||
    //     (custodian && investorSigned && hasCustodian && !custodianSigned) ||
    //     (manager &&
    //       investorSigned &&
    //       (!hasCustodian || custodianSigned) &&
    //       !managerSigned))) ||
    (progress && !isExpired && requestedUnits > 0 && !isSigned) ||
    (manager && !isExpired && isSigned && !managerSigned) ||
    (completed && acceptedUnits > 0);

  const canCollect = acceptedUnits > 0 || (isSigned && earlyPayment);

  const investedFilter =
    (!notconfirmed && !confirmed && !notinvested) ||
    (notinvested && amountReceived == 0 && !canCollect) ||
    // (!notconfirmed && !confirmed) ||
    (notconfirmed && amountReceived == 0 && canCollect) ||
    (confirmed && amountReceived > 0);

  return activityFilter && interestFilter && subscribedFilter && investedFilter;
};
export default useDataUserInvestments;
