import { createStore, applyMiddleware, compose, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import currentUserReducer from './currentUser/reducer';

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(
  combineReducers({
    currentUser: currentUserReducer,
  }),
  {},
  composeEnhancers(applyMiddleware(thunk)),
);
export default store;
