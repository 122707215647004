import React, { useEffect, useState } from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import * as yup from 'yup';
import { useField } from 'formik';
import { Box } from '@material-ui/core';
import { Typography } from '@material-ui/core';
import { useTheme } from '@material-ui/core';

export const CustomCheckBox = (props) => {
  const {
    InputLabelProps,
    shrink = true,
    fullWidth = true,
    required,
    label,
    error,
    ...rest
  } = props;
  const theme = useTheme();

  return (
    <>
      <FormControlLabel
        control={
          <Checkbox
            {...rest}
            style={{ color: error ? theme.palette.warning.main : 'inherit' }}
          />
        }
        label={
          label ? (
            <Typography
              style={{ color: error ? theme.palette.warning.main : 'inherit' }}
            >
              {label}
              {`${required ? ' *' : ''}`}
            </Typography>
          ) : null
        }
        {...{ shrink, ...InputLabelProps }}
        fullWidth={fullWidth}
      />
      <Box>
        <Typography
          variant="caption"
          style={{ color: error ? theme.palette.warning.main : 'inherit' }}
        >
          {props.helperText}
        </Typography>
      </Box>
    </>
  );
};

// eslint-disable-next-line import/no-unused-modules
export const FormikCheckBox = ({
  validation,
  required,
  defaultValue,
  disabled,
  errorLabel,
  ...props
}) => {
  let scheme = required ? validation || yup.boolean() : validation;
  if (required) {
    scheme = scheme
      .required(`${errorLabel ? errorLabel : 'Field'} is required`)
      .nullable();
  }
  const validate = async (value) => {
    try {
      await scheme.validate(value);
    } catch (err) {
      return err.message;
    }
  };
  const [field, meta, helpers] = useField(
    scheme ? { ...props, validate } : props,
  );
  const { value, error, touched } = meta;
  const { setValue, setError } = helpers;

  const [once, setOnce] = useState(0);
  useEffect(() => {
    if (defaultValue && once === 0) {
      setOnce((x) => x + 1);
      if (!value) {
        setValue(defaultValue);
      }
    }
  }, [defaultValue, value, setValue, once]);

  useEffect(() => {
    if (disabled && error) {
      setError(null);
    }
  }, [disabled, error, setError]);

  return (
    <CustomCheckBox
      error={error && touched}
      helperText={error && touched ? error : null}
      variant="filled"
      required={required}
      disabled={disabled}
      checked={value}
      {...field}
      {...props}
    />
  );
};
