import React, { useState } from 'react';
import { Box, Button, Grid, Typography } from '@material-ui/core';

import { CustomCheckBox } from 'components/ui/CustomCheckBox';

const UserInvestmentsFilter = ({
  filterTerms = {},
  onChange,
  onClose,
  dateFiledOfferingAuthorization,
}) => {
  const handleClear = () => {
    onChange(undefined);
    onClose();
  };
  const handleApply = () => {
    if (
      !activity &&
      !noactivity &&
      !notes &&
      !missing &&
      !interested &&
      // !yes &&
      !no &&
      // !maybe &&
      !blank &&
      // !started &&
      // !investor &&
      !manager &&
      // !custodian &&
      !completed &&
      !notconfirmed &&
      !confirmed &&
      !expired &&
      !progress &&
      !notinvested
    ) {
      handleClear();
    } else {
      onChange({
        activity,
        noactivity,
        notes,
        missing,
        // yes,
        interested,
        no,
        // maybe,
        blank,
        // started,
        // investor,
        manager,
        // custodian,
        completed,
        notconfirmed,
        confirmed,
        expired,
        progress,
        notinvested,
      });
      onClose();
    }
  };

  const [notes, setNotes] = useState(filterTerms.notes);
  const [activity, setActivity] = useState(filterTerms.activity);
  const [noactivity, setNoActivity] = useState(filterTerms.noactivity);
  const [missing, setMissing] = useState(filterTerms.missing);
  // const [yes, setYes] = useState(filterTerms.yes);
  const [no, setNo] = useState(filterTerms.no);
  const [interested, setInterested] = useState(filterTerms.interested);
  // const [maybe, setMaybe] = useState(filterTerms.maybe);
  const [blank, setBlank] = useState(filterTerms.blank);
  // const [started, setStarted] = useState(filterTerms.started);
  // const [investor, setInvestor] = useState(filterTerms.investor);
  const [manager, setManager] = useState(filterTerms.manager);
  // const [custodian, setCustodian] = useState(filterTerms.custodian);
  const [completed, setCompleted] = useState(filterTerms.completed);
  const [notconfirmed, setNotConfirmed] = useState(filterTerms.notconfirmed);
  const [confirmed, setConfirmed] = useState(filterTerms.confirmed);
  const [expired, setExpired] = useState(filterTerms.expired);
  const [progress, setProgress] = useState(filterTerms.progress);
  const [notinvested, setNotInvested] = useState(filterTerms.notinvested);

  return (
    <Box padding={2}>
      <Box padding={1}>
        <Grid container spacing={4}>
          <Grid item>
            <Box fontWeight="bold">Activity</Box>
            <Box>
              <CustomCheckBox
                label={<Typography variant="subtitle1">No Activity</Typography>}
                checked={noactivity}
                onChange={() => setNoActivity((x) => !x)}
              />
            </Box>
            <Box>
              <CustomCheckBox
                label={
                  <Typography variant="subtitle1">Has Activity</Typography>
                }
                checked={activity}
                onChange={() => setActivity((x) => !x)}
              />
            </Box>
            <Box>
              <CustomCheckBox
                label={<Typography variant="subtitle1">Has Notes</Typography>}
                checked={notes}
                onChange={() => setNotes((x) => !x)}
              />
            </Box>
          </Grid>
          <Grid item>
            <Box fontWeight="bold">Interested</Box>
            <Box>
              <CustomCheckBox
                label={
                  <Typography variant="subtitle1">Not Expressed</Typography>
                }
                checked={missing}
                onChange={() => setMissing((x) => !x)}
              />
            </Box>
            <Box>
              <CustomCheckBox
                label={<Typography variant="subtitle1">Interested</Typography>}
                checked={interested}
                onChange={() => setInterested((x) => !x)}
              />
            </Box>
            {/* <Box>
              <CustomCheckBox
                label={<Typography variant="subtitle1">Maybe</Typography>}
                checked={maybe}
                onChange={() => setMaybe((x) => !x)}
              />
            </Box> */}
            <Box>
              <CustomCheckBox
                label={<Typography variant="subtitle1">Passed</Typography>}
                checked={no}
                onChange={() => setNo((x) => !x)}
              />
            </Box>
          </Grid>
          {dateFiledOfferingAuthorization && (
            <>
              <Grid item>
                <Box fontWeight="bold">Proposed</Box>
                <Box>
                  <CustomCheckBox
                    label={
                      <Typography variant="subtitle1">Not Proposed</Typography>
                    }
                    checked={blank}
                    onChange={() => setBlank((x) => !x)}
                  />
                </Box>
                <Box>
                  <CustomCheckBox
                    label={
                      <Typography variant="subtitle1">Progressing</Typography>
                    }
                    checked={progress}
                    onChange={() => setProgress((x) => !x)}
                  />
                </Box>
                <Box>
                  <CustomCheckBox
                    label={<Typography variant="subtitle1">Accept</Typography>}
                    checked={manager}
                    onChange={() => setManager((x) => !x)}
                  />
                </Box>
                <Box>
                  <CustomCheckBox
                    label={
                      <Typography variant="subtitle1">Accepted</Typography>
                    }
                    checked={completed}
                    onChange={() => setCompleted((x) => !x)}
                  />
                </Box>
                <Box>
                  <CustomCheckBox
                    label={<Typography variant="subtitle1">Expired</Typography>}
                    checked={expired}
                    onChange={() => setExpired((x) => !x)}
                  />
                </Box>
              </Grid>
              <Grid item>
                <Box fontWeight="bold">Invested</Box>
                <Box>
                  <CustomCheckBox
                    label={
                      <Typography variant="subtitle1">Not Invested</Typography>
                    }
                    checked={notinvested}
                    onChange={() => setNotInvested((x) => !x)}
                  />
                </Box>
                <Box>
                  <CustomCheckBox
                    label={<Typography variant="subtitle1">Collect</Typography>}
                    checked={notconfirmed}
                    onChange={() => setNotConfirmed((x) => !x)}
                  />
                </Box>
                <Box>
                  <CustomCheckBox
                    label={
                      <Typography variant="subtitle1">Collected</Typography>
                    }
                    checked={confirmed}
                    onChange={() => setConfirmed((x) => !x)}
                  />
                </Box>
              </Grid>
            </>
          )}
        </Grid>
      </Box>
      <Box marginTop={1}>
        <Grid container justify="space-between">
          <Grid item></Grid>
          <Grid item>
            <Button color="primary" onClick={handleClear}>
              Clear Filters
            </Button>
            <Button
              color="primary"
              style={{ marginLeft: '2rem' }}
              onClick={handleApply}
              variant="contained"
            >
              Apply Filters
            </Button>
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default UserInvestmentsFilter;
