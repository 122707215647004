import React, { useState } from 'react';

import Form from 'components/Form/Form';

import { validateForm } from 'components/Form/SaveFormButton';

import { FormikCurrencyInput } from 'components/ui/CustomCurrencyInput';

const useFormTargetAmount = ({ data }) => {
  const [formRef, setFormRef] = useState({});

  const validate = async () => {
    const {
      values: { targetAmount },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        targetAmount,
      },
    };
  };

  const form = (
    <Form initialValues={data} enableReinitialize setRef={setFormRef}>
      <FormikCurrencyInput
        variant="filled"
        name="targetAmount"
        label="Targeted Amount"
        fullWidth
        style={{ maxWidth: '20rem' }}
      />
    </Form>
  );

  return { form, validate };
};

export default useFormTargetAmount;
