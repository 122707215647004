import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Box, Grid, Hidden, MenuItem } from '@material-ui/core';
import * as yup from 'yup';
import { FormikCurrencyInput } from 'components/ui/CustomCurrencyInput';
import Form from 'components/Form/Form';

import SaveFormButton, { validateForm } from 'components/Form/SaveFormButton';

import { FormikTextInput } from 'components/ui/CustomTextField';
import { FormikDatePicker } from 'components/ui/CustomDatePicker';

import { CustomSelect, FormikSelect } from 'components/ui/CustomSelect';

import getServerResponseErrors from 'api/getServerResponseErrors';
import roundsClient from 'api/roundsClient';
import Button from 'components/ui/Button/Button';
import { DialogWithActions } from 'components/ui/DialogWithTitle';

const useFormRound = ({ data }) => {
  const { dateClosed } = data || {};
  const [checked, setChecked] = useState();
  const [formRef, setFormRef] = useState({});
  useEffect(() => {
    setChecked(dateClosed ? 1 : 0);
  }, [dateClosed]);

  const validate = async () => {
    const {
      values: {
        name,
        investmentType,
        premoneyValuation,
        targetAmount,
        raisedAmount,
        dateClosed,
        investmentOther,
        investmentCap,
        investmentDiscount,
        investmentInterest,
      },
      errors,
    } = await validateForm(formRef);

    return {
      errors,
      values: {
        name,
        investmentType,
        premoneyValuation,
        targetAmount,
        raisedAmount,
        dateClosed: checked ? dateClosed : null,
        investmentOther,
        investmentCap,
        investmentDiscount,
        investmentInterest,
      },
    };
  };

  const form = useMemo(
    () => (
      <Form initialValues={data} enableReinitialize setRef={setFormRef}>
        {({ values }) => {
          return (
            <>
              <Box>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormikTextInput
                      variant="filled"
                      name="name"
                      label="Name"
                      required
                      placeholder="Series A"
                    />
                  </Grid>
                  <Hidden smDown>
                    <Grid item md={8}></Grid>
                  </Hidden>
                  <Grid item xs={12} sm={6} md={4}>
                    <CustomSelect
                      variant="filled"
                      label="Round Status"
                      required
                      value={checked ? 1 : 0}
                      onChange={(e) => setChecked(!!e.target.value)}
                    >
                      <MenuItem key="previous" value={1}>
                        Previous Round
                      </MenuItem>
                      <MenuItem key="open" value={0}>
                        Open Round
                      </MenuItem>
                    </CustomSelect>
                    {/* <CustomCheckBox
                      label="Round has closed"
                      checked={checked}
                      onChange={() => setChecked((x) => !x)}
                      InputLabelProps={{
                        style: { marginTop: '1rem' },
                      }}
                    /> */}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    {checked ? (
                      <Box>
                        <FormikDatePicker
                          inputVariant="filled"
                          name="dateClosed"
                          label="Date Closed"
                          required
                        />
                      </Box>
                    ) : (
                      <FormikCurrencyInput
                        variant="filled"
                        name="targetAmount"
                        label="Raise Amount"
                        defaultValue="0"
                        validation={yup.number().min(1)}
                        required
                      />
                    )}
                  </Grid>
                  <Grid item xs={12} sm={6} md={4}>
                    <FormikCurrencyInput
                      variant="filled"
                      name="raisedAmount"
                      label={
                        checked ? 'Amount Raised' : 'Amount Already Raised'
                      }
                      defaultValue="0"
                      validation={yup.number().min(0)}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={4}>
                    <FormikSelect
                      name="investmentType"
                      variant="filled"
                      label="Investment Type"
                      required
                    >
                      <MenuItem value="preferred">Preferred Stock</MenuItem>
                      <MenuItem value="common">Common Stock</MenuItem>
                      <MenuItem value="safe">SAFE</MenuItem>
                      {values.investmentType === 'note' && (
                        <MenuItem value="note">Convertible Note</MenuItem>
                      )}
                      <MenuItem value="other">Other</MenuItem>
                    </FormikSelect>
                  </Grid>
                  {values.investmentType === 'other' && (
                    <Grid item xs={12} sm={6} md={8}>
                      <FormikTextInput
                        variant="filled"
                        name="investmentOther"
                        label="Other Type"
                        required
                      />
                    </Grid>
                  )}
                  {(values.investmentType == 'preferred' ||
                    values.investmentType == 'common') && (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikCurrencyInput
                        variant="filled"
                        name="premoneyValuation"
                        label="Pre Money Valuation"
                        defaultValue="0"
                        validation={yup.number().min(0)}
                        required
                      />
                    </Grid>
                  )}
                  {(values.investmentType === 'note' ||
                    values.investmentType === 'safe') && (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikCurrencyInput
                        variant="filled"
                        name="investmentCap"
                        label="Cap"
                        defaultValue="0"
                        validation={yup.number().min(0)}
                        helperText="0 if not applicable"
                        required
                      />
                    </Grid>
                  )}
                  {(values.investmentType === 'note' ||
                    values.investmentType === 'safe') && (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikTextInput
                        variant="filled"
                        name="investmentDiscount"
                        label="Discount %"
                        defaultValue="0"
                        validation={yup.number().min(0)}
                        helperText="0 if not applicable"
                        type="number"
                        required
                      />
                    </Grid>
                  )}
                  {values.investmentType === 'note' && (
                    <Grid item xs={12} sm={6} md={4}>
                      <FormikTextInput
                        variant="filled"
                        name="investmentInterest"
                        label="Interest %"
                        defaultValue="0"
                        validation={yup.number().min(0)}
                        helperText="0 if not applicable"
                        type="number"
                        required
                      />
                    </Grid>
                  )}
                </Grid>
              </Box>
            </>
          );
        }}
      </Form>
    ),
    [checked, data],
  );

  return { form, validate };
};

export const useEditRound = ({ opportunityId, onChange }) => {
  const [modal, showModal] = useState();
  const { form, validate } = useFormRound({
    data: modal,
  });

  const onSave = useCallback(
    async (onError) => {
      try {
        const { values, errors } = await validate();
        if (errors.length > 0) {
          onError(errors);
          return false;
        }

        const upsert = modal?.id ? roundsClient.update : roundsClient.create;
        const response = await upsert({
          id: modal?.id,
          opportunityId,
          data: values,
        });
        onChange(response.data);
        setTimeout(() => {
          showModal(false);
        }, 500);
      } catch (e) {
        const error = getServerResponseErrors(e);
        onError(error);
        return false;
      }
      return true;
    },
    [modal?.id, onChange, opportunityId, validate],
  );

  const modalElement = useMemo(() => {
    const footer = (
      <Box padding={2}>
        <Grid container justify="space-between" spacing={3}>
          <Grid item>
            <Button variant="contained" onClick={() => showModal()}>
              Cancel
            </Button>
          </Grid>
          <Grid item>
            <SaveFormButton
              onSave={onSave}
              name={modal?.id ? 'Save' : 'Add Round'}
            ></SaveFormButton>
          </Grid>
        </Grid>
      </Box>
    );

    return modal ? (
      <DialogWithActions
        {...{
          title: `${modal.id ? 'Edit' : 'Add'} Round`,
          // header,
          footer,
          onClose: () => showModal(),
          fullWidth: true,
        }}
      >
        <Box>{form}</Box>
      </DialogWithActions>
    ) : null;
  }, [form, modal, onSave]);

  return { modal: modalElement, showModal, form, onSave };
};
