import PropTypes from 'prop-types';
import React from 'react';
import { Route, withRouter } from 'react-router-dom';
import Blank from 'layouts/Blank';
import { useProfile } from 'hooks/useAppState';
import SignIn from 'views/SignIn/SignIn';
import UserSetup from 'views/Profile/UserSetup';

const AuthenticatedRoute = ({
  layout: Layout,
  component: Component,
  isPublic,
  ...rest
}) => {
  const { isValid, isSetup } = useProfile((state) => ({
    isValid: state.isValid,
    isSetup: state.isSetup,
  }));

  if (!isValid && !isPublic) {
    return (
      <Route {...rest}>
        <Blank>
          <SignIn />
        </Blank>
      </Route>
    );
  } else if (isValid && !isSetup) {
    return (
      <Route {...rest}>
        <Blank>
          <UserSetup />
        </Blank>
      </Route>
    );
  }

  return (
    <Route
      {...rest}
      exact
      render={(matchProps) => (
        <Layout>
          <Component {...matchProps} />
        </Layout>
      )}
    />
  );
};

AuthenticatedRoute.defaultProps = {
  layout: Blank,
  isPublic: false,
  authRedirect: '/sign-in',
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.any,
  history: PropTypes.shape({
    push: PropTypes.func,
  }),
  layout: PropTypes.any,
  location: PropTypes.shape({
    pathname: PropTypes.any,
  }),
  isPublic: PropTypes.bool,
  authRedirect: PropTypes.string,
};

export default withRouter(AuthenticatedRoute);
